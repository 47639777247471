<template>
  <v-card class="note-form-container">
    <div class="d-flex flex-col justify-center  align-center text-center text-h6 text-primary font-weight-bold ">
      <h5 class="text-h5 text-center text-onSurfaceVar font-weight-black w-100 py-2 bg-surfContainerHigh">Add
        Note</h5>
      <patient-id-card class="w-100 px-2" v-if="patientProfile.patientInfo"
        :patient-info="patientProfile.patientInfo" />
      <v-spacer />
    </div>
    <v-card-text>
      <v-label>Note:</v-label>
      <QuillEditor v-model="quillText" :style="{ height: '200px' }" theme="snow" :options="editorOptions"
        @ready="onEditorReady($event)" @textChange="extractContent" v-model:content="content" />

    </v-card-text>
    <v-checkbox class="ml-4" v-model="showAssignTaskForm" label="Assign Task" color="primary"
      @update:model-value="checkTaskFormFilled" hide-details></v-checkbox>
    <div v-if="showAssignTaskForm">
      <NotesTaskForm :is-assign-task-form-open="true" :user-handler="userHandler" ref="taskAssigningForm"
        :task-queue-loader="taskQueueLoader" :list-assignees="listAssignees" :assign-task-handler="assignTaskHandler"
        @assign-task-form-data-changed="handleAssignTaskFormDataChanged" @is-valid-form="isValidTaskForm" />
    </div>
    <v-card-actions class="mr-4 mb-3">
      <v-spacer></v-spacer>
      <v-btn color="primary" class="px-8" variant="tonal" rounded="false" @click="dialogVisible">Cancel</v-btn>
      <v-btn color="primary" class="px-8" variant="flat" rounded="false" @click="saveNote"
        :disabled="isInvalidContent || isTaskValidForm">Save Note</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts" setup>
import { PropType, ref, watch } from "vue";
import { QuillEditor } from '@vueup/vue-quill';
import { AssignTaskInput, NoteInputType, PatientNotes } from "@/interfaces/notes.interface";
import { IUser } from "@/interfaces/user.interface";
import { IAssigneeInput, IAssigneeItem, ITaskQueueItem } from "@/interfaces/task.interface";
import { IPatientProfileProps } from "@/interfaces/patient.interface";
import { extractTextFromQuill, formatDate, } from "@/composables/FormUtility";
import { useEventBus } from "@vueuse/core";
import { noteTaskListEventBusKey } from "@/events/bus-keys/note-task-events.bus-keys";
import NotesTaskForm from '../NotesTaskForm.vue';
import PatientIdCard from '@/components/patient/profile/PatientIdCard.vue';
import moment from "moment";

const props = defineProps({
  userHandler: {
    type: Function as PropType<() => IUser>,
    required: true,
  },
  taskQueueLoader: {
    type: Function as PropType<() => Promise<ITaskQueueItem[]>>,
    required: true,
  },
  listAssignees: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
  assignTaskHandler: {
    type: Function as PropType<({ noteId, taskInput }: { noteId: string, taskInput: AssignTaskInput }) => Promise<PatientNotes>>,
    required: true,
  },
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
});

const { userHandler, patientProfile } = props;
const emits = defineEmits(['saveNote', 'close']);


const quillText = ref('');
const quillEditorInstance = ref();
const content = ref();
const isQuillEditorEmpty = ref(true);

const editorOptions = {
  modules: {
    toolbar: false,
  },
};

const onEditorReady = (editor: null) => {
  quillEditorInstance.value = editor;
};

const extractContent = (content: any) => {
  isQuillEditorEmpty.value = content.delta.ops[0].delete;
};

const dialogVisible = () => {
  quillEditorInstance.value.setContents([]);
  emits('close');
  showAssignTaskForm.value = false;
};

const noteTaskActionEventBus = useEventBus(noteTaskListEventBusKey);
const showAssignTaskForm = ref(false);
const isTaskValidForm = ref(false);
const taskAssigningForm = ref();
const isInvalidContent = ref(true);

const taskAssignFormData = ref({
  title: '',
  assignees: [],
  taskQueue: '',
  priority: '',
  dueDate: '',
  description: '',
});

const checkTaskFormFilled = (data: boolean | null) => isValidTaskForm(!data);

const isValidTaskForm = (isValidForm: boolean) => {
  const isInvalidFormAndTaskFormVisible = !isValidForm && showAssignTaskForm.value;
  const isEmptyEditorAndInvalidFormAndTaskFormVisible = isQuillEditorEmpty.value && !isValidForm && showAssignTaskForm.value;
  const isEmptyEditorAndTaskFormHidden = isQuillEditorEmpty.value && !showAssignTaskForm.value;

  isTaskValidForm.value = isInvalidFormAndTaskFormVisible || isEmptyEditorAndInvalidFormAndTaskFormVisible || isEmptyEditorAndTaskFormHidden;
}

const handleAssignTaskFormDataChanged = (data: any) => {
  const { title, assignees, taskQueue, priority, dueDate, description } = data;
  const formattedDueDate = moment(dueDate, 'MMM/DD/YYYY, h:mm A').toISOString();
  const taskAssignees = assignees.map((assignee: IAssigneeItem) => assignee.value?.id);
  taskAssignFormData.value = {
    title,
    assignees: taskAssignees,
    taskQueue,
    priority,
    ...(dueDate && { dueDate: formattedDueDate }),
    description,
  };
};


const saveNote = async () => {
  const patientId = patientProfile.patientId as string;
  const noteContent = extractTextFromQuill(quillEditorInstance.value.getContents());

  const { firstName, lastName, emailAddress, userId } = await userHandler();
  const {
    title,
    assignees,
    taskQueue,
    priority,
    dueDate,
  } = taskAssignFormData.value;

  let saveNotesValidation = true;

  if (showAssignTaskForm.value) {
    const saveNotesValidationResult = await taskAssigningForm.value.$refs.taskAssigningForm.validate();
    saveNotesValidation = saveNotesValidationResult.valid;
  }

  if (saveNotesValidation) {

    const input: NoteInputType = {
      mpi: patientId,
      noteContext: {
        id: patientId,
        type: 'PATIENT_PROFILE',
      },
      content: {
        title: '',
        contentType: 'TEXT',
        message: noteContent,
      },
      author: {
        firstName: firstName,
        lastName: lastName,
        email: emailAddress,
        userId: userId,
      },
    };

    if (showAssignTaskForm.value && taskQueue) {
      input.task = {
        taskQueueId: taskQueue,
        task: {
          assignees,
          description: noteContent,
          dueDate: dueDate,
          priority: priority,
          title: title,
          requestorId: patientId,
        },
      };
    }

    emits('saveNote', input);
    emits('close');
    quillText.value = '';
    quillEditorInstance.value.setContents([]);
  }
};

watch(content, (newContent: { ops: { insert: string }[] }) => {
  isInvalidContent.value = newContent.ops.every((op: { insert: string; }) => op.insert.trim() === '');
})

</script>
<style>
.note-form-container {
  overflow-y: visible;
}
</style>
