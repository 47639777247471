<template>
  <v-card>
    <v-data-table :items="items" :headers="headers" :hide-default-footer="true" :header-props="{ class: 'bg-surface'}">
      <template v-slot:item="{ item }">
        <tr :class="`bg-${rowClass(item.pulseCriteria)}`">
          <td>{{ item.pulseCriteria }}</td>
          <td>{{ item.pulseRange }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script lang="ts" setup>
import { defineProps, watch, PropType, ref, computed } from 'vue';
import { IVitalConfigThresholds } from '@/interfaces/IVitalConfigure';

const props = defineProps({
  pulseValuesRange: {
    type: Object as PropType<IVitalConfigThresholds>,
    required: true,
  },
});

const pulseVitalRange = ref<IVitalConfigThresholds>();

const headers = [
  { title: 'Risk Category', value: 'pulseCriteria' },
  { title: 'Pulse bpm', value: 'pulseRange' },
]

watch(() => props.pulseValuesRange, (newValue: IVitalConfigThresholds) => {
  pulseVitalRange.value = newValue;
})

const items = computed(() => {

  return[
  {
    pulseCriteria: 'Normal',
    pulseRange: pulseVitalRange.value?.normal,
  },
  {
    pulseCriteria: 'Warning',
    pulseRange: `${pulseVitalRange.value?.warningMin},   ${pulseVitalRange.value?.warningMax}`,
  },
  {
    pulseCriteria: 'Critical',
    pulseRange: `${pulseVitalRange.value?.criticalMin},   ${pulseVitalRange.value?.criticalMax}`,
  },
  ]
})

const rowClass = (pulseCriteria: string) => {
  switch (pulseCriteria) {
    case 'Normal':
      return 'success';
    case 'Warning':
      return 'warning';
    case 'Critical':
      return 'error';
  }
};

</script>
<style scoped>
</style>
