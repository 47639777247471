<template>
  <v-card :class="$vuetify.display.smAndUp ? 'parentContainer' : 'width-90%'" color="surfContainer">
    <div class="text-center">
      <v-card-text class="font-weight-bold text-h5 py-8 text-primary" :style="{ color: primaryColor }">
        {{ isEditForm ? 'Edit' : 'Add' }} Patient
      </v-card-text>
    </div>
    <FormLoader v-if="loading" />
    <v-form v-else ref="patientForm" @submit.prevent="handlePatientCreationOrEdit" id="patientForm">
      <v-container>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.firstName" :label="inputFirstNameLabel" :rules="[
              (value) => isValidName(value) || 'Enter valid first name',
              (value) => isNotEmpty(value) || 'First name is required',
            ]" required density="compact" variant="outlined" :hint="inputFirstNameHint" class="text-left"
              :color="primaryColor"></v-text-field>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.middleName" :label="inputMiddleNameLabel" :rules="[
              (value) => !value || isValidName(value) || 'Enter valid middle  name',
            ]" density="compact" variant="outlined" class="text-left" :color="primaryColor"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.lastName" :label="inputLastNameLabel" :rules="[
              (value) => isValidName(value) || 'Enter valid last name',
              (value) => isNotEmpty(value) || 'Last name is required',
            ]" required density="compact" variant="outlined" :hint="inputLastNameHint" class="text-left"
              :color="primaryColor"></v-text-field>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.dob" :label="inputDOBLabel" v-maska="dateValidationMask" required
              density="compact" variant="outlined" :hint="inputDOBHint" placeholder="mm/dd/yyyy" class="text-left"
              :color="primaryColor" :rules="dateRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-select v-model="form.gender" :items="genderOptions" :label="inputGenderLabel"
              :rules="[(v: any) => !!v || 'Gender is required']" required density="compact" variant="outlined"
              :hint="inputGenderHint" class="text-left" :color="primaryColor"></v-select>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.email" :label="inputEmailLabel" type="email" required density="compact"
              variant="outlined" :loading="isLoading" @input="checkEmailAlreadyExists" :rules="emailValidationRules"
              :hint="inputEmailHint" class="text-left" :color="primaryColor"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-text-field v-model="form.phoneNumber" :label="inputPhoneNumberLabel" :rules="[
              (v: any) => !!v || 'Phone Number is required',
              (value) =>
                isValidPhoneNumber(value) || 'Enter a valid phone number',
            ]" v-maska="phoneNumberMask" required density="compact" variant="outlined" :hint="inputPhoneNumberHint"
              class="text-left" :color="primaryColor"></v-text-field>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" v-if="!orgId">
            <v-select v-model="form.organization" :items="orgOptions" :label="inputOrganizationLabel"
              :rules="[(v: any) => !!v || 'Organization is required']" @update:model-value="updateOrganization" required
              density="compact" variant="outlined" :hint="inputOrganizationHint" class="text-left"
              :color="primaryColor"></v-select>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-combobox v-model="form.provider" :items="primaryProviderOptions" :label="inputProviderLabel"
              :rules="[(v) => !!v || 'Primary provider is required', (v) => isValidProviderSelected || 'Select a valid provider']"
              required density="compact" variant="outlined" :hint="inputProviderHint" class="text-left"
              :color="primaryColor" :loading="providerLoader" item-title="title" item-value="value"
              :return-object="false" @input="loadPrimaryProviders"
              @update:modelValue="filterSecondaryProviders"></v-combobox>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <v-combobox v-model="secondaryProvider" multiple variant="outlined" dense density="compact"
              label="Secondary Provider(s)" hint="Enter surface provider" :items="secondaryProviderOptions"
              @update:search="loadSecondaryProviders" item-title="title" item-value="value" color=primary
              :loading="secondaryProviderLoader" chips closable-chips clearable
              :rules="[isSecondaryProviderValid || 'One or more invalid assignee selected']"></v-combobox>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.emrId"
              :rules="[(value) => (!value || isValidExternalId(value)) || 'Enter valid EMR ID without spaces and between 2 to 20 characters']"
              label="EMR ID" variant="outlined" density="compact" color="primary" hint="Enter EMR ID"
              class="text-left"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.addressLine1"
              :rules="[(value) => isWhitespaceOrHasContent(value) || 'Enter valid address', value => validateMaxLength(value, 50) || 'Address cannot be longer than 50 characters']"
              label="Address Line 1" variant="outlined" density="compact" color="primary" hint="Enter address line 1"
              class="text-left"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.addressLine2" label="Address Line 2" variant="outlined"
              :rules="[(value) => isWhitespaceOrHasContent(value) || 'Enter valid address', value => validateMaxLength(value, 50) || 'Address cannot be longer than 50 characters']"
              density="compact" color="primary" hint="Enter address line 2" class="text-left"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.city" label="City"
              :rules="[value => isAddressValid(value) || 'City name can only consist of alphabets, spaces, and hyphens in between.', value => value === '' || minimumLengthValidationCity(value) || 'City must have minimum three characters']"
              variant="outlined" density="compact" color="primary" hint="Enter city" class="text-left"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.state" label="State"
              :rules="[value => isAddressValid(value) || 'State name can only consist of alphabets, spaces, and hyphens in between.', value => value === '' || minimumLengthValidation(value) || 'State must have minimum two characters']"
              variant="outlined" density="compact" color="primary" hint="Enter state" class="text-left"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="form.zip" label="Zip"
              :rules="[value => !value || isValidZip(value) || 'Enter valid 5 digits zip code']" variant="outlined"
              density="compact" color="primary" hint="Enter zip" class="text-left"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col lg="2" md="2" sm="6" xs="12">
            <v-btn block rounded="false" variant="tonal" :text="primaryColor" :color="primaryColor"
              @click="closeForm">{{
                cancelButtonName }}</v-btn>
          </v-col>
          <v-col lg="2" md="2" sm="6" xs="12">
            <v-btn v-if="!isEditForm" type="submit" rounded="false" block :color="primaryColor"
              :disabled="!isFormValid || !isSecondaryProviderValid" elevation="3">{{
                submitButtonName }}</v-btn>
            <v-btn v-else type="submit" rounded="false" block :color="primaryColor"
              :disabled="!isFormValid || !isSecondaryProviderValid" elevation="3">Save Changes</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar color="green" class="text-white mt-16" v-model="showPatientEditMessage" location="top right">
      {{ successMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showPatientEditMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" class="text-white mt-16" v-model="showPatientCreatedMessage" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showPatientCreatedMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts" setup>
import { PropType, computed, inject, ref, watch } from "vue";
import { ApolloClient } from "@apollo/client";
import { CREATE_PATIENT } from "../graphql/resources/PatientRepository";
import { GET_ORGS } from '../graphql/resources/OrganizationRepository';
import { onMounted, reactive } from "vue";
import { vMaska } from "maska/vue";
import router from "../router";
import {
  isValidName,
  isNotEmpty,
  isValidEmail,
  isValidPhoneNumber,
  extractErrorMessage,
  validateMaxLength,
  isValidExternalId,
  isValidZip, isAddressValid, isWhitespaceOrHasContent, minimumLengthValidation, minimumLengthValidationCity
} from "../composables/FormUtility";
import { genderOptions } from "../enums/genderType";
import { debounce, filter, find, isEmpty, map, omit } from 'lodash';
import { useUserStore } from "@/store/modules/User";
import { IFormSelectItem } from "@/interfaces/input.interface";
import { GET_ALL_ORG_USERS } from "@/graphql/resources/OrganizationUserRepository";
import moment from "moment";
import { IPatientSubscribeProgram, SubscribeProgramUsingShortCodeInput } from "@/interfaces/econsent-template.interface";
import { Program } from "@/enums/patient-program.enum";
import hasAccess, { viewOrgInventoryRoles } from '../composables/roleAccess';
import { IOrgServiceProvider } from "@/interfaces/IOrganization";
import { patientCreated } from "@/services/common.service";
import { ShowSnackbar } from "@/enums/sanckbar-show.enum";
import { doesPatientEmailExist, getPatientByPatientId, updatePatient } from '@/services/patient.service';
import { useRoute } from "vue-router";
import { IUpdatePatientInput, ProviderOption, IProvider } from "@/interfaces/patient.interface";
import { IPatientProvider, IPatientInfo } from '../interfaces/patient.interface';
import { EXTERNAL_IDENTITIES, EXTERNAL_IDENTITY_LABELS } from "@/enums/patient.enum";
import FormLoader from "./common/FormLoader.vue";
import { encodePatientInfo } from "@/composables/patient/view-utility.composable";
import { patientDetailsEventBusKey } from '@/events/bus-keys/patient-active-event.bus-keys';
import { useEventBus } from '@vueuse/core';
import { MaskInputOptions } from "maska";

const emit = defineEmits(['patientCreated', 'patientEdited']);

const form = ref({
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  gender: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  provider: "",
  organization: "",
  emrId: "",
});

const isValidProviderSelected = computed(() => {
  return !isEmpty(primaryProviderOptions.value.filter(providerOpt => providerOpt.value === form.value.provider))
});

const isFormValid = computed(() => {
  const {
    firstName,
    middleName,
    lastName,
    dob,
    gender,
    email,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    emrId
  } = form.value;

  const isFirstNameValid = isValidName(firstName) && isNotEmpty(firstName);
  const isMiddleNameValid = props.isEditForm ? true : isValidName(middleName);
  const isLastNameValid = isValidName(lastName) && isNotEmpty(lastName);
  const isEmailValid = emailRules.every(rule => rule(email));
  const isPhoneNumberValid = isValidPhoneNumber(phoneNumber);
  const isValidDateFormat = isValidDate(dob);
  const isAddressLineValid = validateMaxLength(addressLine1, 50) && isWhitespaceOrHasContent(addressLine1);
  const isAddressLine2Valid = validateMaxLength(addressLine2, 50) && isWhitespaceOrHasContent(addressLine2);
  const isCityValid = !city || (minimumLengthValidationCity(city) && isAddressValid(city));
  const isStateValid = !state || (minimumLengthValidation(state) && isAddressValid(state));
  const isZipValid = !zip || isValidZip(zip);
  const isEmrIdValid = !emrId || (minimumLengthValidation(emrId) && validateMaxLength(emrId, 20))

  return (
    isFirstNameValid &&
    isMiddleNameValid &&
    isLastNameValid &&
    isValidDateFormat &&
    dob.trim() !== "" &&
    gender.trim() !== "" &&
    isEmailValid &&
    isPhoneNumberValid &&
    isValidProviderSelected.value &&
    isAddressLineValid &&
    isAddressLine2Valid &&
    isCityValid &&
    isStateValid &&
    isZipValid &&
    !isLoading.value &&
    !isEmailExist.value &&
    isEmrIdValid
  );
});

const primaryProviderOptions = ref([] as IFormSelectItem[]);
const secondaryProviderOptions = ref([] as IFormSelectItem[]);
const orgOptions = ref([] as IFormSelectItem[]);
const loading = ref(false);
const orgId = useUserStore().$state.user.organizationId;
const providerLoader = ref(false);
const secondaryProviderLoader = ref(false);
const showPatientCreatedMessage = ref(false);
const errorMessage = ref("");
const orgServiceProvidersLists = ref([] as IOrgServiceProvider[]);
const isLoading = ref(false);
const isEmailExist = ref(false);
const patientForm = ref();
const patientInfo = ref<IPatientInfo>();
const route = useRoute();
const patientId = ref('');
const showPatientEditMessage = ref(false);
const successMessage = ref('');
const secondaryProvider = ref<ProviderOption[]>([]);
const existingEmail = ref('');
const updatedPatientInfo = ref<IPatientInfo>();
const patientDetailUpdatedEventBus = useEventBus(patientDetailsEventBusKey);

const phoneNumberMask = reactive<MaskInputOptions> ({
  mask: "(###) ### - ####",

})
const dateValidationMask = reactive<MaskInputOptions>({
  mask:"##/##/####",

}) ;


watch(() => patientInfo.value, async (newValue) => {
  if (newValue) {
    loading.value = true;
    await getAllOrgUser();
    loadSecondaryProviders();
    const primaryProvider = newValue.providers?.find(providerData => providerData.isPrimaryProvider === true);
    const emrId = find(newValue?.externalIds, { 'key': EXTERNAL_IDENTITIES.EMR_ID })?.value!;
    secondaryProvider.value = (newValue.providers?.filter(providerData => !providerData.isPrimaryProvider) || []).map(providerData => {
      return {
        title: `${providerData.firstName} ${providerData.lastName}`,
        value: providerData.userId
      };
    });
    form.value = {
      firstName: newValue?.firstName as string,
      lastName: newValue?.lastName as string,
      dob: newValue?.dob as string,
      gender: newValue?.gender as string,
      email: newValue?.email as string,
      city: newValue?.city as string,
      state: newValue?.state as string,
      zip: newValue?.zip as string,
      organization: orgId,
      phoneNumber: newValue?.mobilePhone as string,
      middleName: newValue?.middleName as string,
      addressLine1: newValue?.addressLine1 as string,
      addressLine2: newValue?.addressLine2 as string,
      provider: primaryProvider?.userId as string,
      emrId
    };

    existingEmail.value = newValue.email!;
  }
  loading.value = false;
})

const dateRules = [
  (v: string) => !!v || "Date is required",
  (v: string) => isValidDate(v) || "Date is invalid",
];

const isValidDate = (date: string) => {
  const dobMoment = moment(date, 'MM/DD/YYYY', true);
  const today = moment().startOf('day');
  const isValidDate = dobMoment.isValid();
  const isValidAge = dobMoment.isSameOrBefore(today) && dobMoment.isAfter(moment().subtract(150, 'years'));

  return isValidDate && isValidAge;
};

const routeTo = (routeName: string) => {
  if (route.query.name) {
    router.push({
      name: 'patientProfileWithInfo',
      params: {
        patientId: patientId.value,
        patient: encodePatientInfo(updatedPatientInfo.value as IPatientInfo),
      }
    })
  } else {
    router.push({
      name: routeName,
    });
  }
};

const apolloClient = inject("apolloClient") as ApolloClient<any>;

const filterSecondaryProviders = (filterValue: string) => {
  loadSecondaryProviders();
  secondaryProvider.value = filter(secondaryProvider.value, obj => obj.value !== filterValue);

};

const getAllOrgUser = async (search?: string) => {
  try {
    const limit = search ? 0 : 20;
    const { data } = await apolloClient.query({
      query: GET_ALL_ORG_USERS,
      variables: {
        filter: { roles: "ORG_PROVIDER", organizationId: orgId || form.value.organization, orgUserName: search, isActive: true, tenantId: import.meta.env.VITE_TENANT_ID },
        limit,
        offset: 0,
      },
      fetchPolicy: "network-only",
    });

    return data;

  } catch (error) {
    const { message } = error as Error;
    showPatientCreatedMessage.value = true;
    errorMessage.value = message;
  }
};

const loadPrimaryProviders = debounce(async () => {
  providerLoader.value = true;
  const response = await getAllOrgUser(form.value.provider);
  if (response && response.listOrganizationUsers) {
    primaryProviderOptions.value = response.listOrganizationUsers.results.map(formatProviderOption);
  }
  providerLoader.value = false;
}, 500)

const loadSecondaryProviders = debounce(async (search?: string) => {
  secondaryProviderLoader.value = true;
  const response = await getAllOrgUser(search);
  if (response && response.listOrganizationUsers) {
    secondaryProviderOptions.value = response.listOrganizationUsers.results
      .map(formatProviderOption)
      .filter(
        (provider: { value: string }) => provider.value !== form.value.provider
      );
  }
  secondaryProviderLoader.value = false;
}, 500);

const formatProviderOption = (provider: { firstName: string; lastName: string; userId: string; }) => ({
  title: `${provider.firstName} ${provider.lastName}`,
  value: provider.userId,
});

const getOrgs = async () => {
  const { data: { listOrganizations: { organizations } } } = await apolloClient.query({
    query: GET_ORGS,
    fetchPolicy: "network-only",
  });

  orgOptions.value = map(organizations, (org) => {
    return {
      title: org.name,
      value: org.orgId,
    };
  })
}

const handlePatientCreationOrEdit = async () => {
  await props.isEditForm ? editPatient() : createPatient();
}

const editPatient = async () => {
  loading.value = true;
  try {
    const formData = omit(form.value, "__typename");

    const secondaryProviders: IPatientProvider[] = secondaryProvider.value.map((p) => {
      return { providerUUID: p.value, isPrimaryProvider: false };
    });

    const patientInput: IUpdatePatientInput = {
      patientId: patientId.value,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      dob: formData.dob,
      gender: formData.gender,
      email: formData.email,
      mobilePhone: formData.phoneNumber,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      providers: [{ providerUUID: formData.provider, isPrimaryProvider: true }, ...secondaryProviders],
      ...(form.value.emrId && form.value.emrId.trim() !== "" && {
        externalIds: [{
          key: EXTERNAL_IDENTITIES.EMR_ID,
          value: form.value.emrId,
          label: EXTERNAL_IDENTITY_LABELS.EMR_ID,
        }]
      })
    }

    updatedPatientInfo.value = await updatePatient(patientInput)

    patientCreated.value = ShowSnackbar.PATIENT_EDITED;

    if (props.isPatientProfile) {
      patientDetailUpdatedEventBus.emit(updatedPatientInfo.value!);
      emit('patientEdited');
    }
    else {
      routeTo('ListPatient');
    }

  } catch (error) {
    showPatientCreatedMessage.value = true;
    errorMessage.value = 'Error while updating patient details';
  }
  loading.value = false;
}
const isSecondaryProviderValid = computed(() => !!secondaryProvider.value.every(provider => provider?.value));

const createPatient = async () => {

  const secondoryProvider = secondaryProvider.value.map((p) => {
    return { providerUUID: p.value, isPrimaryProvider: false };
  });

  const patientData = {
    firstName: form.value.firstName,
    middleName: form.value.middleName,
    lastName: form.value.lastName,
    dob: form.value.dob,
    gender: form.value.gender,
    email: form.value.email,
    mobilePhone: form.value.phoneNumber,
    addressLine1: form.value.addressLine1,
    addressLine2: form.value.addressLine2,
    city: form.value.city,
    state: form.value.state,
    zip: form.value.zip,
    providers: [{ providerUUID: form.value.provider, isPrimaryProvider: true }, ...secondoryProvider],
    organizationId: form.value.organization,
    ...(form.value.emrId && form.value.emrId.trim() !== "" && {
      externalIds: [{
        key: EXTERNAL_IDENTITIES.EMR_ID,
        value: form.value.emrId,
        label: EXTERNAL_IDENTITY_LABELS.EMR_ID,
      }]
    })
  };

  try {
    if (
      form.value?.firstName &&
      form.value?.lastName &&
      form.value?.dob &&
      form.value?.gender &&
      form.value?.email &&
      form.value?.phoneNumber &&
      form.value?.provider ||
      form.value?.organization
    ) {
      loading.value = true;
      const createdPatient = await apolloClient.mutate({
        mutation: CREATE_PATIENT,
        variables: {
          patient: patientData,
        },
        fetchPolicy: "network-only",
      });

      await subscribeProgram(createdPatient.data.createPatient.patientId)
      loading.value = false;

      if (hasAccess.viewOrgInventory(viewOrgInventoryRoles) && !isEmpty(orgServiceProvidersLists.value)) {
        emit('patientCreated', createdPatient.data.createPatient);
      } else {
        routeTo('ListPatient');
        patientCreated.value = ShowSnackbar.PATIENT_CREATED;
      }
    }
  } catch (error) {
    loading.value = false;
    const { message } = error as Error;
    errorMessage.value = message;
    showPatientCreatedMessage.value = true;
  }
};

const updateOrganization = async () => {
  try {
    providerLoader.value = true;
    await getAllOrgUser();
    providerLoader.value = false;
  } catch (error) {
    providerLoader.value = false;
    const { message } = error as Error;
    errorMessage.value = extractErrorMessage(message);
    showPatientCreatedMessage.value = true;
  }
};

const props = defineProps({
  primaryColor: {
    type: String,
    required: false,
    default: "primary",
  },
  inputFirstNameLabel: {
    type: String,
    required: false,
    default: "First Name*",
  },
  inputFirstNameHint: {
    type: String,
    required: false,
    default: "Enter First Name",
  },
  inputMiddleNameLabel: {
    type: String,
    required: false,
    default: "Middle Name",
  },
  inputLastNameLabel: {
    type: String,
    required: false,
    default: "Last Name*",
  },
  inputLastNameHint: {
    type: String,
    required: false,
    default: "Enter Last Name",
  },
  inputDOBLabel: {
    type: String,
    required: false,
    default: "Date of Birth*",
  },
  inputDOBHint: {
    type: String,
    required: false,
    default: "Enter Date of Birth",
  },
  inputGenderLabel: {
    type: String,
    required: false,
    default: "Gender*",
  },
  inputGenderHint: {
    type: String,
    required: false,
    default: "Enter the gender",
  },
  inputEmailLabel: {
    type: String,
    required: false,
    default: "Email*",
  },
  inputEmailHint: {
    type: String,
    required: false,
    default: "Enter Email",
  },
  inputPhoneNumberLabel: {
    type: String,
    required: false,
    default: "Phone Number*",
  },
  inputPhoneNumberHint: {
    type: String,
    required: false,
    default: "Enter Phone Number",
  },
  inputProviderLabel: {
    type: String,
    required: false,
    default: "Primary Provider*",
  },
  inputProviderHint: {
    type: String,
    required: false,
    default: "Enter Provider",
  },
  inputOrganizationLabel: {
    type: String,
    required: false,
    default: "Organization*",
  },
  inputOrganizationHint: {
    type: String,
    required: false,
    default: "Enter Organization",
  },
  submitButtonName: {
    type: String,
    required: false,
    default: "Submit",
  },
  cancelButtonName: {
    type: String,
    required: false,
    default: "Cancel",
  },
  subscribeProgramUseByShortCode: {
    type: Function as PropType<(subscribeProgramInput: SubscribeProgramUsingShortCodeInput) => Promise<IPatientSubscribeProgram>>
  },
  listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
  },
  patientDetail: {
    type: Function as PropType<(patientId: string) => Promise<IPatientInfo>>,
  },
  isEditForm: {
    type: Boolean,
  },
  isPatientProfile: {
    type: Boolean,
    default: false,
  },
  profilePatientId: {
    type: String,
    required: false,
  }
});

defineExpose({
  genderOptions,
});

const subscribeProgram = async (patientId: string) => {
  if (props.subscribeProgramUseByShortCode) {
    const subscribeProgramUsingShortCodeInput = {
      patientId,
      programShortCode: Program.RPM,
    };
    await props.subscribeProgramUseByShortCode(subscribeProgramUsingShortCodeInput);
  }
};

const emailRules = [
  (value: string) => isNotEmpty(value) || 'Email is required',
  (value: string) => isValidEmail(value) || 'Enter a valid email',
  (value: string) => validateMaxLength(value, 50) || 'Email cannot be longer than 50 characters',
]

const emailValidationRules = computed(() => {
  const dynamicRule = isEmailExist.value ? 'Email is already associated with another patient' : true;
  return [...emailRules, dynamicRule];
});
const checkEmailAlreadyExists = debounce(async () => {
  await doesEmailExist(form.value.email);
}, 500);

const doesEmailExist = async (email: string) => {
  isLoading.value = true;
  isEmailExist.value = (existingEmail.value != email) ? await doesPatientEmailExist(email) : false;
  isLoading.value = false;
  patientForm.value.validate();
};

const closeForm = () => {
  props.isPatientProfile ? emit('patientEdited') : routeTo('ListPatient');
}

onMounted(async () => {
  patientId.value = props.profilePatientId || route.params["patientId"] as string;
  loading.value = true;
  if (!patientId.value) {
    loading.value = false;
  }
  orgServiceProvidersLists.value = await props.listOrgServiceProviders!(orgId);
  if (orgId) {
    const response = await getAllOrgUser(form.value.provider)
    if (response && response.listOrganizationUsers.results) {
      const userData = response.listOrganizationUsers.results.map(
        formatProviderOption,
      )
      primaryProviderOptions.value = userData
      secondaryProviderOptions.value = userData
    }
  }
  if (props.isPatientProfile)
    patientInfo.value = await props.patientDetail!(patientId.value);
})
</script>

<style scoped>
.parentContainer {
  width: 70%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 1% 3% 2% 3%;
}
</style>
