import { UserRoles } from "@/enums/Roles";
import { useUserStore } from "@/store/modules/User";
import { includes, map, some } from "lodash";

const hasAuthorizedRoles = (allowedRoles: UserRoles[]) => {
  const store = useUserStore();
  const userStore = store.$state.user;
  const userRoles = map(userStore.roles, (role) => role.role as UserRoles);

  return some(userRoles, (role) => includes(allowedRoles, role));
};

export const viewTaskBoardRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_PROVIDER,
  UserRoles.ORG_STAFF,
];
export const viewPatientsRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_PROVIDER,
  UserRoles.ORG_STAFF,
];
export const viewProgramInsightsRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_PROVIDER,
  UserRoles.ORG_STAFF,
];
export const viewDashboardRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_PROVIDER,
  UserRoles.ORG_STAFF,
];
export const viewAllOrgsRoles = [UserRoles.SYSTEM_ADMIN];
export const viewOrgRoles = [UserRoles.ORG_ADMIN];
export const viewOrgAdminRole = [UserRoles.ORG_ADMIN];
export const viewOrgInventoryRoles = [UserRoles.ORG_ADMIN];
export const viewOrgIntegrationsRoles = [UserRoles.ORG_ADMIN];
export const viewOrgReportsRoles = [UserRoles.ORG_ADMIN];
export const viewMultiPatientConsentsRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_STAFF,
  UserRoles.ORG_PROVIDER,
];
export const viewMultiPatientConsentsFilterRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_STAFF,
];
export const viewPatientInsightsFilterRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_STAFF,
];

export const showGlobalSearchRoles = [
  UserRoles.ORG_ADMIN,
  UserRoles.ORG_STAFF,
  UserRoles.ORG_PROVIDER,
];

export default {
  /* Login Routing*/
  viewDashBoard: hasAuthorizedRoles,
  viewPatients: hasAuthorizedRoles,
  viewTaskBoard: hasAuthorizedRoles,
  viewAllOrganizations: hasAuthorizedRoles,
  viewOrganization: hasAuthorizedRoles,
  /* Features */
  viewOrgInventory: hasAuthorizedRoles,
  viewOrgIntegrations: hasAuthorizedRoles,
  viewOrgReports: hasAuthorizedRoles,
  viewMultiPatientConsents: hasAuthorizedRoles,
  viewPatientInsightsFilter: hasAuthorizedRoles,
  viewMultiPatientConsentsFilte: hasAuthorizedRoles,
  showGlobalSearch: hasAuthorizedRoles,
  viewPatientActions: hasAuthorizedRoles,
};
