<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import { Roles, Designation, UserRoles } from '../enums/Roles';
import { isNotEmpty, validateMaxLength, isValidEmail, isValidPhoneNumber, isValidNPI, isValidDEANumber, validateAlphabetWithSpace } from '../composables/FormUtility';
import { inject } from 'vue';
import { ApolloClient } from '@apollo/client';
import { onMounted } from 'vue';
import { GET_ORGS } from '../graphql/resources/organization/Organization';
import { vMaska } from "maska/vue"
import { reactive } from 'vue';
import _, { debounce, first, omit, uniq, pull } from 'lodash';
import router from '../router';
import { CREATE_ORG_USER, UPDATE_ORG_USER } from '../graphql/resources/OrganizationUserRepository';
import { IOrganizationUser, IOrganizationUserDetails } from '../interfaces/IOrganization';
import { useRoute } from 'vue-router';
import Loader from './common/Loader.vue';
import { useUserStore } from "@/store/modules/User";
import { orgDetailsCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { doesUserDeaExists, doesUserEmailExists, doesOrgUserNpiExists } from '../services/organizationUser.service';
import { userDetailEventBusKey } from '@/events/bus-keys/user-details-event.bus-key';
import { useEventBus } from '@vueuse/core';
import { MaskInputOptions } from 'maska';

const step = ref(1);
const loading = ref(false);
const organizations = ref([] as any[]);
const orgAdminRole = 'ORG_ADMIN';
const userRoles = Roles.filter(role => { return role.value !== orgAdminRole });
const userType = ref("");
const designations = ref(Designation);
const showOrgUserCreatedMessage = ref(false);
const showOrgUserCreatedErr = ref(false);
const isAdmin = ref(false);
const orgUserForm = ref();
const errorMessage = ref('');
const route = useRoute();
const disableOrgSelection = ref(false);
const orgId = useUserStore().$state.user.organizationId;
const orgUser = ref({
  firstName: "",
  lastName: "",
  emailAddress: "",
  mobilePhoneNumber: "",
  deaNumber: "",
  npi: "",
  designation: "",
  roles: [] as string[],
  organizationId: ""
} as IOrganizationUser);
const isLoading = ref(false);
const isValidUserEmail = ref(false);
const isEmailExist = ref(false);
const isDeaExist = ref(false);
const isDeaLoading = ref(false);
const isNpiExist = ref(false);
const isNpiLoading = ref(false);
const isPayloadAvailable = ref(false);
const successMessage = ref('');
const currentUserId = useUserStore().$state.user.userId;
const isCurrentUser = ref(false);
const userDetailEventBus = useEventBus(userDetailEventBusKey);
const orgUserAllRoles = ref(['']);
const existingEmail = ref('');
const existingNpi = ref('');
const existingDeaNumber = ref('');

const emit = defineEmits([
  'user-added',
  'cancel-org-creation',
  'user-edited'
])

const addOrgUser = async () => {
  if (await isValidForm()) {
    prepareOrgUser();
    if (props.orgUserDetail) {
      emit('user-edited', orgUser)
    } else {
      emit('user-added', orgUser)
    }
  }
}


const validateDuplicateEmail = (email: string) => {
  if (!props.orgUserList) {
    return true;
  }

  const duplicateEmail = props.orgUserList.some((orgUser: IOrganizationUser) => orgUser.emailAddress === email);
  return !(duplicateEmail && (email != existingEmail.value));
}

const validateDuplicateDea = (deaNumber: string) => {
  if (!props.orgUserList) {
    return true;
  }

  const isDuplicateDea = props.orgUserList.some((orgUser: IOrganizationUser) => orgUser.deaNumber === deaNumber);
  return !(isDuplicateDea && (deaNumber != existingDeaNumber.value));
}

const validateDuplicatePhoneNumber = (phoneNumber: string) => {
  if (props.orgUserList) {
    const duplicatePhoneNumberCount = props.orgUserList.filter((orgUser: IOrganizationUser) => { return orgUser.mobilePhoneNumber === phoneNumber }).length;
    if ((props.orgUserDetail && duplicatePhoneNumberCount > 1) || !props.orgUserDetail && duplicatePhoneNumberCount > 0) {
      return false;
    }
  }
  return true;
}

const isValidForm = async () => {
  const isFormValid: boolean = (await orgUserForm.value.validate()).valid;
  return isFormValid;
}

const prepareOrgUser = async () => {
  if (isAdmin.value) {
    orgUser.value.roles = uniq([userType.value, orgAdminRole, ...orgUserAllRoles.value])
  } else {
    const roles = pull(orgUserAllRoles.value, orgAdminRole);
    orgUser.value.roles = uniq([userType.value, ...roles])
  }
  orgUser.value.mobilePhoneNumber = orgUser.value.mobilePhoneNumber.replace(/[^\d]/g, "");
}

const cancelUserCreation = () => {
  emit('cancel-org-creation')
}

const phoneNumberMask = reactive<MaskInputOptions> ({
  mask: "(###) ### - ####",

})
const apolloClient = inject('apolloClient') as ApolloClient<any>;

onMounted(async () => {
  if (!orgId) {
    await getOrgs();
  }
});


const getOrgs = async () => {
  await apolloClient.query({
    query: GET_ORGS,
    variables: {
      limit: 100,
      offset: 0
    },
    fetchPolicy: 'network-only'
  }).then((data) => {
    if (data.data) {
      const organizationList = data.data.listOrganizations.organizations;
      organizations.value = organizationList.map((organization: any) => {
        return { title: organization.name, value: organization.orgId }
      });
    }
  }).catch((_err) => {
    loading.value = false;
    errorMessage.value = 'Unable to fetch organizations, please contact support or try again later';
    showOrgUserCreatedErr.value = true;
  });
}


const createOrgUser = async () => {
  if (await isValidForm()) {
    if (orgUser.value) {
      prepareOrgUser();
      loading.value = true;
      await apolloClient.mutate({
        mutation: CREATE_ORG_USER,
        variables: {
          orgUser: [{ ...orgUser.value, tenantId: import.meta.env.VITE_TENANT_ID }]
        }
      }).then(() => {
        loading.value = false;
        orgDetailsCreated.value = ShowSnackbar.ORG_USER_CREATED;
        navigateToOrgUsers();
      }).catch(error => {
        showOrgUserCreatedErr.value = true;
        const { message } = error;
        errorMessage.value = message;
      });
    }
  }
}

const updateOrgUser = async () => {
  if (await isValidForm()) {
    loading.value = true;
    prepareOrgUser();
    await apolloClient
      .mutate({
        mutation: UPDATE_ORG_USER,
        fetchPolicy: "network-only",
        variables: {
          input: {
            ...omit(orgUser.value, "__typename"),
            tenantId: import.meta.env.VITE_TENANT_ID
          },
        },
      })
      .then((data) => {
        loading.value = false;
        showOrgUserCreatedMessage.value = true;
        if (data.data.updateOrgUser.userId === currentUserId) {
          userDetailEventBus.emit(data.data.updateOrgUser);
        }
        orgDetailsCreated.value = ShowSnackbar.ORG_USER_EDITED;
        navigateToOrgUsers();
      });
  }
};

const navigateToOrgUsers = () => {
  router.push({
    name: 'org-users',
    params: {
      'orgId': route.params["orgId"] || props.orgUserDetail?.organizationId
    }
  })
}

const reloadDesignations = () => {
  designations.value = Designation.filter((designation) => { return _.isEqual(designation.associatedRole, userType.value) });
}

const handleDesignations = () => {
  reloadDesignations();
  orgUser.value.designation = designations.value.length === 1 ? designations.value[0].value : '';

  if (userType.value !== UserRoles.ORG_PROVIDER) {
    orgUser.value.npi = '';
    orgUser.value.deaNumber = '';
  }
}

defineExpose({
  step,
  loading,
});
const props = defineProps({
  isStepperForm: {
    type: Boolean,
    default: false
  },
  orgUserDetail: {
    type: Object as PropType<IOrganizationUserDetails>,
  },
  orgUserList: {
    type: Object,
    required: false
  },
  organizationId: {
    type: String,
    required: false
  },
  isEditForm: {
    type: Boolean,
  }
});

watch(() => props.orgUserDetail, (newValue) => {
  isPayloadAvailable.value = props.orgUserDetail ? true : false;
  isCurrentUser.value = currentUserId === props.orgUserDetail?.userId;
  if (newValue) {
    orgUserAllRoles.value = newValue.roles;
    orgUser.value = newValue;
    if (newValue.roles.includes(orgAdminRole)) {
      isAdmin.value = true;
    }
    const userRole = first(orgUser.value.roles.filter(role => { return [UserRoles.ORG_PROVIDER.toString(), UserRoles.ORG_STAFF.toString()].includes(role) }));
    if (userRole) {
      userType.value = userRole;
      reloadDesignations();
    }
    existingNpi.value = newValue.npi;
    existingDeaNumber.value = newValue.deaNumber;
  }
})

const selectDefaultOrganizaion = () => {
  if (props.organizationId) {
    orgUser.value.organizationId = props.organizationId as string;
    disableOrgSelection.value = true;
  } else if (route.params["orgId"]) {
    orgUser.value.organizationId = route.params["orgId"] as string;
    disableOrgSelection.value = true;
  }
}

const emailRules = [
  (value: string) => isNotEmpty(value) || 'Email is required',
  (value: string) => isValidEmail(value) || 'Enter a valid email',
  (value: string) => validateDuplicateEmail(value) || 'Email is already associated with another user',
  (value: string) => validateMaxLength(value, 128) || 'Email cannot be longer than 128 characters',
]

const emailRulesComputed = computed(() => {
  const dynamicRule = isEmailExist.value ? 'Email is already associated with another user' : true;
  return [...emailRules, dynamicRule];
});

const debouncedCheckEmailExists = _.debounce(async () => {
  await isEmailExists(orgUser.value.emailAddress);
}, 500);

const isEmailExists = async (email: string) => {
  isLoading.value = true;
  isEmailExist.value = true;
  isValidUserEmail.value = false;
  isEmailExist.value = await doesUserEmailExists(email);
  isValidUserEmail.value = !isEmailExist.value;
  isLoading.value = false;
  orgUserForm.value.validate();
};
const deaValidation = computed(() => {
  return ((orgUser.value.deaNumber != existingDeaNumber.value) && isDeaExist.value) ? 'DEA number is already associated with another user' : true;
});

const debouncedCheckDeaExists = _.debounce(async () => {
  await isDeaExists(orgUser.value.deaNumber);
}, 500);

const isDeaExists = async (deaNumber: string) => {
  isDeaLoading.value = true;
  isDeaExist.value = true;
  isDeaExist.value = await doesUserDeaExists(deaNumber);
  isDeaLoading.value = false;
  orgUserForm.value.validate();
};

const validateDuplicateNpi = (npi: string) => {
  if (!props.orgUserList) {
    return true;
  }

  const isDuplicateNpi = props.orgUserList.some((orgUser: IOrganizationUser) => orgUser.npi === npi);
  return !(isDuplicateNpi && (npi != existingNpi.value));
}


const npiValidation = computed(() => {
  const isDuplicate = ((existingNpi.value != orgUser.value.npi) && isNpiExist.value) ? 'NPI is already associated with another user' : true;
  return isDuplicate;
});

const loadOrgUserNpi = debounce(async () => {
  await isNpiExists(orgUser.value.npi);
}, 500);

const isNpiExists = async (npi: string) => {
  isNpiLoading.value = true;
  isNpiExist.value = true;
  isNpiExist.value = await doesOrgUserNpiExists(npi);
  isNpiLoading.value = false;
  orgUserForm.value.validate();
}


const formInputValid = computed(() => {
  const { firstName, lastName, emailAddress, mobilePhoneNumber, deaNumber, npi, designation } = orgUser.value;

  const isFirstNameValid = isNotEmpty(firstName) && validateAlphabetWithSpace(firstName);
  const isLastNameValid = isNotEmpty(lastName) && validateAlphabetWithSpace(lastName);
  const isEmailAddressValid = emailRules.every(rule => rule(emailAddress) === true);
  const isPhoneNumberValid = isNotEmpty(mobilePhoneNumber) && isValidPhoneNumber(mobilePhoneNumber) && validateDuplicatePhoneNumber(mobilePhoneNumber);
  const isDesignationValid = isNotEmpty(designation);
  const isDeaNumberValid = userType.value === 'ORG_PROVIDER' ? (isValidDEANumber(deaNumber) && !isDeaExist.value) : true;
  const isNpiValid = userType.value === 'ORG_PROVIDER' ? (isValidNPI(npi) && !isNpiExist.value) : true;
  const isUserTypeValid = (userType.value === 'ORG_PROVIDER' || userType.value === 'ORG_STAFF');
  const isDynamicEmailValid = !isEmailExist.value;


  return (
    isFirstNameValid &&
    isLastNameValid &&
    isEmailAddressValid &&
    isPhoneNumberValid &&
    isDesignationValid &&
    isNpiValid &&
    isDeaNumberValid &&
    isUserTypeValid &&
    isDynamicEmailValid

  )
});

onMounted(() => {
  if (props.orgUserDetail) {
    orgUser.value = props.orgUserDetail;
    if (props.orgUserDetail.roles.includes(orgAdminRole)) {
      isAdmin.value = true;
    }
    const userRole = first(orgUser.value.roles.filter(role => { return role !== orgAdminRole }));
    if (userRole) {
      userType.value = userRole;
      reloadDesignations();
    }
    existingEmail.value = props.orgUserDetail.emailAddress;
    existingNpi.value = props.orgUserDetail.npi;
    existingDeaNumber.value = props.orgUserDetail.deaNumber;
  }

  if (orgDetailsCreated.value === ShowSnackbar.ORGANIZATION_CREATED) {
    successMessage.value = 'Organization created successfully';
    showOrgUserCreatedMessage.value = true;
    orgDetailsCreated.value = '';
  }
  selectDefaultOrganizaion();
});


</script>

<template>
  <v-card class="org-profile-card  rounded-lg">
    <v-card-text class="text-h6 text-center font-weight-bold text-primary">
      {{ isEditForm || props.orgUserDetail ? 'Edit' : 'Create' }} Organization User
    </v-card-text>
    <v-form class="mt-5" ref="orgUserForm" @submit.prevent="addOrgUser()">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-text-field label="First Name*" variant="outlined" density="compact" color="primary"
            :rules="[value => isNotEmpty(value) || 'First name is required', value => validateAlphabetWithSpace(value) || 'First name can only consist of alphabetical characters and spaces in between', value => validateMaxLength(value, 20) || 'First name cannot be longer than 20 characters']"
            hint="Enter first name" class="text-left" v-model="orgUser.firstName" :disabled="isEditForm"></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-text-field label="Last Name*" variant="outlined" density="compact" color="primary"
            :rules="[value => isNotEmpty(value) || 'Last name is required', value => validateAlphabetWithSpace(value) || 'Last name can only consist of alphabetical characters and spaces in between', value => validateMaxLength(value, 20) || 'Last name cannot be longer than 20 characters']"
            hint="Enter last name" class=" text-left" v-model="orgUser.lastName"></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12" v-if="!orgId">
          <v-select label="Organization*" class="text-left" variant="outlined" density="compact" color="primary"
            :disabled="disableOrgSelection" :rules="[value => isNotEmpty(value) || 'Organization is required']"
            hint="Select organization" :items="organizations" v-model="orgUser.organizationId"></v-select>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-select label="User Type*" class="text-left" variant="outlined" density="compact" color="primary"
            :rules="[(value) => isNotEmpty(value) || 'User type is required']" hint="User type" :items="userRoles"
            v-model="userType" @update:model-value="handleDesignations()" :disabled="isEditForm"></v-select>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-select label="Designation*" class="text-left" variant="outlined" density="compact" color="primary"
            :rules="[value => isNotEmpty(value) || 'Designation is required']" hint="Select designation"
            :items="designations" v-model="orgUser.designation"></v-select>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-text-field label="Email*" variant="outlined" density="compact" color="primary" hint="Enter email"
            :loading="isLoading" @input="debouncedCheckEmailExists" :rules="emailRulesComputed" :disabled="isEditForm"
            v-model="orgUser.emailAddress" class=" text-left"></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-text-field label="Phone Number*" v-maska="phoneNumberMask"
            :rules="[value => isNotEmpty(value) || 'Phone number is required', value => isValidPhoneNumber(value) || 'Enter valid phone number', value => validateDuplicatePhoneNumber(value) || 'Phone number is already associated with another user']"
            variant="outlined" density="compact" color="primary" v-model="orgUser.mobilePhoneNumber"
            hint="Enter phone number" class=" text-left"></v-text-field>
        </v-col>
        <v-col md="6" v-if="userType === 'ORG_PROVIDER'" sm="12" cols="12">
          <v-text-field label="NPI*" variant="outlined" density="compact"
            :rules="[value => isNotEmpty(value) || 'NPI is required', value => isValidNPI(value) || 'NPI must be 10 digits', value => validateDuplicateNpi(value) || 'NPI is already associated with another user', npiValidation]"
            color="primary" hint="Enter NPI" v-model="orgUser.npi" :loading="isNpiLoading" @input="loadOrgUserNpi"
            class=" text-left"></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12" v-if="userType === 'ORG_PROVIDER'">
          <v-text-field label="DEA Number*"
            :rules="[value => isNotEmpty(value) || 'DEA number is required', value => isValidDEANumber(value) || 'DEA number must be two uppercase letters followed by seven digits', value => validateDuplicateDea(value) || 'DEA is already associated with another user', deaValidation]"
            variant="outlined" density="compact" :loading="isDeaLoading" @input="debouncedCheckDeaExists"
            color="primary" hint="Enter DEA number" v-model="orgUser.deaNumber" class=" text-left"></v-text-field>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-checkbox color="primary" v-model="isAdmin" label="Is Admin?" :disabled="isCurrentUser"></v-checkbox>
        </v-col>


      </v-row>
      <div :class="isStepperForm ? 'btn-container-wrapper d-flex justify-end' : 'btn-container-wrapper'">

        <v-row>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="2">
            <v-btn v-if="isStepperForm && !isEditForm" block class="mt-5" elevation="3" rounded="false"
              :variant="'tonal'" color="primary" @click="cancelUserCreation()">
              Cancel
            </v-btn>
            <v-btn v-else block class="mt-5" elevation="3" rounded="false" :variant="'tonal'" color="primary"
              @click="navigateToOrgUsers()">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn v-if="isEditForm" block class="mt-5 text-white" elevation="3" rounded="false" color="primary"
              type="submit" @click="updateOrgUser()">
              Save Changes
            </v-btn>
            <v-btn v-else-if="isStepperForm" block class="mt-5 text-white" elevation="3" rounded="false" color="primary"
              type="submit">
              {{ props.orgUserDetail ? " Edit" : "Add" }}
            </v-btn>
            <v-btn :disabled="!formInputValid" v-else block class="mt-5 text-white" elevation="3" rounded="false"
              color="primary" @click="createOrgUser()">
              Create
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-snackbar color="green" class="text-white mt-16" v-model="showOrgUserCreatedMessage" location="top right">
      {{ successMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" class="text-white mt-16" v-model="showOrgUserCreatedErr" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-card>
</template>

<style scoped>
.org-profile-card {
  width: 70%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 2%;
}


.btn-container {
  width: 200px;
  margin: 0 auto;
}

.btn-stepper-container {
  width: 200px;
}

.btn-container-wrapper {
  width: 100%;
}
</style>
