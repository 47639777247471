<template>
  <v-card flat>
    <v-row justify="start" class="py-5">
      <v-col v-if="props.selectedFilterValue?.organizationName" cols="auto" class="py-1 pe-0">
        <v-chip closable variant="outlined" class="font-weight-bold text-subtitle-1 text-truncate" color="primary" rounded
          @click:close="removeFilter('organizationName')">
          Name: {{ props.selectedFilterValue.organizationName }}
        </v-chip>
      </v-col>
      <v-col v-if="props.selectedFilterValue?.organizationType" cols="auto" class="py-1 pe-0">
        <v-chip closable rounded variant="outlined" class=" font-weight-bold text-subtitle-1" color="primary"
          @click:close="removeFilter('organizationType')">
          Type: {{ organizationFilterType }}
        </v-chip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { OrganizationType } from '@/enums/OrganizationTypes';
import { get } from 'lodash';
import { defineProps, defineEmits, watchEffect, ref } from 'vue';

const props = defineProps({
  selectedFilterValue: Object,
});
const organizationFilterType = ref('');

watchEffect(() => {
  const selectedFilterValue = get(props, 'selectedFilterValue.organizationType');
  const selectedOrgType = OrganizationType.find(orgType => orgType.value === selectedFilterValue);
  organizationFilterType.value = selectedOrgType ? selectedOrgType.title : '';
});

const emit = defineEmits(['removeFilter']);

const removeFilter = (removedFilterChipValue: string) => {
  emit('removeFilter', removedFilterChipValue);
};
</script>
