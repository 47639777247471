<template>
  <v-menu v-model="menuOpen" :close-on-content-click="false" location="bottom center" :persistent="true">
    <template v-slot:activator="{ props }">
      <v-btn color="primary" class="mx-2" v-bind="props" :rounded="false" size="x-small" variant="tonal" elevation="3"
        @click="removedValue" icon="mdi-filter">
      </v-btn>
    </template>
    <v-card class=" ma-2  card-container">
      <v-card-text class="mt-5">
        <v-text-field v-model="filteredPatientName" label="Patient Name" variant="outlined" density="compact"
          color="primary">
        </v-text-field>
        <v-text-field v-model="filteredProviderName" label="Primary Provider Name" variant="outlined" density="compact"
          color="primary">
        </v-text-field>
        <v-text-field v-model="filteredDOB" label="DOB" placeholder="mm/dd/yyyy" variant="outlined" density="compact"
          color="primary">
        </v-text-field>
        <v-select v-model="PatientEventFilter.eventName" label="Event Name" title="title" value="value"
          :items="eventOptions" variant="outlined" density="compact" color="primary">
        </v-select>
        <VueDatePicker v-model="date" :range="true" teleport-center utc time-picker-inline :max-date="new Date()">
          <template #trigger>
            <v-text-field v-model="eventDateRange" label="Date Range" :readonly="true" density="compact"
              variant="outlined" hint="Filter the event with date range" dense clearable
              @click:clear="eventDateRange = ''" />
          </template>
        </VueDatePicker>
        <v-checkbox-btn v-model="isPatientWithoutDevice" label="Patients without a device"></v-checkbox-btn>
      </v-card-text>
      <v-card-actions class="mx-5 mb-6">
        <v-spacer></v-spacer>
        <v-btn variant="tonal" density="default" @click="closeFilter" rounded="false" elevation="3">Close</v-btn>
        <v-btn variant="elevated" density="default" :disabled="isValidFilter" @click="applyFilter" rounded="false"
          elevation="3">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>

</template>

<script setup lang="ts">
import { reactive, ref, computed, watch, PropType } from 'vue';
import { PatientEventName } from '../enums/patient-event.enum';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
import { IPatientEventFilter } from '../interfaces/patient.interface';

const filteredPatientName = ref("");
const filteredProviderName = ref("");
const filteredDOB = ref('');
const menuOpen = ref(false);
const isPatientWithoutDevice = ref(false);
const props = defineProps({
  removedPatientNameValue: String,
  patientWithoutDevice: Boolean,
  removedProviderNameValue: String,
  removedDOBValue: String,
  removedPatientEventValue: {
    type: Object as PropType<IPatientEventFilter>,
  },
});

const emit = defineEmits(["applyFilter"]);

const eventOptions = [
  { title: 'Last Call', value: PatientEventName.phone_call },
  { title: 'Last Appointment', value: PatientEventName.appointment },
];

const date = ref([new Date(), new Date()]);
const eventDateRange = ref('');

const PatientEventFilter = reactive({
  eventName: '',
  startDate: '',
  endDate: '',
});

watch(() => date.value, (newValue) => {
  if (newValue[0] && newValue[1]) {
    eventDateRange.value = `${moment(newValue[0].toString()).format('MM/DD/YYYY  hh:mmA')} - ${moment(newValue[1]?.toString()).format('MM/DD/YYYY  hh:mmA')}`;
  }
});

watch(() => props.patientWithoutDevice, (newVal) => {
  isPatientWithoutDevice.value = newVal as boolean;
});

const isValidFilter = computed(() => {
  const { eventName } = PatientEventFilter;
  return !(eventName !== '' && eventDateRange.value !== '') && !(eventName === '' && eventDateRange.value === '');
});

const closeFilter = () => {
  menuOpen.value = false;
  PatientEventFilter.eventName = '';
}

const applyFilter = () => {
  menuOpen.value = false;
  PatientEventFilter.startDate = PatientEventFilter.eventName !== '' ? date.value[0].toString() : '';
  PatientEventFilter.endDate = PatientEventFilter.eventName !== '' ? date.value[1].toString() : '';

  emit("applyFilter", { ...PatientEventFilter, patientName: filteredPatientName.value, patientWithoutDevice: isPatientWithoutDevice.value, providerName: filteredProviderName.value, dob: filteredDOB.value });
  PatientEventFilter.eventName = '';
};

const removedValue = () => {
  filteredPatientName.value = props.removedPatientNameValue as string;
  filteredProviderName.value = props.removedProviderNameValue as string;
  filteredDOB.value = props.removedDOBValue as string;
  PatientEventFilter.eventName = props.removedPatientEventValue?.eventName as string;
  eventDateRange.value = props.removedPatientEventValue?.startDate ? `${props.removedPatientEventValue.startDate}- ${props.removedPatientEventValue.endDate}` : '';
};
</script>

<style scoped>
.card-container {
  width: 320px;
}
</style>
