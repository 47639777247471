import { createApp } from "vue";
import "vuetify/dist/vuetify.min.css";
import "./style.css";
import App from "./App.vue";
import "./main.scss";
import "./index.css";
import createVuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import router from "./router";
import graphQLClient from "./plugins/graphqlclient";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";
import { useSocketStore } from "./store/modules/socket.store";
import "v-calendar/style.css";
//@ts-ignore
import VueSignaturePad from "vue-signature-pad";
import * as Sentry from "@sentry/vue";
import VueApexCharts from "vue3-apexcharts";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
);

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
Sentry.init({
  environment: import.meta.env.VITE_APP_ENV,
  app,
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGET_DEFAULT,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app
  .use(pinia)
  .use(createVuetify)
  .use(router)
  .use(graphQLClient)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(VueSignaturePad)
  .use(VueApexCharts)
  .mount("#app");
const socketStore = useSocketStore();
socketStore.listenOnLocalBus();
/* Connect to the socket server on page reloads - if logged out, user is forced to login */
socketStore.connect();
