<template>
  <v-container fluid class="forbidden-container" align-center>
    <v-row justify="center" class="ma-0">
      <v-col cols="12" md="8">
        <div class="title-container">
          <h1 class="title text-primary mb-10">403 ERROR</h1>
        </div>
        <div class="d-flex justify-center">
          <v-icon size="10rem" color="primary"
            >mdi mdi-hand-back-left-off-outline</v-icon
          >
        </div>
        <div class="subHeading mb-10 text-primary mt-4 text-center">
          Access Forbidden
        </div>
        <div class="button-container d-flex justify-center">
          <v-btn
            class="home-button px-8"
            color="primary"
            @click="router.push({ name: getInitialRoute() });"
            >Home</v-btn
          >
          <v-btn class="login-button px-8" color="primary" @click="handleLogout()"
            >Login</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import router from "../router";
import hasAccess, { viewProgramInsightsRoles } from "../composables/roleAccess";
import { logoutUser } from "@/composables/CognitoService";

const handleLogout = () => {
  router.push({
    path: "/login",
  });
  logoutUser();
};

const getInitialRoute = () =>
  hasAccess.viewDashBoard(viewProgramInsightsRoles)
    ? "dashboard"
    : "OrganizationList";
</script>

<style scoped>
.forbidden-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  text-align: center;
}

.button-container {
  text-align: center;
  margin-top: 20px;
}
.title {
  font-size: 6rem;
  font-weight: 900;
}
.subHeading {
  font-size: 3rem;
  font-weight: 600;
}

.home-button,
.login-button {
  font-weight: bold;
  text-transform: none;
  margin: 0 15px;
}
</style>
