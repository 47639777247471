import gql from "graphql-tag";

export const GET_ALL_PROGRAMS = gql`
  query {
    getAllPrograms {
      id
      orgId
      shortCode
      label
      parentProgramId
      isDefault
    }
  }
`;

export const GET_ALL_PROGRAMS_CONFIG = gql`
  query {
    getAllProgramsConfiguration {
      id
      orgId
      shortCode
      label
      parentProgramId
      isDefault
      codes {
        code
        bucket
        unitSlabMins
        unitRate
        maxCount
        primaryProcedureCode
        description
      }
    }
  }
`;
