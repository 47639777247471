<template>
  <v-container fluid>
    <v-form @submit.prevent="saveThreshold">
    <v-row>
      <v-col cols="12">
        <v-card color="surfContainerHigh" rounded="false">
          <v-card-item class="bg-surface">
            <template v-slot:title>
              <div class="d-flex align-center">
                <div class="d-flex flex-wrap vt-widget-title-wrap-lg">
                  <span class="ml-2 text-primary">Blood Pressure Criteria Configuration</span>
                  <span class="ml-2 v-card-subtitle flex-1-1-100 text-primary">Configure BP criteria for {{ patientId ? 'the patient'
                    : 'organization' }}</span>
                </div>
                <v-spacer />
              </div>
            </template>
          </v-card-item>
          <v-card-item>
            <v-container>
              <v-row no-gutters>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="pb-2">
                  <v-card class="mx-auto w-100 text-onSurfaceVar elevation-1 my-2 mb-4" color="surface" flat>
                    <v-card-tile>
                          <div class="d-flex flex-wrap pa-2 bg-primary">
                            <span class=" text-subtitle-1 ">Systolic Pressure Configuration</span>
                          </div>
                    </v-card-tile>
                    <v-card-text class="px-8">
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <div class="py-8">
                              <Slider v-model="systolicRangeThresholds" :step="stepSize" :lazy="false" :classes="classes"
                                :min="SELECTION_RANGE_DEFAULT.min" :max="SELECTION_RANGE_DEFAULT.max"
                                :format="toolTipFormatFn" @update:model-value="emitUpdatedThresholds()" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                  <v-card class="mx-auto w-100 text-onSurfaceVar elevation-1 my-2 mt-4" color="surface" flat>
                   <v-card-tile>
                          <div class="d-flex flex-wrap pa-2 bg-primary">
                            <span class=" text-subtitle-1 ">Diastolic Pressure Configuration</span>
                          </div>
                    </v-card-tile>
                    <v-card-text class="px-8">
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <div class="py-8">
                              <Slider v-model="diastolicRangeThresholds" :lazy="false" :step="1" :classes="classes"
                                :min="SELECTION_RANGE_DEFAULT.min" :max="SELECTION_RANGE_DEFAULT.max"
                                :format="toolTipFormatFn" @update:model-value="emitUpdatedThresholds()"/>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="d-flex align-center justify-center">
                  <bP-range-criteria-visualizer :systolicValuesRange="systolicValuesRange"
                    :diastolicValuesRange="diastolicValuesRange" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-item>
          <v-divider />
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions style="float: right;">
        <v-btn class="px-8 mt-2" variant="elevated" rounded="false" type="submit">Save
          Configuration</v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import Slider from "@vueform/slider";
import { ref, onMounted, PropType, computed, defineEmits } from "vue";
import VitalReportingFrequencyConfig from "@/components/vital/config/thresholds/reportingFrequency/VitalReportingFrequencyConfig.vue";
import SeverityGroupThresholdConfig from "@/components/vital/config/thresholds/severityGroup/SeverityGroupThresholdConfig.vue";
import BPRangeCriteriaVisualizer from "./BPRangeCriteriaVisualizer.vue";
import {
  IBPCriteriaConfiguration,
  IVitalCriteriaConfigService,
  IVitalRangeThresholds,
} from "@/interfaces/vitals-configuration.interface";
import { IVitalConfigure, BPVitalThresholdCriteria } from "@/interfaces/IVitalConfigure";
import { Vitals } from '@/enums/patientInsights.enum';


const { vitalBPConfigurationData } = defineProps({
  vitalBPConfigurationData: {
    type: Object as PropType<IVitalConfigure>,
    required: true,
  },
  patientId: {
    type: String,
    required: false
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = ref(true);

const systolicRangeThresholds = ref([0, 0, 0, 0]);
const diastolicRangeThresholds = ref([0, 0, 0, 0]);

const vitalConfig = ref<IVitalConfigure>();
const selectionRangeMin = Number(import.meta.env.VITE_BP_CONFIG_THRESHOLD_MIN || 0);
const selectionRangeMax = Number(import.meta.env.VITE_BP_CONFIG_THRESHOLD_MAX || 400);
const stepSize = 1;

const emits = defineEmits(["update:rangeSelection"]);

const classes = {
  target: "slider-target",
  ltr: "slider-ltr",
  rtl: "slider-rtl",
  horizontal: "slider-horizontal",
  vertical: "slider-vertical",
  textDirectionRtl: "slider-txt-dir-rtl",
  textDirectionLtr: "slider-txt-dir-ltr",
  base: "slider-base bg-critical vt-bp-config-slider",
  connects: "slider-connects",
  connect: "slider-connect",
  origin: "slider-origin",
  handle: "slider-handle",
  handleLower: "slider-handle-lower",
  handleUpper: "slider-handle-upper",
  touchArea: "slider-touch-area",
  tooltip: "slider-tooltip bg-surface",
  tooltipTop: "slider-tooltip-top",
  tooltipBottom: "slider-tooltip-bottom",
  tooltipLeft: "slider-tooltip-left",
  tooltipRight: "slider-tooltip-right",
  active: "slider-active",
  draggable: "slider-draggable",
  tap: "slider-state-tap",
  drag: "slider-state-drag",
};

const SELECTION_RANGE_DEFAULT = {
  min: selectionRangeMin,
  max: selectionRangeMax,
};

const createThresholdObject = (range: number[]) => ({
  criticalMin: Number(SELECTION_RANGE_DEFAULT.min),
  warnMin: range[0],
  normalMin: range[1],
  normalMax: range[2],
  warnMax: range[3],
  criticalMax: Number(SELECTION_RANGE_DEFAULT.max),
});

const bpReadingData = ref([
  createThresholdObject(systolicRangeThresholds.value),
  createThresholdObject(diastolicRangeThresholds.value),
]);

const vitalUnit = computed(() => {
  return "mmHg";
});

const sysRange = computed(() => {
  const config = vitalConfig.value?.config as BPVitalThresholdCriteria
  const systolicThresholds = config?.systolic?.thresholds as
    | IVitalRangeThresholds
    | undefined;
  return {
    min: systolicThresholds?.criticalMin ?? SELECTION_RANGE_DEFAULT.min,
    max: systolicThresholds?.criticalMax ?? SELECTION_RANGE_DEFAULT.max,
  };
});

const diaRange = computed(() => {
  const config = vitalConfig.value?.config as BPVitalThresholdCriteria
  const diastolicThresholds = config?.diastolic?.thresholds as
    | IVitalRangeThresholds
    | undefined;
  return {
    min: diastolicThresholds?.criticalMin ?? SELECTION_RANGE_DEFAULT.min,
    max: diastolicThresholds?.criticalMax ?? SELECTION_RANGE_DEFAULT.max,
  };
});

const systolicValuesRange = computed(() => {
  const criticalMaxFrom = systolicRangeThresholds.value[3] === sysRange.value.max ? systolicRangeThresholds.value[3] : systolicRangeThresholds.value[3] + stepSize;

  return {
    normal: `${systolicRangeThresholds.value[1] + stepSize} - ${systolicRangeThresholds.value[2]}`,
    warningMin: `${systolicRangeThresholds.value[0] + stepSize} - ${systolicRangeThresholds.value[1]}`,
    warningMax: `${systolicRangeThresholds.value[2] + stepSize} - ${systolicRangeThresholds.value[3]}`,
    criticalMin: `${sysRange.value.min} - ${systolicRangeThresholds.value[0]}`,
    criticalMax: `${criticalMaxFrom} - ${sysRange.value.max}`,
  };
});

const diastolicValuesRange = computed(() => {
  const criticalMaxFrom = diastolicRangeThresholds.value[3] === diaRange.value.max ? diastolicRangeThresholds.value[3] : diastolicRangeThresholds.value[3] + stepSize;

  return {
    normal: `${diastolicRangeThresholds.value[1] + stepSize} - ${diastolicRangeThresholds.value[2]}`,
    warningMin: `${diastolicRangeThresholds.value[0] + stepSize} - ${diastolicRangeThresholds.value[1]}`,
    warningMax: `${diastolicRangeThresholds.value[2] + stepSize} - ${diastolicRangeThresholds.value[3]}`,
    criticalMin: `${diaRange.value.min} - ${diastolicRangeThresholds.value[0]}`,
    criticalMax: `${criticalMaxFrom} - ${diaRange.value.max}`,
  };
});

const toolTipFormatFn = (val: number) => {
  return `${val} ${vitalUnit.value}`;
};

const emitUpdatedThresholds = () => {
  bpReadingData.value = [
    createThresholdObject(systolicRangeThresholds.value),
    createThresholdObject(diastolicRangeThresholds.value),
  ];
};

const saveThreshold = () => {
  emits("update:rangeSelection", bpReadingData.value, Vitals.BP);
}

onMounted(() => {
  vitalConfig.value = vitalBPConfigurationData;

  const config = vitalConfig.value.config as BPVitalThresholdCriteria

  const {
    normalMax: diaNormalMax,
    normalMin: diaNormalMin,
    warnMax: diaWarnMax,
    warnMin: diaWarnMin,
  } = config.diastolic.thresholds;

  const {
    normalMax: sysNormalMax,
    normalMin: sysNormalMin,
    warnMax: sysWarnMax,
    warnMin: sysWarnMin,
  } = config.systolic.thresholds;
  systolicRangeThresholds.value = [
    sysWarnMin,
    sysNormalMin,
    sysNormalMax,
    sysWarnMax,
  ];
  diastolicRangeThresholds.value = [
    diaWarnMin,
    diaNormalMin,
    diaNormalMax,
    diaWarnMax,
  ];

  emitUpdatedThresholds();

  loading.value = true;
});
</script>

<style src="@vueform/slider/themes/default.css">
.vt-bp-config-slider:first-child {
  background-color: yellow !important;
}
</style>
../thresholds/reportingFrequency/VitalReportingFrequencyConfig.vue
