<template>
  <v-card class="h-100" color="surfContainerHighest">
    <v-skeleton-loader v-if="loading"
      type="card, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" />
    <v-card-item v-else class="w-100">
      <div class="bg-surfContainer d-flex justify-center flex-column pa-4">
        <template v-if="response.length === 0">
          <div v-if="response.length === 0" class="d-flex flex-column align-center justify-center my-8">
            <v-label class="text-h6 font-weight-bold">No tasks yet</v-label>
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-end my-4">
            <v-select color="primary" class="vt-single-line-multi-select" chips clearable item-title="label"
                            item-value="value" density="comfortable" variant="solo" hide-details
                            label="Filter by Assignees" v-model="selectedAssignees" :items="assignees"
                            @update:model-value="listPatientTask()">
            </v-select>
          </div>
          <div class="d-flex justify-center flex-column  my-4">
            <v-card v-for="(item, index) in response" :key="index" class="pa-2 mb-2 card"
              @click="viewTaskDetail(item.task, item.actors)" elevation="3">
              <v-row class="d-flex justify-center">
                <v-col cols="1" class="d-flex align-center" xs="6">
                  <v-avatar rounded size="35px" color="primary" class="ml-5 text-h6">
                    <template v-if="getReporterDetails(item)?.name.charAt(0)">
                      {{ getReporterDetails(item)?.name.charAt(0) }}
                    </template>
                  </v-avatar>
                  <v-tooltip activator="parent" location="top"> {{ getReporterDetails(item)?.name
                    }}
                  </v-tooltip>
                </v-col>
                <v-col cols="2" xs="6">
                  <v-chip class="mt-2 ml-5" rounded="0" color="primary">
                    #{{ item.task.taskIdentifier }}
                  </v-chip>
                  <v-tooltip activator="parent" location="end">
                    #{{ item.task.taskIdentifier }}
                  </v-tooltip>
                </v-col>
                <v-col cols="4" xs="12">
                  <v-card-title class="text-subtitle-1">
                    {{ item.task.title }}
                  </v-card-title>
                </v-col>
                <v-col cols="2" xs="4">
                                    <v-chip
                                        v-if="item.task.dueDate && item.task.status.category.toString() !== TaskStatusCategory.DONE"
                    class="mt-2 ml-5" :color="getDueDateColor(item.task.dueDate)" rounded="1">
                    <span class="text-caption">Due {{ daysFromNow(item.task.dueDate) }}</span>
                  </v-chip>
                                    <v-chip
                                        v-if="item.task.dueDate && item.task.status.category.toString() == TaskStatusCategory.DONE"
                    color="success" class="mt-2 ml-5" rounded="1">
                    <span class="text-caption">Done</span>
                  </v-chip>
                </v-col>
                <v-col cols="1" xs="4">
                  <v-icon :color="getPriorityIconColor(item.task.priority)" class="text-h3">
                    {{ getPriorityIcon(item.task.priority) }}
                  </v-icon>
                  <v-tooltip activator="parent" location="top"> {{ item.task.priority }}
                  </v-tooltip>
                </v-col>
                <v-col cols="2" xs="4">
                  <div class="position-relative ml-2 mt-2">
                                        <v-avatar rounded
                                            v-for="(assigneeId, assigneeIndex) in item.task.assignees.slice(0, 3)"
                      :key="assigneeIndex" class="position-absolute text-h6"
                      :style="{ left: `${assigneeIndex * 20}px` }" size="35px" color="primary">
                      {{ getAssigneeDetails(item, assigneeId)?.name.charAt(0) }}
                      <v-tooltip activator="parent" location="top">
                        {{ getAssigneeName(item, assigneeId) }}
                      </v-tooltip>
                    </v-avatar>
                                        <v-avatar rounded v-if="item.task.assignees.length > 2"
                                            class="position-absolute" :style="{ left: `${3 * 20}px` }" size="35px"
                                            color="surface">
                      +{{ item.task.assignees.length - 3 }}
                      <v-tooltip activator="parent" location="top">Remaining
                        Assignees</v-tooltip>
                    </v-avatar>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </template>
      </div>
    </v-card-item>
    <v-dialog v-model="taskPanelOpen" persistent fullscreen :scrim="false" transition="dialog-bottom-transition">
      <patient-task-details :patient-info="patientProfile.patientInfo" :task-actors="taskActorDetails"
        :task="taskInView!" :task-queue="getTaskQueue(taskInView!)" :status-states="statusStates"
        :task-status-update-handler="getTaskDetailDependencies.updateTaskStatus"
        :get-dependencies="getTaskDetailDependencies" :patient-id="(selectedRequestorId as string)"
        @task-details-closed="handleTaskDetailClosed" :current-user="currentUser()"
        :add-comment-handler="addCommentHandler" :task-action-definitions="taskActionDefinition"
        :is-patient-profile-task-view="isTaskPanelOpen" :task-queues="taskQueues" :requestor-input="requestorInput!"
        :assignee-input="assigneeInput!" :edit-task-detail="editTaskDetail!" :task-queue-input="taskQueueInput!"
        :submit-action-delegate="addTaskHandler!" @task-edit-success="updateTaskDetails" />
    </v-dialog>
    <v-snackbar color="warning" v-model="showMessage" location="top right" class="mt-16 font-weight-bold">
      <v-icon class="pr-3 pb-2" icon="mdi mdi-alert"></v-icon>You cannot open another
      task from within a task
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="success" class="text-white" v-model="showEditSuccessMessage" location="top right">
      Task detail(s) edited successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showEditSuccessMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>

</template>

<script setup lang="ts">
import { PropType, ref, onMounted } from 'vue';
import { RequestorTask, ListPatientTaskInput, TaskFilter, ITask, ITaskActionDefinition, ITaskQueue, ITaskActor, ITaskStatusState, ITaskQueueItem, IRequestorInput, IAssigneeInput, ITaskQueueInput, ICreateTaskInput, IUpdatedTaskResponse, UpdateTaskInput } from '../../../interfaces/task.interface';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { TaskPriority, TaskStatusCategory } from '@/enums/task.enum';
import { getDueDateColor, } from '../../../composables/DateUtility'
import { flatMap, get, keyBy, merge, uniqBy } from 'lodash';

import PatientTaskDetails from '../../patient/data-components/tasks/PatientTaskDetails.vue';
import { IPatientData, } from '@/interfaces/Service.interface';
import { useEventBus } from '@vueuse/core';
import { noteTaskListEventBusKey } from '@/events/bus-keys/note-task-events.bus-keys';
import { daysFromNow } from '@/helpers/date.helpers';
import { CommentInput, IComment } from '@/interfaces/comment.interface';
import { currentUser } from '@/composables/user/user.session.composable';
import { TaskStatusState } from '@/interfaces/notes.interface';
import { listTaskQueueItems } from '@/services/task.service';

const loading = ref(true);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const selectedAssignees = ref<string[]>([]);
const response = ref<RequestorTask[]>([]);
const assignees = ref<{ label: string; value: string }[]>([]);
const taskFilter = ref<TaskFilter>({
  reporters: [],
  assignees: [],
});
const isDialogOpen = ref(false);
const noteTaskActionEventBus = useEventBus(noteTaskListEventBusKey);

const selectedRequestorId = ref(null as null | string);
const taskInView = ref<ITask>();
const taskPanelOpen = ref(false);
const taskActionDefinition = ref<ITaskActionDefinition[]>();
const statusStates = ref([] as ITaskStatusState[]);
const isTaskPanelOpen = ref(false);
const taskActorDetails = ref([] as ITaskActor[]);
const showMessage = ref(false);
const taskQueues = ref<ITaskQueueItem[]>([]);
const showEditSuccessMessage = ref(false);


const { listPatientTaskLoader, patientProfile, activityLoader, getOrgTaskStatusStates, isTaskBoardTaskView } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  listPatientTaskLoader: {
    type: Function as PropType<(listPatientTaskInput: ListPatientTaskInput) => Promise<RequestorTask[]>>
  },
  getTaskDetailDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  addCommentHandler: {
    type: Function as PropType<(createCommentInput: CommentInput) => Promise<IComment>>,
    required: true,
  },
  activityLoader: {
    type: Function as PropType<() => Promise<ITaskActionDefinition[]>>,
    required: true,
  },
  getOrgTaskStatusStates: {
    type: Function as PropType<() => Promise<TaskStatusState[]>>,
    required: true,
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  addTaskHandler: {
    type: Function as PropType<(createTaskInput: ICreateTaskInput) => Promise<ITask>>,
    required: false,
  },
  requestorInput: {
    type: Object as PropType<IRequestorInput>,
    required: false,
  },
  assigneeInput: {
    type: Object as PropType<IAssigneeInput>,
    required: false,
  },
  taskQueueInput: {
    type: Object as PropType<ITaskQueueInput>,
    required: false,
  },
  editTaskDetail: {
    type: Function as PropType<(updateInput: UpdateTaskInput) => Promise<IUpdatedTaskResponse>>,
    required: false,
  }
})

const getAssigneeDetails = (item: RequestorTask, assigneeId: string) => {
  const assignee = item.actors.find(actor => actor.id === assigneeId);
  return assignee || null;
}

const getAssigneeName = (item: RequestorTask, assigneeId: string) => {
  const assignee = getAssigneeDetails(item, assigneeId);
  return assignee ? assignee.name : '';
}

const getReporterDetails = (item: RequestorTask) => {
  const reporterId = item.task.reporterId;
  const reporter = item.actors.find(actor => actor.id === reporterId);
  return reporter;
}

const getPriorityIconColor = (priority: string) => {
  switch (priority) {
    case TaskPriority.LOW:
      return 'green';
    case TaskPriority.MEDIUM:
      return 'yellow';
    case TaskPriority.HIGH:
      return 'orange';
    case TaskPriority.CRITICAL:
      return 'red';
    default:
      return 'default';
  }
}

const getPriorityIcon = (priority: string) => {
  switch (priority) {
    case TaskPriority.LOW:
      return 'mdi mdi-chevron-down';
    case TaskPriority.MEDIUM:
      return 'mdi mdi-equal';
    case TaskPriority.HIGH:
      return 'mdi-chevron-up';
    case TaskPriority.CRITICAL:
      return 'mdi-chevron-double-up';
    default:
      return '';
  }
}

const extractReportersAndAssignees = (response: RequestorTask[]) => {
  const allActors: ITaskActor[] = flatMap(response, 'actors');
  const actorsById: { [key: string]: ITaskActor } = keyBy(allActors, 'id');

  const assigneesFromTasks = [] as { label: string; value: string; }[];

  const allAssignees: string[] = flatMap(flatMap(response, 'task'), 'assignees');
  allAssignees.forEach(assigneeId => {
    const assignee = actorsById[assigneeId];
    if (assignee) {
      assigneesFromTasks.push({
        label: assignee.name,
        value: assigneeId
      })
    }
  });

  assignees.value = uniqBy(assigneesFromTasks, 'value');
};

const listPatientTask = async () => {
  try {
    loading.value = true;

    if (listPatientTaskLoader) {
      taskFilter.value = {
        assignees: selectedAssignees.value,
      };

      const result = await listPatientTaskLoader({
        requestorId: patientProfile.patientId,
        filter: { ...taskFilter.value },
      });

      if (result) {
        response.value = result;
        extractReportersAndAssignees(result);
      } else {
        throw new Error('Response is missing required properties.');
      }
    } else {
      throw new Error('listPatientTaskLoader is undefined');
    }
    loading.value = false;
  } catch (err) {
    loading.value = false;
    errorMessage.value = 'Error fetching patient task';
    showErrorMessage.value = true;
  }
};

const viewTaskDetail = async (task: ITask, actors: ITaskActor[]) => {
  if (!isTaskBoardTaskView) {
    loading.value = true;
    selectedRequestorId.value = task.requestorId;
    taskInView.value = merge({}, task, { id: get(task, 'taskId') });
    taskActorDetails.value = actors;
    await getAllActivities();
    loading.value = false;
    isTaskPanelOpen.value = true;
    taskPanelOpen.value = true;
  }
  else {
    showMessage.value = true;
  }
}

const getAllActivities = async () => {
  taskActionDefinition.value = await activityLoader();
};

const orgTaskStatusStates = async () => {
  const orgTaskStatusStates = await getOrgTaskStatusStates();
  statusStates.value = orgTaskStatusStates.map((state) => ({
    id: state.taskStatusStateId,
    orgId: state.orgId,
    category: state.category as TaskStatusCategory,
    taskStatusStateId: state.taskStatusStateId,
    name: state.name,
  }));
};

const getTaskQueue = (task: ITask) => {
  const { currentQueue: currentQueueTransition } = task;
  if (currentQueueTransition) {
    return {} as ITaskQueue;
  }
};

const updateTaskDetails = async () => {
  await listPatientTask()
  const taskDetail = response.value.find((item) => item.task.taskIdentifier === taskInView.value?.taskIdentifier)
  viewTaskDetail(taskDetail?.task!, taskDetail?.actors!)
}

const handleTaskDetailClosed = (action: boolean) => {
  taskPanelOpen.value = false;
  showEditSuccessMessage.value = !action;
  !action && listPatientTask();
}

onMounted(async () => {
  await orgTaskStatusStates();
  await listPatientTask();
  taskQueues.value = await listTaskQueueItems();
  noteTaskActionEventBus.on(() => { listPatientTask() });
});

</script>

<style scoped>
.no-data-image {
  width: 15%;
  height: auto;
}

.card {
  max-width: 100%;
  min-width: 800px
}

.vt-single-line-multi-select {
  max-width: 320px
}

.chip-overflow {
  max-width: 150px;
  padding: 2px 5px;
}

:deep(.v-chip__content) {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
