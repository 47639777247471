<template>
    <v-card flat class='mx-auto qardioForm px-5 pb-6'>
        <v-container fluid>
            <v-card-text v-if="orgServiceProviderId" class="text-primary text-center pb-12  text-h5 font-weight-bold">
                Update Qardio Configuration
            </v-card-text>
            <v-form ref="qardioValidForm" @submit.prevent="emitConfigureServiceProvider">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field label="API Domain*" color="primary" v-model.trim="qardioForm.apiDomain" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'API domain is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Username*" color="primary" v-model.trim="qardioForm.userName" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'Username is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Password*" color="primary" :type="showPassword ? 'text' : 'password'"
                            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append-inner="showPassword = !showPassword" v-model.trim="qardioForm.password"
                            variant="outlined" density="compact"
                            :rules="[(v) => !!v || 'Password is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Client ID*" color="primary" :type="showClientId ? 'text' : 'password'"
                            :append-inner-icon="showClientId ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append-inner="showClientId = !showClientId" v-model.trim="qardioForm.clientId"
                            variant="outlined" density="compact"
                            :rules="[(v) => !!v || 'Client id is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label=" Client Secret*" color="primary" :type="showClientSecret ? 'text' : 'password'"
                            :append-inner-icon="showClientSecret ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append-inner="showClientSecret = !showClientSecret" v-model.trim="qardioForm.clientSecret"
                            variant="outlined" density="compact"
                            :rules="[(v) => !!v || 'Client secret is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Clinic ID*" color="primary" v-model.trim="qardioForm.clinicId" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'Clinic id is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="API Version*" color="primary" v-model.trim="qardioForm.apiVersion"
                            variant="outlined" density="compact"
                            :rules="[(v) => !!v || 'API version is required']"></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn color="primary" class="text-white rounded-xl text-none px-12" @click="emitClose"
                            variant="outlined" block>
                            Cancel
                        </v-btn>
                    </v-col>
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn color="primary" class="text-white rounded-xl text-none px-12" variant="flat" type="submit" :disabled="!isFormDataValid"
                            block>
                            {{ orgServiceProviderId ? 'Update' : 'Configure' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-card>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

const showPassword = ref(false);
const showClientId = ref(false);
const showClientSecret = ref(false);
const props = defineProps(["editData", "organizationServiceProviderId"]);

const {
    apiDomain = '',
    userName = '',
    password = '',
    clientId = '',
    clientSecret = '',
    clinicId = '',
    apiVersion = '',
} = props.editData?.configuration || {};

const qardioForm = ref({
    apiDomain,
    userName,
    password,
    clientId,
    clientSecret,
    clinicId,
    apiVersion,
});
const qardioValidForm = ref();
const orgServiceProviderId = ref('');

const isFormDataValid = computed(() => {
  const formValues = Object.values(qardioForm.value);
  return formValues.every(field => field.trim() !== '');
});

const isValidForm = async () => {
    const isFormValid: boolean = (await qardioValidForm.value.validate()).valid;
    return isFormValid;
};

const emit = defineEmits([
    "show-configure-service-provider-form",
    "close",
    "show-configure-form",
]);

const emitConfigureServiceProvider = async () => {
    const isFormValid = await isValidForm();
    if (isFormValid) {
        emit("show-configure-service-provider-form", qardioForm.value);
    }
};
const emitClose = () => {
    emit("close");
};
onMounted(() => {
    if (props.organizationServiceProviderId) {
        orgServiceProviderId.value = props.organizationServiceProviderId;
    }
});

</script>
<style scoped>
.qardioForm {
    width: 100%;
}
</style>