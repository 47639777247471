import { AuditEventInfo } from "@/interfaces/audit-event.interface";

export const AuditEventDetail: { [key: string]: AuditEventInfo } = {
  STANDARDIZED_BILLING_DOWNLOADED: {
    auditEventType: "STANDARDIZED_BILLING_DOWNLOADED",
    auditEventCode: "9-0-08",
    description: "Standardized billing was downloaded successfully",
  },
  OPTIMIZED_BILLING_DOWNLOADED: {
    auditEventType: "OPTIMIZED_BILLING_DOWNLOADED",
    auditEventCode: "9-0-09",
    description: "Optimized billing was downloaded successfully",
  },
  CUSTOMIZED_BILLING_DOWNLOADED: {
    auditEventType: "CUSTOMIZED_BILLING_DOWNLOADED",
    auditEventCode: "9-0-10",
    description: "Customized billing was downloaded successfully",
  },
};
