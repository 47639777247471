<script setup lang="ts">
import { useDisplay, useTheme } from "vuetify";
import AvatarComp from "./AvatarComp.vue";
import BrandComp from "./BrandComp.vue";
import router from "../router";
import { logoutUser } from "../composables/CognitoService";
import { capitalizeFirstLetter, initialName } from "../composables/FormUtility";
import { useEventBus } from "@vueuse/core";
import { userDetailEventBusKey } from "@/events/bus-keys/user-details-event.bus-key";
import { onMounted, ref } from 'vue';
import { IUser } from "@/interfaces/user.interface";
import AppEntitySearch from "./search/AppEntitySearch.vue";
import hasAccess, { showGlobalSearchRoles } from "../composables/roleAccess";
import { isIdleUserLogout } from "../composables/idleCalculation";
import { patientProgramInsightsEventBusKey } from "@/events/bus-keys/patient-program-insights-event.bus-key";
import { IPatientReadingDetail } from "@/interfaces/patientInsights.interface";
import VitalToast from "../components/VitalToast.vue";
import UserLinkedPatientToast from './views/patient/PE/UserLinkedPatientToast.vue'
import { userPatientLinkEventBusKey } from "@/events/bus-keys/PE/user-patient-link-event.bus-keys";
import { IUserLinkPatientPE } from "@/interfaces/PE/usePatientLink.interface";


const theme = useTheme();

const toggleTheme = () => {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
};

const handleLogout = () => {
  isIdleUserLogout.value = false
  router.push({
    path: "/login",
  });
  logoutUser();
};

const routeTo = (routeName: string) => {
  router.push({
    name: routeName,
  });
};

const hasAccessToSearch = hasAccess.showGlobalSearch(showGlobalSearchRoles);

const { smAndUp } = useDisplay();
const userDetailEventBus = useEventBus(userDetailEventBusKey);
const initialFirstName = ref('');
const initialLastName = ref('');
const userName = ref('');
const email = ref('');
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const userPatientLinkEvent = useEventBus(userPatientLinkEventBusKey);
const patientDetails = ref({} as IPatientReadingDetail);
const userPatientLinkData = ref<IUserLinkPatientPE>()

const setUserInfoDetails = (userData: IUser | null) => {
  if (userData) {
    const capitalizedFirstName = capitalizeFirstLetter(userData.firstName);
    const capitalizedLastName = capitalizeFirstLetter(userData.lastName);
    initialFirstName.value = initialName(userData.firstName);
    initialLastName.value = initialName(userData.lastName);
    email.value = userData.emailAddress;
    userName.value = `${capitalizedFirstName} ${capitalizedLastName}`;
  }
}

onMounted(() => {
  const userInfo = sessionStorage.getItem("user") as string;
  const user = JSON.parse(userInfo).user;
  setUserInfoDetails(user);

  patientProgramInsightsEvent.on((e) => {
    const parsedData = JSON.parse(e);
    patientDetails.value = parsedData;
  })

  userDetailEventBus.on((e) => {
    setUserInfoDetails(e);
  });

  userPatientLinkEvent.on((e) => {
    const parsedData = JSON.parse(e);
    userPatientLinkData.value = parsedData;
  })
});
</script>

<template>
  <v-app-bar flat :height="smAndUp ? 64 : 128" class="app-box-shadow" color="surface">
    <v-row class="ma-0 pa-0" style="height: 100%">
      <v-col md="4" sm="3" cols="12" class="nav-col nav-col-start">
        <BrandComp />
      </v-col>
      <v-col md="4" sm="6" cols="12" class="d-flex justify-end align-center py-2">
        <app-entity-search v-if="hasAccessToSearch" />
      </v-col>
      <v-col md="4" sm="3" cols="12" class="nav-col nav-col-end">
        <div class="pa-4">
          <slot name="menu-button">
            <v-menu contained>
              <template v-slot:activator="{ props }">
                <v-btn color="primary" variant="tonal" v-bind="props" rounded="false" class="mx-2" elevation="1"
                  width="220">
                  <AvatarComp :avatar-text="initialFirstName + initialLastName" />
                  <h6 class="text-subtitle-1 mx-2 font-weight-bold text-onSurfaceVar overflow-clip "
                    style="width:140px;">{{
                      userName
                    }}
                  </h6>
                  <div class="mx-1">
                    <v-icon icon="mdi-chevron-down"></v-icon>
                  </div>
                </v-btn>
              </template>
              <v-card color="primary" variant="tonal">
                <v-list color="primary">
                  <v-list-item variant="text">
                    <template v-slot:prepend>
                      <AvatarComp :avatar-text="initialFirstName + initialLastName" />
                    </template>
                    <div class="ml-3 my-2">
                      <v-list-item-title>{{ userName }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        email
                      }}</v-list-item-subtitle>
                    </div>
                  </v-list-item>
                  <v-divider thickness="1"></v-divider>
                  <v-list-item class="d-flex justify-start" variant="text" @click="routeTo('updatePassword')">
                    <template v-slot:prepend>
                      <v-icon color="primary" size="large">mdi-lock-reset</v-icon>
                    </template>
                    <div class="my-2">
                      <v-list-item-title>Reset Password</v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </div>
                  </v-list-item>
                  <v-divider thickness="1"></v-divider>
                  <v-list-item class="d-flex justify-start" variant="text" @click="handleLogout">
                    <template v-slot:prepend>
                      <v-icon color="primary" size="large">mdi-logout</v-icon>
                    </template>
                    <div class="my-2">
                      <v-list-item-title>Logout</v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </slot>
          <slot name="change-theme-button">
            <v-tooltip location="bottom"> <template v-slot:activator="{ props }"> <v-btn v-bind="props" rounded
                  class="mx-2" size="x-small" color="primary" variant="tonal" elevation="3" @click="toggleTheme"
                  icon="mdi-theme-light-dark" />
              </template>{{ theme.global.current.value.dark ? 'Dark Theme' : 'Light Theme' }}</v-tooltip>
          </slot>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
  <vital-toast :patientDetail="patientDetails" />
  <UserLinkedPatientToast :linked-patient="userPatientLinkData" />
</template>

<style scoped>
.app-box-shadow {
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.app-bar-pos {
  margin-bottom: 6px;
}

.search-input>div>div {
  border-radius: 100px;
}

.nav-col {
  height: 100%;
  display: flex;
  align-items: center;
  max-height: 100%;
}

.nav-col-start {
  justify-content: start;
}

.nav-col-end {
  justify-content: end;
}

.logoutContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.list {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
</style>
