export const Roles = [
    {
        title: "Admin",
        value: "ORG_ADMIN"
    },
    {
        title: "Staff",
        value: "ORG_STAFF"
    },
    {
        title: "Provider",
        value: "ORG_PROVIDER"
    }
]

export const Designation = [
    {
        title: "Physician Assistant",
        value: "PHYSICIAN_ASSISTANT",
        associatedRole: "ORG_PROVIDER"
    },
    {
        title: "Physician",
        value: "PHYSICIAN",
        associatedRole: "ORG_PROVIDER"
    },
    {
        title: "Registered Nurse",
        value: "REGISTERED_NURSE",
        associatedRole: "ORG_PROVIDER"
    },
    {
      title: "Nurse Practitioner",
      value: "NURSE_PRACTITIONER",
      associatedRole: "ORG_PROVIDER"
    },
    {
        title: "Manager",
        value: "MANAGER",
        associatedRole: "ORG_STAFF"
    },
    {
      title: "Other",
      value: "OTHER",
      associatedRole: "ORG_STAFF"
    },
]


export enum UserRoles {
    SUPER_USER = 'SUPER_USER',
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    ORG_ADMIN = 'ORG_ADMIN',
    ORG_STAFF = 'ORG_STAFF',
    ORG_PROVIDER = 'ORG_PROVIDER',
    PATIENT = 'PATIENT',
  }
