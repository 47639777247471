import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { md } from "vuetify/iconsets/md";
import { fa } from "vuetify/iconsets/fa";
import { md3 } from "vuetify/blueprints";
import { mdi } from "vuetify/iconsets/mdi";

import { createVuetify } from "vuetify";
import * as labsComponents from "vuetify/labs/components";

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  blueprint: md3,
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#6750A4",
          secondary: "#625B71",
          tertiary: "#7D5260",
          error: "#B3261E",
          onPrimary: "#FFFFFF",
          onSecondary: "#FFFFFF",
          onTertiary: "#FFFFFF",
          onError: "#FFFFFF",
          primaryContainer: "#EADDFF",
          secondaryContainer: "#E8DEF8",
          tertiaryContainer: "#FFD8E4",
          errorContainer: "#F9DEDC",
          onPrimaryContainer: "#21005D",
          onSecondaryContainer: "#1D192B",
          onTertiaryContainer: "#31111D",
          onErrorContainer: "#410E0B",
          primaryFixed: "#EADDFF",
          primaryFixedDim: "#D0BCFF",
          onPrimaryFixed: "#21005D",
          onPrimaryFixedVariant: "#4F378B",
          secondaryFixed: "#E8DEF8",
          secondaryFixedDim: "#CCC2DC",
          onSecondaryFixed: "#1D192B",
          onSecondaryFixedVariant: "#4A4458",
          tertiaryFixed: "#FFD8E4",
          tertiaryFixedDim: "#EFB8C8",
          onTertiaryFixed: "#31111D",
          onTertiaryFixedVariant: "#633B48",
          surfaceDim: "#DED8E1",
          surface: "#FEF7FF",
          surfaceBright: "#FEF7FF",
          surfContainerLowest: "#FFFFFF",
          surfContainerLow: "#F7F2FA",
          surfContainer: "#F3EDF7",
          surfContainerHigh: "#ECE6F0",
          surfContainerHighest: "#E6E0E9",
          onSurface: "#1D1B20",
          onSurfaceVar: "#49454F",
          outline: "#79747E",
          outlineVariant: "#CAC4D0",
          inverseSurface: "#322F35",
          inverseOnSurface: "#F5EFF7",
          inversePrimary: "#D0BCFF",
          scrim: "#000000",
          shadow: "#000000",
          // Custom Colors
          late: "#FFA500",
          veryLate: "#800040",
          critical: "#B3261E",
          high: "#E25E3E",
          moderate: "#FFC26F",
          normal: "#ABC4AA",
          needsAttention: "#FFC436",
          complianceCall: "#FF9B50",
          medChange: "#97FFF4",
          vitalsCheckIn: "#088395",
          noActivity: "#A459D1",
          dcEnrollment: "#49454F",
          errorToastMain:"#F44336",
          errorToastSecondary:"#fbbbb6",
          warningToastMain:"#FF9800",
          warningToastSecondary:"#ffddb3",
          successToastMain:"#4CAF50",
          successToastSecondary:"#cae8cb",
          toastInfoMain:"#2196F3",
          toastInfoSecondary:"#b6dcfb"

        },
        variables: {
          "border-opacity": "0.125",
        },
      },
      dark: {
        colors: {
          primary: "#D0BCFF",
          secondary: "#CCC2DC",
          tertiary: "#EFB8C8",
          error: "#F2B8B5",
          onPrimary: "#381E72",
          onSecondary: "#381E72",
          onTertiary: "#492532",
          onError: "#601410",
          primaryContainer: "#4F378B",
          secondaryContainer: "#4A4458",
          tertiaryContainer: "#633B48",
          errorContainer: "#8C1D18",
          onPrimaryContainer: "#EADDFF",
          onSecondaryContainer: "#E8DEF8",
          onTertiaryContainer: "#FFD8E4",
          onErrorContainer: "#F9DEDC",
          primaryFixed: "#EADDFF",
          primaryFixedDim: "#D0BCFF",
          onPrimaryFixed: "#21005D",
          onPrimaryFixedVariant: "#4F378B",
          secondaryFixed: "#E8DEF8",
          secondaryFixedDim: "#CCC2DC",
          onSecondaryFixed: "#1D192B",
          onSecondaryFixedVariant: "#4A4458",
          tertiaryFixed: "#FFD8E4",
          tertiaryFixedDim: "#EFB8C8",
          onTertiaryFixed: "#31111D",
          onTertiaryFixedVariant: "#633B48",
          surfaceDim: "#141218",
          surface: "#141218",
          surfaceBright: "#3B383E",
          surfContainerLowest: "#0F0D13",
          surfContainerLow: "#1D1B20",
          surfContainer: "#211F26",
          surfContainerHigh:"2B2930",
          surfContainerHighest: "#36343B",
          onSurface: "#E6E0E9",
          onSurfaceVar: "#CAC4D0",
          outline: "#938F99",
          outlineVariant: "#49454F",
          inverseSurface: "#E6E0E9",
          inverseOnSurface: "#322F35",
          inversePrimary: "#6750A4",
          scrim: "#000000",
          shadow: "#000000",
            // Custom Colors
          late: "#FFA500",
          veryLate: "#800040",
          critical: "#B3261E",
          high: "#E25E3E",
          moderate: "#FFC26F",
          normal: "#ABC4AA",
          needsAttention: "#FFC436",
          complianceCall: "#FF9B50",
          medChange: "#97FFF4",
          vitalsCheckIn: "#088395",
          noActivity: "#A459D1",
          dcEnrollment: "#49454F",
        },
        variables: {
          "border-opacity": "0.125",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      md,
      mdi,
      fa,
    },
  },
});
