import gql from "graphql-tag";

export const ACTIVITY_LIST = gql`
  query activities {
    activities {
      activityId
      orgId
      label
      description
      isActive
      isDefault
      variableDefs {
        key
        required
        parseAs
      }
    }
  }
`;