import { setContext } from "apollo-link-context";
import { useUserStore } from "@/store/modules/User";

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { getAccessToken } from "@/composables/CognitoService";
import router from "@/router";
import { map } from "lodash";

const customHeaders = {};

const getAuthHeaders = async () => {
  const organizationId = useUserStore().$state.user.organizationId;
  if (!organizationId) {
    return { Authorization: await getAccessToken() };
  }

  return {
    Authorization: await getAccessToken(),
    "x-organization-id": organizationId,
    "x-tenant-id": import.meta.env.VITE_TENANT_ID,
  };
};

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...customHeaders,
      ...(await getAuthHeaders()),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === "USER_DISABLED" || message === "Not Authorised!") {
        router.push({
          name: "forbidden",
        });
      }
    });
  }

  const errorMessage = graphQLErrors
    ? `GraphQL Errors occurred: ${map(graphQLErrors, "message").join(", ")}`
    : networkError
    ? `Network Error: ${networkError.message}`
    : null;

  if (errorMessage) {
    throw new Error(errorMessage);
  }
});

const authLinks = ApolloLink.from([
  errorLink,
  authLink as unknown as ApolloLink,
  httpLink,
]);

export const apolloClient = new ApolloClient({
  link: authLinks,
  cache: new InMemoryCache(),
});

export default {
  install: (app: any) => {
    app.provide("apolloClient", apolloClient);
  },
};
