import { PatientProgramCyclePeriod } from "@/enums/patient-program-cycle.enum";
import { PATIENT_PROGRAM_CYCLES } from "@/graphql/resources/PatientComplianceCycleRepository";
import { apolloClient } from "@/plugins/graphqlclient";

export const getPaitentCompliance = async (
  patientId: string,
  period: PatientProgramCyclePeriod,
  program?: string
) => {
  const patientProgramCycles = await apolloClient.query({
    query : PATIENT_PROGRAM_CYCLES,
    variables : {
      patientId,
      activityPeriod : period,
      program,
    },
    fetchPolicy : 'network-only',
  });
  return patientProgramCycles.data.PatientProgramCycles;
};
