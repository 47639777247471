<template>
  <div v-if="showTrackerNotification" class="tracker-notification">
    <v-avatar rounded color="primary" variant="tonal" size="x-small">
      <v-icon size="small" icon="mdi-timer-play" />
    </v-avatar>
  </div>
  <template v-if="inactivityDialogVisible && socketConnected">
    <v-dialog persistent v-model="inactivityDialogVisible" color="surface">
      <v-card class="pa-8" color="surface">
        <v-card-title class="text-h6 text-primary font-weight-bold">
          <h4 class="text-h4 text-primary font-weight-bold mb-2"> Are you still here ?</h4>
        </v-card-title>
        <v-card-subtitle class="text-subtitle-2 text-onSurfaceVar font-weight-bold">
          <span class="text-subtitle-2 font-weight-bold"> Time tracking against your current activity has been
            paused.</span>
        </v-card-subtitle>
        <v-card-text class="text-onSurfaceVar text-body-2">
          <v-container fluid>
            <v-row>
              <v-col cols="8" class="d-flex align-center">
                <p class="text-onSurfaceVar text-body-1">We noticed you have not been active here since <span
                    class="text-body-1 font-weight-bold">&nbsp;{{ inActiveFromTimeAgo
                    }},&nbsp;</span> while you
                  were
                  <span class="text-body-1 font-weight-bold">&nbsp;{{ activityDescription
                    }}.&nbsp;</span><br />
                  <template v-if="maxCustomRecordTime >= customRecordSliderStep">
                    <span> The first {{ inactivityTimeout / (60 * 1000) }} minutes of your inactivity has already been
                      recorded.</span>
                    <span> You can choose how much of the remaining elapsed time you want to track, using the
                      slider on the right. </span>
                    <br />
                    <span class="text-body-1 font-weight-bold">How would you like to proceed
                      ?</span>
                  </template>
                  <template v-else>
                    <span> You can choose to continue tracking by clicking on <span
                        class="text-body-1 font-weight-bold">'Continue' </span> or end tracking
                      by clicking <span class="text-body-1 font-weight-bold">'Exit'</span> </span>
                  </template>
                </p>
              </v-col>
              <v-col cols="4 mt-n12">
                <div class="text-onSurfaceVar mt-10" v-if="maxCustomRecordTime >= customRecordSliderStep">
                  <h6 class="text-h6 font-weight-bold pb-2">
                    How much of the elapsed time would you like to track ?
                  </h6>
                  <v-slider v-model="customRecordTime" class="align-center" :step="customRecordSliderStep"
                    :max="maxCustomRecordTime" :min="minCustomRecordTime" hide-details>
                  </v-slider>
                  <h6 class="text-h6 font-weight-bold pb-4 text-center"> {{ customRecordTime }}
                    minutes
                  </h6>
                </div>
                <v-card class="information-card text-black" variant="tonal" color="tertiary"
                  v-if="maxCustomRecordTime >= customRecordSliderStep">
                  <div class="d-flex align-center justify-space-around font-weight-bold  text-onSurfaceVar">
                    <v-icon color="onSurfaceVar" size="x-large" class="mx-2">mdi-information</v-icon>
                    <span>By tracking your inactive time, you affirm its accuracy to the best of
                      your knowledge.</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template v-if="maxCustomRecordTime >= customRecordSliderStep">
            <v-btn variant="tonal" rounded="false" class="ma-2" elevation="3"
              @click="handleTimeElapsedDuringInactivity(AppActivityInactiveTimeHandleMode.RECORD_AND_EXIT)">
              Track and exit</v-btn>
            <v-btn variant="flat" rounded="false" class="ma-2" elevation="3"
              @click="handleTimeElapsedDuringInactivity(AppActivityInactiveTimeHandleMode.RECORD_AND_CONTINUE)">Track
              and Continue</v-btn>
          </template>
          <template v-else>
            <v-btn variant="tonal" rounded="false" class="ma-2" elevation="3"
              @click="handleTimeElapsedDuringInactivity(AppActivityInactiveTimeHandleMode.RECORD_AND_EXIT)">
              Exit</v-btn>
            <v-btn variant="flat" rounded="false" class="ma-2" elevation="3"
              @click="handleTimeElapsedDuringInactivity(AppActivityInactiveTimeHandleMode.RECORD_AND_CONTINUE)">Continue</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
</template>


<script setup lang="ts">
import { forEach, includes } from 'lodash';
import { ref, defineEmits, onMounted, onBeforeUnmount, watch, computed, PropType, nextTick } from 'vue';
import {
  AppActivityTrackerState,
  AppActivityTrackerTriggerType,
  AppActivityInactiveTimeHandleMode,
  AppActivityTrackerInactivityExitMode,
  AppActivityTrackerInactivityResumeMode,
  AppActivityEventType,
} from "../../../enums/app-activity-tracker.enum";
import { AppActivityTrackerEmits } from '../../../types/app-activity-tracker.type';
import { useCounter, useEventBus, useIdle, useTimeAgo } from '@vueuse/core';
import { IAppActivityTrackerInactivityCycleDetails } from '../../../interfaces/app-activity-tracker.interface';
import { CustomIntersectionObserverEntry } from '../../../interfaces/extended.interface';
import { useSocketStore } from '@/store/modules/socket.store';
import { useStopwatch } from "vue-timer-hook";
import moment from 'moment';
import { ITaskActionEvent } from '../../../interfaces/task.interface';
import { newActivityStartedEventBusKey, resetActivityStartTimeEventBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { useServerTimeSyncStore } from '../../../store/modules/server-time-sync.store';


const props = defineProps({
  initialStatus: {
    type: String,
    default: AppActivityTrackerState.RUNNING,
  },
  activityTarget: {
    type: Element,
    required: true
  },
  activityDescription: {
    type: String,
    required: true
  },
  activity: {
    type: Object as PropType<ITaskActionEvent>,
    required: false
  },
  showTrackerNotification: {
    type: Boolean,
    default: false
  },
  observerThresholds: {
    type: Array<number>,
    default: [0.25, 0.5, 0.75, 0.8, 0.9, 0.95, 0.975, 0.99, 1]
  },
  observerTrackVisibility: {
    type: Boolean,
    default: true
  },
  observerTrackDelay: {
    type: Number,
    default: 1000
  },
  heartBeatFrequency: {
    type: Number,
    default: 15000
  },
  inactivityTimeout: {
    type: Number,
    default: 1 * 60 * 1000
  },
  promptOnInactive: {
    type: Boolean,
    default: false
  },
  pauseTimerOnHidden: {
    type: Boolean,
    default: false
  },
  resetUserTimeTrackingStart: {
    type: Boolean,
    default: false
  }
});

const {
  initialStatus,
  activityTarget,
  observerThresholds,
  observerTrackVisibility,
  observerTrackDelay,
  inactivityTimeout,
  heartBeatFrequency,
  promptOnInactive,
  pauseTimerOnHidden,
  resetUserTimeTrackingStart
} = props;

const emit = defineEmits<AppActivityTrackerEmits>();

const { inc: idleCountInc, count: idleCount } = useCounter();
const { idle, lastActive, reset: resetIdleTimer } = useIdle(inactivityTimeout);

//@ts-ignore
const enterTime = ref<number | null>(GoTime.now());
const userActiveOnLastHeartBeat = ref(true);
const userActiveSince = ref(Date.now());
//@ts-ignore
const userTimeTrackingStartFrom = ref(GoTime.now());
const isPageVisible = ref(true);
const trackedElementInView = ref(true);
const timerStatus = ref(initialStatus);
const observer = ref<IntersectionObserver>();
const isLastActiveTimeLogSaved = ref(false);

const inactivityDialogVisible = ref(false);
const inactiveSince = ref(0);

const customRecordTime = ref(0);
const customRecordSliderStep = 0.25;

const inactivityStopwatch = useStopwatch(0, false);

const socketStore = useSocketStore();
const socketConnected = ref(socketStore.connected);
const resetActivityStartTimeEventBus = useEventBus(resetActivityStartTimeEventBusKey);
const newActivityStartedEventBus = useEventBus(newActivityStartedEventBusKey);

let heartBeatInterval = null as null | ReturnType<typeof setInterval>;

socketStore.$subscribe((mutation, state) => {
  socketConnected.value = state.connected;
});


const inActiveFromTimeAgo = computed(() => {
  return useTimeAgo(new Date(inactiveSince.value)).value.replace('"', '');
});

const minCustomRecordTime = 0;

const maxCustomRecordTime = computed(() => {
  const timerReading = {
    days: inactivityStopwatch.days.value,
    hours: inactivityStopwatch.hours.value,
    minutes: inactivityStopwatch.minutes.value,
    seconds: inactivityStopwatch.seconds.value,
  }
  const elapsedTime = moment.duration(timerReading);

  return Math.round(elapsedTime.asMinutes());
});

watch(idle, (idleValue) => {
  if (idleValue) {
    idleCountInc();
    inactivityDialogVisible.value = promptOnInactive;
    inactiveSince.value = inactiveSince.value > 0 && promptOnInactive ? inactiveSince.value : lastActive.value;
  } else {
    userActiveSince.value = Date.now();
  }
  const { triggerType, timerStatus } = idleValue ?
    { triggerType: AppActivityTrackerTriggerType.ON_USER_INACTIVE, timerStatus: AppActivityTrackerState.PAUSED } :
    { triggerType: AppActivityTrackerTriggerType.ON_USER_ACTIVE, timerStatus: AppActivityTrackerState.RUNNING };


  emit('userActivityStateChange', {
    triggerType,
    userInactive: idle.value,
    userLastActive: lastActive.value,
    userInactivityCount: idleCount.value,
    lastInactivityCycle: currentInactivityCycleDetails(AppActivityEventType.USER_ACTIVITY_STATE_CHANGE),
    type: AppActivityEventType.USER_ACTIVITY_STATE_CHANGE,
    //@ts-ignore
    actionAt: GoTime.now()
  });

  emitTimerStatechange({ triggerType, status: timerStatus });
  resetActivityStartTimeEventBus.emit();
});

watch(inactivityDialogVisible, (inactivityDialogVisibleValue) => {
  if (inactivityDialogVisibleValue) {
    inactivityStopwatch.start();
    isLastActiveTimeLogSaved.value = false;
  } else {
    customRecordTime.value = 0;
    inactivityStopwatch.reset();
  }
});


watch(() => props.resetUserTimeTrackingStart, (resetValue) => {
  if (resetValue) {
    //@ts-ignore
    userTimeTrackingStartFrom.value = GoTime.now();
    emit('disableResetTimer');
  }
});

watch(socketConnected, (newConnectionState) => {
  if (!newConnectionState) {
    emitTimerStatechange({
      triggerType: AppActivityTrackerTriggerType.ON_SOCKET_DISCONNECT,
      status: AppActivityTrackerState.SUSPENDED
    });
  } else {
    emitTimerStatechange({
      triggerType: AppActivityTrackerTriggerType.ON_SOCKET_RECONNECT,
      status: AppActivityTrackerState.RUNNING
    });
  }
});


const emitTrackerExit = () => {
  emit('trackerExit', {
    triggerType: AppActivityTrackerTriggerType.ON_TRACKER_EXIT,
    userInactive: idle.value,
    userLastActive: lastActive.value,
    userInactivityCount: idleCount.value,
    lastInactivityCycle: currentInactivityCycleDetails(AppActivityEventType.TRACKER_EXIT),
    type: AppActivityEventType.TRACKER_EXIT,
    //@ts-ignore
    actionAt: GoTime.now(),
  });
  resetActivityStartTimeEventBus.emit();
}

const beforeUnloadHandler = () => {
  emitTrackerExit();
  return undefined;
}

onMounted(() => {
  window.addEventListener('beforeunload', beforeUnloadHandler);

  const observerOpts = {
    threshold: observerThresholds,
    trackVisibility: observerTrackVisibility,
    delay: observerTrackDelay
  };

  observer.value = new IntersectionObserver((entries: CustomIntersectionObserverEntry[]) => {
    forEach(entries, (entry) => {
      const elementInView = entry.isIntersecting && (entry["isVisible"] || true);
      trackedElementInView.value = elementInView;
      const { triggerType, timerStatus } = elementInView ?
        { triggerType: AppActivityTrackerTriggerType.ON_ELEMENT_IN_VIEWPORT, timerStatus: AppActivityTrackerState.RUNNING } :
        { triggerType: AppActivityTrackerTriggerType.ON_ELEMENT_NOT_IN_VIEWPORT, timerStatus: AppActivityTrackerState.PAUSED };
      const type = AppActivityEventType.TRACKED_ELEMENT_INTERSECTION_CHANGE;
      emit('targetIntersectionChange', {
        triggerType,
        entry,
        type,
        lastInactivityCycle: currentInactivityCycleDetails(type),
        //@ts-ignore
        actionAt: GoTime.now(),
        userInactive: idle.value,
        userLastActive: lastActive.value,
        userInactivityCount: idleCount.value,
      });
      emitTimerStatechange({ triggerType, status: timerStatus });
    });
  }, observerOpts);

  observer.value.observe(activityTarget);

  document.addEventListener('visibilitychange', handlePageVisibilityChange);

  heartBeatInterval = setInterval(() => {
    emit('heartBeat', {
      triggerType: AppActivityTrackerTriggerType.ON_HEART_BEAT,
      isPageVisible: isPageVisible.value,
      isTrackedElementInView: trackedElementInView.value,
      userInactive: idle.value,
      userLastActive: lastActive.value,
      userInactivityCount: idleCount.value,
      lastInactivityCycle: currentInactivityCycleDetails(AppActivityEventType.HEART_BEAT),
      type: AppActivityEventType.HEART_BEAT,
      //@ts-ignore
      actionAt: GoTime.now()
    });
    userActiveOnLastHeartBeat.value = !idle.value;
  }, heartBeatFrequency);

  newActivityStartedEventBus.on(() => {
    emitTrackerExit();
    //@ts-ignore
    userTimeTrackingStartFrom.value = GoTime.now();
    emit('disableResetTimer');
  })
});

onBeforeUnmount(() => {
  emitTrackerExit();
  observer.value?.unobserve(activityTarget);
  document.removeEventListener('visibilitychange', handlePageVisibilityChange);
  document.removeEventListener('beforeunload', beforeUnloadHandler);
  if (heartBeatInterval) {
    clearInterval(heartBeatInterval)
  }
});

const emitTimerStatechange = ({
  status,
  triggerType
}: { triggerType: AppActivityTrackerTriggerType; status: AppActivityTrackerState; }) => {
  timerStatus.value = inactivityDialogVisible.value || !socketConnected.value ? AppActivityTrackerState.SUSPENDED : status;

  const isTrackedElementHidden = includes([AppActivityTrackerTriggerType.ON_ELEMENT_NOT_IN_VIEWPORT, AppActivityTrackerTriggerType.ON_HIDDEN_VIEWPORT], triggerType);

  const shouldEmitPaused = isTrackedElementHidden && pauseTimerOnHidden;

  const timerStatusToEmit = shouldEmitPaused ? AppActivityTrackerState.PAUSED : timerStatus.value;

  const type = AppActivityEventType.TIMER_STATE_CHANGE;

  const lastInactivityCycle = currentInactivityCycleDetails(type);


  if (isTrackedElementHidden && timerStatus.value === AppActivityTrackerState.PAUSED && !shouldEmitPaused) {
    emit('timerStateChange', {
      triggerType,
      timerStatus: AppActivityTrackerState.PAUSE_SUPPRESSED,
      type,
      lastInactivityCycle,
      //@ts-ignore
      actionAt: GoTime.now(),
      userInactive: idle.value,
      userLastActive: lastActive.value,
      userInactivityCount: idleCount.value,
    });
  } else {
    emit('timerStateChange', {
      triggerType,
      timerStatus: timerStatusToEmit,
      type,
      lastInactivityCycle,
      //@ts-ignore
      actionAt: GoTime.now(),
      userInactive: idle.value,
      userLastActive: lastActive.value,
      userInactivityCount: idleCount.value,
    });
  }
};

const handlePageVisibilityChange = () => {
  const visibilityState = document.visibilityState;
  isPageVisible.value = !document.hidden;
  const triggerType =
    visibilityState === "hidden"
      ? AppActivityTrackerTriggerType.ON_HIDDEN_VIEWPORT
      : AppActivityTrackerTriggerType.ON_VISIBLE_VIEWPORT;

  emitTimerStatechange({
    triggerType,
    status: isPageVisible.value ? AppActivityTrackerState.RUNNING : AppActivityTrackerState.PAUSED
  });

  emit('pageVisibilityChange', {
    triggerType,
    type: AppActivityEventType.PAGE_VISIBILITY_CHANGE,
    lastInactivityCycle: currentInactivityCycleDetails(AppActivityEventType.PAGE_VISIBILITY_CHANGE),
    //@ts-ignore
    actionAt: GoTime.now(),
    userInactive: idle.value,
    userLastActive: lastActive.value,
    userInactivityCount: idleCount.value,
  });
};


const resetInactivityState = () => {
  inactivityDialogVisible.value = false;
  resetIdleTimer();
};

const currentInactivityCycleDetails = (activityEventType: AppActivityEventType): IAppActivityTrackerInactivityCycleDetails => {
  const activityStart = enterTime.value!;
  const userActiveFrom = userActiveSince.value;
  let userWasActive = userActiveOnLastHeartBeat.value;
  const inactivityPrompted = inactivityDialogVisible.value;
  const userTimeTrackingStartAt = userTimeTrackingStartFrom.value;
  const inactivityEnd = Date.now();
  const idleCycleCount = idleCount.value;
  const inactivityDuration = inactiveSince.value > 0 ? (inactivityEnd - inactiveSince.value) : 0;
  const customRecordTimeDuration = customRecordTime.value * 60 * 1000;
  const timeToRecord = customRecordTimeDuration > 0 ? customRecordTimeDuration : 0;
  if ([AppActivityEventType.USER_INACTIVITY_RESUME, AppActivityEventType.USER_INACTIVITY_EXIT].includes(activityEventType) ||
    (AppActivityEventType.HEART_BEAT === activityEventType && idle.value && userWasActive && inactivityPrompted)) {
    if (!isLastActiveTimeLogSaved.value && inactivityDialogVisible.value) {
      isLastActiveTimeLogSaved.value = true;
    } else if (isLastActiveTimeLogSaved.value && inactivityDialogVisible.value) {
      userWasActive = false;
    }
    emit('disableResetTimer');
  }
  return {
    activityStart,
    userActiveFrom,
    userWasActive,
    userTimeTrackingStartAt,
    inactivityPrompted,
    inactivityEnd,
    idleCycleCount,
    inactivityDuration,
    inactivityTimeout,
    timeToRecord
  };
};

const handleSkipAndExit = () => {
  handleTrackerInactivityExit(AppActivityTrackerInactivityExitMode.EXIT_ON_INACTIVITY_SKIP);
};

const handleRecordAndExit = () => {
  handleTrackerInactivityExit(AppActivityTrackerInactivityExitMode.EXIT_ON_INACTIVITY_RECORD);
};

const handleRecordAndContinue = () => {
  handleTrackerInactivityResume(AppActivityTrackerInactivityResumeMode.CONTINUE_AFTER_RECORD);
};

const handleSkipAndContinue = () => {
  handleTrackerInactivityResume(AppActivityTrackerInactivityResumeMode.CONTINUE_AFTER_SKIP);
}

const handleTrackerInactivityExit = (mode: AppActivityTrackerInactivityExitMode) => {
  const lastInactivityCycle = currentInactivityCycleDetails(AppActivityEventType.USER_INACTIVITY_EXIT);
  emit('trackerInactivityExit', {
    mode,
    type: AppActivityEventType.USER_INACTIVITY_EXIT,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    lastInactivityCycle,
    //@ts-ignore
    actionAt: GoTime.now(),
    userInactive: idle.value,
    userLastActive: lastActive.value,
    userInactivityCount: idleCount.value,
  });
  idleCount.value = 0;
};

const handleTrackerInactivityResume = (mode: AppActivityTrackerInactivityResumeMode) => {
  const lastInactivityCycle = currentInactivityCycleDetails(AppActivityEventType.USER_INACTIVITY_RESUME);
  emit('trackerInactivityResume', {
    mode,
    type: AppActivityEventType.USER_INACTIVITY_RESUME,
    triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
    lastInactivityCycle,
    //@ts-ignore
    actionAt: GoTime.now(),
    userInactive: idle.value,
    userLastActive: lastActive.value,
    userInactivityCount: idleCount.value,
  });
  inactiveSince.value = 0;
  resetActivityStartTimeEventBus.emit();
};

const handleTimeElapsedDuringInactivity = (handleMode: AppActivityInactiveTimeHandleMode) => {
  const inactiveTimeElapsedHandlers: Record<AppActivityInactiveTimeHandleMode, () => void> = {
    [AppActivityInactiveTimeHandleMode.SKIP_AND_EXIT]: handleSkipAndExit,
    [AppActivityInactiveTimeHandleMode.RECORD_AND_EXIT]: handleRecordAndExit,
    [AppActivityInactiveTimeHandleMode.RECORD_AND_CONTINUE]: handleRecordAndContinue,
    [AppActivityInactiveTimeHandleMode.SKIP_AND_CONTINUE]: handleSkipAndContinue,
  }

  const handler = inactiveTimeElapsedHandlers[handleMode] ||
    inactiveTimeElapsedHandlers[AppActivityInactiveTimeHandleMode.SKIP_AND_EXIT];

  resetInactivityState();
  handler();
};


</script>

<style lang="scss">
.tracker-notification {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.information-card {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px;
}
</style>
