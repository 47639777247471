import { io } from "socket.io-client";

const URL = import.meta.env.VITE_SOCKET_IO_SERVER;

export const socket = io(URL, {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: import.meta.env.VITE_SOCKET_IO_RECONNECTION_DELAY,    
    transports: ["websocket", "polling"] // use WebSocket first, if available
});
