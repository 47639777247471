<template>
  <v-sheet class="mt-2" color="transparent">
    <v-row no-gutters class="d-flex justify-center">
      <template v-for="(detail, type) in bucketDetails" :key="type">

        <v-col cols="3" sm="3"
          v-if="patientActivePrograms.includes(type) && billingEligiblePrograms.includes(type as Program)">
          <v-card :disabled="!patientActivePrograms.includes(type)" flat class="mx-1 text-primary bg-surface"
            elevation="1">
            <v-card-title class="text-subtitle-1 text-high-emphasis font-weight-black text-center mt-1">{{
              type.toUpperCase()
            }}</v-card-title>
            <v-card-title class="text-center text-h56 text-bold">
              ${{ round((detail.revenue!), 3) }}
              <div class="text-subtitle-2 pb-n4">{{ detail.bucket || 'N/A' }}</div>
            </v-card-title>
          </v-card>
        </v-col>
      </template>
      <v-col cols="3" sm="3">
        <v-card flat class="mx-1 text-primary bg-surface" elevation="1">
          <v-card-title
            class="text-subtitle-1 text-high-emphasis font-weight-black text-center mt-1">Total</v-card-title>
          <v-card-title class="text-center text-h56 text-bold">
            ${{ totalReimbursement }}
            <div class="text-subtitle-2 pb-n4"> Potential Reimbursement</div>
          </v-card-title>
        </v-card>

      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup lang="ts">
import { PropType, ref, watch, onMounted, computed } from 'vue';
import { round, isEmpty } from 'lodash';
import { useEventBus } from "@vueuse/core";

import { IProgramBucketsBillingDetailsProps } from '../../../../../interfaces/billing.interface';
import { patientActiveSubscribedProgramKey } from '@/events/bus-keys/billing-program-subscription-event.bus-keys';
import { IPatientProgramCycle } from '@/interfaces/patient-program-cycle.interface';
import { Program } from '@/enums/patient-program.enum';

const props = defineProps({
  propBucketDetails: {
    type: Object as PropType<IProgramBucketsBillingDetailsProps>,
    required: true,
  },
  programsSelectedForBilling: {
    type: Array as PropType<IPatientProgramCycle[]>,
    required: true,
  }
});

const bucketDetails = ref({
  RPM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0 },
  PCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0 },
  CCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0 },
  CCCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0 }
} as IProgramBucketsBillingDetailsProps);

const patientActivePrograms = ref<string[]>([]);

const patientActiveSubscribedPrograms = useEventBus(patientActiveSubscribedProgramKey);

const billingEligiblePrograms = computed(() => {
  return props.programsSelectedForBilling.map((billableProgram) => billableProgram.program);
});

const totalReimbursement = computed(() => {
  return Object.values(bucketDetails.value).reduce((acc, curr) => {
    return acc + (curr.revenue || 0);
  }, 0).toFixed(2);
});

watch(() => props.propBucketDetails, (newProps) => {
  if (!isEmpty(newProps)) {
    bucketDetails.value = newProps;
  }
}, { deep: true, immediate: true }
);


onMounted(() => {
  patientActiveSubscribedPrograms.on((e) => {
    patientActivePrograms.value = e;
  })
})
</script>
