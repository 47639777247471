import { useSocketStore } from "@/store/modules/socket.store";
import { useClientMetaStore } from '../store/modules/client-meta.store';



export const pushToServer = ({eventType, payload}:{eventType: string, payload: any}) => {
    /* we push to server without specifying tenant/room, the injected session property will be used by the server to determine tenancy */
    const { socket, session } = useSocketStore();
    const { meta } = useClientMetaStore();
    socket.emit(eventType, {
        payload, 
        context: {
            session,
            meta
        }
    });
};