<template>
  <v-card class="w-100 pa-6" flat>
    <v-card-title v-if="isFieldVisible" class="text-center text-h5">Task Assignment</v-card-title>
    <v-form ref="taskAssigningForm" class="mt-5" @submit.prevent="assignTaskToNote">
      <v-row>
        <v-col md="6" sm="12" cols="12">
          <v-text-field label="Title*" v-model="form.title" variant="outlined" color="primary" hint="Enter title"
            :rules="titleRules" class="text-left"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-select label="Task Queue*" variant="outlined" color="primary" hint="Enter task queue"
            :rules="[(v: any) => !!v || 'Task queue is required']" v-model="form.taskQueue" :items="availableTaskQueues"
            class="text-left" item-title="label" item-value="value.id" />
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-combobox multiple required variant="outlined" color="primary" dense label="Assignees*"
            hint="Enter Assignees" @update:search="triggerLoadFilteredAssignees" v-model="form.assignees"
            class="text-left" :items="filteredAssignees" :loading="assigneesLoading" item-text="title"
            item-value="value.id"
            :rules="[(v) => !!v && v.length > 0 || 'Assignees are required', (v) => validateAssignees(v) || 'One or more invalid assignee selected']"
            chips closable-chips clearable></v-combobox>
        </v-col>
        <v-col md="6" sm="12" cols="12">
          <v-select required variant="outlined" dense label="Priority*" color="primary" hint="Select priority"
            :rules="[(v: any) => !!v || 'Priority is required']" v-model="form.priority" :items="priorities"></v-select>
        </v-col>
        <v-col cols="12">
          <VueDatePicker class="vt-date-time-picker-btn" v-model="dueDate" v-bind:teleport-center=true :is-24="false"
            utc time-picker-inline :min-date="getPastOneDay()">
            <template #trigger>
              <v-text-field v-model="dueDateFormatted.date" :label="dueDateLabel()" :readonly="true" variant="outlined"
                hint="When does this need to be done ?" dense clearable
                :rules="[(value) => taskDueValidation(value) || 'please select a future time']"
                @click:clear="dueDate = ''" />
            </template>
          </VueDatePicker>
        </v-col>
        <v-col cols="12" v-if="isFieldVisible">
          <v-textarea label="Description" v-model="formDescription" color="primary" variant="outlined"
            hint="Provide additional details to the assignee(s)" />
        </v-col>
      </v-row>
      <v-row justify="end" v-if="isFieldVisible">
        <v-col sm="12" md="3" class="text-center">
          <v-btn color="primary" class="text-white rounded-xl text-none px-12" @click="handleCancelEvent"
            variant="outlined" block>
            Cancel
          </v-btn>
        </v-col>
        <v-col sm="12" md="3" class="text-center">
          <v-btn color="primary" class="text-white rounded-xl text-none px-12" variant="flat" type="submit" block>
            Assign Task
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <Loader :overlay="loading" />
</template>

<script setup lang="ts">
import { TaskPriority } from '@/enums/task.enum';
import { enumToArray } from '@/helpers/utils.helpers';
import moment from 'moment';
import { computed, ref, onMounted, watch, PropType } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { extractErrorMessage, getPastOneDay } from '@/composables/FormUtility';
import { useRoute } from 'vue-router';
import Loader from '../common/Loader.vue';
import { IUser } from '@/interfaces/user.interface';
import { AssignTaskInput, PatientNotes } from '@/interfaces/notes.interface';
import { IAssigneeInput, IAssigneeItem, ITaskQueueItem, ITaskQueue } from '../../interfaces/task.interface';
import { IOrganizationUserFilter } from '@/interfaces/IOrganization';
import { debounce } from 'lodash';

const route = useRoute();
const loading = ref(false);
const isFieldVisible = ref(true);
const selectedNoteId = ref('');

const patientId = ref(route.params.patientId as string);
const taskAssigningForm = ref();
const form = ref({
  title: '',
  taskQueue: null as ITaskQueue | null,
  assignees: [] as string[],
  priority: '' as TaskPriority,
  dueDate: '',
});
const formDescription = ref('');
const priorities = enumToArray(TaskPriority);
const dueDate = ref<string>('');
const dueDateFormatted = computed(() => {
  if (!dueDate.value) {
    return { date: '', daysUntilDue: 0 };
  }
  const currentDate = moment();
  const localTime = moment(dueDate.value);
  const formattedDueDate = localTime.format('MMM/DD/YYYY, h:mm A');
  const daysUntilDue = localTime.diff(currentDate, 'days');
  return { date: `${formattedDueDate}`, daysUntilDue };
});

const dueDateLabel = () => {
  if (!dueDateFormatted.value.date) {
    return 'Due Date and Time';
  }
  return `Due in ${dueDateFormatted.value.daysUntilDue} days`;
}

const taskDueValidation = (value: string) => !moment(value, 'MMM/DD/YYYY, h:mm A').isBefore(moment(), 'minute');

const titleRules = [
  (v: string) => v.trim() !== '' || 'Title is required',
  (v: string) => v.length <= 75 || 'title must be less than or equal to 75',
];

watch(
  () => dueDateFormatted.value.date,
  (newVal) => {
    form.value.dueDate = newVal;
  },
);
const isValidForm = async () => {
  const isFormValid: boolean = (await taskAssigningForm.value.validate()).valid;
  return isFormValid;
}

const emit = defineEmits(["assign-task-form-data-changed", "cancel-add-task", "task-added", "isValidForm"]);

watch(form.value,
  (newVal) => {
    const isFormValid = validateForm();
    emit("assign-task-form-data-changed", newVal);
    emit("isValidForm", isFormValid);
  },
  { deep: true }
);

const validateForm = () => {
  const { title, taskQueue, assignees, priority, dueDate } = form.value;
  const areAssigneesValid = validateAssignees(assignees);
  const isDueDateValid = taskDueValidation(dueDate);
  return (
    title.trim() !== "" &&
    taskQueue &&
    assignees.length > 0 &&
    priority &&
    areAssigneesValid &&
    isDueDateValid
  );
};

const props = defineProps({
  isAssignTaskFormOpen: Boolean,
  noteId: String,
  taskQueueLoader: {
    type: Function as PropType<() => Promise<ITaskQueueItem[]>>,
    required: true,
  },
  userHandler: {
    type: Function as PropType<() => IUser>,
    required: true,
  },
  listAssignees: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
  assignTaskHandler: {
    type: Function as PropType<({ noteId, taskInput }: { noteId: string, taskInput: AssignTaskInput }) => Promise<PatientNotes>>,
    required: true,
  },
});

const availableTaskQueues = ref<ITaskQueueItem[]>([]);
const filteredAssignees = ref<IAssigneeItem[]>([]);
const selectedAssignees = ref<IAssigneeItem[]>([]);
const assigneesLoading = ref(false);


const loadFilteredAssignees = async (search: string) => {
  try {
    assigneesLoading.value = true;
    const searchData = {
      orgUserName: search,
      isActive: true
    } as IOrganizationUserFilter;
    const dataLoader = await props.listAssignees.dataLoader(searchData)
    filteredAssignees.value = search ?
      [...(dataLoader.map((data) => {
        return {
          title: data.label,
          value: data.value,
        };
      })), ...selectedAssignees.value]
      : [];
    assigneesLoading.value = false;
  } catch (error) {
    const { message } = error as Error
    extractErrorMessage(message);
  }
};


const triggerLoadFilteredAssignees = debounce(loadFilteredAssignees, 300)

const validateAssignees = (value: any[]) => {
  return value && value.length > 0 && value.every((item) => item.value !== undefined);
};

const assignTaskToNote = async () => {
  try {
    const isFormValid = await isValidForm();
    if (!isFormValid) {
      return;
    }

    loading.value = true;

    const taskInput = {
      taskQueueId: form.value.taskQueue?.id,
      task: {
        title: form.value.title,
        description: formDescription.value,
        priority: form.value.priority,
        dueDate: form.value.dueDate,
        assignees: form.value.assignees.map((assignee: any) => assignee.value) as [string],
        requestorId: patientId.value
      }
    };

    await props.assignTaskHandler({
      noteId: selectedNoteId.value,
      taskInput: taskInput
    });

    loading.value = false;
    emit("task-added");
  } catch (error) {
    loading.value = false;
    const err = error as Error;
    extractErrorMessage(err.message);
  }
};


const handleCancelEvent = () => {
  emit("cancel-add-task");
}

onMounted(async () => {
  if (props.isAssignTaskFormOpen) {
    isFieldVisible.value = false;
  }
  if (props.noteId) {
    selectedNoteId.value = props.noteId;
    patientId.value = route.params.patientId as string;
  }
  availableTaskQueues.value = await props.taskQueueLoader();
});

</script>
