<template>
  <v-card class="h-100 mx-2" color="surfContainer" elevation="2">
    <v-card-item class="bg-surface pb-0">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-poll</v-icon>
          <div class="w-50 d-flex flex-wrap">
            <span class="ml-4"> Reports </span>
            <span class="ml-4 v-card-subtitle flex-1-1-100">Add and manage reports for your organization</span>
          </div>
          <div class="w-50 d-flex justify-end align-center">
            <v-tabs v-model="reportTab" color="primary" class="bg-surface text-primary" density="compact">
              <v-tab :value="ReportType.BILLING_OVERVIEW">
                <div class="d-flex flex-wrap text-subtitle-1 font-weight-bold">
                  <v-icon size="x-large">mdi-finance</v-icon>
                  <span class="ml-2">Billing Overview</span>
                </div>
              </v-tab>
              <v-tab :value="ReportType.ACTIVITY_REPORT">
                <div class="d-flex flex-wrap text-subtitle-1 font-weight-bold">
                  <v-icon size="x-large"> mdi-timer-outline</v-icon>
                  <span class="ml-2">Activity Report</span>
                </div>
              </v-tab>
            </v-tabs>
          </div>
        </div>
        <v-divider thickness="1" class="mt-2" />
      </template>
    </v-card-item>
    <v-sheet class="w-100  pa-2" color="surfContainer">
      <v-window v-model="reportTab" class="w-100 window-container  rounded-lg">
        <v-window-item :value="ReportType.BILLING_OVERVIEW">
          <org-program-billing :org-id="orgId" :data-loader="billingReportDependencies.dataLoader"
            :cycle-selector-props="billingReportDependencies.cycleSelectorProps" :audit-event-creation="auditEventCreation"
            :billing-configuration-dependencies="billingConfigurationDependencies" />
        </v-window-item>
        <v-window-item :value="ReportType.ACTIVITY_REPORT">
          <org-cycle-activity :org-id="orgId" :data-loader="activityReportDependencies.dataLoader"
            :cycle-selector-props="activityReportDependencies.cycleSelectorProps" />
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-card>

</template>
<script setup lang="ts">
import OrgProgramBilling from './billing/OrgProgramBilling.vue';
import OrgCycleActivity from './activity/OrgCycleActivity.vue';
import { IOrgBillingConfigurationDependencies, IOrgBillingReportDependencies } from '../../../interfaces/billing.interface';
import { PropType, ref } from 'vue';
import { ReportType } from '@/enums/reports.enum';
import { IOrgActivityReportDependencies } from '@/interfaces/activity-log.interface';
import { AuditEvent } from '@/interfaces/audit-event.interface';

const { billingReportDependencies } = defineProps({
  orgId: {
    type: String,
    required: true,
  },
  billingReportDependencies: {
    type: Object as PropType<IOrgBillingReportDependencies>,
    required: true,
  },
  activityReportDependencies: {
    type: Object as PropType<IOrgActivityReportDependencies>,
    required: true,
  },
  billingConfigurationDependencies: {
    type: Object as PropType<IOrgBillingConfigurationDependencies>,
    required: true,
  },
  auditEventCreation: {
    type: Function as PropType<(auditEventInput: AuditEvent) => Promise<AuditEvent>>,
    required: false,
  }
});

const reportTab = ref(ReportType.BILLING_OVERVIEW);
</script>
