<template>
  <v-card :class="$vuetify.display.mdAndUp ? 'device-add-form mx-auto' : ''">
    <v-card-title class="text-center py-5">
      <span class="text-h6 text-primary font-weight-bold"> {{
    isFormEdit ? "Edit Tenovi Device" : "Add Tenovi Device" }}</span>
    </v-card-title>
    <v-container>
      <v-form ref="deviceInventoryForm" @submit.prevent="handleAddDevice" class="px-4">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select variant="outlined" color="primary" :items="serviceProviderDetails"
              v-model="deviceFormInput.serviceProviderId" :rules="[(v) => !!v || 'Select a service provider']"
              label="Service Provider*" required></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select :items="inventoryItemTypeDetails" variant="outlined" color="primary" return-object
              :disabled="isFormEdit" v-model="selectedItemType" :rules="[(v) => !!v || 'Select an item type']"
              label="Device Type*" required></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Gateway ID*" variant="outlined" color="primary" :disabled="isFormEdit"
              v-model="deviceFormInput.gatewayId" :rules="!isFormEdit ? [(v) => !!v || 'Gateway id is required', (v) =>
    isValidGatewayId(v) || 'Gateway ID should be of alphanumeric and length 12'] : []" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Name*" variant="outlined" :disabled="!isNameFieldDisabled" color="primary"
              :loading="isLoading" @input="loadDeviceName" v-model="deviceFormInput.name"
              :rules="[(v) => !!v || 'Name is required', nameValidation]" required></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="pt-3">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" @click="cancelAdding" rounded="false" variant="tonal"
                elevation="3" block>
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-center">
              <v-btn v-if="!isFormEdit" color="primary" class="text-white" variant="elevated" type="submit"
                rounded="false" elevation="3" block>
                Add
              </v-btn>
              <v-btn v-else color="primary" class="text-white" variant="elevated" @click="editInventoryDevice"
                density="comfortable" rounded="false" elevation="3" block>
                Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>
<script setup lang="ts">
import { IInventoryItemData, IInventoryItemInput, IInventoryItemTypeOption, IServiceProviderOption, ITenoviMeta } from '@/interfaces/InventoryItem.interface';
import { debounce, find, get, map } from 'lodash';
import { PropType, computed, watchEffect, watch } from 'vue';
import { onMounted, reactive, ref } from 'vue';
import { checkDeviceNameExists } from '@/services/inventory.service';
import { isValidGatewayId } from '@/composables/FormUtility';

const serviceProviderName = ref("");
const isFormEdit = ref(false);

const deviceFormInput = ref({
  serviceProviderId: "",
  gatewayId: "",
  name: "",
});

const isNameFieldDisabled = computed(() => {
  return deviceFormInput.value.serviceProviderId && selectedItemType.value && (isFormEdit.value || deviceFormInput.value.gatewayId);
})

const { serviceProviders, inventoryItemInput, inventoryItemData } = defineProps({
  serviceProviders: {
    type: Array as () => IServiceProviderOption[],
    required: true,
  },
  inventoryItemInput: {
    type: Object as PropType<IInventoryItemInput>,
    required: true,
  },
  inventoryItemData: {
    type: Object as PropType<IInventoryItemData>,
    required: false,
  }
});

const inventoryItemTypeDetails = ref([] as IInventoryItemTypeOption[]);
const selectedItemType = ref(null as IInventoryItemTypeOption | null);

const getInventoryItemTypes = async (serviceProviderId: string) => {
  const { dataLoader } = inventoryItemInput;
  const inventoryItemTypes = await dataLoader(serviceProviderId);
  const inventoryItemTypeOptions = map(
    inventoryItemTypes,
    (inventoryItemTypeDetail) => ({
      title: inventoryItemTypeDetail.label,
      value: inventoryItemTypeDetail.type,
    })
  );
  inventoryItemTypeDetails.value = inventoryItemTypeOptions;
};

const deviceInventoryForm = ref();
const serviceProviderDetails = ref([] as IServiceProviderOption[]);
const emit = defineEmits(["deviceAdded", "addDeviceCancelled", "editInventoryDevice"]);

const isValidForm = async () => {
  const isFormValid: boolean = (await deviceInventoryForm.value.validate())
    .valid;
  return isFormValid;
};

const isLoading = ref(false);
const isNameExist = ref(false);

const isNameExists = async (name: string) => {
  isLoading.value = true;
  isNameExist.value = await checkDeviceNameExists(name);
  isLoading.value = false;
  await isValidForm();
}

watch(() => deviceFormInput.value.name, () => {
  if (!isFormEdit.value) {
    loadDeviceName();
  }
})

const loadDeviceName = debounce(async () => {
  await isNameExists(deviceFormInput.value.name);
}, 500);

const nameValidation = computed(() => {
  const isDuplicate = ((!(deviceFormInput.value.name === inventoryItemData?.name))) ? isNameExist.value ? 'name is already Exists' : true : true;
  return isDuplicate;
})

const handleAddDevice = async () => {
  const checkAllFields = await isValidForm();
  if (!checkAllFields) return;
  const { title, value } = selectedItemType.value as IInventoryItemTypeOption;
  const { gatewayId, name, serviceProviderId } = deviceFormInput.value;
  const deviceData = {
    serviceProviderId,
    deviceInput: {
      name,
      itemType: {
        label: title,
        type: value,
      },
      gatewayId
    }
  };
  emit("deviceAdded", deviceData);
};

const cancelAdding = () => {
  isFormEdit.value = false;
  emit("addDeviceCancelled");
};

watchEffect(() => {
  const { serviceProviderId } = deviceFormInput.value;
  if (serviceProviderId && selectedItemType.value && !isFormEdit.value && (isFormEdit.value || deviceFormInput.value.gatewayId)) {
    const formattedName = `${deviceFormInput.value.gatewayId}-${selectedItemType.value.title}`
    deviceFormInput.value.name = formattedName;
  }
});

const editInventoryDevice = async () => {
  const checkAllFields = await isValidForm();
  if (checkAllFields) {
    const editDetails = {
      sourceId: inventoryItemData!.id,
      name: deviceFormInput.value.name,
    }
    emit("editInventoryDevice", editDetails)
  }
}


onMounted(() => {
  if (inventoryItemData) {
    isFormEdit.value = true;
    const { name, type, meta } = inventoryItemData;
    deviceFormInput.value = {
      serviceProviderId: get(serviceProviderDetails.value, '[0].value', ''),
      name,
      gatewayId: (meta as ITenoviMeta).device.hardwareUuid,
    };
    selectedItemType.value = {
      title: type?.label || '',
      value: type?.type || '',
    };
  }
  serviceProviderDetails.value = serviceProviders;
  serviceProviderName.value = get(serviceProviderDetails.value, '[0].title', '')
  deviceFormInput.value.serviceProviderId = get(serviceProviderDetails.value, '[0].value', '');
  getInventoryItemTypes(deviceFormInput.value.serviceProviderId)
});
</script>
<style scoped>
.device-add-form {
  width: 800px;
}
</style>
