<template>
  <v-card :color="toastColor" class="pa-1" elevation="8" variant="flat">
    <v-card-title class="font-weight-bold text-subtitle-1 bg-surface text-onSurfaceVar">Patient user link
      request</v-card-title>
    <v-card-item>
      <span class="text-primary">{{ info.userFirstName }} {{ info.userLastName }}</span>
      has sent a link request for the patient below with the relationship marked as
      {{ info.userRelationship }}.
    </v-card-item>
    <v-row no-gutters class="px-3">
      <v-col cols="9">
        <div class="text-subtitle-2">
          {{ info.patientFirstName }} {{ info.patientLastName }}
        </div>
        <span class="text-caption">
          {{ age(info.patientDob) }} years, {{ info.patientGender }}</span>
      </v-col>
      <v-col cols="3" class="d-flex items-center">
        <v-btn :loading="isLoading" elevation="3" class="text-caption ml-3" size="x-small" @click="updateStatus"
          :rounded="false" variant="flat">
          Activate
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-snackbar color="success" location="top right" v-model="isSuccess">{{ successMessage
    }}<template v-slot:actions>
      <v-icon class="ml-3" @click="isSuccess = false">mdi-close</v-icon>
    </template></v-snackbar>
  <v-snackbar color="error" location="top right" v-model="isError">{{ errorMessage
    }}<template v-slot:actions>
      <v-icon class="ml-3" @click="isError = false">mdi-close</v-icon>
    </template></v-snackbar>
</template>
<script lang="ts" setup>
import {
  IUpdateUserPatientLinkStatusInput,
  IUserLinkPatientPE,
} from "@/interfaces/PE/usePatientLink.interface";
import { updateUserPatientLinkStatus } from "@/services/PE/userPatientLink.service";
import { ref, PropType } from "vue";
import { age } from "@/helpers/date.helpers";
import { Gender, GenderLabel } from "@/enums/genderType";
import { useEventBus } from "@vueuse/core";
import { listUserPatientLinkDataEventBusKey } from "@/events/bus-keys/PE/user-patient-link-event.bus-keys";

const props = defineProps({
  toastColor: {
    type: String,
    required: true,
  },
  userPatientLinkInfo: {
    type: Object as PropType<IUserLinkPatientPE>,
    required: true,
  },
});

const { userPatientLinkInfo: { patient: { firstName, lastName, gender, dob }, patientId, peFirstName, peLastName, peUserId, relationship }  } = props;

const isSuccess = ref(false);
const successMessage = ref("");
const isError = ref(false);
const errorMessage = ref("");
const isLoading = ref(false);

const listUserPatientLinkDataEventBus = useEventBus(
  listUserPatientLinkDataEventBusKey,
);

const info = ref({
  patientId,
  userId: peUserId,
  userFirstName: peFirstName,
  userLastName: peLastName,
  patientFirstName: firstName,
  patientLastName: lastName,
  patientGender: GenderLabel[gender as Gender],
  patientDob: dob,
  userRelationship: relationship,
});

const updateStatus = async () => {
  const { patientId, userId } = info.value;
  const updateUserPatientLinkStatusInput: IUpdateUserPatientLinkStatusInput = {
    patientId,
    userId,
    isActive: true,
  };
  isLoading.value = true;
  await updateUserPatientLinkStatus(updateUserPatientLinkStatusInput)
    .then((data) => {
      if (!data) {
        isError.value = true;
        errorMessage.value = `Unable to ${updateUserPatientLinkStatusInput.isActive ? "activate" : "deactivate"} the user patient link.`;
      }

      isSuccess.value = true;
      successMessage.value = `User patient link ${updateUserPatientLinkStatusInput.isActive ? "activate" : "deactivate"} successfully.`;
      listUserPatientLinkDataEventBus.emit("");
    })
    .catch((error) => {
      const err = error as Error;
      isError.value = true;
      errorMessage.value = err.message;
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
