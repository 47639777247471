import { defineStore } from "pinia";
import {IUser} from "../../interfaces/user.interface";


export const useUserStore = defineStore("user", {
  state: () => ({
    user: {} as IUser
  }),
  getters:{
    getUser: (state) => state.user
  },
  actions: {
    saveUser(user: IUser) {
        this.user = user;
    }
  },
  persist:{
    storage: sessionStorage,
  }
});
