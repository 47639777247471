<template>
    <v-card style="overflow-y: visible;">
        <div class="text-center pa-1">
            <div
                class="d-flex flex-col justify-center  align-center text-center text-h6 text-primary font-weight-bold ">
                <h5 class="text-h5 text-center text-onSurfaceVar font-weight-black w-100 py-2 bg-surfContainerHigh">Add
                    Time Log</h5>
                <patient-id-card class="w-100 px-2" v-if="patientProfile.patientInfo"
                    :patient-info="patientProfile.patientInfo" />
                <v-spacer />
            </div>
        </div>
        <v-card-item>
            <v-form @submit.prevent="submitActionHandler">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-select variant="outlined" color="primary" label="Activity*" required density="compact"
                                :items="activityOptionDetails" v-model="addTimeLogFormInput.activity"
                                :rules="[(v: any) => !!v || 'Please select a activity']"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <VueDatePicker class="vt-date-time-picker-btn" v-model="addTimeLogFormInput.startTime"
                                :teleport="true" utc :is-24="false" time-picker-inline :max-date="new Date()">
                                <template #trigger>
                                    <v-text-field v-model="startDateTimeFormatted.date" label="Start Date and Time*"
                                        color="primary" :readonly="true" variant="outlined" density="compact" clearable
                                        required @click:clear="addTimeLogFormInput.startTime = ''">
                                    </v-text-field>
                                </template>
                            </VueDatePicker>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="addTimeLogFormInput.endTime" label="End Date and Time"
                                color="primary" :readonly="true" variant="outlined" density="compact" required disabled
                                @click:clear="addTimeLogFormInput.endTime = ''">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="addTimeLogFormInput.timeSpent" variant="outlined" color="primary"
                                density="compact" required label="Time Spent (mins)*" :rules="timeRules">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div>
                                <v-label>Add Note</v-label>
                                <QuillEditor v-model="addTimeLogFormInput.note" :style="{ height: '200px' }"
                                    theme="snow" toolbar="essential" @ready="onEditorReady($event)" />
                            </div>
                        </v-col>
                        <v-col class="ml-n3 mt-n3">
                            <v-checkbox v-model="addTimeLogFormInput.isAcknowledged" hide-details class="shrink mr-2"
                                color="primary">
                                <template v-slot:label>
                                    <div class="mt-4 text-body-2">
                                        I confirm the accuracy of this manual time entry for time tracked outside of
                                        this application.
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-end">
                            <v-btn variant="tonal" rounded="false" class="mx-2" @click="cancelForm">
                                Cancel
                            </v-btn>
                            <v-btn variant="flat" rounded="false" :disabled="!formInputValid"
                                type="submit">
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-item>
    </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, PropType, watchEffect } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { QuillEditor } from '@vueup/vue-quill';
import moment from 'moment';
import { IActivityItem, IActivityInput, IAddTimeLog } from '../../../../interfaces/timelog.interface';
import { map } from 'lodash';
import { IPatientProfileProps } from '../../../../interfaces/patient.interface'
import { extractTextFromQuill } from '@/composables/FormUtility';
import { isNumeric } from '../../../../composables/FormUtility';
import PatientIdCard from '@/components/patient/profile/PatientIdCard.vue';
import { useServerTimeSyncStore } from '@/store/modules/server-time-sync.store';

const { activityOption, patientProfile } = defineProps({
    activityOption: {
        type: Object as PropType<IActivityInput>,
        required: true,
    },
    patientProfile: {
        type: Object as PropType<IPatientProfileProps>,
        required: true,
    },
});

const quillEditorInstance = ref();

const isEndTimeValid = computed(() => {
    const currentTime = moment();
    const endTime = moment(addTimeLogFormInput.value.endTime, 'MMM/DD/YYYY, hh:mm A');
    return endTime.isBefore(currentTime);
});

const timeRules = [
    (v: string) => !!v || "Please add the time spent",
    (value: string) =>
        isNumeric(value) || "Please enter positive numeric value for time spent",
    () => isEndTimeValid.value || "End time cannot be a future time.",
];

const addTimeLogFormInput = ref({
    patientId: patientProfile?.patientId,
    activity: "",
    startTime: "",
    endTime: "",
    timeSpent: 0 as number,
    note: "",
    isAutoLogged: false,
    isAcknowledged: true,
} as IAddTimeLog);

const activityOptionDetails = ref([] as IActivityItem[]);

const serverTimeSyncStore = useServerTimeSyncStore();


const startDateTimeFormatted = computed(() => {
    if (!addTimeLogFormInput.value.startTime) {
        return { date: '' }
    }
    const startDateTime = moment(addTimeLogFormInput.value.startTime);
    const startDate = startDateTime.format('MMM/DD/YYYY,  h:mm A');
    return { date: `${startDate}` };
});

const onEditorReady = (editor: null) => {
    quillEditorInstance.value = editor;
};

const emit = defineEmits(["addTimeLogCancelled", "createTimeLog"]);

const cancelForm = () => {
    emit("addTimeLogCancelled")
};

const getActivity = async () => {
    const getActivityData = await activityOption.dataLoader();
    const activityValue = map(
        getActivityData, (
            activityItem: any
        ) => ({
            title: activityItem.label,
            value: activityItem.activityId
        })
    )
    activityOptionDetails.value = activityValue;
};

watchEffect(() => {
    if (addTimeLogFormInput.value.startTime && addTimeLogFormInput.value.timeSpent > 0) {
        const startTime = moment(addTimeLogFormInput.value.startTime);
        const timeSpent = addTimeLogFormInput.value.timeSpent;
        const endTime = startTime.clone().add(timeSpent, 'minutes');
        const endTimeFormat = endTime.format('MMM/DD/YYYY,  h:mm A');
        addTimeLogFormInput.value.endTime = endTimeFormat;
    } else {
        addTimeLogFormInput.value.endTime = '';
    }
    return '';
});

const submitActionHandler = async () => {
    const noteContent = extractTextFromQuill(quillEditorInstance.value.getContents());
    addTimeLogFormInput.value.startTime =  moment(addTimeLogFormInput.value.startTime).add(serverTimeSyncStore.$state.clockDrift).toISOString();
    addTimeLogFormInput.value.note = noteContent;
    addTimeLogFormInput.value.endTime = moment(addTimeLogFormInput.value.endTime, 'MMM/DD/YYYY,  h:mm A').add(serverTimeSyncStore.$state.clockDrift, 'milliseconds')?.toISOString() ?? '';
    addTimeLogFormInput.value.timeSpent = Number(moment.duration(addTimeLogFormInput.value.timeSpent, 'minutes').asMilliseconds());
    emit("createTimeLog", addTimeLogFormInput.value)
};

const formInputValid = computed(() => {
    const {
        activity,
        startTime,
        endTime,
        timeSpent,
        isAcknowledged
    } = addTimeLogFormInput.value;
    return (
        activity &&
        startTime &&
        endTime &&
        timeSpent &&
        isEndTimeValid.value &&
        isAcknowledged
    )
})

onMounted(async () => {
    getActivity();
})

</script>
