<template>
    <Toaster v-if="showToast" :visibleToasts="5" />
</template>

<script setup lang="ts">
import { defineProps, watch, ref, PropType } from 'vue';
import { Toaster, toast } from 'vue-sonner';
import UserPatientLinkToastTemplate from './UserPatientLinkToastTemplate.vue';
import { h } from 'vue';
import { IUserLinkPatientPE } from '@/interfaces/PE/usePatientLink.interface';

const props = defineProps({
  linkedPatient: {
    type: Object as PropType<IUserLinkPatientPE>,
    require: true
  }
});
const toastColor = ref('surfContainerHigh');
const showToast = ref(false);

const showLinkedPatientToast = (newValue: IUserLinkPatientPE) => {
  toast(
    h(UserPatientLinkToastTemplate, {
      userPatientLinkInfo: newValue,
      toastColor: toastColor.value,
    }), {
    style: {
      backgroundColor: `${toastColor.value} !important`,
      padding: 0,
      width: '360px',
    },
    closeButton: true,
    duration: import.meta.env.VITE_TOAST_VISIBLE_DURATION || 60000,
    position: 'bottom-left'
  });
};


watch(() => props.linkedPatient, (newValue) => {
  if (newValue && !showToast.value) {
    showLinkedPatientToast(newValue!);
  }
});
</script>

<style>
[data-sonner-toast] [data-content] {
  display: flex;
  flex-direction: column;
  gap: 2px;
  transform: translateZ(0);
  width: 100%;
}
</style>
