<template>
    <v-card flat color="surfContainer" class="w-100 h-100">
        <div class="d-flex flex-column">
            <v-tabs v-model="dataTab" color="primary" class="bg-surface text-primary" density="compact">
                <v-tab :value="PATIENT_COMPLIANCE.CURRENT">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_COMPLIANCE.CURRENT }} </span>
                </v-tab>
                <v-tab :value="PATIENT_COMPLIANCE.ALL_TIME">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_COMPLIANCE.ALL_TIME }} </span>
                </v-tab>
            </v-tabs>
            <v-window v-model="dataTab">
                <v-window-item :value="PATIENT_COMPLIANCE.CURRENT">
                    <PatientComplianceCurrent :compact-view="compactView" :patient-profile="patientProfile"
                        :data-loader="getPatientProgramCycleDependencies?.getPatientProgramCycles" />
                </v-window-item>
                <v-window-item :value="PATIENT_COMPLIANCE.ALL_TIME">
                    <PatientComplianceAllTime :patient-profile="patientProfile"
                        :data-loader="getPatientProgramCycleDependencies?.getPatientProgramCycles" />
                </v-window-item>
            </v-window>
        </div>
    </v-card>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { PATIENT_COMPLIANCE } from '../../../../enums/patient.enum'
import PatientComplianceCurrent from './PatientComplianceCurrent.vue';
import PatientComplianceAllTime from './PatientComplianceAllTime.vue';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { IPatientComplianceDependencies } from '@/interfaces/Service.interface';
const dataTab = ref(PATIENT_COMPLIANCE.CURRENT);

const { getPatientProgramCycleDependencies, patientProfile } = defineProps({
    patientProfile: {
        type: Object as PropType<IPatientProfileProps>,
        required: true,
    },

    getPatientProgramCycleDependencies: {
        type: Object as PropType<IPatientComplianceDependencies>,
        required: true,
    },
    compactView: {
        type: Boolean,
        required: false,
        default: false
    }
})
</script>