<template>
  <v-sheet color="surfContainer" class="text-center page-sizer-wrapper pa-2 rounded-xl">
    <v-btn-toggle v-model="selectedSize" :density="density" :color="color" variant="flat" elevation="2">
      <v-btn :value="PAGE_SIZER.EXPAND_LEFT" @click="emitSizeChange(PAGE_SIZER.EXPAND_LEFT)"
        :disabled="selectedSize === PAGE_SIZER.EXPAND_LEFT">
        <v-icon right>
          mdi-view-dashboard-variant
        </v-icon>
      </v-btn>

      <v-btn :value="PAGE_SIZER.EXPAND_RIGHT" @click="emitSizeChange(PAGE_SIZER.EXPAND_RIGHT)"
        :disabled="selectedSize === PAGE_SIZER.EXPAND_RIGHT">
        <v-icon right>
          mdi-view-list
        </v-icon>
      </v-btn>

      <v-btn :value="PAGE_SIZER.SPLIT_SCREEN" @click="emitSizeChange(PAGE_SIZER.SPLIT_SCREEN)"
        :disabled="selectedSize === PAGE_SIZER.SPLIT_SCREEN">
        <v-icon right>
          mdi-view-split-vertical
        </v-icon>
      </v-btn>

      <v-btn :value="PAGE_SIZER.CLOSE" @click="emitSizeChange(PAGE_SIZER.CLOSE)"
        :disabled="selectedSize === PAGE_SIZER.CLOSE">
        <v-icon right>
          mdi-close-thick
        </v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-sheet>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';


const { initialSelection } = defineProps({
  density: {
    type: String as PropType<'compact' | 'default'>,
    default: 'comfortable'
  },
  color: {
    type: String,
    default: 'onSurfaceVar'
  },
  initialSelection: {
    type: Number,
    default: PAGE_SIZER.SPLIT_SCREEN
  }
});
const emits = defineEmits(['page-size-change']);
const selectedSize = ref(initialSelection);

const emitSizeChange = (change: PAGE_SIZER) => {
  emits('page-size-change', change);
}

</script>
<style scoped>
.page-sizer-wrapper {
  position: absolute;
  right: 0;
  z-index: 99999;
}
</style>
<script lang="ts">
export enum PAGE_SIZER {
  EXPAND_RIGHT,
  EXPAND_LEFT,
  SPLIT_SCREEN,
  CLOSE
}

</script>