<template>
  <v-card flat>
    <v-card-actions>
      <v-spacer v-if="$vuetify.display.smAndUp"></v-spacer>
      <v-btn :color="primaryColor" class="text-none font-weight-bold px-4 mt-5" rounded variant="outlined" size="large"
        id="createButton" @click="organizationCreation()" density='comfortable'>
        {{ createButtonName }}
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-row>
        <v-col cols="12" xs="12" md="2" sm="3" order-md="2" order-sm="2"
          :class="$vuetify.display.xs ? '' : 'd-flex justify-end'">
          <OrganizationFilter :organizationFilterForm="organizationFilterForm" @filter="handleFilter"
            :remove-org-filter="removedOrgFilterValues" />
        </v-col>
        <v-col xs="12" md="10" sm="9" order-md="1" order-sm="1">
          <OrganizationFilterChips :selectedFilterValue="organizationFilterValues"
            @remove-filter="clearOrgFilterValues" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-table id="table">
      <thead>
        <tr>
          <th v-for="column in props.columns" :key="column.key" class="text-left font-weight-bold " scope="row">
            {{ column.label }}
          </th>
          <th class="text-left font-weight-bold" scope="row">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!orgList.length">
          <td :colspan="columns.length + 1" class="text-center">
            No data available !
          </td>
        </tr>
        <tr v-else v-for="organization in orgList" :key="organization.id">
          <td v-for="(column) in props.columns" :key="column.key" class="text-left text-body-1">
            <div class="tableData">
              {{ organization[column.key] }}
            </div>
          </td>
          <td class="text-left tableData">
            <v-icon v-if="false" :color="primaryColor" class="mr-4">mdi-file</v-icon>
            <v-btn v-if="false" id="editButton" class="mr-2" rounded="false" variant="tonal" density="comfortable"
              elevation="3" @click="$emit('edit')">
              Edit Organization
            </v-btn>
            <v-btn id="viewButton" rounded="false" variant="tonal" density="comfortable" elevation="3"
              @click="openOrgProfile(organization.orgId)">
              View Profile
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="paginationContainer">
      <v-pagination v-model="currentPage" rounded="circle" :density="'comfortable'" color="primary" variant="text"
        :total-visible="$vuetify.display.mdAndUp ? totalVisiblePageNo : $vuetify.display.sm ? 6 : 2"
        :length="totalPages" @input="updateCurrentPage" class="my-4 px-5 custom-pagination"></v-pagination>
    </div>
    <v-snackbar color="green" class="text-white mt-16" v-model="showOrgUserCreatedMessage" location="top right">
      Organization users created successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-card>
</template>
<script lang="ts" setup>
import { ApolloClient } from '@apollo/client';
import { computed, inject, ref, watch, onMounted } from 'vue';
import { GET_ORGS } from '../graphql/resources/OrganizationRepository';
import router from '../router';
import { OrganizationType } from '../enums/OrganizationTypes';
import { first, unset } from 'lodash';
import { ITableHeader } from '@/interfaces/utility.interface';
import Loader from './common/Loader.vue';
import OrganizationFilter from './organizationFilter/OrganizationFilter.vue';
import OrganizationFilterChips from './organizationFilter/OrganizationFilterChips.vue';
import { IOrganizationFilter } from '@/interfaces/IOrganization';
import { orgDetailsCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';


const props = defineProps({
  columns: {
    type: [Object],
    default: () => [
      { key: 'name', label: 'Name' },
      { key: 'orgType', label: 'Type' },
      { key: 'npi', label: 'NPI' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
    ] as ITableHeader[],
  },
  organizations: {
    type: Array
  },
  createButtonName: {
    type: String,
    default: 'Create Organization',
  },
  filterButtonName: {
    type: String,
    default: 'Filter',
  },
  primaryColor: {
    type: String,
    default: 'primary',
  },
  itemsPerPage: {
    type: Number,
    default: 10,
  },
});
const loading = ref(false);
const currentPage = ref(1);
const totalVisiblePageNo = import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO;
const orgList = ref([] as any[]);
const apolloClient = inject('apolloClient') as ApolloClient<any>;
const totalCount = ref(0);
const totalPages = computed(() =>
  Math.ceil(totalCount.value / props.itemsPerPage),
);
const organizationFilterForm = ref({} as IOrganizationFilter);
const removedOrgFilterValues = ref({} as IOrganizationFilter);
const updateCurrentPage = (page: number) => {
  currentPage.value = page;
};
const showOrgUserCreatedMessage = ref(false);

const organizationCreation = () => {
  router.push({
    name: 'createOrg',
  });
};


const getOrgs = async () => {
  loading.value = true;
  await apolloClient.query({
    query: GET_ORGS,
    variables: {
      limit: props.itemsPerPage,
      offset: props.itemsPerPage * (currentPage.value - 1),
      filter: {
        orgType: organizationFilterForm.value.organizationType,
        organizationName: organizationFilterForm.value.organizationName,
      }
    },
    fetchPolicy: 'network-only'
  }).then((data) => {
    if (data.data) {
      orgList.value = data.data.listOrganizations.organizations;
      totalCount.value = data.data.listOrganizations.count;
      orgList.value = orgList.value.map((org: any) => {
        return {
          ...org,
          orgType: getDesignationLabel(org.orgType)
        }
      })
    }
  }).catch((err: any) => {
    console.log('Error in fetching user info', err);
  });
  loading.value = false;
}

const getDesignationLabel = (value: String) => {
  return first(
    OrganizationType.filter(type => {
      return type.value === value
    }))?.title
}
onMounted(() => {
  getOrgs();
  if (props.organizations) {
    orgList.value = props.organizations;
  }
  if(orgDetailsCreated.value === ShowSnackbar.ORG_USER_CREATED){
    showOrgUserCreatedMessage.value = true;
    orgDetailsCreated.value = '';
  }
})

const emit = defineEmits(['view', 'edit'])

const openOrgProfile = (orgId: string) => {
  router.push({
    name: 'org-profile-view',
    params: {
      orgId: orgId
    }
  })
  emit('view');
}

watch(currentPage, async () => {
  getOrgs();
})

const handleFilter = (filterValues: IOrganizationFilter) => {
  organizationFilterForm.value = { ...organizationFilterForm.value, ...filterValues };
  removedOrgFilterValues.value = { ...removedOrgFilterValues.value, ...filterValues };
  currentPage.value = 1;
  getOrgs();
};
const organizationFilterValues = computed(() => organizationFilterForm.value);
const clearOrgFilterValues = (removedChips: string) => {
  if (removedChips === 'organizationName' || removedChips === 'organizationType') {
    unset(organizationFilterForm.value, removedChips);
    unset(removedOrgFilterValues.value, removedChips);
    getOrgs();
  }
};

</script>
<style scoped>
.actionContainer {
  display: flex;
  justify-content: flex-end;
}

.tableData {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
