import {
  IPatientVitalConfigureResponse,
  IVitalConfigure,
  PatientVitalsInput,
  VitalsInput,
} from "@/interfaces/IVitalConfigure";
import { apolloClient } from "../plugins/graphqlclient";
import {
  CREATE_PATIENT_VITAL_BP,
  CREATE_PATIENT_VITAL_PULSE,
  CREATE_PATIENT_VITAL_SPO2,
  CREATE_VITAL_BP,
  CREATE_VITAL_PULSE,
  CREATE_VITAL_SPO2,
  GET_PATIENT_VITALS_BP,
  GET_PATIENT_VITALS_PULSE,
  GET_PATIENT_VITALS_SPO2,
  GET_VITALS_BP,
  GET_VITALS_PULSE,
  GET_VITALS_SPO2,
} from "@/graphql/resources/VitalConfiguration";
import { VITAL } from "@/enums/readings";

export const createVital = async (vitalInput: VitalsInput) => {
  const { vital } = vitalInput
  if (vital === VITAL.BP) {
    const response = await apolloClient.mutate({
      mutation: CREATE_VITAL_BP,
      variables: {
        input: vitalInput,
      },
      fetchPolicy: "network-only",
    });
    return response;
  }
  if (vital === VITAL.SPO2) {
    const response = await apolloClient.mutate({
      mutation: CREATE_VITAL_SPO2,
      variables: {
        input: vitalInput,
      },
      fetchPolicy: "network-only",
    });
    return response;
  }
  if (vital === VITAL.PULSE) {
    const response = await apolloClient.mutate({
      mutation: CREATE_VITAL_PULSE,
      variables: {
        input: vitalInput,
      },
      fetchPolicy: "network-only",
    });
    return response;
  }
};

export const getVital = async (vital: VITAL) => {
  if (vital === VITAL.BP) {
    const response = await apolloClient.query({
      query: GET_VITALS_BP,

    variables: {
      vital,
    },
  });
  return response;
}

  if (vital === VITAL.SPO2) {
    const response = await apolloClient.query({
      query: GET_VITALS_SPO2,
      variables: {
        vital,
      },
      fetchPolicy: "network-only",
    });
    return response;
  }
  if (vital === VITAL.PULSE) {
    const response = await apolloClient.query({
      query: GET_VITALS_PULSE,
      variables: {
        vital,
      },
      fetchPolicy: "network-only",
    });
    return response;
  }
};

export const savePatientVitalsThresholdConfig = async (
  patientVitalInput: PatientVitalsInput
): Promise<IVitalConfigure> => {
  const { vital } = patientVitalInput
  const vitalQueries = async (vital: string) => {
    if (vital === VITAL.BP) {
      const response = await apolloClient.mutate({
        mutation: CREATE_PATIENT_VITAL_BP,
        variables: {
          input: patientVitalInput,
        },
      });
      return response.data;
    }
    if (vital === VITAL.SPO2) {
      const response = await apolloClient.mutate({
        mutation: CREATE_PATIENT_VITAL_SPO2,
        variables: {
          input: patientVitalInput,
        },
      });
      return response.data;
    }
    if (vital === VITAL.PULSE ) {
      const response = await apolloClient.mutate({
        mutation: CREATE_PATIENT_VITAL_PULSE,
        variables: {
          input: patientVitalInput,
        },
      });
      return response.data;
    }
  }
  return vitalQueries(vital)
};

export const getPatientVitalsThresholdConfig = async (
  patientId: string,
  vital: VITAL
): Promise<IPatientVitalConfigureResponse> => {
  const vitalQueries = async (vital: VITAL) => {
    if (vital === VITAL.BP) {
      const response = await apolloClient.query({
        query: GET_PATIENT_VITALS_BP,
        variables: {
          patientId: patientId,
          vital,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    }
    if (vital === VITAL.SPO2) {
      const response = await apolloClient.query({
        query: GET_PATIENT_VITALS_SPO2,
        variables: {
          patientId: patientId,
          vital,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    }
    if (vital === VITAL.PULSE) {
      const response = await apolloClient.query({
        query: GET_PATIENT_VITALS_PULSE,
        variables: {
          patientId: patientId,
          vital,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    }
  };
  return vitalQueries(vital);
};
