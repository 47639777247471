<template>
  <v-card class="grap-card" flat color="surface">
    <div id="chart">
      <div class="ml-5 text-subtitle-2 font-weight-bold">SPO2&nbsp;<span class="font-weight-light">(Percent)</span>
      </div>
      <VueApexCharts type="line" height="150" ref="spO2Chart" class="mr-8 ml-1" :options="spO2ChartOptions"
        :series="spO2DataPoints">
      </VueApexCharts>
      <div class="ml-5 text-subtitle-2 font-weight-bold">Perfusion Index&nbsp;<span class="font-weight-light">(Percent)</span>
      </div>
      <VueApexCharts type="line" height="150" ref="spO2Chart" class="mr-8 ml-1" :options="piChartOptions"
        :series="piDataPoints">
      </VueApexCharts>
      <div class="ml-5 text-subtitle-2 font-weight-bold">HR&nbsp;<span class="font-weight-light">(bpm)</span>
      </div>
      <VueApexCharts type="line" height="150px" class="mr-8 ml-3" :options="hrChartOptions" :series="hrDataPoints">
      </VueApexCharts>
    </div>
    <v-snackbar color="error" class="text-white mt-16" v-model="showLoadThresholdError" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadThresholdError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import VueApexCharts from "vue3-apexcharts";
import { PropType, ref } from 'vue';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { IPatientVitalConfigureResponse } from '@/interfaces/IVitalConfigure'
import ApexCharts from 'apexcharts';
import { VITAL } from '@/enums/readings';


const props = defineProps({
  spO2DataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  hrDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  piDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true
  },
})

const spO2Chart = ref<ApexCharts | null>(null);
const showLoadThresholdError = ref(false);
const errorMessage = ref("");

const spO2ChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "bp-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
    markers: {
      size: [4, 7]
    }
  },
  markers: {
    size: [4, 6],
    discrete: []
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },

} as ApexChart & { markers: { discrete: object[] } });

const piChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "bp-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
    markers: {
      size: [4, 7]
    }
  },
  markers: {
    size: [4, 6],
    discrete: []
  },
  colors: ['#00ff00'],
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },

} as ApexChart & { markers: { discrete: object[] } });

const hrChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),
    group: "bp-hr-graph",
    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },
  markers: {
    size: [4, 6]
  },
  colors: ['#F75E38'],
} as ApexChart);

</script>

<style lang="scss" scoped>
#chart {
  margin-right: 15px;
}
</style>
