import { minutesFromNowInt } from "@/helpers/date.helpers";
import { RequestorTask } from "@/interfaces/task.interface";
import moment from "moment";

export const convertEpochToDate = (epochTimestamp: number): string => {
  const formattedDate = moment(epochTimestamp).format("MM/DD/YYYY HH:mm:ss a");
  return formattedDate;
};

export const getDueDateColor = (item: string) => {
  return minutesFromNowInt(item) < 0 ? 'error' : 'success';
}


export const getDueDateText = (item: RequestorTask) => {
  if (!item.task.dueDate) return "";

  const dueDate = moment(item.task.dueDate);
  const currentDate = moment();
  const daysDifference = dueDate.diff(currentDate, "days");

  const dayText = daysDifference === 1 ? "day" : "days";
  return `Due in ${daysDifference} ${dayText}`;
};

export const convertMillisecondsToMinsSecs = (milliseconds: number) => {
  const duration = moment.duration(milliseconds);
  const minutes = Math.floor(duration.asMinutes());
  const seconds = duration.seconds();
  const formattedTime = `${minutes.toString().padStart(2, '0')} min(s)`;
  return formattedTime;
};


export const convertMillisecondsHMS = (milliseconds: number) => {
  const duration = moment.duration(milliseconds);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const getLastTwoDigits = (value: number) => `0${value}`.slice(-2);

export const generateRandomNumber = () => {
  const currentDate = new Date();
  const year = getLastTwoDigits(currentDate.getFullYear());
  const month = getLastTwoDigits(currentDate.getMonth() + 1);
  const day = getLastTwoDigits(currentDate.getDate());
  const hours = getLastTwoDigits(currentDate.getHours());
  const minutes = getLastTwoDigits(currentDate.getMinutes());
  const seconds = getLastTwoDigits(currentDate.getSeconds());
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
export const convertMillisecondsToReadableFormat = (milliseconds: number) => {
  const duration = moment.duration(milliseconds);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${hours.toString().padStart(2, '0')} hrs ${minutes.toString().padStart(2, '0')} mins ${seconds.toString().padStart(2, '0')} secs`;
}
