export const LookBackRange = [
    {
      title: "One week",
      value: "ONE_WEEK",
      short: "1W"
    },
    {
      title: "One month",
      value: "ONE_MONTH",
      short: "1M"
    },
    {
      title: "Three months",
      value: "THREE_MONTHS",
      short: "3M"
    },
    {
      title: "Six months",
      value: "SIX_MONTHS",
      short: "6M"
    },
    {
      title: "Twelve months",
      value: "TWELVE_MONTHS",
      short: "1Y"
    },
    {
      title: "All Time",
      value: "ALL",
      short: "AT"
    }
  ]

  export enum ReadingTrend {
    UP,
    DOWN,
    NONE
  }


export enum VITAL {
  BP = "BP",
  PULSE = "PULSE",
  SPO2 = "SPO2",
  // WEIGHT = "WEIGHT",
  // BPM = "BPM",
  // RESP_RATE = "RESP_RATE",
  // BGL = "BGL",
  // BODY_TEMP = "BODY_TEMP",
  // ECG = "ECG",
}

export const PatientVitalBPTableHeader = [
  {
    key: "date",
    title: "Date",
    sortable: true,
  },
  {
    key: "time",
    title: "Time",
  },
  {
    key: "sys",
    title: "SYS",
  },
  {
    key: "dia",
    title: "DIA",
  },
  {
    key: "hr",
    title: "HR",
  },
  {
    key: "ihb",
    title: "IHB",
  },
  {
    key: "details",
    title: "Details",
  },
];

export const PatientVitalPulseTableHeader = [
  {
    key: "date",
    title: "Date",
    sortable: true,
  },
  {
    key: "time",
    title: "Time",
  },
  {
    key: "hr",
    title: "HR",
  },
  {
    key: "ihb",
    title: "IHB",
  },
  {
    key: "details",
    title: "Details",
  },
];

export const PatientVitalSPO2TableHeader = [
  {
    key: "date",
    title: "Date",
    sortable: true,
  },
  {
    key: "time",
    title: "Time",
  },
  {
    key: "spO2",
    title: "SPO2",
  },
  {
    key: "pi",
    title: "Perfusion Index",
  },
  {
    key: "hr",
    title: "HR",
  },
  {
    key: "details",
    title: "Details",
  },
];
