import gql from "graphql-tag";

export const LIST_PATIENT_NOTES = gql`
  query listPatientNotes($mpi: String!) {
    listPatientNotes(mpi: $mpi) {
      orgId
      mpi
      mrn
      note {
        noteId
        bucketId
        createdAt
        lastModifiedAt
        noteContext {
          id
          type
        }
        author {
          email
          firstName
          lastName
          userId
        }
        tags
        noteContent {
          contentType
          message
          source
          title
        }
        type
        currentState {
          actionAt
          actionBy
          state
        }
        stateHistory {
          actionAt
          actionBy
          state
        }
        task {
          taskId
          orgId
          taskIdentifier
          requestorId
          reporterId
          assignees
          status {
            taskStatusStateId
            orgId
            category
            name
          }
          title
          description
          dueDate
          priority
          currentQueue {
            taskQueueTransitionId
            taskId
            taskQueueId
            taskBoardId
            orgId
            entryAt
            exitAt
            entryFrom
            exitTo
            entryBy
            exitBy
          }
        }
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote($input: CreatePatientNoteInput!) {
    createPatientNote(input: $input) {
      orgId
      mpi
      mrn
      note {
        noteId
        bucketId
        createdAt
        lastModifiedAt
        noteContext {
          type
          id
        }
        author {
          firstName
          lastName
          email
          userId
        }
        tags
        noteContent {
          title
          contentType
          message
          source
        }
        type
        currentState {
          state
          actionAt
          actionBy
        }
        stateHistory {
          state
          actionAt
          actionBy
        }
        task {
          taskId
          orgId
          taskIdentifier
          requestorId
          reporterId
          assignees
          title
          description
          dueDate
          priority
        }
      }
    }
  
  }
`;



export const LIST_TASK_QUEUE = gql`
  query listTaskQueue {
    listTaskQueue {
      taskQueueDefinitionId
      title
      orgId
    }
  }
`;

export const ASSIGN_TASK_TO_NOTE = gql`
  mutation assignNoteTask($noteId: String!, $input: AssignTaskInput!) {
    updatePatientNote(noteId: $noteId, input: $input) {
      noteId
      noteContextType
      noteContextId
      authorId
      content {
        title
        contentType
        message
        source
      }
      tags
      isDeleted
      taskId
      createdAt
      modifiedAt
    }
  }
`;
