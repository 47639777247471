import gql from "graphql-tag";

export const GET_USER_TIME_LOGS = gql`
  query getUserTimeLogs(
    $patientId: UUID!
    $filter: TimeLogFilter
    $pagination: PaginationInput
  ) {
    timeLogs(patientId: $patientId, filter: $filter, pagination: $pagination) {
      count
      results {
        timeLogId
        activity {
          description
          activityId
          description
          isActive
          isDefault
          label
          orgId
          variableDefs {
            key
            parseAs
            required
          }
        }
        startTime
        endTime
        timeSpent
        isAutoLogged
        loggedBy {
          deaNumber
          designation
          emailAddress
          firstName
          homePhoneNumber
          userId
          roles
          orgUserId
          organizationId
          npi
          lastName
          mobilePhoneNumber
        }
        note {
          mpi
          mrn
          note {
            author {
              email
              firstName
              lastName
              userId
            }
            bucketId
            createdAt
            currentState {
              actionAt
              actionBy
              actionAt
              state
            }
            noteContent {
              title
              contentType
              message
              source
            }
            lastModifiedAt
          }
          orgId
        }
      }
    }
  }
`;

export const ADD_TIMELOG_CREATION = gql`
  mutation addTimeLogCreation($input: TimeLogInput) {
    createTimeLog(input: $input) {
      timeLogId
      activity
      startTime
      endTime
      timeSpent
      loggedBy
      note {
        orgId
        mpi
        mrn
        note {
          noteId
          bucketId
          createdAt
          lastModifiedAt
          noteContext {
            type
            id
          }
          author {
            firstName
            lastName
            email
            userId
          }
          tags
          noteContent {
            title
            contentType
            message
            source
          }
          type
          currentState {
            state
            actionAt
            actionBy
          }
          stateHistory {
            state
            actionAt
            actionBy
          }
        }
      }
    }
  }
`;

export const ACTIVITY_LIST = gql`
  query activities {
    activities {
      activityId
      orgId
      label
      description
      isActive
      isDefault
      variableDefs {
        key
        required
        parseAs
      }
    }
  }
`;

export const DELETE_TIMELOG = gql`
  mutation deleteTimeLog($timeLogId: UUID!, $isAcknowledged: Boolean) {
    deleteTimeLog(timeLogId: $timeLogId, isAcknowledged: $isAcknowledged) {
      timeLogId
      activity
      startTime
      endTime
      timeSpent
      loggedBy
      isDeleted
    }
  }
`;
