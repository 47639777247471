<template>
  <v-card flat class="w-100" color="surfContainerHigh">
    <v-card-title class="bg-surfContainer pa-2">
      <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary">Billing Comparison</h6>
    </v-card-title>
    <v-card-item>
      <div class="d-flex justify-center align-items-center ">
        <VueApexCharts width="500" type="bar" :options="chartOptions" :series="series" />
      </div>
    </v-card-item>
  </v-card>
</template>
<script setup lang="ts">
import VueApexCharts from "vue3-apexcharts";
import { ref, watch, defineProps, PropType, computed } from 'vue';
import { IProgramBucketsBillingDetailsProps } from "@/interfaces/billing.interface";
import { IPatientProgramCycle } from "@/interfaces/patient-program-cycle.interface";
import { map } from 'lodash';


const props = defineProps({
  beforeCustomizedBucket: {
    type: Object as PropType<IProgramBucketsBillingDetailsProps>,
    required: true,
  },
  afterCustomizedBucket: {
    type: Object as PropType<IProgramBucketsBillingDetailsProps>,
    required: true,
  },
  programsSelectedForBilling: {
    type: Array as PropType<IPatientProgramCycle[]>,
    required: true,
  },
});

const chartOptions = computed(() => {
  return {
    colors: ["#6750A4", "#D0BCFF",],
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            enabled: true,
            position: "top",
          },
        },
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 2,
        right: 4,
        blur: 10,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
      offsetY: -20,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        foreColor: "#6750A4",
        borderRadius: 2,
        padding: 2,
        opacity: 0.8,
        borderWidth: 2,
      },
      formatter: function (val: number, opt: { seriesIndex: number }) {
        return `$${val.toFixed(2)}`;
      },
    },
    stroke: {
      curve: "smooth",
      width: [4, 8, 4, 8],
    },
    grid: {
      borderColor: "#49454f",
      row: {
        colors: ["rgba(255,255,255, 0.125)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: [0, 4, 0, 4],
    },
    xaxis: {
      categories: billedProgramCodes.value,
    },
    yaxis: [
      {
        seriesName: "Reimbursement",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        labels: {
          formatter: function (val: number) {
            return `$ ${val.toFixed(1)}`;
          },
        },
        title: {
          text: "Reimbursement ($)",
          fontSize: "14px",
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
    },
  };
});

const series = ref([
  {
    name: "Before",
    data: [] as number[],
  },
  {
    name: "After",
    data: [] as number[],
  },

]);

const billedProgramCodes = computed(() => {
  return props.programsSelectedForBilling.map((program) => program.program);
});

const updateSeries = (index: number, bucketDetails: IProgramBucketsBillingDetailsProps) => {
  const programRevenues = map(billedProgramCodes.value, (program) => {
    return bucketDetails[program]?.revenue ?? 0;
  });


  series.value[index].data = programRevenues;
};

watch(
  () => props.beforeCustomizedBucket,
  (newValue) => {
    updateSeries(0, newValue);
  },
  { deep: true, immediate: true }
);

watch(
  () => props.afterCustomizedBucket,
  (newValue) => {
    updateSeries(1, newValue);
  },
  { deep: true, immediate: true }
);
</script>
