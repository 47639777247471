<template>
  <v-card
    id="card"
    :class="
      $vuetify.display.mdAndUp
        ? 'rounded-lg pt-16 cardContainer-md'
        : 'rounded-lg py-8 cardContainer-sm '
    "
    elevation="4"
  >
    <v-row justify="center">
      <v-col class="px-5" md="6" sm="8" xs="12">
        <div class="text-center">
          <img
            :src="logoSrc"
            class="align-center pr-12"
            :alt="alternativeText"
            height="60"
            contain
          />
        </div>
        <div class="text-center mb-3 pt-8 mail-container">
          <i class="mdi mdi-email-outline text-h1 text-primary"></i>
          <div
            id="card-title"
            class="font-weight-bold text-h5 pb-5 text-primary"
          >
            {{ cardTitle }}
          </div>
          <div class="text-center">
            <div class="text-subtitle-1" id="caption">
              {{ caption }}
            </div>
          </div>
          <div class="caption-container text-center pt-4">
            <div v-for="(caption, index) in captions" :key="index">
              <div class="font-weight-bold text-subtitle-1" id="caption">
                {{ caption }}
              </div>
            </div>
          </div>
        </div>
        <v-btn
          block
          class="mb-5 text-white rounded-xl text-none"
          rounded="lg"
          color="primary"
          size="large"
          variant="flat"
          @click="routeTo('forgotPassword')"
        >
          {{ buttonName }}
        </v-btn>
        <v-btn
          block
          class="mb-5 text-white rounded-xl text-none"
          rounded="lg"
          color="primary"
          size="large"
          variant="flat"
          @click="routeTo('resetPassword')"
        >
          Reset Password
        </v-btn>
        <div class="text-center">
          <v-icon class="mr-2 black">mdi-arrow-left</v-icon>Back to
          <router-link
            id="routeToLogin"
            class="login-link font-weight-bold text-deep-purple-darken-1"
            :to="routeToLogin"
          >
            {{ routeToName }}</router-link
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import router from "../router";

const routeTo = (routeName: string) => {
  router.push({
    name: routeName,
  });
};

defineProps({
  cardTitle: {
    type: String,
    default: "Check your email",
  },
  caption: {
    type: String,
    default: "We have send password reset instructions to your email address",
  },
  buttonName: {
    type: String,
    default: "Try another email address",
  },
  logoSrc: {
    type: String,
    default: "/vitatraq.svg",
  },
  alternativeText: {
    type: String,
    default: "Vitatraq logo",
  },
  routeToLogin: {
    type: String,
    default: "/login",
  },
  routeToName: {
    type: String,
    default: "Login",
  },
});
const captions = ["Did not receive the email?, Check your spam folder,", "or"];
</script>

<style scoped>
.cardContainer-md {
  width: 900px;
  height: 676px;
}
.cardContainer-sm {
  width: 100%;
}
.mail-container {
  display: flex;
  flex-direction: column;
}

.caption-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
