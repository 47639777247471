import { DeviceShortCode } from "@/enums/serviceprovider.enum";
import QardioConfigureForm from "@/components/serviceProvider/QardioConfigureForm.vue";
import TenoviConfigureForm from "@/components/serviceProvider/TenoviConfigureForm.vue";
import InventoryDeviceAddForm from '@/components/inventory/InventoryDeviceAddForm.vue';
import InventoryTenoviDeviceForm from '@/components/inventory/InventoryTenoviDeviceForm.vue';

export const getServiceProviderConfigForm = (shortCode: string) => {
  return (
    {
      [DeviceShortCode.QARDIO]: QardioConfigureForm,
      [DeviceShortCode.TENOVI]: TenoviConfigureForm,
    }[shortCode] || null
  );
};

export const getInventoryDeviceAddForm = (shortCode: string) => {
  return (
    {
      [DeviceShortCode.QARDIO]: InventoryDeviceAddForm,
      [DeviceShortCode.TENOVI]: InventoryTenoviDeviceForm,
    }[shortCode] || null
  );
};
