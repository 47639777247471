import gql from "graphql-tag";

export const GET_USER_INFO = gql`
  {
    getUserInfo {
      firstName
      lastName
      emailAddress
      userId
      organizationId
      isActive
      roles {
        role
        description
        label
        permissions
      }
      permissions {
        id
        tenantId
        orgId
        label
        module
        moduleLabel
        moduleShortCode
        featureAccess {
          access
          feature
          featureLabel
          featureShortCode
        }
        active
      }
      organizationTimeZone
    }
  }
`;

export const DOES_USER_EMAIL_EXIST = gql`
  query doesUserEmailExists($email: String!) {
    doesUserEmailExists(email: $email)
  }
`;
