<template>
  <v-card color="surfContainer pa-2">
    <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
      <div class="d-flex align-center items-center text-primary">
        <v-icon size="large">mdi-note-multiple</v-icon>
        <div class="d-flex flex-wrap">
          <span class="ml-4">Patient Notes</span>
          <span class="v-card-subtitle flex-1-1-100"> Add notes to patient's profile </span>
        </div>
      </div>
      <v-spacer />
      <div class="d-flex justify-end">
        <v-dialog v-model="dialog" persistent width="1024">
          <template v-slot:activator="{ props }">
            <v-btn :color="primaryColor" v-bind="props" size="small" rounded="false" elevation="3"
              :loading="loading">
              <v-icon class="mx-2" size="large" elevation="3">mdi-note-plus-outline</v-icon>{{ popUpBtn }}</v-btn>
          </template>
          <v-card>
            <note-add-form :user-handler="userHandler" :task-queue-loader="taskQueueLoader"
              :list-assignees="listAssignees" @close="dialog = false" :assign-task-handler="assignTaskHandler"
              @save-note="saveNote" :patient-profile="patientProfile"></note-add-form>
          </v-card>
        </v-dialog>
      </div>
    </v-card-title>
    <v-card-item>
      <v-skeleton-loader v-if="loading" color="surface" type="table"></v-skeleton-loader>
      <v-container v-else fluid class="pa-0">
        <div v-if="items.length === 0" class="d-flex flex-column align-center justify-center">
          <v-label class="text-h6 font-weight-bold">{{ nothingFound }}</v-label>
          <img src="../../../assets/empty.png" alt="No data found" class="no-data-image" />
        </div>
        <v-table v-else>
          <thead>
            <tr>
              <th v-for="header in headers" :key="header.value" class="text-left font-weight-bold" scope="row">
                {{ header.text }}
              </th>
              <th class="text-left font-weight-bold" scope="row"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="cursor-pointer">
              <td class="author-column">{{ item.author }}</td>
              <td class="notes-content">{{ item.noteContent }}</td>
              <td class="timestamp-column">{{ item.createdAt }}</td>
              <td class="timestamp-column">{{ item.editAt }}</td>
              <td class="table-data" v-if="false">
                <v-checkbox :color="primaryColor"></v-checkbox>
              </td>
              <v-menu transition="slide-x-transition">
                <template v-slot:activator="{ props }">
                  <td>
                    <v-icon v-if="showActionIcon" v-bind="props">{{ actionIcon }}</v-icon>
                  </td>
                </template>
                <v-list class="d-flex flex-column cursor-pointer">
                  <v-list-item>
                    <v-list-item-title>{{ actionEdit }}</v-list-item-title>
                  </v-list-item>
                  <v-divider thickness="1"></v-divider>
                  <v-list-item>
                    <v-list-item-title>{{ actionDelete }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-dialog v-model="item.actionPop" activator="parent" width="auto">
                <v-card class="pa-4">
                  <v-card class="pl-4 pr-3 pt-3 pb-3 note-card" :color="cardColor" rounded="0">
                    <div class="d-flex justify-end">
                      <v-label><v-icon>{{ markIcon }}</v-icon></v-label>
                    </div>
                    {{ item.noteContent }}
                  </v-card>
                  <div class="py-5 px-2 d-flex flex-column align-start justify-start">
                    <v-label><strong class="mr-6">Author:</strong>{{ item.author }}</v-label>
                    <v-label><strong class="mr-1">CreatedAt:</strong>{{ item.createdAt }}</v-label>
                    <v-label><strong class="mr-3">EditedAt:</strong>{{ item.editAt }}</v-label>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="primaryColor" class="text-none" variant="outlined" size="small"
                      @click="item.actionPop = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-card-item>

    <v-snackbar color="success" class="text-white mt-16" v-model="showSuccessMessage" location="top right">
      Notes saved successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="red" class="text-white mt-16" v-model="showErrorMessage" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref, PropType, onMounted, } from 'vue';
import { formatTimestamp } from '../../../composables/FormUtility';
import { AssignTaskInput, INotes, ListNotes, NoteInputType, PatientNotes } from '../../../interfaces/notes.interface';
import { IUser } from '@/interfaces/user.interface';
import { IAssigneeInput, ITaskQueueItem } from '../../../interfaces/task.interface';
import { IPatientProfileProps } from '../../../interfaces/patient.interface';
import { useEventBus } from '@vueuse/core';
import { notesListEventBusKey, noteTaskListEventBusKey } from '@/events/bus-keys/note-task-events.bus-keys';
import NoteAddForm from './NoteAddForm.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { noteTimeLogEventBusKey } from '@/events/bus-keys/notes-timelog-event.bus-keys';

const items = ref([] as ListNotes[]);
const dialog = ref(false);
const loading = ref(false);
const showAssignTaskForm = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const showActionIcon = ref(false)
const showSuccessMessage = ref(false);
const queryClient = useQueryClient();

const noteTaskActionEventBus = useEventBus(noteTaskListEventBusKey);
const notesListEventBus = useEventBus(notesListEventBusKey);
const notesTimeLogEventBus = useEventBus(noteTimeLogEventBusKey);


interface Header {
  text: string;
  value: string;
}

const { noteDataLoader, userHandler, addNoteHandler, patientProfile } = defineProps({
  primaryColor: {
    type: String,
    default: 'primary',
  },
  cardColor: {
    type: String,
    default: '#ffff80',
  },
  popUpBtn: {
    type: String,
    default: 'Add Note',
  },
  editorName: {
    type: String,
    default: 'Note',
  },
  editorHead: {
    type: String,
    default: 'Add Note',
  },
  nothingFound: {
    type: String,
    default: 'No Notes Found',
  },
  actionEdit: {
    type: String,
    default: 'Edit Note',
  },
  actionDelete: {
    type: String,
    default: 'Delete Note',
  },
  actionClose: {
    type: String,
    default: 'Close',
  },
  actionSave: {
    type: String,
    default: 'Save Note',
  },
  actionIcon: {
    type: String,
    default: 'mdi-dots-vertical',
  },
  markIcon: {
    type: String,
    default: 'mdi mdi-bookmark',
  },
  headers: {
    type: Array as () => Header[],
    default: () => [
      { text: 'Author', value: 'author' },
      { text: 'Notes', value: 'noteContent' },
      { text: 'Created at', value: 'createdAt' },
      { text: 'Edited at', value: 'editAt' },
    ],
  },
  addNoteHandler: {
    type: Function as PropType<(createNoteInput: NoteInputType) => Promise<INotes>>,
    required: true,
  },
  noteDataLoader: {
    type: Function as PropType<(patientId: string) => Promise<INotes[]>>,
    required: true,
  },
  userHandler: {
    type: Function as PropType<() => IUser>,
    required: true,
  },
  taskQueueLoader: {
    type: Function as PropType<() => Promise<ITaskQueueItem[]>>,
    required: true,
  },
  listAssignees: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
  assignTaskHandler: {
    type: Function as PropType<({ noteId, taskInput }: { noteId: string, taskInput: AssignTaskInput }) => Promise<PatientNotes>>,
    required: true,
  },
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
});

const listNoteData = async () => {
  try {
    loading.value = true;
    const stickyNotesData: INotes[] = await noteDataLoader(patientProfile.patientId);
    loading.value = false;

    if (stickyNotesData) {
      items.value = stickyNotesData.map((data) => {
        return {
          id: data.mpi,
          author: `${data.note.author.firstName}  ${data.note.author.lastName}`,
          createdAt: formatTimestamp(data.note.createdAt),
          editAt: formatTimestamp(data.note.lastModifiedAt),
          noteContent: data.note.noteContent.message,
          actionPop: false,
        }
      })
    }
  } catch (err) {
    loading.value = false;
    errorMessage.value = 'Error fetching notes';
    showErrorMessage.value = true;
  }
};

const saveNote = async (input: NoteInputType) => {
  try {
    loading.value = true;
    const createdNote = await addNoteHandler(input);
    dialog.value = false;
    loading.value = false;
    showSuccessMessage.value = true;
    showAssignTaskForm.value = false;

    const newNote: ListNotes = {
      id: createdNote.mpi,
      author: `${createdNote.note.author.firstName} ${createdNote.note.author.lastName}`,
      createdAt: formatTimestamp(createdNote.note.createdAt),
      editAt: formatTimestamp(createdNote.note.lastModifiedAt),
      noteContent: createdNote.note.noteContent.message,
      actionPop: false,
    };
    items.value.unshift(newNote);

    noteTaskActionEventBus.emit('note-task-list-event-bus');
    if (input.task) {
      queryClient.invalidateQueries({ queryKey: ['taskBoard'] })
    }
  }
  catch (error) {
    loading.value = false;
    dialog.value = false;
    errorMessage.value = 'Error while saving note, please try again later';
    showErrorMessage.value = true;
  }
};

onMounted(async () => {
  await listNoteData();
  notesListEventBus.on((e) => {
    items.value.unshift(e);
  });
  notesTimeLogEventBus.on(() => { listNoteData() });
});

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.table-data {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.no-data-image {
  width: 15%;
  height: 15%;
}

.note-card {
  max-width: 310px;
}

.notes-content {
  max-width: 500px;
}

.timestamp-column {
  max-width: 10px;
}

.author-column {
  max-width: 100px;
}
</style>
