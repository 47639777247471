<template>
  <v-dialog persistent fullscreen>
    <template v-slot:activator="{ props: activatorProps }">

      <v-btn v-bind="activatorProps" variant="elevated" elevation="2" rounded="false" :disabled="!value.isCustomizable"
        density="compact" icon="mdi-tune-variant" />
    </template>

    <template v-slot:default="{ isActive }">
      <v-card class="w-100" color="surfContainerHighest">
        <v-card-title class="bg-surface">
          <v-toolbar density="comfortable" color="surface">
            <v-toolbar-title :class="$vuetify.display.lgAndDown ? 'w-50 pa-2 mr-4' : 'pa-2 w-50'">
              <h5 class="text-h5 text-onSurfaceVar font-weight-black ">Billing Overrides</h5>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-end align-center w-25">
              <v-btn text="Save Overrides" color="primary" @click="isConfirmation = true" variant="flat" rounded="false"
                elevation="3" class="mx-4" :loading="saveInProgress" />
              <v-btn icon="mdi-close-thick" color="secondary" @click="isActive.value = false" variant="tonal" rounded
                size="x-small" elevation="3" class="mx-4" />
            </div>
          </v-toolbar>
        </v-card-title>
        <v-card-item class="pa-0">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-card flat color="surfContainerHigh">
                  <v-card-title class="pa-2 d-flex bg-surfContainer">
                    <div class="w-50 px-2">
                      <patient-id-card :patient-info="patientInfo" />
                    </div>
                    <div class="w-50 px-2">
                      <patient-billing-tiles :prop-bucket-details="customizedBillingDetails"
                        :programs-selected-for-billing="value.programsSelectedForBilling" />
                    </div>
                  </v-card-title>
                  <v-card-item class="pa-0">
                    <v-container v-show="saveInProgress">
                      <v-skeleton-loader class="mx-auto border" type="image@3"></v-skeleton-loader>
                    </v-container>
                    <PatientBillingCustomizedForm v-show="!saveInProgress" :patient-id="patientInfo.patientId"
                      @customized-distributed-code="customizedDistributionCodes" :generated-billing="value"
                      :prop-bucket-details="customizedBillingDetails"
                      :original-bucket-details="billingDetailsBeforeCustomization" />
                  </v-card-item>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <v-col cols="12">
                    <PatientBillingReportGraph :before-customized-bucket="billingDetailsBeforeCustomization"
                      :after-customized-bucket="customizedBillingDetails"
                      :programs-selected-for-billing="value.programsSelectedForBilling" />
                  </v-col>
                  <v-col cols="12">
                    <v-card flat color="surfContainerHigh">
                      <v-tabs v-model="activeContextTab" bg-color="surfContainer" density="compact">
                        <v-tab value="activities">
                          <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary">Activities</h6>
                        </v-tab>
                      </v-tabs>

                      <v-card-text>
                        <v-tabs-window v-model="activeContextTab">
                          <v-tabs-window-item value="activities">
                            <PatientBillingActivitiesList :patientActivitiesData="patientActivitiesData!" />
                          </v-tabs-window-item>
                        </v-tabs-window>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-container>
        </v-card-item>
      </v-card>

      <v-dialog v-model="isConfirmation" persistent width="900px">
        <v-card>
          <v-card-title class="font-weight-bold bg-surfContainer text-onSurfaceVar pl-5 text-high-emphasis ">
            Save Overrides
          </v-card-title>
          <v-card-text>
            <p>
              <span class="text-body-1"> Please be advised that our platform allows users to override billing reports
                generated by our
                algorithm, which follows the regulations for CPT codes as set by the American Medical
                Association (AMA). The transfer of unused time from
                Qualified Healthcare Professionals (QHCP) to clinical staff, may affect compliance
                with AMA regulations.</span>

            </p>
            <div class="mt-6">
              <span class="text-subtitle-1 text-high-emphasis font-weight-black mb-6">
                By making these adjustments, you acknowledge and confirm that:
              </span>
              <div class="px-3">
                <li>There are potential risks associated with altering the original report.</li>
                <li>Necessary precautions to ensure compliance with relevant billing and coding
                  standards have been taken.</li>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end" class="my-1">
              <v-col sm="12" md="1" class="text-center mx-6">
                <v-btn text="Cancel" color="primary" @click="isConfirmation = false" variant="tonal" rounded="false"
                  elevation="3" class="mx-4" />
              </v-col>
              <v-col sm="12" md="3" class="text-center">
                <v-btn text="Save Overrides" color="primary" @click="saveCustomizedBilling" variant="flat"
                  rounded="false" elevation="3" class="mx-4" :loading="saveInProgress" />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>
  </v-dialog>

  <v-snackbar color="green" class="text-white mt-16" v-model="showSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" class="text-white mt-16" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>


</template>
<script lang="ts" setup>
import { IBillingCPTDistribution, IProgramBucketsBillingDetailsProps, ICustomAdditionalBillingInfo, ICustomCPTDistributionInput, IPatientBillingCycleCustomizer, ICustomizedBillingDistributionsInput } from '@/interfaces/billing.interface';
import PatientIdCard from '@/components/patient/profile/PatientIdCard.vue';
import { ref, watch, onMounted, computed } from 'vue';
import { ICycle } from '@/interfaces/cycle.interface';
import { IOrgCycleActivityLogs } from '@/interfaces/activity-log.interface';
import { useQuery } from '@tanstack/vue-query';
import PatientBillingActivitiesList from './PatientBillingActivitiesList.vue';
import { createCUstomizedBillingDistribution } from '../../../../../services/billing.service';
import PatientBillingTiles from './PatientBillingTiles.vue';
import { CPTCodeBucket } from '../../../../../enums/billing.enum';
import { get, cloneDeep, isEmpty, reduce, filter } from 'lodash';
import PatientBillingCustomizedForm from './PatientBillingCustomizedForm.vue';
import PatientBillingReportGraph from "./PatientBillingReportGraph.vue";
import { map, find, some, compact, omit } from "lodash";
import { IPatientProgramConfiguration } from '@/interfaces/econsent.interface';
import { IExtendedCPTCode, ProgramBucketDistribution } from '../../../../../interfaces/billing.interface';
import { useQueryClient } from '@tanstack/vue-query';


const queryClient = useQueryClient();

const props = defineProps<{
  value: IPatientBillingCycleCustomizer,
  programsConfigurationLoader: () => Promise<IPatientProgramConfiguration[]>,
  patientActivitiesLoader: (orgId: string, cycle: ICycle) => Promise<IOrgCycleActivityLogs>,
}>();
const { programsConfigurationLoader, patientActivitiesLoader } = props;



const patientInfo = computed(() => {
  return {
    patientId: props.value.patientId,
    firstName: props.value.firstName,
    lastName: props.value.lastName,
    gender: props.value.gender,
    dob: props.value.dob,
    isActive: props.value.isActive,
    isDeceased: props.value.isDeceased,
    providers: [props.value.provider],
  };
});

const activeContextTab = ref('activities');
const patientActivitiesData = ref<IOrgCycleActivityLogs | null>(null);
const customizedCPTDistribution = ref([] as IBillingCPTDistribution[]);
const customizedAdditionalBillingInfo = ref({} as ICustomAdditionalBillingInfo,);
const customizedBillingDetails = ref({} as IProgramBucketsBillingDetailsProps);
const billingDetailsBeforeCustomization = ref({} as IProgramBucketsBillingDetailsProps);
const saveInProgress = ref(false);
const isConfirmation = ref(false);


const showSuccessMessage = ref(false);
const successMessage = ref('');
const showErrorMessage = ref(false);
const errorMessage = ref('');

const { data: programsConfigurationsData } = useQuery({
  queryKey: ['programsConfigurations'],
  queryFn: programsConfigurationLoader
});

const { isError: patientActivitiesIsError, data: allPatientActivityData, isLoading: patientActivitiesIsLoading } = useQuery({
  queryKey: ['allPatientActivities'],
  queryFn: () => patientActivitiesLoader(props.value.orgId, props.value.cycle),

});


const loadPatientActivities = () => {
  if (allPatientActivityData.value) {
    const patientActivities = allPatientActivityData.value.loggedActivities.filter(activity => activity.patientId === props.value.patientId);
    patientActivitiesData.value = {
      ...allPatientActivityData.value,
      loggedActivities: patientActivities,
    };
  }
};



const updateBucketAndRevenue = (
  billingDistributions: IBillingCPTDistribution[],
) => {

  const categories: Array<keyof IProgramBucketsBillingDetailsProps> = ["RPM", "PCM", "CCM", "CCCM"];

  const updatedBuckets = reduce(billingDistributions, (bucketTotals: IProgramBucketsBillingDetailsProps, distribution) => {
    categories.forEach((program) => {
      const billableBucket = get(customizedAdditionalBillingInfo.value, `${program}.billableBucket`);
      const cptBucket = get(distribution, "cptCode.bucket");
      const bucketPath = `${program.toLowerCase()}Bucket`;
      const revenue = get(distribution, `${bucketPath}.revenue`, 0);
      const timeInCycle = get(distribution, `${bucketPath}.timeInCycle`, 0);
      if (cptBucket === billableBucket) {
        bucketTotals[program].revenue! += revenue;
        bucketTotals[program].bucket = cptBucket;
        if (billableBucket === "QHCP") {
          bucketTotals[program].QHCPBillingTime! += timeInCycle;
        } else if (billableBucket === "STAFF") {
          bucketTotals[program].staffBillingTime! += timeInCycle;
        }
      } else if (cptBucket === "OTHER") {
        bucketTotals[program].revenue! += revenue;
      } else {
        if (cptBucket === "QHCP") {
          bucketTotals[program].QHCPUnbilledTimeAllocation! += timeInCycle;
        } else if (cptBucket === "STAFF") {
          bucketTotals[program].staffUnbilledTimeAllocation! += timeInCycle;
        }

      }
    });
    return bucketTotals;
  },
    {
      RPM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0, QHCPUnbilledTimeAllocation: 0, staffUnbilledTimeAllocation: 0 },
      PCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0, QHCPUnbilledTimeAllocation: 0, staffUnbilledTimeAllocation: 0 },
      CCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0, QHCPUnbilledTimeAllocation: 0, staffUnbilledTimeAllocation: 0 },
      CCCM: { revenue: 0, bucket: "", staffBillingTime: 0, QHCPBillingTime: 0, QHCPUnbilledTimeAllocation: 0, staffUnbilledTimeAllocation: 0 },
    },
  );

  return updatedBuckets;
};

const buildDistributionProgramBuckets = (codeProgramDetails: IPatientProgramConfiguration, cptConfig: IExtendedCPTCode, count: number) => {

  const buildBucketInfo = () => {
    return {
      timeInCycle: (cptConfig.unitSlabMins * count) * 60 * 1000,
      billingCount: count,
      revenue: cptConfig.unitRate * count,
      billable: true,
    };
  }

  const prgBuckets: Record<string, any> = {
    'RPM': {
      ccmBucket: null,
      cccmBucket: null,
      pcmBucket: null,
      rpmBucket: buildBucketInfo(),
    },
    'PCM': {
      ccmBucket: null,
      cccmBucket: null,
      pcmBucket: buildBucketInfo(),
      rpmBucket: null,
    },
    'CCM': {
      ccmBucket: buildBucketInfo(),
      cccmBucket: null,
      pcmBucket: null,
      rpmBucket: null,
    },
    'CCCM': {
      ccmBucket: null,
      cccmBucket: buildBucketInfo(),
      pcmBucket: null,
      rpmBucket: null,
    },
  };

  return prgBuckets[codeProgramDetails.shortCode];

};

const generateNewDistribution = (cptDistributionsMap: Record<string, number>) => {

  const billableCPTDistributionsCount = !isEmpty(filter(cptDistributionsMap, (value) => value > 0));

  const newDistribution: IBillingCPTDistribution[] = compact(map(cptDistributionsMap, (count, cptCode) => {
    if (count === 0 && billableCPTDistributionsCount) {
      return;
    }
    const codeProgramDetails = find(programsConfigurationsData.value, (program) => {
      return some(program.codes, { code: cptCode });
    });
    const cptConfig = find(codeProgramDetails?.codes, { code: cptCode });
    return {
      cptCode: {
        code: cptCode,
        ...omit(cptConfig, ['__typename']),
        programsApplicable: [
          {
            programShortCode: codeProgramDetails?.shortCode,
            programId: codeProgramDetails?.id,
          },
        ],
      },
      ...buildDistributionProgramBuckets(codeProgramDetails!, cptConfig!, count),
    }
  }));
  return newDistribution;
};

const generateNewAdditionalBillingInfo = (cptDistribution: IBillingCPTDistribution[]) => {
  const { programsSelectedForBilling } = props.value;

  const newAdditionalBillingInfoArray = map(programsSelectedForBilling, (patientProgram) => {
    const prgShortCode = patientProgram.program;
    const programDistributionBucket = `${prgShortCode.toLowerCase()}Bucket` as keyof IBillingCPTDistribution;
    const allProgramCodes = programsConfigurationsData.value?.find((program) => program.shortCode === prgShortCode)?.codes;
    const billedProgramCodes = compact(map(allProgramCodes, (code) => {
      return find(cptDistribution, (distribution) => distribution.cptCode.code === code.code);
    }));
    const billedStaffCodes = billedProgramCodes.filter((code) => code.cptCode.bucket === CPTCodeBucket.STAFF);
    const billedQHCPCodes = billedProgramCodes.filter((code) => code.cptCode.bucket === CPTCodeBucket.QHCP);

    const staffCodesTotal = billedStaffCodes.reduce((total, code) => {
      const codeBucket = code[programDistributionBucket] as ProgramBucketDistribution;
      return total + codeBucket.revenue;
    }, 0);

    const qhcpCodesTotal = billedQHCPCodes.reduce((total, code) => {
      const codeBucket = code[programDistributionBucket] as ProgramBucketDistribution;
      return total + codeBucket.revenue;
    }, 0);

    const billableBucket = staffCodesTotal > qhcpCodesTotal ? CPTCodeBucket.STAFF : qhcpCodesTotal === 0 ? CPTCodeBucket.OTHER : CPTCodeBucket.QHCP;
    return {
      [prgShortCode]: {
        billableBucket,
      },
    };
  });

  const newAdditionalBillingInfo = newAdditionalBillingInfoArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});

  return newAdditionalBillingInfo;
};

const customizedDistributionCodes = (cptDistributionsMap: Record<string, number>) => {
  customizedCPTDistribution.value = generateNewDistribution(cptDistributionsMap);
  customizedAdditionalBillingInfo.value = generateNewAdditionalBillingInfo(customizedCPTDistribution.value);

};


const saveCustomizedBilling = async () => {
  const data: ICustomCPTDistributionInput = {
    patientId: patientInfo.value.patientId,
    patientBillingsInCycleId: props.value.id,
    customizedBillingDistributionsInput: [
      ...customizedCPTDistribution.value,
    ] as ICustomizedBillingDistributionsInput[],
    customAdditionalBillingInfo: customizedAdditionalBillingInfo.value,
  };

  isConfirmation.value = false;
  saveInProgress.value = true;

  await createCUstomizedBillingDistribution(data).then(() => {
    queryClient.invalidateQueries({ queryKey: [`org-billing-report`] });
    saveInProgress.value = false;
    showSuccessMessage.value = true;
    successMessage.value = 'Billing overrides saved successfully'
  }).catch((error) => {
    const { message } = error as Error;
    saveInProgress.value = false;
    showErrorMessage.value = true;
    errorMessage.value = message;
  });

};
watch(allPatientActivityData, () => {
  if (!patientActivitiesIsLoading.value && !patientActivitiesIsError.value) {
    loadPatientActivities();
  }
}, { deep: true, immediate: true });

watch(
  () => props.value, (newValue) => {
    customizedCPTDistribution.value = cloneDeep(newValue.billingDistributions);
    customizedAdditionalBillingInfo.value = cloneDeep(newValue.additionalBillingInfo);
    billingDetailsBeforeCustomization.value = updateBucketAndRevenue(cloneDeep(props.value.billingDistributions));
    loadPatientActivities();
  }, { deep: true, immediate: true },
);
watch(() => customizedCPTDistribution.value, (newCPTDistribution) => {
  const updatedBucketDetails = updateBucketAndRevenue(cloneDeep(newCPTDistribution));
  if (!isEmpty(updatedBucketDetails)) {
    customizedBillingDetails.value = updatedBucketDetails;
  }
}, { deep: true, immediate: true },
);


onMounted(() => {
  billingDetailsBeforeCustomization.value = updateBucketAndRevenue(cloneDeep(props.value.billingDistributions));
});
</script>
