<template>
  <v-card width="600px" height="350px" class="pa-2">
    <h5 class="text-h5 text-center text-onSurfaceVar font-weight-black w-100 py-2 bg-surfContainerHigh">
      Add Patient Call Record</h5>
    <v-form class="px-5 pt-10" @submit.prevent="recordPatientLastCallEvent">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <VueDatePicker class="vt-date-time-picker-btn" v-model="patientLastCall.callAt" teleport-center :is-24="false"
            utc time-picker-inline :max-date="new Date()">
            <template #trigger>
              <v-text-field v-model="callDateFormatted.date" label="Call Start Date and Time*" :readonly="true"
                variant="outlined" hint="Enter date and time" dense clearable
                @click:clear="patientLastCall.callAt = ''" density="compact" color="primary" />
            </template>
          </VueDatePicker>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="callEndDateTime" label="Call End Date and Time*" color="primary" :readonly="true"
            variant="outlined" density="compact" required disabled @click:clear="callEndDateTime = ''">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="timeSpentMinutes" variant="outlined" color="primary" density="compact" required
            label="Call Duration (mins)*" :rules="timeSpentRules">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn variant="tonal" rounded="false" elevation="3" class="mx-2" @click="$emit('close')">
            cancel
          </v-btn>
          <v-btn :disabled="!formInputValid" variant="elevated" rounded="false" class="mx-2" type="submit"
            elevation="3">
            Save Call
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script setup lang="ts">
import { computed, reactive, ref, watchEffect } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';

import moment from 'moment';
import { isNumeric } from '../../../composables/FormUtility';
import { useUserStore } from '../../../store/modules/User';
import { PatientEventName } from '../../../enums/patient-event.enum';

const emits = defineEmits(["close", "saveCallEvent"]);

const patientLastCall = reactive({
  callAt: "",
  callBy: useUserStore().$state.user.userId,
  callDuration: 0,
  isAutoTracked: false,
  eventName: PatientEventName.phone_call,
});

const timeSpentMinutes = ref(0);
const callEndDateTime = ref("");

const timeSpentRules = [
  (v: string) => !!v || "Please add the call duration",
  (value: string) => isNumeric(value) || "Please enter a positive numeric value for call duration",
  () => isEndTimeValid.value || "End time cannot be a future time.",
];

const isEndTimeValid = computed(() => {
  const currentTime = moment();
  const endTime = moment(callEndDateTime.value, 'MMM/DD/YYYY, hh:mm A');
  return endTime.isBefore(currentTime);
});

const callDateFormatted = computed(() => {
  if (!patientLastCall.callAt) {
    return { date: '', daysUntilDue: 0 };
  }
  const currentDate = moment();
  const localTime = moment(patientLastCall.callAt);
  const dueDate = localTime.format('MMM/DD/YYYY,  h:mm A');
  const daysUntilDue = localTime.diff(currentDate, 'days');
  return { date: `${dueDate}`, daysUntilDue };
});

watchEffect(() => {
  if (patientLastCall.callAt && timeSpentMinutes.value > 0) {
    const startTime = moment(patientLastCall.callAt);
    const endTime = startTime.clone().add(timeSpentMinutes.value, 'minutes');
    const endTimeFormat = endTime.format('MMM/DD/YYYY, h:mm A');
    callEndDateTime.value = endTimeFormat;
  } else {
    callEndDateTime.value = '';
  }
});

const formInputValid = computed(() => {

  return (
    patientLastCall.callAt &&
    timeSpentMinutes &&
    isEndTimeValid.value
  )
})

const recordPatientLastCallEvent = () => {
  const callStart = new Date(patientLastCall.callAt);
  const callEnd = new Date(callEndDateTime.value);
  patientLastCall.callDuration = (callEnd.getTime() - callStart.getTime()) || 0;

  emits('saveCallEvent', patientLastCall)
};

</script>
