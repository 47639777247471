<template>
  <v-card color="surfContainer" elevation="2">
    <div class="flex justify-end">
      <v-toolbar density="compact" color="surface" class="rounded-md py-2">
        <v-toolbar-title class="d-flex justify-start bg-surface">
          <h6 class="text-h6 font-weight-black text-primary">
            {{ greeting }}, {{ username }}
          </h6>
        </v-toolbar-title>
        <v-btn v-if="isEditing" @click="addWidget" rounded="false" class="mx-2 p-2" color="primary" variant="tonal">
          <v-icon size="large" class="mx-2">mdi-widgets-outline</v-icon>
          <span class="text-body"> Add Widget </span>
        </v-btn>
        <!-- <v-btn rounded="false" @click="toggleEdit" color="primary" class="mx-2 p-2"
          :variant="`${isEditing ? 'flat' : 'tonal'}`">
          <template v-if="isEditing">
            <v-icon size="large" class="mx-2">mdi-content-save-settings</v-icon>
            <span class="text-body"> Save </span>
          </template>
<template v-else>
            <v-icon size="x-large" class="mx-2">mdi-view-dashboard-edit-outline</v-icon>
          </template>
</v-btn> -->
      </v-toolbar>
    </div>
    <div :class="`${isEditing ? 'border-2 rounded-md border-slate-300 border-dashed' : 'border-none'} `">
      <div class="grid-stack ">
        <Widget v-for="widget in widgets" :key="widget.id" :data="widget" :is-editing="isEditing" @delete="deleteWidget"
          class="elevation-1 bg-surfContainerLowest" />
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted, Ref } from "vue";
import Widget from "@/components/widgets/Widget.vue";
import { IWidget } from "@/interfaces/widget.interface";
import { GridStack } from "gridstack";
import { useUserStore } from "@/store/modules/User";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";

let grid: GridStack | null = null;
const widgets: Ref<IWidget[]> = ref([
  {
    id: "1-0",
    title: "Widget 1-0",
    grid: {
      x: 0,
      y: 0,
      w: 4,
      h: 4,
    },
    name: "RPMReviewPriorityWidget",
  },
  {
    id: "1-1",
    title: "Widget 1-1",
    grid: {
      x: 4,
      y: 0,
      w: 1,
      h: 2,
    },
    name: "PatientsActiveWidget",
  },
  {
    id: "1-2",
    title: "Widget 1-2",
    grid: {
      x: 5,
      y: 0,
      w: 1,
      h: 2,
    },
    name: "PatientSubscriptionsWidget",
  },
  {
    id: "2-0",
    title: "Widget 2-0",
    grid: {
      x: 0,
      y: 4,
      w: 4,
      h: 4,
    },
    name: "RPMSeverityPriorityWidget",
  },
  {
    id: "2-1",
    title: "Widget 2-1",
    grid: {
      x: 4,
      y: 2,
      w: 2,
      h: 8,
    },
    name: "MyTasksWidget",
  },
  {
    id: "2-2",
    title: "Widget 2-2",
    grid: {
      x: 4,
      y: 2,
      w: 2,
      h: 2,
    },
    name: "PatientsWithoutDevice",
  },
  {
    id: "3-0",
    title: "Widget 3-0",
    grid: {
      x: 0,
      y: 810,
      w: 4,
      h: 4,
    },
    name: "RPMCompliancePriorityWidget",
  },
]);

const greeting = ref("");

const { user } = useUserStore().$state;
const username = `${user.firstName} ${user.lastName}`;

const setGreeting = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();

  if (hours >= 3 && hours < 12) {
    greeting.value = "Good morning";
  } else if (hours >= 12 && hours < 18) {
    greeting.value = "Good afternoon";
  } else {
    greeting.value = "Good evening";
  }
};

function initGridStack() {
  grid = GridStack.init({
    column: 6,
    cellHeight: 100,
    margin: 10,
    disableResize: !isEditing.value,
    disableDrag: !isEditing.value,
  });
  makeWidgets(widgets.value);
}

function makeWidgets(widgets: any[]) {
  widgets.forEach((widget) => {
    makeWidget(widget);
  });
}
function makeWidget(item: IWidget) {
  const elSelector = `#${item.id}`;
  return grid?.makeWidget(elSelector);
}

async function addWidget() {
  const widgetCount = widgets.value.length + 1;
  const widget = {
    id: `${widgetCount}`,
    title: `Widget ${widgetCount}`,
    grid: {
      w: 1,
      h: 1,
    },
    name: "ErrorWidget",
  };
  widgets.value.push(widget);
  await nextTick();
  makeWidget(widget);
}

function deleteWidget(widget: IWidget) {
  const index = widgets.value.findIndex((w) => w.id === widget.id);
  if (index === -1) {
    return;
  }
  const selector = `#${CSS.escape(widget.id)}`;
  grid?.removeWidget(selector);
  grid?.compact();
  widgets.value.splice(index, 1);
}

const isEditing = ref(false);

function toggleEdit() {
  if (isEditing.value) {
    grid?.disable();
  } else {
    grid?.enable();
  }
  isEditing.value = !isEditing.value;
}

onMounted(() => {
  setGreeting();
  initGridStack();
});
</script>
