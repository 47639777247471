import gql from 'graphql-tag';

export const UPSERT_ORG_SERVICE_PROVIDER = gql`
    mutation upsertOrgServiceProvider($input: UpsertOrgServiceProviderInput!) {
        orgServiceProviders {
            upsertOrgServiceProvider(input: $input) {
            orgServiceProviderId
            orgId
            serviceProviderId
            isActive
            serviceProviderName
            sourceTypes
            customFieldDefinitions
            extendedCustomFieldDefinitions
            configuration
        }
    }
    }
`;

export const GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID = gql`
    query getOrgServiceProvidersByOrgId($orgId: UUID!) {
        orgServiceProviders {
            listOrgServiceProvidersByOrgId(orgId: $orgId) {
                orgServiceProviderId
                orgId
                serviceProviderId
                isActive
                serviceProviderName
                sourceTypes
                customFieldDefinitions
                extendedCustomFieldDefinitions
                configuration
                shortCode
            }
        }
    }
`;

export const GET_ORG_SERVICE_PROVIDER_BY_ID = gql`
query getOrgServiceProviderById($orgServiceProviderId: UUID!){
    orgServiceProviders {
        orgServiceProvider(orgServiceProviderId: $orgServiceProviderId) {
            orgServiceProviderId
            orgId
            serviceProviderId
            isActive
            serviceProviderName
            sourceTypes
            customFieldDefinitions
            extendedCustomFieldDefinitions
            configuration
        }
    }
}
`