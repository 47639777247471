import gql from "graphql-tag";

export const SAVE_ORG_SOURCE_DEFINITION = gql`
  mutation SaveOrgSourceDefinition($serviceProviderId: String!) {
    orgInventory {
    saveOrgSourceDefinition(serviceProviderId: $serviceProviderId) {
      id
      sourceDefinitionId
      orgId
    }
   }
  }
`;