import type { EventBusKey } from '@vueuse/core';
const billingProgramSubscribeEventBusKey: EventBusKey<String> = Symbol('billing-program-subscribe-event-bus');
const subscriptionEventBusKey: EventBusKey<String> = Symbol('subscription-event-bus');
const patientActiveSubscribedProgramKey: EventBusKey<string[]> = Symbol('patient-active-subscribed-programs');

export {
    billingProgramSubscribeEventBusKey,
    subscriptionEventBusKey,
    patientActiveSubscribedProgramKey
};
