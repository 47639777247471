export enum Program {
  RPM = "RPM",
  CCM = "CCM",
  CCCM = "CCCM",
  PCM = "PCM",
}
export enum EConsentStatus {
  SIGNED = "SIGNED",
  PENDING = "PENDING",
}

export enum EPatientProgramStatus {
  PENDING = "PENDING",
  SIGNED = "SIGNED",
  NO_SUBSCRIPTION = "NO_SUBSCRIPTION"
}

export enum EConsentAcknowledgement {
  PHONE_CONSENT = "PHONE_CONSENT",
  IN_PERSON_CONSENT = "IN_PERSON_CONSENT",
}
