import router from "@/router"
import { useIdle } from "@vueuse/core"
import { ref, watch } from "vue"
import { logoutUser } from "./CognitoService"


const isIdleUserLogout = ref(false)

const setIdleTimeout = () => {
  const { idle, lastActive } = useIdle(import.meta.env.VITE_IDLE_USER_LOGOUT_TIME || 3 * 60 * 60 * 1000);
  watch(idle, async (idleValue) => {
    if (idleValue) {
      await logoutUser();
      router.push({
        path: "/",
      });
      isIdleUserLogout.value = true
    }
  })
}

export { setIdleTimeout, isIdleUserLogout };
