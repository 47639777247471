<template>
  <div style="position: relative;" class="w-100">
    <template v-if="socketConnected">
      <v-skeleton-loader :loading="!timerSynced" type="list-item-two-line" color="surface">
        <v-sheet color="surfaceBright" width="180">
          <div class="w-100 text-center">
            <span class="text-primary font-weight-bold text-h6">
              {{ `${timeElapsed.minutes} mins` }} {{ `${timeElapsed.seconds} sec` }}</span>
          </div>
          <div class="w-100 text-center">
            <v-chip color="primary" size="x-small" class="mx-1">
              <span class="text-caption font-weight-bold">
                {{ trackerMode === "TOTAL" ? "Total Time Spent In Cycle" : "" }}</span>
            </v-chip>
          </div>
        </v-sheet>

      </v-skeleton-loader>
    </template>
    <template v-else>
      <div class="w-100">
        <v-chip color="warning" size="x-large">
          <v-icon icon="mdi-lan-disconnect" />
          <span class="text-body-2 font-weight-bold">Timer Disconnected</span>
        </v-chip>
      </div>

    </template>

    <app-activity-tracker :heart-beat-frequency="heartBeatFrequency" :activityTarget="patientMonitorTarget"
      :showTrackerNotification="showTrackerNotification" :activity-description="currentActivityDesc"
      :activity="currentActivityEvent" :prompt-on-inactive="true" :inactivity-timeout="inactivityTimeout"
      :reset-user-time-tracking-start="resetUserTimeTrackingStart" @timer-state-change="handleTimerStateChange"
      @tracker-inactivity-exit="handleTrackerInactivityExit" @tracker-inactivity-resume="handleTrackerInactivityResume"
      @page-visibility-change="handlePatientMonitorTimerTrackerEvent"
      @target-intersection-change="handlePatientMonitorTimerTrackerEvent"
      @user-activity-state-change="handlePatientMonitorTimerTrackerEvent"
      @heart-beat="handlePatientMonitorTimerTrackerEvent" @tracker-exit="handlePatientMonitorTimerTrackerEvent"
      @disable-reset-timer="disableResetTimer" />

    <v-snackbar v-model="isWebsocketSnackbar" :color="socketStatusAlertColor" location="top" class="mt-6">
      <v-icon class="pr-3">{{ socketStatusAlertIcon }}</v-icon>
      {{ socketStatusAlertMsg }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="isWebsocketSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup lang="ts">
import { defineProps, PropType, computed, watch, ref, onMounted } from 'vue';
import moment from "moment";
import AppActivityTracker from "../../../trackers/app-activity/AppActivityTracker.vue";
import { IAppActivityTimerState, IAppActivityTrackerInactivityExit, IAppActivityTrackerInactivityResume, IPatientMonitorTimeTrackerActivity, } from "@/interfaces/app-activity-tracker.interface";
import { AppActivityTrackerInactivityResumeMode, AppActivityTrackerState, AppActivityTrackerInactivityExitMode, AppActivityTrackerTriggerType, AppActivityEventType } from '@/enums/app-activity-tracker.enum';
import { useStopwatch } from "vue-timer-hook";
import { PatientMonitorTrackerEmits } from "@/types/app-activity-tracker.type";
import { ITaskActionEvent } from "@/interfaces/task.interface";
import { useEventBus } from '@vueuse/core';
import { patientMonitorTimeTrackerBusKey, patientTimerSyncRequestBusKey, patientTimerSyncResponseBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { taskTimerControlEventBusKey } from '@/events/bus-keys/task-events.bus-keys';
import { includes } from 'lodash';
import { useSocketStore } from '@/store/modules/socket.store';
import { getLoggedInUserInfo } from '@/services/common.service';


const props = defineProps({
  propsPatientId: {
    type: String,
    required: true,
  },
  patientName: {
    type: String,
    required: true,
  },
  trackerMode: {
    type: String as PropType<"TOTAL" | "CURRENT">,
    required: true,
  },
  currentActivity: {
    type: Object as PropType<ITaskActionEvent>,
    required: false,
  },
  isActivityTimerPaused: {
    type: Boolean,
    required: false,
  },
  offsetTimestamp: {
    type: Number,
    required: false,
  },
  showTrackerNotification: {
    type: Boolean,
    default: false,
  },
  autoStartStopwatch: {
    type: Boolean,
    default: false
  },
  stopwatchOffsetTimestamp: {
    type: Number,
    default: 0
  },
  inactivityTimeout: {
    type: Number,
    default: 1 * 60 * 1000
  },
});

const heartBeatFrequency = Number(import.meta.env.VITE_ACTIVITY_HEARTBEAT_FREQ || 5000);

const { propsPatientId, autoStartStopwatch, stopwatchOffsetTimestamp, inactivityTimeout, patientName } = props;

const patientMonitorEmit = defineEmits<PatientMonitorTrackerEmits>();

const patientMonitorTarget = document.body;

const patientId = ref(propsPatientId);


const stopwatch = useStopwatch(stopwatchOffsetTimestamp, autoStartStopwatch);

const currentActivityDesc = ref(`Monitoring Patient ${patientName}'s profile`);

const currentActivityEvent = ref<ITaskActionEvent>();

const taskTimerControlEventBus = useEventBus(taskTimerControlEventBusKey);

const patientMonitorTimeTrackerEvent = useEventBus(patientMonitorTimeTrackerBusKey);

const patientTimerSyncRequestEvent = useEventBus(patientTimerSyncRequestBusKey);

const patientTimerSyncResponseEvent = useEventBus(patientTimerSyncResponseBusKey);

const timerSynced = ref(false);

const resetUserTimeTrackingStart = ref(false);

const timeElapsed = computed(() => {
  const timerReading = {
    days: stopwatch.days.value,
    hours: stopwatch.hours.value,
    minutes: stopwatch.minutes.value,
    seconds: stopwatch.seconds.value,
  }
  const elapsedTime = moment.duration(timerReading);
  return {
    minutes: Math.floor(elapsedTime.asMinutes()),
    seconds: elapsedTime.seconds(),
    elapsedTime,
    timerReading
  };
});

const socketStore = useSocketStore();
const socketConnected = ref(socketStore.connected);

const isWebsocketSnackbar = ref(false);
const socketStatusAlertColor = ref('');
const socketStatusAlertMsg = ref('');
const isWebsocketConnected = ref(false);
const socketStatusAlertIcon = ref('');

socketStore.$subscribe((_mutation, state) => {
  socketConnected.value = state.connected;
  isWebsocketConnected.value = socketConnected.value;
});

watch(() => isWebsocketConnected.value, (newValue) => {
  socketStore.$state.disConnected = !newValue || socketStore.$state.disConnected;

  if (socketStore.$state.disConnected && isWebsocketConnected.value) {
    isWebsocketSnackbar.value = true;
    socketStatusAlertColor.value = 'success';
    socketStatusAlertMsg.value = 'Internet connection has been established. Hence, the tracking timer has started tracking time';
    socketStatusAlertIcon.value = 'mdi-lan-connect',
      socketStore.$state.disConnected = false
  }
  else if (socketStore.$state.disConnected) {
    isWebsocketSnackbar.value = true;
    socketStatusAlertColor.value = 'error';
    socketStatusAlertMsg.value = 'Internet connection has been disconnected. Hence, the tracking timer has stopped tracking time';
    socketStatusAlertIcon.value = 'mdi-lan-disconnect'
  }

});

watch(() => props.currentActivity, (newActivity, oldActivity) => {
  if (newActivity) {
    currentActivityDesc.value = `Performing the action: ${newActivity.actionDefinition.description} on task ${newActivity.task.taskIdentifier} for patient ${patientName}`;
    currentActivityEvent.value = newActivity;
  } else {
    currentActivityDesc.value = `Monitoring Patient ${patientName}'s profile`;
    currentActivityEvent.value = undefined;
  }
  if ((!!oldActivity && !newActivity) || includes(['COMPLETE', 'PAUSED'], newActivity?.actionState)) {
    resetUserTimeTrackingStart.value = true;
  } else {
    resetUserTimeTrackingStart.value = false;
  }
});


const handleTimerStateChange = ($evt: IAppActivityTimerState) => {
  if ($evt.timerStatus === AppActivityTrackerState.RUNNING) {
    !stopwatch.isRunning.value && stopwatch.start();

  } else if ($evt.timerStatus !== AppActivityTrackerState.PAUSE_SUPPRESSED) {
    stopwatch.isRunning.value && stopwatch.pause();
    taskTimerControlEventBus.emit({
      activity: props.currentActivity,
      timerState: {
        ...$evt,
        timerStatus: AppActivityTrackerState.PAUSED,
        triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
      }
    });
  }
  handlePatientMonitorTimerTrackerEvent($evt);
};

const addInactivityTime = ($evt: IAppActivityTrackerInactivityResume | IAppActivityTrackerInactivityExit, timeToRecord: number, pauseAfterAdd = false) => {
  const stopWatchReading = timeElapsed.value.elapsedTime.asMilliseconds();

  if (stopwatch.isRunning.value) {
    stopwatch.pause();
  }

  const resetToTime = ((stopWatchReading + timeToRecord)) / 1000;
  stopwatch.reset(resetToTime);

  if (!pauseAfterAdd) {
    taskTimerControlEventBus.emit({
      activity: props.currentActivity,
      timerState: {
        ...$evt,
        timerStatus: AppActivityTrackerState.RUNNING,
        triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
        type: AppActivityEventType.TIMER_STATE_CHANGE
      },
      timeToRecord
    });
  } else {
    stopwatch.pause();
    taskTimerControlEventBus.emit({
      activity: props.currentActivity,
      timerState: {
        ...$evt,
        timerStatus: AppActivityTrackerState.PAUSED,
        triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
        type: AppActivityEventType.TIMER_STATE_CHANGE
      },
      timeToRecord
    });
  }

}

const handleTrackerInactivityResume = ($evt: IAppActivityTrackerInactivityResume) => {
  const resumeHandlers: Record<AppActivityTrackerInactivityResumeMode, () => void> = {
    [AppActivityTrackerInactivityResumeMode.CONTINUE_AFTER_RECORD]: () => {
      const { timeToRecord } = $evt.lastInactivityCycle;
      addInactivityTime($evt, timeToRecord);
    },
    [AppActivityTrackerInactivityResumeMode.CONTINUE_AFTER_SKIP]: () => {
      addInactivityTime($evt, 0);
    },
  };
  resumeHandlers[$evt.mode]();
  handlePatientMonitorTimerTrackerEvent($evt);

};

const emitPatientMonitorExitTimerControlEvent = ($evt: IAppActivityTrackerInactivityExit, timeToRecord = 0) => {
  if (stopwatch.isRunning.value) {
    stopwatch.pause();
    taskTimerControlEventBus.emit({
      activity: props.currentActivity,
      timerState: {
        ...$evt,
        timerStatus: AppActivityTrackerState.PAUSED,
        triggerType: AppActivityTrackerTriggerType.ON_CUSTOM_EVENT,
        type: AppActivityEventType.TIMER_STATE_CHANGE
      },
      timeToRecord
    });
  }

};

const handleTrackerInactivityExit = ($evt: IAppActivityTrackerInactivityExit) => {
  const { timeToRecord } = $evt.lastInactivityCycle;
  const exitHandlers: Record<AppActivityTrackerInactivityExitMode, () => void> = {
    [AppActivityTrackerInactivityExitMode.EXIT_ON_INACTIVITY_RECORD]: () => {
      addInactivityTime($evt, timeToRecord, true);
      emitPatientMonitorExitTimerControlEvent($evt);
    },
    [AppActivityTrackerInactivityExitMode.EXIT_ON_INACTIVITY_SKIP]: () => {
      emitPatientMonitorExitTimerControlEvent($evt, timeToRecord);
    },
    [AppActivityTrackerInactivityExitMode.EXIT_ON_INTENT]: () => {
      emitPatientMonitorExitTimerControlEvent($evt, timeToRecord);
    },
  };
  exitHandlers[$evt.mode]();
  handlePatientMonitorTimerTrackerEvent($evt);
  patientMonitorEmit("requestPatientMonitorExit");
}

const handlePatientMonitorTimerTrackerEvent = ($evt: IPatientMonitorTimeTrackerActivity) => {
  patientMonitorTimeTrackerEvent.emit({
    patientId: patientId.value,
    patientName,
    activity: $evt,
    timerReading: timeElapsed.value.timerReading,
    currentActivity: props.currentActivity && props.isActivityTimerPaused ? undefined : props.currentActivity,
  });
  const { type, userInactive, lastInactivityCycle: { userWasActive, inactivityPrompted } } = $evt;

  if ([AppActivityEventType.USER_INACTIVITY_RESUME, AppActivityEventType.USER_INACTIVITY_EXIT].includes(type) ||
    (AppActivityEventType.HEART_BEAT === type && userInactive && userWasActive && inactivityPrompted)) {
    resetUserTimeTrackingStart.value = true;
  }
}

const emitTimerSyncRequest = () => {
  patientTimerSyncRequestEvent.emit({
    patientId: patientId.value,
    orgId: getLoggedInUserInfo().organizationId
  });
}

const disableResetTimer = () => {
  resetUserTimeTrackingStart.value = false;
}

watch(() => props.propsPatientId, (newValue, oldValue) => {
  if(newValue !== oldValue)
  patientId.value = newValue;
  emitTimerSyncRequest();
})

onMounted(() => {
  emitTimerSyncRequest();
  patientTimerSyncResponseEvent.on((response) => {
    if (response.patientId === patientId.value) {
      timerSynced.value = false;
      const offSetSeconds = response.timerOffset / 1000;
      if (stopwatch.isRunning.value) {
        stopwatch.reset(offSetSeconds);
      }
    }
    timerSynced.value = true;
  });
});
</script>
