import { CHECK_NPI_EXITS_ORG } from "@/graphql/resources/OrganizationRepository";
import { apolloClient } from "../plugins/graphqlclient";

export const doesOrgNpiExist = async (npi: string): Promise<boolean> => {
  const response = await apolloClient.query({
    query: CHECK_NPI_EXITS_ORG,
    variables: {
      npi,
    },
    fetchPolicy: "network-only",
  });
  const { checkNPIExist } = response.data;
  return checkNPIExist;
};