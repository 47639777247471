<template>
  <v-form ref="form">
    <v-row class="px-10">
      <v-col v-for="i in 14" :key="i" cols="6">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-skeleton-loader type="actions" class="my-10 mx-10"></v-skeleton-loader>
  </v-form>
</template>
<script setup lang="ts">
</script>
