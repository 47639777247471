<template>
  <v-container fluid class="px-0 py-1">
    <v-row>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-select color="primary" class="vt-single-line-multi-select" clearable density="compact" variant="solo"
          label="Filter by AutoTracked" item-value="value" item-title="title" :items="autoTracked"
          v-model="filter.autoTracked" @update:model-value="applyFilter">
        </v-select>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-select color="primary" class="vt-single-line-multi-select" clearable density="compact" variant="solo"
          label="Filter by Activity" item-value="value" item-title="title" v-model="filter.activity"
          :items="activityOptionDetails" @update:model-value="applyFilter">
        </v-select>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-select color="primary" class="vt-single-line-multi-select" clearable density="compact" variant="solo"
          label="Filter by Reporter" item-text="title" item-value="value.id" :items="filteredAssignees"
          v-model="filter.loggedBy" @update:model-value="applyFilter">
        </v-select>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-center">
        <VueDatePicker class="vt-date-time-picker-btn" v-model="filter.startTime" :teleport="true" :max-date="maxDate"
          @update:model-value="applyFilter">
          <template #trigger>
            <v-text-field v-model="startDate" label="Filter by Start date" clearable color="primary" :readonly="true"
              variant="outlined" density="compact" @click:clear="filter.startTime = null"
              @update:model-value="updateFilter">
            </v-text-field>
          </template>
        </VueDatePicker>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <VueDatePicker class="vt-date-time-picker-btn" v-model="filter.endTime" :teleport="true" :min-date="startDate"
          @update:model-value="applyFilter" input-class-name="dp-custom-input">
          <template #trigger>
            <v-text-field v-model="endDate" label="Filter by End date" clearable color="primary" :readonly="true"
              variant="outlined" density="compact" @click:clear="filter.endTime = null"
              @update:model-value="updateFilter">
            </v-text-field>
          </template>
        </VueDatePicker>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-space-around">
        <div class="mt-n6">
          <v-btn variant="tonal" :disabled="isEmpty(patientTimeLog)" @click="exportTimeLog" size="small"
            icon="mdi-file-chart" rounded elevation="3" />
          <v-tooltip activator="parent" location="bottom"> Export Time Log </v-tooltip>
        </div>
        <div class="mt-n6">
          <v-btn icon="mdi-filter-remove" variant="tonal" size="small" @click="removeFilter" rounded elevation="3" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table-server class="patient-time-log-table" :items-length="totalItems" :headers="dataTableHeaders"
          :total-items="totalItems" :items="patientTimeLog" v-model:items-per-page="itemsPerPage" :page="page"
          :loading="patientTimeLogsLoader" @update:options="loadPatientTimeLogsOnPageChange">
          <template v-slot:headers="{ columns }">
            <tr>
              <template v-for="column in columns" :key="column.key">
                <td v-if="!(column.key === 'action' && !hasAccess.viewPatientActions(viewOrgAdminRole))"
                  class="time-log-table-header">
                  <div>{{ column.title }}</div>
                </td>
              </template>
            </tr>
          </template>
          <template v-slot:[`item.action`]="{ item }" v-if="hasAccess.viewPatientActions(viewOrgAdminRole)">
            <v-btn id="editPatient" rounded class="mx-2" variant="tonal" size="small"
              @click="confirmDeleteTimeLog(item)" icon="mdi-delete" />
          </template>
        </v-data-table-server>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="showConfirmationPopup" persistent width="auto">
    <v-card min-height="200px">
      <v-card-title class="font-weight-bold bg-surface pl-5">Delete time log </v-card-title>
      <v-card-text class="text-h6">
        Are you sure you want to delete the selected time log?
      </v-card-text>
      <v-card-actions class="px-4 py-5">
        <v-row>
          <v-col class="ml-n3 mt-n3">
            <v-checkbox v-model="isAcknowledged" hide-details class="shrink mr-2" color="primary">
              <template v-slot:label>
                <div class="mt-4 text-body-2">
                  I acknowledge that I am removing some of the time spent on this patient
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn variant="tonal" rounded="false" class="mx-2" density="comfortable" elevation="3"
              @click="showConfirmationPopup = false">
              Cancel
            </v-btn>
            <v-btn variant="flat" rounded="false" type="submit" elevation="3" density="comfortable"
              :disabled="!isAcknowledged" @click='deleteTimeLog'>
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar color="green" class="text-white mt-16" v-model="showSnackBarSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSnackBarSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" class="text-white mt-16" v-model="showSnackBarErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSnackBarErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-container fluid class="pa-0">
  </v-container>
</template>

<script setup lang="ts">
import { defineProps, PropType, Ref, ref, watch, computed } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { IActivityInput, IActivityItem, IPatientTimeLog, ITimeLogFilter, IUserTimeLogResponse, ITimeLog } from '@/interfaces/timelog.interface';
import { IPaginationInput } from '@/interfaces/utility.interface';
import { onMounted } from 'vue';
import moment from 'moment';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { useEventBus } from '@vueuse/core';
import { patientManualTimetrackerBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { map, isEmpty } from 'lodash';
import { IAssigneeInput, IAssigneeItem } from '@/interfaces/task.interface';
import { IOrganizationUserFilter } from '@/interfaces/IOrganization';
import { getMonthlyCycle } from '../../../../services/patient-progam.service';
import hasAccess, {
  viewOrgAdminRole
} from "../../../../composables/roleAccess";
import { noteTimeLogEventBusKey } from '@/events/bus-keys/notes-timelog-event.bus-keys';

const emit = defineEmits(["reloadedPatientTimeLog"])
const props = defineProps({
  dataLoader: {
    type: Function as PropType<(patientId: string, filter: ITimeLogFilter | null, pagination: IPaginationInput | null) => Promise<IUserTimeLogResponse>>,
    required: true,
  },
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  dataTableHeaders: {
    type: Object as PropType<any[]>,
    required: false,
    default: () => {
      return [
        {
          title: 'Activity', key: 'activity',
          align: "start",
          sortable: false,
        },
        {
          title: 'Auto Tracked', key: 'isAutoLogged',
          align: "start",
          sortable: false,
        },
        {
          title: 'Start', key: 'startTime',
          align: "start",
          sortable: false,
        },
        {
          title: 'End', key: 'endTime',
          align: "start",
          sortable: false,
        },
        {
          title: 'Duration', key: 'timeSpent',
          align: "start",
          sortable: false,
        },
        {
          title: 'Added By', key: 'loggedBy',
          align: "start",
          sortable: false,
        },
        {
          title: 'Notes', key: 'noteContent',
          align: "start",
          sortable: false,
          width: '200px'
        },
        {
          title: 'Action',
          key: 'action',
          align: "start",
          sortable: false
        }
      ]
    }
  },
  reloadPatientTimeLog: {
    type: Boolean,
    required: true,
  },
  activityOption: {
    type: Object as PropType<IActivityInput>,
    required: true,
  },
  listAssignees: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
  deleteTimeLog: {
    type: Function as PropType<(timeLogId: string, isAcknowledged: boolean) => Promise<ITimeLog>>,
    required: true,
  },
});
const { cycleStart, cycleEnd } = getMonthlyCycle();
const startDate = ref(moment(cycleStart).format('MM/DD/YYYY'));
const endDate = ref(moment(cycleEnd).format('MM/DD/YYYY'));
const filter = ref({
  loggedBy: null,
  endTime: cycleEnd,
  startTime: cycleStart,
  autoTracked: null,
  activity: null,
} as ITimeLogFilter);
const activityOptionDetails = ref([] as IActivityItem[]);
const filteredAssignees = ref<IAssigneeItem[]>([]);
const selectedAssignees = ref<IAssigneeItem[]>([]);
const assigneesLoading = ref(false);
const page = ref(1);
const itemsPerPage = ref(Number(import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO) || 10);
const totalItems = ref(0);
const patientTimeLogsLoader = ref(false);
const patientManualTimetrackerBus = useEventBus(patientManualTimetrackerBusKey);
const patientTimeLog: Ref<IPatientTimeLog[]> = ref([]);
const autoTracked = [
  {
    title: 'Both',
    value: null
  },
  {
    title: 'Yes',
    value: true
  },
  {
    title: 'No',
    value: false
  }
];
const showConfirmationPopup = ref(false);
const showSnackBarErrorMessage = ref(false);
const showSnackBarSuccessMessage = ref(false);
const isAcknowledged = ref(true);
const timeLogId = ref('');
const errorMessage = ref("");
const successMessage = ref('');
const notesTimeLogEventBus = useEventBus(noteTimeLogEventBusKey);

const listOrgUsers = async (search: string) => {
  assigneesLoading.value = true;
  const searchData = {
    orgUserName: search
  } as IOrganizationUserFilter;
  const dataLoader = await props.listAssignees.dataLoader(searchData)
  filteredAssignees.value = [...(dataLoader.map((data) => {
    return {
      title: data.label,
      value: data.value,
    };
  })), ...selectedAssignees.value];
  assigneesLoading.value = false;
};

const maxDate = computed(() => {
  return moment().endOf('month').format('MM/DD/YYYY') !== endDate.value ? new Date(endDate.value) : new Date();
});


const updateFilter = () => {
  filter.value.startTime = startDate.value ? moment(filter.value.startTime).startOf('day').toISOString() : moment().startOf('month').toISOString()
  filter.value.endTime = endDate.value ? moment(filter.value.endTime).endOf('day').toISOString() : moment().endOf('month').toISOString()
  applyFilter()
}

const removeFilter = () => {
  filter.value.endTime = moment().endOf('month').toISOString();
  filter.value.startTime = moment().startOf('month').toISOString();
  filter.value.autoTracked = null;
  filter.value.activity = null;
  filter.value.loggedBy = null;
  applyFilter()
}

const resetPagination = () => {
  page.value = 1;
  itemsPerPage.value = 10;
}

const applyFilter = () => {
  startDate.value = filter.value.startTime ? moment(filter.value.startTime).format('MM/DD/YYYY') : startDate.value;
  endDate.value = filter.value.endTime ? moment(filter.value.endTime).format('MM/DD/YYYY') : endDate.value;
  loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: page.value, itemsPerPage: itemsPerPage.value })
};

function loadPatientTimeLogsOnPageChange({ page: updatedPage, itemsPerPage: updatedItemsPerPage }: { page: number, itemsPerPage: number }) {
  page.value = updatedPage;
  itemsPerPage.value = updatedItemsPerPage;
  loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: updatedPage, itemsPerPage: updatedItemsPerPage });
}

const loadPatientTimeLogs = (patientId: string, filter: ITimeLogFilter, { page, itemsPerPage }: { page: number, itemsPerPage: number }) => {
  patientTimeLogsLoader.value = true;
  const pagination: IPaginationInput = {
    limit: itemsPerPage == -1 ? 0 : itemsPerPage,
    offset: (page - 1) * itemsPerPage
  }
  props.dataLoader(patientId, filter, itemsPerPage > 0 ? pagination : null).then(patientTimeLogRes => {
    patientTimeLog.value = prepareTableData(patientTimeLogRes)
    totalItems.value = patientTimeLogRes.count;
    patientTimeLogsLoader.value = false;
    emit("reloadedPatientTimeLog");
  });

}

watch(() => props.reloadPatientTimeLog, (doReloadPatientTimeLog) => {
  if (doReloadPatientTimeLog) {
    resetPagination();
    loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: page.value, itemsPerPage: itemsPerPage.value })
  }
}, { immediate: true })

const getActivity = async () => {
  const getActivityData = await props.activityOption.dataLoader();
  activityOptionDetails.value = map(getActivityData, (activityItem: any) => ({
    title: activityItem.label,
    value: activityItem.activityId
  }))
};

const exportTimeLog = async () => {
  const allData = await props.dataLoader(props.patientProfile.patientId, filter.value, null);
  const preparedData = prepareTableData(allData);
  const csvContent = [props.dataTableHeaders.map(header => header.title),
  ...preparedData.map(log => props.dataTableHeaders.map(header => log[header.key as keyof IPatientTimeLog] || ''))
  ].map(row => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const link = Object.assign(document.createElement('a'), { href: URL.createObjectURL(blob), download: `patient_time_log_${props.patientProfile.patientId}_${Date.now()}.csv` });
  link.click();
};

const prepareTableData = (patientTimeLogRes: IUserTimeLogResponse) => {
  return patientTimeLogRes.results.map(timeLog => {
    const { activity, isAutoLogged, loggedBy, endTime, startTime, note, timeSpent } = timeLog;
    const timeSpentDuration = moment.duration(timeSpent, 'milliseconds');
    return {
      timeLogId: timeLog.timeLogId,
      endTime: moment(endTime).format('MM/DD/YYYY hh:mm a'),
      startTime: moment(startTime).format('MM/DD/YYYY hh:mm a'),
      loggedBy: `${loggedBy.firstName} ${loggedBy.lastName}`,
      activity: activity.label,
      isAutoLogged: isAutoLogged ? 'Yes' : 'No',
      noteContent: note?.note?.noteContent?.message || 'N/A',
      timeSpent: `${Math.floor(timeSpentDuration.asMinutes())} min ${timeSpentDuration.seconds()} secs`
    } as IPatientTimeLog;
  });
};

const confirmDeleteTimeLog = async (item: IPatientTimeLog) => {
  showConfirmationPopup.value = true;
  timeLogId.value = item.timeLogId
};

const deleteTimeLog = async () => {
  try {
    showConfirmationPopup.value = false;
    patientTimeLogsLoader.value = true;
    await props.deleteTimeLog(timeLogId.value, isAcknowledged.value);
    loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: page.value, itemsPerPage: itemsPerPage.value });
    showSnackBarSuccessMessage.value = true
    successMessage.value = 'Timelog deleted successfully'
    notesTimeLogEventBus.emit('note-timelog-list-event-bus')
  } catch (error) {
    const { message } = error as Error;
    patientTimeLogsLoader.value = false;
    showSnackBarErrorMessage.value = true
    errorMessage.value = message;
  }
}


onMounted(() => {
  getActivity()
  listOrgUsers('')
  loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: page.value, itemsPerPage: itemsPerPage.value });
  patientManualTimetrackerBus.on(() => {
    loadPatientTimeLogs(props.patientProfile.patientId, filter.value, { page: page.value, itemsPerPage: itemsPerPage.value });
  })
})
</script>

<script lang="ts">
export default {
  name: 'ListPatientTimeLog'
}
</script>

<style lang="scss" scoped>
.time-log-table-header {
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  font-weight: bold;
}
</style>
