<template>
  <v-card class="w-100 h-100" color="surface" elevation="2">
    <v-card-title class="text-primary">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="4" class="d-flex align-center flex-column justify-center">
            <div class="text-primary ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="primary" class="pa-4" elevation="2">
                  <span class="text-subtitle-2"> PRACTICE <v-icon icon="mdi-clock" size="small"></v-icon> &nbsp; </span>
                  <span class="text-subtitle-1 font-weight-bold">{{ convertMillisecondsHMS(totalTimeSpent)
                    }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>

            <div class="text-primary ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="success" class="pa-4" elevation="2">
                  <span class="text-subtitle-2"> QHCP <v-icon icon="mdi-clock" size="small"></v-icon> &nbsp; </span>
                  <span class="text-subtitle-1 font-weight-bold">{{ convertMillisecondsHMS(totalTimeSpentQHCP)
                    }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>
            <div class="text-primary ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="info" class="pa-4" elevation="2">
                  <span class="text-subtitle-2">STAFF <v-icon icon="mdi-clock" size="small"></v-icon> &nbsp;</span>
                  <span class="text-subtitle-1 font-weight-bold">{{ convertMillisecondsHMS(totalTimeSpentStaff)
                    }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center flex-column justify-center">
            <div class="text-primary  ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="primary" elevation="2" class="pa-4">
                  <span class="text-subtitle-2"> Start Date &nbsp;</span>
                  <span class="text-subtitle-1 font-weight-bold">{{ data?.cycleStart ?
                    moment(data.cycleStart).format("DD/MM/YYYY")
                    : 'N/A' }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>
            <div class="text-primary ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="primary" elevation="2" class="pa-4">
                  <span class="text-subtitle-2"> Patients in Cycle &nbsp; </span>
                  <span class="text-subtitle-1 font-weight-bold">{{ patientsCountInCycle }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>
            <div class="text-primary  ma-1">
              <v-skeleton-loader type="list-item" :loading="reportLoading" width="200" color="surface">
                <v-chip color="primary" elevation="2" class="pa-4">
                  <span class="text-subtitle-2"> Days in Cycle &nbsp; </span>
                  <span class="text-subtitle-1 font-weight-bold">{{ data?.cycleDays || 'N/A' }}</span>
                </v-chip>
              </v-skeleton-loader>
            </div>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <cycle-selector :org-cycle-type="OrgCycleType.ACTIVITY" :org-id="orgId" density="comfortable"
              label="Select a cycle to generate report" :data-loader="cycleSelectorProps.dataLoader" class="mt-4 px-8"
              @cycle-change="handleCycleChange" />
          </v-col>
          <v-col class="d-flex align-center justify-center" style="max-width: 100px;">
            <div class="mx-6">
              <v-btn v-if="isDataAvailable" @click="exportFile" variant="tonal" density="default" elevation="3" rounded
                class="ma-2" icon="mdi-file-chart" size="small" />
              <v-tooltip activator="parent" location="bottom">
                Export activity report
              </v-tooltip>

            </div>
          </v-col>
        </v-row>
        <v-divider thickness="1" class="my-2" />
      </v-container>
    </v-card-title>

    <v-card-item>
      <v-skeleton-loader v-if="reportLoading" type="table" color="surface" :rows="10" :columns="10" />
      <template v-else>
        <div style="height: 75vh;" v-if="isDataAvailable" class="bg-surface">
          <v-grid id="org-activity-report-grid" :theme="theme.current.value.dark ? 'darkMaterial' : 'material'"
            :source="rows" :columns="columns" :readonly="true" :exporting="true" :filter="true" :resize="true"
            :col-size="180" :column-types="columnTypes" />
        </div>
        <div v-else class="no-data-container mt-16">
          <div class="text-h6 font-weight-bold">No data available</div>
          <img src="../../../../assets/empty.png" alt="No data found" class="no-data-image" />
        </div>
      </template>

    </v-card-item>
    <v-snackbar color="error" class="text-white" v-model="showActivityReportErr" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showActivityReportErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script setup lang="ts">
import { IOrgCycleActivityLogs } from "@/interfaces/activity-log.interface";
import VGrid, { ColumnGrouping, ColumnRegular, ColumnType, DataType } from "@revolist/vue3-datagrid";
import { onMounted, ref, defineProps, PropType, watch } from 'vue';
import { convertMillisecondsHMS } from "@/composables/DateUtility";
import CycleSelector from "@/components/cycle/CycleSelector.vue";
import moment from 'moment';
import { ICycle, ICycleSelectorDependencies } from "@/interfaces/cycle.interface";
import { map, includes, filter, first, uniqBy, isEmpty } from 'lodash';
import { OrgCycleType } from "@/enums/cycle.enum";
import NumberColumnType from '@revolist/revogrid-column-numeral';
import DateTypePlugin from '@revolist/revogrid-column-date';
import { Provider } from "@/interfaces/patient.interface";
import { useTheme } from "vuetify";

const theme = useTheme();

const columnTypes = {
  'numeric': new NumberColumnType('0,0'),
  'dollar': new NumberColumnType('$0,0.00'),
  'date': new DateTypePlugin() as unknown as ColumnType
};



const { orgId, dataLoader } = defineProps({
  orgId: {
    type: String,
    required: true
  },
  cycleSelectorProps: {
    type: Object as PropType<ICycleSelectorDependencies>,
    required: true
  },
  dataLoader: {
    type: Function as PropType<(orgId: string, cycle?: ICycle) => Promise<IOrgCycleActivityLogs>>,
    required: true
  }
});
const data = ref<IOrgCycleActivityLogs>();
const exportPlugin = ref();
const totalTimeSpent = ref(0);
const totalTimeSpentQHCP = ref(0);
const totalTimeSpentStaff = ref(0);
const patientsCountInCycle = ref(0)
const reportLoading = ref(false);
const isDataAvailable = ref(false)
const showActivityReportErr = ref(false);
const errorMessage = ref("");
const columns = ref<(ColumnRegular | ColumnGrouping)[]>([{
  prop: 'patientFirstName',
  name: 'First Name',
  sortable: true,
  pin: 'colPinStart'
},
{
  prop: 'patientLastName',
  name: 'Last Name',
  sortable: true,
  pin: 'colPinStart'
},
{
  prop: 'patientDob',
  name: 'DOB',
  columnType: 'date',
  sortable: true,
  pin: 'colPinStart'
},
{
  prop: 'providerName',
  name: 'Provider',
  sortable: true,
  pin: 'colPinStart'
},
{
  prop: 'activityTime',
  name: 'Activity Time',
  columnType: 'date',
  sortable: true,
},
{
  prop: 'timeSpent',
  columnType: 'date',
  name: 'Time Spent',
  sortable: true,
},
{
  prop: 'actorName',
  name: 'Actor Name',
  sortable: true,
},
{
  prop: 'actorRole',
  name: 'Actor Role',
  sortable: true,
},
]);

const rows = ref<DataType[]>([]);

const exportFile = () => {
  /* to-do: find correct type for exportPlugin */
  (exportPlugin.value as any)?.exportFile({
    filename: `export-activity-report-${orgId}-${Date.now()}`,
  });
};

const loadExportPlugin = () => {
  const grid = first(filter(document.getElementsByTagName('revo-grid'), (grid) => {
    return grid.id === 'org-activity-report-grid';
  }));
  if (grid) {
    grid.getPlugins().then(plugins => {
      exportPlugin.value = plugins.find((plugin) => 'exportFile' in plugin);
    });
  }
};

const countUniquePatientsInCycle = (data: IOrgCycleActivityLogs) => {
  return (uniqBy(data?.loggedActivities, "patientId").length)
}

const buildDataRows = (data: IOrgCycleActivityLogs) => {
  const { loggedActivities } = data;

  let qhcpTimespent = 0;
  let staffTimespent = 0;

  const activityRows = map(loggedActivities, (activity) => {
    if (includes(activity.loggedBy.roles, 'ORG_PROVIDER')) {
      qhcpTimespent += activity.timeSpent;
    } else {
      staffTimespent += activity.timeSpent;
    }
    return {
      patientFirstName: activity.firstName,
      patientLastName: activity.lastName,
      patientDob: activity.dob,
      providerName: `${(activity.provider as Provider)?.firstName} ${(activity.provider as Provider)?.lastName}`,
      activityTime: activity.startTime,
      timeSpent: convertMillisecondsHMS(activity.timeSpent),
      actorName: `${activity.loggedBy.firstName} ${activity.loggedBy.lastName}`,
      actorRole: activity.loggedBy.designation,
    };
  });
  rows.value = activityRows;
  totalTimeSpent.value = qhcpTimespent + staffTimespent;
  totalTimeSpentQHCP.value = qhcpTimespent;
  totalTimeSpentStaff.value = staffTimespent;
}

const loadData = async (cycle?: ICycle) => {
  try {
    reportLoading.value = true;
    const result = await dataLoader(orgId, cycle);
    data.value = result;
    reportLoading.value = false;
    /* find a better solution over using setTimeout  to wait for the html rendering*/
    setTimeout(() => loadExportPlugin());
  } catch (error) {
    const result = {} as IOrgCycleActivityLogs
    errorMessage.value = 'failed to fetch  activity report';
    showActivityReportErr.value = true;
    data.value = result;
    setDefaultValues()
  }
};


const handleCycleChange = (payload: ICycle) => {
  if (payload) {
    loadData(payload);
  }
};

watch(data, (newData) => {
  if (newData) {
    const dataVal = data.value!;
    if (isEmpty(dataVal)) {
      setDefaultValues();
    } else if (!isEmpty(dataVal)) {
      buildDataRows(dataVal);
      patientsCountInCycle.value = countUniquePatientsInCycle(dataVal);
      isDataAvailable.value = true;
    }
  }
});

const setDefaultValues = () => {
  totalTimeSpent.value = 0;
  totalTimeSpentQHCP.value = 0;
  totalTimeSpentStaff.value = 0;
  patientsCountInCycle.value = 0;
  isDataAvailable.value = false;
  reportLoading.value = false;
};

onMounted(() => {
  loadData();
});

</script>
