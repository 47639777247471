<template>
  <v-card class="h-100 py-2" color="surfContainer" elevation="2">
    <v-card-title>
      <v-toolbar density="compact" color="surface" class="pa-2">
        <v-toolbar-title class="text-primary pa-2">
          <div class="d-flex align-center items-center text-primary">
            <v-icon size="large">mdi-shape-plus </v-icon>
            <div class="d-flex flex-wrap">
              <span class="ml-4">Subscriptions</span>
              <span class="v-card-subtitle flex-1-1-100"> Manage patients' subscriptions to programs</span>
            </div>
          </div>
        </v-toolbar-title>
        <v-tabs v-model="consentTab" color="primary" class="bg-surface text-primary" density="compact">
          <v-tab :value="EPatientProgramStatus.PENDING" color="primary">
            <span class="text-subtitle-1 font-weight-bold text-capitalize">Pending Consents</span>
          </v-tab>
          <v-tab :value="EPatientProgramStatus.SIGNED">
            <span class="text-subtitle-1 font-weight-bold text-capitalize">Active Subscriptions</span>
          </v-tab>
          <v-tab :value="EPatientProgramStatus.NO_SUBSCRIPTION">
            <span class="text-subtitle-1 font-weight-bold text-capitalize">No Subscriptions</span>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-sheet class="w-100" color="surfContainer">
        <v-window class="w-100 window-container" v-model="consentTab">
          <v-window-item :value="EPatientProgramStatus.PENDING">
            <multi-patient-consent-list :consent-template-loader="patientProgramConsentDependencies.getProgramEConsentTemplate
                " :get-consent-upload-url="patientProgramConsentDependencies.getProgramEConsentUploadUrl
                  " :sign-patient-program-consent="patientProgramConsentDependencies.signPatientProgramConsent
                    " :subscribe-programs="patientProgramConsentDependencies.subscribePrograms
                      " :un-subscribe-program="patientProgramConsentDependencies.unSubscribeProgram
                        " :get-all-patient-consents="patientProgramConsentDependencies.getAllPatientConsents
                          " :program-status="EPatientProgramStatus.PENDING" @consent-signed="consentSigned"
              :is-consent-signed=false :is-consent-reset="isConsentReset" :is-no-subscription-tab="false"
              :get-all-programs="patientProgramConsentDependencies.getAllPrograms" :is-pending-tab="true"
              @consent-reset-triggered="resetTriggered"></multi-patient-consent-list>
          </v-window-item>
          <v-window-item :value="EPatientProgramStatus.SIGNED">
            <multi-patient-consent-list :consent-template-loader="patientProgramConsentDependencies.getProgramEConsentTemplate
                " :get-consent-upload-url="patientProgramConsentDependencies.getProgramEConsentUploadUrl
                  " :sign-patient-program-consent="patientProgramConsentDependencies.signPatientProgramConsent
                    " :subscribe-programs="patientProgramConsentDependencies.subscribePrograms
                      " :un-subscribe-program="patientProgramConsentDependencies.unSubscribeProgram
                        " :get-all-patient-consents="patientProgramConsentDependencies.getAllPatientConsents
                          " :program-status="EPatientProgramStatus.SIGNED" @consent-reset="consentReset"
              :is-consent-reset=false :is-consent-signed="isConsentSigned" :is-no-subscription-tab="false"
              :get-all-programs="patientProgramConsentDependencies.getAllPrograms" :is-pending-tab="false"
              @consent-signed-triggered="consentSignedTriggered"></multi-patient-consent-list>
          </v-window-item>
          <v-window-item :value="EPatientProgramStatus.NO_SUBSCRIPTION">
            <multi-patient-consent-list :consent-template-loader="patientProgramConsentDependencies.getProgramEConsentTemplate
                " :get-consent-upload-url="patientProgramConsentDependencies.getProgramEConsentUploadUrl
                  " :sign-patient-program-consent="patientProgramConsentDependencies.signPatientProgramConsent
                    " :subscribe-programs="patientProgramConsentDependencies.subscribePrograms
                      " :un-subscribe-program="patientProgramConsentDependencies.unSubscribeProgram
                        " :get-all-patient-consents="patientProgramConsentDependencies.getAllPatientConsents
                          " :program-status="EPatientProgramStatus.NO_SUBSCRIPTION" @consent-reset="consentReset"
              :is-consent-reset=false :is-consent-signed="isConsentSigned" :is-no-subscription-tab="true"
              :get-all-programs="patientProgramConsentDependencies.getAllPrograms" :is-pending-tab="false"
              @consent-signed-triggered="consentSignedTriggered"></multi-patient-consent-list>
          </v-window-item>
        </v-window>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { IPatientData } from "@/interfaces/Service.interface";
import { pick } from "lodash";
import { PropType, ref, watch } from "vue";
import MultiPatientConsentList from "../components/econsent/MultipatientEConsentList.vue";
import { EPatientProgramStatus } from "@/enums/patient-program.enum";
import { multiSubscriptionEventBusKey } from "../events/bus-keys/consents-subscription-event.bus-keys";
import { useEventBus } from "@vueuse/core";

const { getDependencies } = defineProps({
  getDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
});

const consentTab = ref(EPatientProgramStatus.PENDING);
const isConsentSigned = ref(false);
const isConsentReset = ref(false);
const multiSubscriptionEventBus = useEventBus(multiSubscriptionEventBusKey);

const patientProgramConsentDependencies = pick(getDependencies, [
  "getProgramEConsentTemplate",
  "getProgramEConsentUploadUrl",
  "getProgramEConsentDownloadUrl",
  "signPatientProgramConsent",
  "subscribePrograms",
  "unSubscribeProgram",
  "getAllPatientConsents",
  "getAllPrograms",
]);

const consentSigned = () => {
  isConsentSigned.value = true;
}

const resetTriggered = () => {
  isConsentReset.value = false;
}

const consentSignedTriggered = () => {
  isConsentSigned.value = false;
}

const consentReset = () => {
  isConsentReset.value = true;
}

watch(() => consentTab.value, () => {
    multiSubscriptionEventBus.emit(true);
})


</script>
<style scoped></style>
