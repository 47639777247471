import { DOES_USER_DEA_EXIST, GET_ALL_ORGANIZATION_USERS, DOES_USER_NPI_EXIST } from "@/graphql/resources/OrganizatonUserRepository";
import { apolloClient } from "../plugins/graphqlclient";
import { ListLabels } from "@/interfaces/notes.interface";
import { DOES_USER_EMAIL_EXIST } from "@/graphql/resources/UserRepository";
import { IOrganizationUserDetails } from "@/interfaces/IOrganization";
import { GET_ORG_USER_BY_ID } from "@/graphql/resources/OrganizationUserRepository";

export const listOrganizationUsers = async (
  orgId: string
): Promise<ListLabels[]> => {
  const retrievedOrganizationUsers: Promise<ListLabels[]> = new Promise(
    (resolve, reject) => {
      apolloClient
        .query({
          query: GET_ALL_ORGANIZATION_USERS,
          fetchPolicy: "network-only",
          variables: {
            filter: { organizationId: orgId },
          },
        })
        .then((data) => {
          const organizationUsers: ListLabels[] = data.data.listOrganizationUsers
          resolve(organizationUsers);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

  return retrievedOrganizationUsers;
};

export const getOrgUserDetail = async (
  orgUserId: string
): Promise<IOrganizationUserDetails> => {
  const retrievedOrganizationUsers: Promise<IOrganizationUserDetails> = new Promise(
    (resolve, reject) => {
      apolloClient
        .query({
          query: GET_ORG_USER_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            filter: { orgUserId: orgUserId },
          },
        })
        .then((data) => {
          const organizationUsers: IOrganizationUserDetails = data.data.getOrgUserById
          resolve(organizationUsers);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

  return retrievedOrganizationUsers;
};

export const doesUserEmailExists = async (email: string): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: DOES_USER_EMAIL_EXIST,
    variables: {
      email,
    },
    fetchPolicy: 'network-only'
  });

  const { doesUserEmailExists } = data;

  return doesUserEmailExists;
}

export const doesUserDeaExists = async (deaNumber: string): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: DOES_USER_DEA_EXIST,
    variables: {
      deaNumber,
    },
    fetchPolicy: "network-only"
  });

  const { doesOrgUserDEAExists } = data;

  return doesOrgUserDEAExists;
}


export const doesOrgUserNpiExists = async (npi: string): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: DOES_USER_NPI_EXIST,
    variables: {
      npi,
    },
    fetchPolicy: "network-only",
  });
  const { doesOrgUserNpiExists } = data;
  return doesOrgUserNpiExists;
};
