<template>
  <v-card>
    <v-card-title class="text-primary text-center mt-3">Assign Device</v-card-title>
    <v-card-item>
      <v-form class="pa-5" @submit.prevent="assignDevice">
        <v-row>
          <v-col cols="12">
            <v-card-title class="text-subtitle-1">Subscribed Programs : <v-chip
                v-if="isEmpty(programsAvailableForBilling)" color="primary">N/A</v-chip> <v-chip v-else
                v-for="(program, index) in programsAvailableForBilling" :key="index" class="ma-2" color="primary">
                {{ program.title }}
              </v-chip></v-card-title>
          </v-col>
          <v-col cols="6">
            <v-select variant="outlined" color="primary" :items="serviceProviders" v-model="serviceProviderId"
              density="compact" hide-details label="Service Provider*" required />
          </v-col>
          <v-col cols="6">
            <v-text-field hint="Please enter at least 3 characters to search" variant="outlined" color="primary"
              density="compact" v-model="deviceSearchInput" label="Search" @input="searchByDeviceInputs" :dense="false">
              <template v-slot:append-inner>
                <v-tooltip activator="parent" location="bottom">
                  <span>Filter by Device Name, Gateway ID or Device Type</span>
                </v-tooltip>
                <v-icon icon="mdi-magnify" />
              </template>
            </v-text-field>
          </v-col>
          <v-card-title class="mx-5 mt-2 text-subtitle-1 font-weight-black">Devices:</v-card-title>
          <v-col cols="12" v-if="loading" style="height: 360px;">
            <v-skeleton-loader
              type=" list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" />
          </v-col>
          <v-col cols="12" v-else>
            <v-list style="height: 300px;" class="mb-10">

              <v-list-item-title v-if="isEmpty(searchedDevices) && deviceSearchInput.trim().length < 3"
                class="font-weight-thin text-body-1 text-center mt-28">You can search devices by Device Name, Gateway
                ID or Device Type using the search bar.</v-list-item-title>
              <v-list-item-title v-if="isEmpty(searchedDevices) && deviceSearchInput.trim().length >= 3"
                class="font-weight-bold text-body-1 text-center mt-20">No devices found</v-list-item-title>

              <v-list-item v-for="(item, i) in searchedDevices" class="my-3 mx-2" elevation="3" :key="i" :value="item"
                color="surfContainer" variant="elevated" @click="onDeviceSelect(item.sourceMeta.name)">
                <v-row class="d-flex justify-center">
                  <v-col cols="1">
                    <v-avatar color="primary">{{
                      item.sourceMeta.name.charAt(0)
                    }}</v-avatar>
                  </v-col>
                  <v-col cols="5" class="d-flex items-center">
                    <v-list-item-title class="text-subtitle-1">
                      {{ item.sourceMeta.name }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="6" class="d-flex items-center">
                    <v-list-item-title class="text-subtitle-1 ">
                      {{ (item.sourceMeta as ITenoviMeta).device.hardwareUuid }}
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-card-subtitle v-if="deviceNames.length === 10">Type more to get better matches</v-card-subtitle>
          <v-divider />
          <v-btn class="text-white px-10 mx-4" @click="CancelDeviceAssigning" rounded="false" variant="tonal"
            elevation="3">
            Cancel
          </v-btn>
          <v-btn :disabled="isEmpty(selectedDeviceId)" type="submit" class="px-10" rounded="false" elevation="3"
            color="primary" variant="elevated">Assign</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-item>
  </v-card>

  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script lang="ts" setup>
import { BillingDistributionType } from "@/enums/patient-program-cycle.enum";
import { Program } from "@/enums/patient-program.enum";
import { IPatientBillingCycle } from "@/interfaces/billing.interface";
import { IPatientProgram, IProgramConsentResponse } from "@/interfaces/econsent.interface";
import { ITenoviMeta } from "@/interfaces/InventoryItem.interface";
import { IPatientProgramCycle } from "@/interfaces/patient-program-cycle.interface";
import {
  IDropdownInput,
  ISource,
  ISourceList,
} from "@/interfaces/source.interface";
import { debounce, isEmpty, map } from "lodash";
import { onMounted, PropType, reactive, ref } from "vue";

const props = defineProps({
  serviceProviders: {
    type: Array as () => IDropdownInput[],
    required: false,
  },
  deviceInput: {
    type: Object as PropType<ISourceList>,
    required: false,
  },
  profilePatientId: {
    type: String,
    required: false,
  },
  dataLoader: {
    type: Function as PropType<(patientId: string) => Promise<IProgramConsentResponse[]>>,
    required: true,
  },
  programSubscribedDataLoader: {
    type: Function as PropType<(patientId: string, billingDistribution: BillingDistributionType) => Promise<IPatientBillingCycle>>,
    required: true
  },
});

const { profilePatientId, programSubscribedDataLoader } = props;

const emits = defineEmits(["assignDevice", "cancelDeviceAssign"]);

const loading = ref(false);
const serviceProviderId = ref("");
const deviceSearchInput = ref("");
const deviceNames = ref([] as string[]);
const searchedDevices = ref([] as ISource[]);
const selectedDeviceId = ref("");
const showErrorMessage = ref(false);
const errorMessage = ref('');
const programsAvailableForBilling = ref<{ title: Program, value: IPatientProgramCycle, props?: { disabled?: Boolean } }[]>([]);
let controller: AbortController | null = null;

const searchByDeviceInputs = debounce(() => {
  if (!isEmpty(deviceSearchInput.value.trim()) && deviceSearchInput.value.trim().length >= 3) {
    listDevicesByDeviceId(deviceSearchInput.value.trim());
  }
  else {
    searchedDevices.value = [];
    deviceNames.value = [];
    selectedDeviceId.value = '';
  }
}, 1000);

const listDevicesByDeviceId = async (deviceId: string) => {
  selectedDeviceId.value = '';
  try {

    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    if (deviceId) {
      const input = {
        serviceProviderId: serviceProviderId.value,
        isActive: true,
        isAssociated: false,
        filter: {
          deviceDetails: deviceId,
          limit: 10,
          offset: 0,
        },
      };

      loading.value = true;
      searchedDevices.value = await props.deviceInput!.dataLoader(input, controller.signal);
      loading.value = false;

      deviceNames.value = searchedDevices.value.map(
        (device: ISource) => device.sourceMeta.name,
      );

    }
  }
  catch (error) {
    loading.value = false;
    if ((error as Error).message !== 'signal is aborted without reason') {
      showErrorMessage.value = true;
      errorMessage.value = (error as Error).message;
    }
    searchedDevices.value = [];
    deviceNames.value = [];

  }
};



const getSubscribedPrograms = () => {
  programSubscribedDataLoader(profilePatientId!, BillingDistributionType.STANDARDIZED).then((data) => {
    if (data) {
      programsAvailableForBilling.value = map(data.programsInCycle, prg => {
        return {
          title: prg.program,
          value: prg
        }
      });
    }
  }).catch((e) => {
    showErrorMessage.value = true;
    errorMessage.value = (e as Error).message;
  });
}


const onDeviceSelect = (deviceName: string) => {
  const selectedDevice = searchedDevices.value.find(
    (device: ISource) => device.sourceMeta.name === deviceName,
  );
  selectedDeviceId.value = selectedDevice!.sourceId;
};

const CancelDeviceAssigning = () => {
  emits("cancelDeviceAssign");
};

const assignDevice = () => {
  const assignDevice = {
    patientId: props.profilePatientId,
    deviceId: selectedDeviceId.value,
    patientIdentityAtSource: props.profilePatientId,
  };
  emits("assignDevice", assignDevice);
};



onMounted(() => {
  getSubscribedPrograms();
  if (props.serviceProviders) {
    serviceProviderId.value = props.serviceProviders[0]?.value || '';
  }
})
</script>
