import { AuthUserStore } from "../../interfaces/user.interface";
import { defineStore } from "pinia";


export const useAuthUserStore = defineStore("authUser", {
  state: (): AuthUserStore => ({
    isSuperUser: false,
    isAdmin: false,
    isOrgAdmin: false,
    isOrgStaff: false,
    isOrgProvider: false,
    isPatient: false,
  }),
  getters: {
    getIsSuperUser: (state) => state.isSuperUser,
    getIsAdmin: (state) => state.isAdmin,
    getIsOrgAdmin: (state) => state.isOrgAdmin,
    getIsOrgStaff: (state) => state.isOrgStaff,
    getIsOrgProvider: (state) => state.isOrgProvider,
    getIsPatient: (state) => state.isPatient,
  },
  actions: {
    saveAuthUser(authUser: AuthUserStore) {
      this.isAdmin = authUser.isAdmin;
      this.isOrgAdmin = authUser.isOrgAdmin;
      this.isOrgStaff = authUser.isOrgStaff;
      this.isOrgProvider = authUser.isOrgProvider;
      this.isPatient = authUser.isPatient;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
