<template>
  <div class="w-100 p-10">
    <v-row v-if="hasComplianceData">
      <v-col class="flex justify-center" :lg="compactView ? '12' : '6'" sm="12" cols="12">
        <Calendar :attributes="currentCalAttributes" expanded mode="date"
          :is-dark="theme.global.name.value === 'dark'" />
      </v-col>

      <v-col :lg="compactView ? '12' : '6'" sm="12" cols="12">
        <v-card class="mx-auto" variant="flat">
          <v-row class="p-2">
            <v-col md="6" sm="12">
              <v-list-item title="Days Active">
                <template v-slot:prepend>
                  <v-badge color="success" inline></v-badge>
                </template>
                <template v-slot:append>
                  <div>
                    {{ complianceStatus.daysActive }}
                  </div>
                </template>
              </v-list-item>
              <v-list-item title="Days Missed">
                <template v-slot:prepend>
                  <v-badge color="error" inline></v-badge>
                </template>
                <template v-slot:append>
                  <div>
                    {{ complianceStatus.daysMissed }}
                  </div>
                </template>
              </v-list-item>
              <v-list-item title="Days Remaining">
                <template v-slot:prepend>
                  <v-badge color="#e2e8f0" inline></v-badge>
                </template>
                <template v-slot:append>
                  <div>
                    {{ complianceStatus.daysRemaining }}
                  </div>
                </template>
              </v-list-item>
            </v-col>
            <v-col md="6" sm="12">
              <v-list-item title="Patient Added Date">
                <template v-slot:append>
                  <div>
                    {{ enrollmentDate }}
                  </div>
                </template>
              </v-list-item>
              <v-list-item title="RPM Enrollment Date">
                <template v-slot:append>
                  <div>
                    {{ programStartDate }}
                  </div>
                </template>
              </v-list-item>
              <v-list-item title="Cycle Start Date">
                <template v-slot:append>
                  <div>
                    {{ cycleStartDate }}
                  </div>
                </template>
              </v-list-item>
              <v-list-item title="Cycle Identifier">
                <template v-slot:append>
                  <div>
                   {{  cycleIdentifier }}
                  </div>
                </template>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="flex justify-center" cols="12">
        <div>
          No adherence insights found
        </div>
      </v-col>
    </v-row>
  </div>
  <Loader :overlay="loading" />
</template>
<script setup lang="ts">
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { onMounted, ref, PropType } from 'vue';
import moment, { Moment } from 'moment';
import { PatientComplianceColorTheme, PatientProgramCyclePeriod } from '@/enums/patient-program-cycle.enum';
import { IPatientProgramCycle, IPatientProgramCycleStatus, IPatientProgramCyclesResponse } from '@/interfaces/patient-program-cycle.interface';
import _ from 'lodash';
import { Calendar, } from 'v-calendar';
import 'v-calendar/style.css';
import Loader from '../../../common/Loader.vue';
import { Program } from '@/enums/patient-program.enum';
import { useTheme } from "vuetify";

const { dataLoader, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  dataLoader: {
    type: Function as PropType<(patientId: string, period: PatientProgramCyclePeriod, program: string) => Promise<IPatientProgramCyclesResponse>>,
    required: true,
  },
  compactView: {
    type: Boolean,
    required: false,
    default: false
  }
});

const theme = useTheme();

const loading = ref(false)
const currentCalAttributes = ref([] as Partial<{ highlight: any, dates: any[] }>[]);
const complianceStatus = ref({} as IPatientProgramCycleStatus);
const cycleDays = ref(null as string | null);
const cycleNumber = ref(null as string | null);
const programStartDate = ref(null as string | null);
const cycleStartDate = ref(null as string | null);
const enrollmentDate = ref(null as string | null);
const DATE_FORMAT = "MM-DD-YYYY";
const hasComplianceData = ref(false);
const cycleIdentifier = ref('');

const getPatientProgramCycles = async () => {
  loading.value = true;
  try {
    const response: IPatientProgramCyclesResponse = await dataLoader(
      patientProfile.patientId,
      PatientProgramCyclePeriod.CURRENT,
      Program.RPM
    );
    const currentCycle = _.first(response.results);
    if (currentCycle) {
      currentCalAttributes.value = prepareCalendarEntries(currentCycle)
      complianceStatus.value = currentCycle.cycleStatus;
      cycleDays.value = currentCycle.cycleDays.toString();
      cycleNumber.value = currentCycle.cycleNumber.toString();
      cycleIdentifier.value = currentCycle.cycleIdentifier;
      programStartDate.value = moment.unix(Number.parseInt(currentCycle.programStartDate) / 1000).format(DATE_FORMAT);
      cycleStartDate.value = moment(currentCycle.cycleStart).format(DATE_FORMAT);
      hasComplianceData.value = true;
    }
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};

const prepareCalendarEntries = (currentCycle: IPatientProgramCycle): Partial<{ highlight: any, dates: any[] }>[] => {
  const calEntries = [] as Partial<{ highlight: any, dates: any[] }>[];
  const startDate = moment(currentCycle?.cycleStart);
  const endDate = startDate.clone().add((currentCycle?.cycleDays - 1), 'days');
  const currentDate = startDate.clone();
  const activeDates = _.map(currentCycle?.cycleStatus.activityHistory, (activity) => {
    return moment.tz(activity.date, 'UTC').local().format(DATE_FORMAT);
  });

  while (currentDate.isSameOrBefore(endDate)) {
    calEntries.push({
      dates: [{
        start: currentDate.toDate(),
        end: currentDate.toDate(),
      }],
      highlight: getHighlightColor(activeDates, currentDate)
    })
    currentDate.add(1, 'day');
  }
  return calEntries;
}

const getHighlightColor = (activeDates: string[], currentDate: Moment): any => {
  return activeDates.includes(currentDate.format(DATE_FORMAT))
    ? PatientComplianceColorTheme.ACTIVE
    : currentDate.isSameOrAfter(moment())
      ? { color: PatientComplianceColorTheme.REMAINING, fillMode: "light" }
      : { color: PatientComplianceColorTheme.MISSED };
};


onMounted(async () => {
  await getPatientProgramCycles();
  enrollmentDate.value = patientProfile.patientInfo?.createdTimestamp ? moment.unix(patientProfile.patientInfo?.createdTimestamp / 1000).format(DATE_FORMAT) : 'N/A'
})

</script>

<style scoped lang="scss"></style>
