import { defineStore } from 'pinia';
import { patientMonitorTimeTrackerBusKey, patientTimerSyncRequestBusKey, patientTimerSyncResponseBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { useEventBus } from '@vueuse/core';
import { pushToServer } from '../../composables/events.composable';
import { Socket } from 'socket.io-client';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';
import { userPatientLinkEventBusKey } from '@/events/bus-keys/PE/user-patient-link-event.bus-keys';

const patientMonitorTimeTrackerEvent = useEventBus(patientMonitorTimeTrackerBusKey);
const patientTimerSyncRequestEvent = useEventBus(patientTimerSyncRequestBusKey);
const patientTimerSyncResponseEvent = useEventBus(patientTimerSyncResponseBusKey);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const userPatientLinkEvent = useEventBus(userPatientLinkEventBusKey);

export const usePatientMonitorStore = defineStore("patientMonitor", {
    state: () => ({

    }),
    actions: {
        listenForPatientMonitorTrackerEvents() {
            /* Listen for time tracking and user activity events in the current instance of the app */
            patientMonitorTimeTrackerEvent.on(
                (event) => {
                    pushToServer({
                        eventType: event.activity.type,
                        payload: event
                    });
                }
            );

            patientTimerSyncRequestEvent.on((event)=>{
                pushToServer({
                    eventType: 'TIMER_SYNC_REQUEST',
                    payload: event
                });
            });
        },
        listenForRemotePatientMonitorTrackerEvents(socket:Socket) {
            socket.on('TIMER_SYNC_RESPONSE', (payload)=>{
              patientTimerSyncResponseEvent.emit(payload);
            });
      },
      listenForOrgPatientProgramInsightsEvents(socket: Socket, userId: string) {
        socket.on(`ORG_PATIENT_PROGRAM_INSIGHTS_EVENT_${userId}`, (payload) => {
          patientProgramInsightsEvent.emit(payload);
        });
        },
      listenForUserLinkedToPatientEvents(socket: Socket, userId: string){
        socket.on(`PE_USER_PATIENT_LINK_EVENT_${userId}`, (payload) => {
          userPatientLinkEvent.emit(payload);
        });
      }
    }
});
