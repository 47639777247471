import gql from "graphql-tag";

export const GET_ORG_CYCLES = gql`
  query orgCycles($orgId: UUID!, $orgCycleType: OrgCycleType!) {
    orgCycles(orgId: $orgId, orgCycleType: $orgCycleType) {
      cycleStart
      cycleEnd
      cycleType
      label
    }
  }
`;
