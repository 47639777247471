export const DeviceProvider = {
    QARDIO: "Qardio"
}

export enum OwnerType {
    PATIENT = "PATIENT",
    ORG = "ORG"
}

export enum SourceType {
    RPM = "RPM",
    EMR = "EMR"
}

export enum Onboarding {
    ONSITE = "ONSITE",
    SHIP_TO_PATIENT = "SHIP_TO_PATIENT",
    SHIP_TO_ORG = "SHIP_TO_ORG",
}