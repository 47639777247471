<template>
  <div class="d-flex">
    <v-spacer></v-spacer>
    <v-dialog persistent v-model="dialog" width="650">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" class="ma-5" density="default" rounded="false" color="primary">
          <v-icon class="mx-2" size="large" elevation="3">mdi-timer-plus-outline</v-icon>
          Add TimeLog
        </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-card>
          <AddTimeLog :patient-profile="patientProfile" @add-time-log-cancelled="isActive.value = false"
            :activity-option="activityInput" @create-time-log="createTimeLog">
          </AddTimeLog>
        </v-card>
      </template>
    </v-dialog>
  </div>
  <v-snackbar color="success" v-model="showSuccessMessage" location="top right" class="mt=16">{{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" v-model="showErrorMessage" location="top right" class="mt=16"
    :timeout="errorSnackBarTimeout">{{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <Loader :overlay="loading" />
  <v-dialog v-model="errorDialog" persistent width="500px">
    <v-card min-height="200px">
      <v-card-title class="font-weight-bold bg-surface">Error</v-card-title>
      <v-card-text class="text-h6">{{ errorMessage }}
      </v-card-text>
      <v-card-actions class="px-3 py-5">
        <v-row justify="end">
          <v-col sm="12" md="3" class="text-center">
              <v-btn color="error" class="text-white" size="small" rounded="false"
                @click="errorDialog = false" elevation="3" block  variant="elevated">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">

import AddTimeLog from './AddTimeLog.vue';
import { ref, PropType } from 'vue'
import { IPatientProfileProps } from '../../../../interfaces/patient.interface';
import { IActivityInput, IAddTimeLog, ITimeLog } from '@/interfaces/timelog.interface';
import Loader from '../../../common/Loader.vue';
import { attempt } from 'lodash';
import { useEventBus } from '@vueuse/core';
import { noteTimeLogEventBusKey } from '@/events/bus-keys/notes-timelog-event.bus-keys';

const { patientProfile, activityInput, addTimeLog } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  activityInput: {
    type: Object as PropType<IActivityInput>,
    required: true,
  },
  addTimeLog: {
    type: Function as PropType<(input: IAddTimeLog) => Promise<ITimeLog>>,
    required: true,
  }
})

const emit = defineEmits(["createdTimeLog"]);
const dialog = ref(false);
const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('')
const successMessage = ref('');
const loading = ref(false);
const errorSnackBarTimeout = ref(5000);
const errorDialog = ref(false)
const notesTimeLogEventBus = useEventBus(noteTimeLogEventBusKey);

const createTimeLog = async (timeLogInput: IAddTimeLog) => {
  try {
    loading.value = true;
    dialog.value = false;
    await addTimeLog(timeLogInput);
    loading.value = false;
    successMessage.value = "Time log added successfully"
    showSuccessMessage.value = true;
    notesTimeLogEventBus.emit('note-timelog-list-event-bus')
    emit("createdTimeLog");
  } catch (error) {
    loading.value = false;
    const { message } = error as Error;
    const parsedMessage = attempt(JSON.parse, message);
    if (parsedMessage?.code === 'OVER_LAPPING_TIME_LOG') {
      errorDialog.value = true;
      errorMessage.value = parsedMessage.message;
    } else {
      showErrorMessage.value = true;
      errorMessage.value = message;
    }
  }
}

</script>
