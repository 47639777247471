<script setup lang="ts">
import { ApolloClient } from '@apollo/client';
import { ref, onMounted, computed, inject } from 'vue';
import { Organization } from '../interfaces/IOrganization';
import { CREATE_ORGANIZATION, GET_ORG_BY_ID, EDIT_ORGANIZATION } from '../graphql/resources/organization/Organization';
import { isValidNPI, isValidOrgName, isNotEmpty, isValidZip, isNonNumeric, isAddressValid, isWhitespaceOrHasContent, minimumLengthValidation, minimumLengthValidationCity, minimumLengthValidationOrgName } from '../composables/FormUtility';
import { OrganizationType } from '../enums/OrganizationTypes';
import { useRoute } from 'vue-router';
import router from '../router';
import Loader from './common/Loader.vue';
import { debounce, isEqual, omit } from 'lodash';
import { orgDetailsCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { doesOrgNpiExist } from '@/services/organization.service';
import { TIME_ZONES as timeZones } from '../config/time-zone'

const loading = ref(false);
const apolloClient = inject('apolloClient') as ApolloClient<any>;
const showOrgUserCreatedMessage = ref(false);
const showOrgUserEditMessage = ref(false);
const showOrgUserCreatedErr = ref(false);
const orgForm = ref();
const route = useRoute();
const orgId = ref(route.params['orgId']);
const errorMessage = ref('');
const isNpiLoading = ref(false);
const isNpiExists = ref(false);
const existingNpi = ref('');

const isOrgFormValid = computed(() => {
  const { name, npi, addressLine1 } = organization.value;
  const { city, state, zip, country } = organization.value;

  const isValidNameOrgName = isValidOrgName(name) && isNonNumeric(name) && isNotEmpty(name) && minimumLengthValidationOrgName(name);
  const isValidNpi = isValidNPI(npi);
  const isValidAddressLine1 = isWhitespaceOrHasContent(addressLine1) && isNotEmpty(addressLine1);
  const isValidCity = isAddressValid(city) && isNotEmpty(city) && minimumLengthValidationCity(city);
  const isValidState = isAddressValid(state) && isNotEmpty(state) && minimumLengthValidation(state);
  const isValidZipCode = isValidZip(zip) && isNotEmpty(zip);
  const isValidCountry = isAddressValid(country) && isNotEmpty(country) && minimumLengthValidation(country);

  return (
    isValidNameOrgName &&
    isValidNpi &&
    isValidAddressLine1 &&
    isValidCity &&
    isValidState &&
    isValidZipCode &&
    isValidCountry &&
    !isNpiExists.value
  );
})

const organization = ref({
  name: '',
  npi: '',
  orgType: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  timeZone: ''
} as Organization);

const emit = defineEmits([
  'organization-created'
])
defineProps({
  isStepperForm: {
    type: Boolean,
    default: false
  }
})

const navigateToOrgList = () => {
  router.push({
    path: 'organizations'
  })
}

const handleOrganizationCreateAndEdit = async () => {
  const organizationId = orgId.value as string;
  if (organizationId) {
    await editOrganization(organizationId);
  } else {
    await createOrganization();
  }
};

const createOrganization = async () => {
  const isFormValid: boolean = (await orgForm.value.validate()).valid;
  if (isFormValid) {
    loading.value = true;
    await apolloClient.mutate({
      mutation: CREATE_ORGANIZATION,
      variables: {
        organization: { ...organization.value, tenantId: import.meta.env.VITE_TENANT_ID },
      },
    }).then((data) => {
      const orgId = data.data.createOrganization.orgId;
      loading.value = false;
      showOrgUserCreatedMessage.value = true;
      orgDetailsCreated.value = ShowSnackbar.ORGANIZATION_CREATED;
      emit('organization-created', orgId)
    }).catch(error => {
      loading.value = false;
      const { message } = error;
      errorMessage.value = message;
      showOrgUserCreatedErr.value = true;
    });
  }
}

const getOrgById = async (orgId: string) => {
  if (orgId) {
    loading.value = true;
    await apolloClient.query({
      query: GET_ORG_BY_ID,
      variables: {
        orgId
      },
      fetchPolicy: 'network-only'
    }).then(data => {
      const orgDetails = data.data.getOrganizationById as Organization;
      if (orgDetails) {
        organization.value = { ...orgDetails },
          existingNpi.value = orgDetails.npi;
      }
    }).catch(_error => {
      showOrgUserCreatedErr.value = true;
      errorMessage.value = 'Unable to fetch organization details, please contact support or try again later';
    });
    loading.value = false;
  }
}

const editOrganization = async (organizationId: string) => {
  try {
    const isFormValid = (await orgForm.value.validate()).valid;
    if (!isFormValid) {
      return;
    }
    const organizationData = { ...organization.value };
    const dataWithoutTypename = omit(organizationData, ["__typename", "timeZone"]);
    loading.value = true;
    await apolloClient.mutate({
      mutation: EDIT_ORGANIZATION,
      variables: {
        organization: {
          ...dataWithoutTypename,
          orgId: organizationId,
        },
      },
    });
    loading.value = false;
    showOrgUserEditMessage.value = true;
  } catch (error) {
    loading.value = false;
    errorMessage.value =
      "Unable to edit organization, please contact support or try again later";
    showOrgUserCreatedErr.value = true;
  }
};

const debouncedCheckNpiExists = debounce(async () => {
  await isNPIExists(organization.value.npi);
}, 500);

const isNPIExists = async (npi: string) => {
  if (isValidNPI(npi)) {
    isNpiExists.value = true;
    isNpiLoading.value = true;
    isNpiExists.value = isEqual(existingNpi.value, npi) ? false : await doesOrgNpiExist(npi);
    isNpiLoading.value = false;
    await orgForm.value.validate();
  }
};

onMounted(async () => {
  await getOrgById(orgId.value as string);
})


</script>


<template>
  <v-card flat color="surfContainer" class="h-100 mx-2" elevation="2">
    <v-card-item class="bg-surface pb-0 mb-2">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-domain</v-icon>
          <div class="d-flex flex-wrap">
            <span class="ml-4"> {{ orgId ? 'Organization Profile' : 'Create Organization' }}</span>
            <span class="ml-4 v-card-subtitle flex-1-1-100"> Manage Organization</span>
          </div>
          <v-spacer />
        </div>
        <v-divider thickness="1" class="mt-2" />
      </template>
    </v-card-item>
    <v-card-text>
      <v-card :class="$vuetify.display.smAndUp ? 'org-profile-card-md  rounded-lg' : 'org-profile-card-sm rounded-lg'"
        color="surface">
        <v-form class="mt-2" ref="orgForm" @submit.prevent="handleOrganizationCreateAndEdit()">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                :rules="[value => isNonNumeric(value) || 'Organization name can only consist of alphabets, spaces, and hyphens in between', value => isValidOrgName(value) || 'Enter a valid organization name that cannot exceed 75 characters', value => isNotEmpty(value) || 'Organization name is required', value => minimumLengthValidationOrgName(value) || 'Organization name must have minimum five characters']"
                v-model="organization.name" label="Organization Name*" variant="outlined" density="compact"
                color="primary" hint="Enter organization name" class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.npi"
                :rules="[value => isValidNPI(value) || 'NPI cannot be longer than 10 digits', !isNpiExists || 'NPI already exists']"
                label="NPI*" variant="outlined" density="compact" color="primary" hint="Enter NPI" class="text-left"
                :loading="isNpiLoading" @input="debouncedCheckNpiExists"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-select v-model="organization.orgType"
                :rules="[value => isNotEmpty(value) || 'Organization type is required']" label="Organization Type*"
                variant="outlined" density="compact" color="primary" hint="Select organization type" class="text-left"
                :items="OrganizationType"></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.addressLine1"
                :rules="[value => isNotEmpty(value) || 'Address line 1 is required', (value) => isWhitespaceOrHasContent(value) || 'Enter valid address']"
                label="Address Line 1*" variant="outlined" density="compact" color="primary" hint="Enter address line1"
                class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.addressLine2" label="Address Line 2" variant="outlined"
                :rules="[(value) => isWhitespaceOrHasContent(value) || 'Enter valid address']" density="compact"
                color="primary" hint="Enter address line2" class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.city" label="City*"
                :rules="[value => isNotEmpty(value) || 'City is required', value => isAddressValid(value) || 'City name can only consist of alphabets, spaces, and hyphens in between.', value => minimumLengthValidationCity(value) || 'City must have minimum three characters']"
                variant="outlined" density="compact" color="primary" hint="Enter city" class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.state" label="State*"
                :rules="[value => isNotEmpty(value) || 'State is required', value => isAddressValid(value) || 'State name can only consist of alphabets, spaces, and hyphens in between.', value => minimumLengthValidation(value) || 'State must have minimum two characters']"
                variant="outlined" density="compact" color="primary" hint="Enter state"
                class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.zip" label="Zip*"
                :rules="[value => isValidZip(value) || 'Enter valid 5 digits zip code', value => isNotEmpty(value) || 'Zip is required']"
                variant="outlined" density="compact" color="primary" hint="Enter zip" class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field v-model="organization.country" label="Country*"
                :rules="[value => isNotEmpty(value) || 'Country is required', value => isAddressValid(value) || 'Country name can only consist of alphabets, spaces, and hyphens in between.', value => minimumLengthValidation(value) || 'Country must have minimum two characters']"
                variant="outlined" density="compact" color="primary" hint="Enter country"
                class="text-left"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-select v-model="organization.timeZone" :disabled="!!orgId" :items="timeZones" label="Time Zone*"
                :rules="[(v: any) => !!v || 'Time Zone is required']" required density="compact" variant="outlined"
                hint="Time Zone is required" class="text-left "></v-select>
            </v-col>
          </v-row>

          <div :class="isStepperForm ? 'btn-container-wrapper d-flex justify-end' : 'btn-container-wrapper'">
            <v-row>
              <v-spacer> </v-spacer>
              <v-col cols="12" md="3">
                <v-btn v-if="isStepperForm" block class="mt-5" variant="tonal" elevation="3" rounded="false"
                  @click="navigateToOrgList()">
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn v-if="isStepperForm" block class="mt-5 text-white" color="primary" variant="flat" elevation="3"
                  rounded="false" type="submit" :disabled="!isOrgFormValid">
                  Create
                </v-btn>
                <v-btn v-else block class="mt-5 text-white" color="primary" variant="flat" elevation="3" rounded="false"
                  type="submit" :disabled="!isOrgFormValid">
                  {{ orgId ? 'Save Changes' : 'Create Organization' }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <v-snackbar color="green" class="text-white mt-16" v-model="showOrgUserCreatedMessage" location="top right">
          Organization created successfully
          <template v-slot:actions>
            <v-icon class="ml-3" @click="showOrgUserCreatedMessage = false">mdi-close</v-icon>
          </template>
        </v-snackbar>
        <v-snackbar color="green" class="text-white text-center mt-16" v-model="showOrgUserEditMessage"
          location="top right">
          Organization edited successfully
          <template v-slot:actions>
            <v-icon class="ml-3" @click="showOrgUserEditMessage = false">mdi-close</v-icon>
          </template>
        </v-snackbar>
        <v-snackbar color="error" class="text-white mt-16" v-model="showOrgUserCreatedErr" location="top right">
          {{ errorMessage }}
          <template v-slot:actions>
            <v-icon class="ml-3" @click="showOrgUserCreatedErr = false">mdi-close</v-icon>
          </template>
        </v-snackbar>
        <Loader :overlay="loading" />
      </v-card>
    </v-card-text>

  </v-card>
</template>

<style scoped>
.org-profile-card-md {
  max-width: 65%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 5%;
}

.org-profile-card-sm {
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 5%;
}

.btn-container {
  width: 200px;
  margin: 0 auto;
}

.btn-stepper-container {
  width: 200px;
}

.btn-container-wrapper {
  width: 100%;
}
</style>
