<template>
    <v-card flat class="filter-min ma-2 pb-2">
        <v-card-title class="mt-4">
            <h6 class="text-h6">Filter Patients</h6>
        </v-card-title>
        <v-card-subtitle class="px-4 mt-n2">
            <span class="text-subtitle">Use the filters below to narrow your search</span>
        </v-card-subtitle>

        <v-card-item>
            <form @submit.prevent="submit">
                <v-row>
                    <v-col class="mt-3">
                        <v-text-field label="Name" v-model="compliancePatientFilter.patientName" variant="underlined"
                            density="compact" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Days Active" type="number" v-model="compliancePatientFilter.daysActive" variant="underlined"
                            density="compact" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Days Missed" type="number" v-model="compliancePatientFilter.daysMissed" variant="underlined"
                            density="compact" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Days Left" type="number" v-model="compliancePatientFilter.daysLeft" variant="underlined"
                            density="compact" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex justify-end mb-2">
                        <v-btn color="primary" variant="tonal" elevation="3" rounded="false" size="small" class="mx-2"
                          @click="closeFilter"> cancel </v-btn>
                        <v-btn color="primary" variant="elevated" elevation="3" rounded="false" class="mx-2" type="submit"
                          size="small">
                          Apply
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-card-item>
    </v-card>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

const compliancePatientFilter = ref({
   patientName :'',
   daysActive : 0,
   daysMissed : 0,
   daysLeft : 0
});

const emits = defineEmits(["compliancePatient","close"])


const closeFilter = () => {
    emits('close')
}

const submit = () => {
    emits('compliancePatient',compliancePatientFilter.value)
};
</script>
<style scoped>
.filter-min {
    min-width: 280px;
    min-height: 350px;
}
</style>
