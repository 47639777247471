<template>
  <v-card id="card" elevation="0" class="w-100 d-flex justify-center align-center" color="surfContainer">
    <v-card class="w-100" max-width="600" max-height="600" elevation="3">
      <v-card-title class="bg-surface d-flex justify-center">
        <img src="/vitatraq.svg" class="align-center  pa-4" alt="Vitatraq" height="46" contain />
      </v-card-title>
      <v-card-text class="bg-surface d-flex align-center">
        <v-form @submit.prevent="loginUser(email, password)" class="w-100 pa-8">
          <v-text-field id="email" v-model="email" :label="emailLabel" variant="outlined" :color="primaryColor"
            :hint="emailHint" class="mb-5 text-left" :rules="emailRules"></v-text-field>
          <v-text-field id="password" v-model="password" :label="passwordLabel" :required="true" variant="outlined"
            :color="primaryColor" :hint="passwordHint" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'" class="text-left" :rules="passwordRules"
            @click:append-inner="showPassword = !showPassword"></v-text-field>

          <v-btn id="signInButton" block class="text-white " :color="primaryColor" :size="signInButtonSize"
            rounded="false" type="submit">
            {{ signInButtonText }}
          </v-btn>

        </v-form>
      </v-card-text>
      <v-card-actions class="text-primary font-weight-bold pa-4">
        <v-spacer />
        <router-link :to="forgotPasswordLink">
          {{ forgotPasswordText }}
        </router-link>
      </v-card-actions>
    </v-card>
  </v-card>
  <v-snackbar color="warning" class="text-white" v-model="sessionTimeOut" :timeout="timeOut" location="top right">
    Your session has timed out due to inactivity. Please log in again.
    <v-icon class="ml-3" @click="sessionTimeOut = false">mdi-close</v-icon>
  </v-snackbar>
  <v-snackbar color="error" v-model="isLoginFailed" location="top right">{{
    loginErrorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isLoginFailed = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <Loader :overlay="loading" />
</template>
<script lang="ts" setup>
import { onMounted, ref, defineEmits, computed } from "vue";
import { authenticateUser } from "../composables/CognitoService";
import Loader from "../components/common/Loader.vue";
import { isIdleUserLogout } from "../composables/idleCalculation";
import { userSessionEventBusKey } from "@/events/bus-keys/user-events.bus-keys";
import { useEventBus } from "@vueuse/core";
import { getLoginDetails } from "@/services/common.service";
import { isValidEmail } from "../composables/FormUtility";
import { isEmpty } from "lodash";

const userSessionEventBus = useEventBus(userSessionEventBusKey);

const props = defineProps({
  primaryColor: {
    type: String,
    default: "primary",
  },
  cardTitle: {
    type: String,
    default: "Account Sign-In",
  },
  emailLabel: {
    type: String,
    default: "Username",
  },
  emailHint: {
    type: String,
    default: "Your account email",
  },
  passwordLabel: {
    type: String,
    default: "Password",
  },
  passwordHint: {
    type: String,
    default: "Enter your secure password",
  },
  inputtype: {
    type: String,
    default: "password",
  },
  forgotPasswordLink: {
    type: String,
    default: "/forgot-password",
  },
  forgotPasswordText: {
    type: String,
    default: "Forgot your Password?",
  },
  dividerColor: {
    type: String,
    default: "primary",
  },
  signInButtonColor: {
    type: String,
    default: "primary",
  },
  signInButtonSize: {
    type: String,
    default: "large",
  },
  signInButtonText: {
    type: String,
    default: "Sign In",
  },
});

const emit = defineEmits(["userLogIn", "userLogInAttempt"]);

const email = ref("");
const password = ref("");
const showPassword = ref(false);
const sessionTimeOut = ref(false);
const loading = ref(false);
const timeOut = 15 * 60 * 1000;
const isLoginFailed = ref(false);
const loginErrorMessage = ref("");

const loginUser = async (username: string, password: string) => {
  if (username && password && isEmailValid.value) {
    try {
      loading.value = true;
      await authenticateUser(username, password);
      const user = await getUserInfo();
      loading.value = false;
      userSessionEventBus.emit({ user, event: "LOG_IN" });
      emit("userLogIn", user);
    } catch (error) {
      const err = error as Error;
      loading.value = false;
      isLoginFailed.value = true;
      loginErrorMessage.value = err.message;
    }

    emit("userLogInAttempt");
  }
};

const getUserInfo = async () => {
  try {
    const userInfo = await getLoginDetails();
    return userInfo;
  }
  catch (e) {
    loading.value = false;
    isLoginFailed.value = true;
    loginErrorMessage.value = "Something went wrong, Please try again later.";
    throw e;
  }
};

const emailRules = [
  (v: string) => !isEmpty(v) || 'Username is required',
  (v: string) => !/\s/.test(v) || 'Username cannot contain empty spaces',
  (v: string) => isValidEmail(v) || 'Enter a valid username',
];

const passwordRules: ((v: string) => string | true)[] = [
  (v: string) => !!v || "Password is required",
  (v: string) => v.length >= 8 || 'Password must be 8 characters or more',
  (v) => !/\s/.test(v) || 'Password cannot contain empty spaces'
];

const isEmailValid = computed(() => {
  const isUserNameValid = emailRules.every(rule => rule(email.value) === true);
  const isPassWordValid = passwordRules.every(rule => rule(password.value) === true);
  return isUserNameValid && isPassWordValid;
});

onMounted(() => {
  if (isIdleUserLogout.value) {
    sessionTimeOut.value = true;
  }
});
</script>
