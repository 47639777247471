<template>
  <div class="w-100 d-flex justify-center">
    <v-combobox :hide-no-data="!isEmpty(searchResults)" :loading="searchInputLoader" class="search-input mt-0"
      prepend-inner-icon="mdi-magnify" :center-affix="true" :placeholder="searchTextPlaceholder" variant="solo-filled"
      elevation="3" v-model="searchText" @update:model-value="getFilteredResults" density="comfortable"
      bg-color="surface" color="primary" flat :items="searchResults">
      <template v-if="searchResults" v-slot:item="{ item }">
        <v-card variant="flat" color="surface" rounded="false">
          <v-card-item>
            <v-card-title class="text-subtitle-1">{{ item.title }}</v-card-title>
            <v-card-subtitle>{{ item.raw.description }}</v-card-subtitle>
            <template v-slot:append>
              <v-btn density="compact" class="text-none" color="primary" @click="item.raw.actions.click()" text="View"
                variant="tonal"></v-btn>
            </template>
          </v-card-item>
        </v-card>
        <v-divider thickness="1" />
      </template>
      <template v-slot:no-data>
        <v-list-item v-if="searchInputLoader" title="Searching..."></v-list-item>
        <v-list-item v-else-if="searchText" title="No matching results found"></v-list-item>
        <v-list-item v-else title="Please search by Patient Name or by DOB (mm/dd/yyyy)"></v-list-item>
      </template>
    </v-combobox>
  </div>

</template>

<script setup lang="ts">
import { SearchResult } from '@/interfaces/app-entity-search';
import { searchEntity } from '@/services/app-entity-search.service';
import { debounce, isEmpty, trim } from 'lodash';
import { computed, ref } from 'vue';


const selectedEntity = ref('Patient');
const searchResults = ref([] as SearchResult[])
const searchText = ref(null as null | string);
const searchInputLoader = ref(false);

const searchTextPlaceholder = computed(() => {
  return `Search ${selectedEntity.value}`;
});

const getFilteredResults = debounce(async () => {
  const trimmedSearchText = trim(searchText.value || '');
  if (!isEmpty(trimmedSearchText)) {
    searchInputLoader.value = true;
    searchResults.value = await searchEntity[selectedEntity.value]({
      limit: 10,
      offset: 0,
      searchText: trimmedSearchText
    });
    searchInputLoader.value = false;
  }
}, 500)

const resetData = () => {
  searchText.value = '';
  searchResults.value = [];
}

</script>

<style scoped>
.search-input {
  max-width: 520px;
}
</style>
