<script setup lang="ts">

</script>

<template>
  <div class="w-100" id="vitatraq-logo">
    <v-img width="180" aspect-ratio="16/9" cover src="/vitatraq.svg"></v-img>
  </div>
</template>

<style scoped lang="scss">
#vitatraq-logo {
  width: 7.5rem;
}
</style>
