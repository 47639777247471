export enum PatientProgramCyclePeriod {
  CURRENT = "CURRENT",
  ALL_TIME = "ALL_TIME",
}

export enum ProgramCycleType {
  MONTHLY,
  CUSTOM,
}

export enum PatientComplianceColorTheme {
  ACTIVE = "active",
  MISSED = "inactive",
  REMAINING = "gray",
}

export enum BillingDistributionType {
  STANDARDIZED = "standardBillingCPTDistributions",
  OPTIMIZED = "optimizedBillingCPTDistributions",
  CUSTOMIZED = "customizedBillingCPTDistributions",
}

export const BillingDistributionTypes = [
  {
    title: "BASIC",
    value: "standardBillingCPTDistributions",
  },
  {
    title: "OPTIMIZED",
    value: "optimizedBillingCPTDistributions",
  },
  {
    title: "OVERRIDES",
    value: "customizedBillingCPTDistributions",
  },
];
