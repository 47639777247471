export enum AppActivityTrackerState {
    RUNNING="RUNNING",
    PAUSED="PAUSED",
    SUSPENDED="SUSPENDED",
    PAUSE_SUPPRESSED="PAUSE_SUPPRESSED",
}

export enum AppActivityTrackerTriggerType {
    ON_MOUNT="ON_MOUNT",
    ON_UNMOUNT="ON_UNMOUNT",
    ON_VISIBLE_VIEWPORT="ON_VISIBLE_VIEWPORT",
    ON_HIDDEN_VIEWPORT="ON_HIDDEN_VIEWPORT",
    ON_ELEMENT_IN_VIEWPORT="ON_ELEMENT_IN_VIEWPORT",
    ON_ELEMENT_NOT_IN_VIEWPORT ="ON_ELEMENT_NOT_IN_VIEWPORT",
    ON_HEART_BEAT ="ON_HEART_BEAT",
    ON_USER_INACTIVE="ON_USER_INACTIVE",
    ON_USER_ACTIVE="ON_USER_ACTIVE",
    ON_CUSTOM_EVENT="ON_CUSTOM_EVENT",
    ON_TRACKER_EXIT="ON_TRACKER_EXIT",
    ON_SOCKET_RECONNECT="ON_SOCKET_RECONNECT",
    ON_SOCKET_DISCONNECT="ON_SOCKET_DISCONNECT"
}

export enum AppActivityInactiveTimeHandleMode {    
    RECORD_AND_CONTINUE ="RECORD_AND_CONTINUE",
    RECORD_AND_EXIT="RECORD_AND_EXIT",
    SKIP_AND_CONTINUE="SKIP_AND_CONTINUE",
    SKIP_AND_EXIT="SKIP_AND_EXIT"
}

export enum AppActivityTrackerInactivityExitMode {
    EXIT_ON_INACTIVITY_SKIP ="EXIT_ON_INACTIVITY_SKIP",
    EXIT_ON_INACTIVITY_RECORD = "EXIT_ON_INACTIVITY_RECORD",
    EXIT_ON_INTENT ="EXIT_ON_INTENT" 
}

export enum AppActivityTrackerInactivityResumeMode {
    CONTINUE_AFTER_RECORD = "CONTINUE_AFTER_RECORD",
    CONTINUE_AFTER_SKIP = "CONTINUE_AFTER_SKIP"
}

export enum AppActivityEventType {
    TASK_ACTIVITY="TASK_ACTIVITY",
    PAGE_VISIBILITY_CHANGE="PAGE_VISIBILITY_CHANGE",
    HEART_BEAT="HEART_BEAT",
    USER_ACTIVITY_STATE_CHANGE="USER_ACTIVITY_STATE_CHANGE",
    TRACKED_ELEMENT_INTERSECTION_CHANGE="TRACKED_ELEMENT_INTERSECTION_CHANGE",
    TIMER_STATE_CHANGE="TIMER_STATE_CHANGE",
    USER_INACTIVITY_RESUME="USER_INACTIVITY_RESUME",
    USER_INACTIVITY_EXIT="USER_INACTIVITY_EXIT",
    TRACKER_EXIT="TRACKER_EXIT"
}