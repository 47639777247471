import gql from "graphql-tag";

export const GET_SERVICE_PROVIDERS = gql`
  query GetServiceProviders($limit: Int, $offset: Int, $filter: ServiceProviderFilterInput) {
    serviceProviders {
      serviceProviders(limit: $limit, offset: $offset, filter: $filter) {
        serviceProviderId
        isActive
        serviceProviderName
        sourceTypes
        customFieldDefinitions
        shortCode
      }
    }
  }
`;
