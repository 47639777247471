import {
  AssignTaskInput,
  INotes,
  NoteInputType,
} from "@/interfaces/notes.interface";
import { apolloClient } from "../plugins/graphqlclient";
import {
  CREATE_NOTE,
  LIST_PATIENT_NOTES,
  ASSIGN_TASK_TO_NOTE,
} from "../graphql/resources/notes/NoteRepository";

export const createNote = (noteInput: NoteInputType): Promise<INotes> => {
  const createdNote: Promise<INotes> = new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: noteInput,
        },
      })
      .then((result) => {
        const response: INotes = result.data.createPatientNote;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return createdNote;
};

export const getNotes = async (patientId: string): Promise<INotes[]> => {
  try {
    const result = await apolloClient.query({
      query: LIST_PATIENT_NOTES,
      variables: {
        mpi: patientId,
      },
      fetchPolicy:'network-only'
    });

    return result.data.listPatientNotes;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const assignTaskToNote = async ({
  noteId,
  taskInput,
}: {
  noteId: string;
  taskInput: AssignTaskInput;
}) => {
  const assignedTask = new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: ASSIGN_TASK_TO_NOTE,
        variables: {
          noteId: noteId,
          input: taskInput,
        },
      })
      .then((assignedTask) => {
        const response = assignedTask.data.updatePatientNote;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return assignedTask;
};
