import {
  CHECK_NAME_EXISTS,
  EDIT_INTEGRATE_DEVICE,
  ENABLE_INVENTORY_ITEM,
  REMOVE_ITEM_FROM_INVENTORY,
  SHIP_INVENTORY_ITEM,
  PATIENT_WITHOUT_DEVICE,
} from "@/graphql/resources/InventoryRepository";
import {
  ASSIGN_DEVICE,
  GET_INVENTORY_ITEMS,
  INVENTORY_ITEM_CREATION,
  INVENTORY_ITEM_TYPE,
  UNASSIGN_DEVICE,
} from "@/graphql/resources/inventory/InventoryRepository";
import {
  IAssignPatientInput,
  IDeviceInput,
  IShipInventoryItemInput,
  EditDeviceInput,
  IListInventoryFilter,
} from "@/interfaces/InventoryItem.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import { GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID } from "@/graphql/resources/OrganizationServiceProviderRepository";

export const getInventoryItemTypesByServiceProviderId = async (
  serviceProviderId: string,
) => {
  const response = await apolloClient.query({
    query: INVENTORY_ITEM_TYPE,
    variables: {
      serviceProviderId: serviceProviderId,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.orgInventory.inventoryItemTypes;
};

export const createInventoryDevice = async (input: IDeviceInput) => {
  const response = await apolloClient.mutate({
    mutation: INVENTORY_ITEM_CREATION,
    variables: {
      input: input,
    },
  });
  return response;
};

export const getInventoryItems = async (serviceProviderId: string, filter: IListInventoryFilter, signal: unknown) => {
  const response = await apolloClient.query({
    query: GET_INVENTORY_ITEMS,
    variables: {
      serviceProviderId: serviceProviderId,
      filter
    },
    context: {
      fetchOptions: {
        signal,
      },
    },
    fetchPolicy: "network-only",
  });
  return response.data.orgInventory.inventoryItems;
};

export const unAssignDevice = async (itemId: string) => {
  const unAssignedDevice = await apolloClient.mutate({
    mutation: UNASSIGN_DEVICE,
    variables: {
      itemId: itemId,
    },
  });

  return unAssignedDevice.data.orgInventory.unassignInventoryItem;
};

export const assignInventoryItem = async (
  itemId: string,
  input: IAssignPatientInput,
) => {
  const assignedInventoryItem = await apolloClient.mutate({
    mutation: ASSIGN_DEVICE,
    variables: {
      itemId: itemId,
      input: input,
    },
  });
  return assignedInventoryItem;
};

export const removeItemFromInventory = async (itemId: String) => {
  const removedItem = await apolloClient.mutate({
    mutation: REMOVE_ITEM_FROM_INVENTORY,
    variables: {
      itemId: itemId,
    },
  });

  return true;
};

export const enableInventoryItem = async (itemId: String) => {
  await apolloClient.mutate({
    mutation: ENABLE_INVENTORY_ITEM,
    variables: {
      itemId: itemId,
    },
  });

  return true;
};

export const getOrgServiceProvidersByOrgId = async (orgId: String) => {
  const response = await apolloClient.query({
    query: GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID,
    variables: {
      orgId: orgId,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: {
      orgServiceProviders: { listOrgServiceProvidersByOrgId },
    },
  } = response;
  return listOrgServiceProvidersByOrgId;
};

export const createShipInventoryItem = async (
  input: IShipInventoryItemInput,
) => {
  const response = await apolloClient.mutate({
    mutation: SHIP_INVENTORY_ITEM,
    variables: {
      input: input,
    },
  });
  return response;
};

export const checkDeviceNameExists = async (name: string): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: CHECK_NAME_EXISTS,
    variables: {
      name,
    },
    fetchPolicy: "network-only",
  });

  const { checkDeviceNameExists } = data.orgInventory;
  return checkDeviceNameExists;
};

export const editInventoryItem = async (input: EditDeviceInput) => {
  const response = await apolloClient.mutate({
    mutation: EDIT_INTEGRATE_DEVICE,
    variables: {
      input: input,
    },
  });
  return response;
};

export const patientsWithoutDevices = async () => {
  const response = await apolloClient.query({
    query: PATIENT_WITHOUT_DEVICE,
    fetchPolicy: "network-only",
  });
  return response.data.orgInventory.patientsWithoutDeviceAssigned;
};
