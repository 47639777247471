<template>
    <div>
        <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn color="primary" @click="removeOrgFilterValue" variant="outlined" v-bind="props" density="default"
                    class="text-none pr-6 text-left font-weight-bold">
                    <v-icon left class="mr-3 pt-1">mdi-filter-outline</v-icon>
                    Filters
                </v-btn>
            </template>

            <v-card class="pa-5" width="350">
                <v-form
                    @submit.prevent="$emit('filter', pick(organizationFilterForm, ['organizationName', 'organizationType'])), menu = false">
                    <v-text-field v-model="organizationFilterForm.organizationName" label="Name" variant="outlined" dense
                        class="ma-2" color="primary" hint="Enter organization name"></v-text-field>
                    <v-select label="Type" v-model="organizationFilterForm.organizationType" dense class="mt-2 mx-2"
                        color="primary" variant="outlined" hint="Select organization type" :items="orgType"></v-select>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" variant="elevated" class="px-5 text-none"
                            :disabled="organizationFilterForm.organizationName === '' && organizationFilterForm.organizationType === ''">Apply</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-menu>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { pick } from 'lodash';
import { IOrganizationFilter } from '../../interfaces/IOrganization';
import { OrganizationType } from '../../enums/OrganizationTypes';

const menu = ref(false);
const organizationFilterForm = ref({} as IOrganizationFilter);
const orgType = ref(OrganizationType);

const props = defineProps(['removeOrgFilter']);


const removeOrgFilterValue = () => {
    organizationFilterForm.value.organizationName = props.removeOrgFilter?.organizationName;
    organizationFilterForm.value.organizationType = props.removeOrgFilter?.organizationType;
};
</script>