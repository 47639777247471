import gql from "graphql-tag";

export const LIST_COMMENTS = gql`
  query listComments($entityId: String!) {
    listComments(entityId: $entityId) {
      orgId
      commentId
      bucketId
      commentContext {
        type
        id
      }
      author {
        firstName
        lastName
        email
        userId
      }
      access {
        read
        delete
        resolve
      }
      isPinned
      tags
      type
      content {
        title
        contentType
        message
        source
        sourceContext
        currentState {
          state
          actionAt
          actionBy
        }
        stateHistory {
          state
          actionAt
          actionBy
        }
      }
      createdAt
      lastModified
    }
  }
`;
