<template>
  <v-card class="mx-auto w-100 h-100 pa-8  d-flex justify-center align-center" style="min-height:50vh"
    color="transparent" flat>
    <div>
      <v-card-title>
        <h6 class="text-h4 text-center text-slate-500 text-greeting"> Patient Insights
        </h6>
      </v-card-title>
      <v-card-subtitle class="text-center">
        <span class="text-subtitle-1 text-center"> </span>
      </v-card-subtitle>
      <v-card-item>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-card flat color="transparent ">
                <v-card-text class="banner-icon text-center text-slate-600">
                  <v-icon class="huge-icon">mdi-crowd</v-icon>
                </v-card-text>
                <v-card-text class="text-center">
                  <span class="text-h6 text-center text-slate-600">Select a patient from the list on the left, to see
                    their
                    information here</span>
                </v-card-text>

              </v-card>


            </v-col>

          </v-row>
        </v-container>

      </v-card-item>
    </div>

  </v-card>
</template>
<script setup lang="ts">
</script>
<style scoped lang="scss">
.text-greeting {
  text-transform: capitalize !important;
}

.banner-icon {
  font-size: 10rem;
}
</style>
