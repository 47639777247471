<template>
  <v-container>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-card flat class="text-center" color="surfContainerHighest">
            <v-card-title class="px-2 text-left bg-surfContainer pa-2">
              <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary">Time Spent</h6>
            </v-card-title>
            <v-card-item>
              <v-container fluid>
                <v-row class="d-flex justify-around">
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      QHCP Recorded Time <br />
                      <v-chip color="info" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(generatedBilling.timeSpent.qhcp)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      Staff Recorded Time <br />
                      <v-chip color="success" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(
                          generatedBilling.timeSpent.staff,
                        )
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      QHCP Billed Time <br />
                      <v-chip color="info" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(qhcpBilledTime)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      Staff Billed Time <br />
                      <v-chip color="success" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(staffBilledTime)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      QHCP Unbilled Time Allocation <br />
                      <v-chip color="info" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(qhcpUnbilledTimeAllocation)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-high-emphasis text-subtitle-1">
                      Staff Unbilled Time Allocation<br />
                      <v-chip color="success" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(staffUnbilledTimeAllocation)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-subtitle-1 text-high-emphasis">
                      QHCP Unused Time <br />
                      <v-chip color="info" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(qhcpUnusedTime)
                      }}</v-chip>
                    </span>

                  </v-col>
                  <v-col cols="6">
                    <span class="text-subtitle-1 text-high-emphasis">
                      Staff Unused Time<br />
                      <v-chip color="success" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(staffUnusedTime)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="text-subtitle-1 text-high-emphasis">
                      QHCP time spent as Staff<br />
                      <v-chip color="success" class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{
                        convertMillisecondsToReadableFormat(borrowedStaffTime)
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col cols="12" lass="d-flex align-center">

                    <v-card class="information-card text-black pa-8" variant="tonal" color="tertiary">
                      <div
                        class="d-flex align-center justify-center font-weight-bold  text-onSurfaceVar text-body-1 pa-4">
                        <v-icon color="onSurfaceVar" size="x-large" class="mx-2">mdi-information</v-icon>
                        <p class="text-high-emphasis text-left mx-2">You can use the slider below to transfer a
                          percentage of the unused
                          QHCP
                          time, to
                          the staff. You acknowledge that you are transferring QHCP time to staff time, by performing
                          this action.</p>
                      </div>
                      <div class="pa-4 my-2 w-100">
                        <v-slider v-model="unusedTimeConvertRange" step="1" thumb-label="always"
                          append-icon="mdi-undo-variant" @click:append="unusedTimeConvertRange = 0"
                          :min="minTransferableQHCPTimePercentage">
                          <template v-slot:thumb-label="{ modelValue }">
                            {{ `${modelValue}%` }}
                          </template>
                        </v-slider>
                      </div>
                    </v-card>

                  </v-col>

                </v-row>
              </v-container>

            </v-card-item>

          </v-card>
        </v-container>
      </v-col>
      <v-col cols="6">
        <v-container fluid>
          <v-row>
            <v-container fluid>
              <v-skeleton-loader v-if="loading" type="table-tbody" />
              <template v-else>
                <v-row v-if="activeSubscribedPrograms.includes('RPM') && billingEligiblePrograms.includes(Program.RPM)">
                  <v-card flat class="w-100 my-2" color="surfContainerHighest">
                    <v-card-title class="px-2 text-left bg-surfContainer pa-2 mb-2">
                      <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary"> RPM</h6>
                    </v-card-title>
                    <v-card-item>
                      <v-row>
                        <v-col v-for="(cptCode, index) in rpmCodes" :key="index"
                          class="d-flex flex-column align-center">
                          <v-chip
                            :class="`${getCPTCodeBucketColor(cptCode.bucket)} vt-cpt-program-block text-center mb-2`">
                            <span class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{ cptCode.code
                              }}</span>


                            <v-tooltip :text="cptCode.description" location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" icon="mdi-information-box" />
                              </template>
                            </v-tooltip>
                          </v-chip>

                          <v-number-input class="mt-4 mb-0" base-color="transparent" :inset="true"
                            v-model="codeValues[cptCode.code]" control-variant="split" density="compact" :min="0"
                            :max="determineMaxCount(cptCode)" @input="checkValue(cptCode.code, cptCode.maxCount)"
                            color="secondary" />

                          <v-chip color="primary" class="text-center" style="font-size: 12px">
                            <span class="text-body-2 text-
                            -emphasis mx-1 font-weight-black"> ${{ cptCode.unitRate
                              }} / {{
                                formatCPTCodeUnit(cptCode.unitSlabMins) }} </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-row>
                <v-row v-if="activeSubscribedPrograms.includes('PCM') && billingEligiblePrograms.includes(Program.PCM)">
                  <v-card flat class="w-100 my-2" color="surfContainerHighest">
                    <v-card-title class="px-2 text-left bg-surfContainer pa-2 mb-2">
                      <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary"> PCM</h6>
                    </v-card-title>
                    <v-card-item>
                      <v-row>
                        <v-col v-for="(cptCode, index) in pcmCodes" :key="index"
                          class="d-flex flex-column align-center">
                          <v-chip
                            :class="`${getCPTCodeBucketColor(cptCode.bucket)} vt-cpt-program-block text-center mb-2`">
                            <span class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{ cptCode.code
                              }}</span>
                            <v-tooltip :text="cptCode.description" location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" icon="mdi-information-box" />
                              </template>
                            </v-tooltip>
                          </v-chip>
                          <v-number-input class="mt-4 mb-0" base-color="transparent" :inset="true"
                            v-model="codeValues[cptCode.code]" control-variant="split" density="compact" :min="0"
                            :max="determineMaxCount(cptCode)" @input="checkValue(cptCode.code, cptCode.maxCount)"
                            color="secondary" />

                          <v-chip color="primary" class="text-center" style="font-size: 12px">
                            <span class="text-body-2 text-high-emphasis mx-1 font-weight-black"> ${{ cptCode.unitRate
                              }} / {{
                                formatCPTCodeUnit(cptCode.unitSlabMins) }} </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-row>
                <v-row v-if="activeSubscribedPrograms.includes('CCM') && billingEligiblePrograms.includes(Program.CCM)">
                  <v-card flat class="w-100 my-2" color="surfContainerHighest">
                    <v-card-title class="px-2 text-left bg-surfContainer pa-2 mb-2">
                      <h6 class="text-subtitle-1 text-left mb-n2 font-weight-bold text-secondary">CCM</h6>
                    </v-card-title>
                    <v-card-item>
                      <v-row>
                        <v-col v-for="(cptCode, index) in ccmCodes" :key="index"
                          class="d-flex flex-column align-center">
                          <v-chip
                            :class="`${getCPTCodeBucketColor(cptCode.bucket)} vt-cpt-program-block text-center mb-2`">
                            <span class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{ cptCode.code
                              }}</span>
                            <v-tooltip :text="cptCode.description" location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" icon="mdi-information-box" />
                              </template>
                            </v-tooltip>
                          </v-chip>
                          <v-number-input class="mt-4 mb-0" base-color="transparent" :inset="true"
                            v-model="codeValues[cptCode.code]" control-variant="split" density="compact" :min="0"
                            :max="determineMaxCount(cptCode)" @input="checkValue(cptCode.code, cptCode.maxCount)"
                            color="secondary" />

                          <v-chip color="primary" class="text-center" style="font-size: 12px">
                            <span class="text-body-2 text-high-emphasis mx-1 font-weight-black"> ${{ cptCode.unitRate
                              }} / {{
                                formatCPTCodeUnit(cptCode.unitSlabMins) }} </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="activeSubscribedPrograms.includes('CCCM') && billingEligiblePrograms.includes(Program.CCCM)">
                  <v-card flat class="w-100 my-2" color="surfContainerHighest">
                    <v-card-title class="px-2 text-left bg-surfContainer pa-2 mb-2">
                      <h6 class="text-subtitle-1 text-left font-weight-bold text-secondary">CCCM</h6>
                    </v-card-title>
                    <v-card-item>
                      <v-row>
                        <v-col v-for="(cptCode, index) in cccmCodes" :key="index"
                          class="d-flex flex-column align-center">
                          <v-chip
                            :class="`${getCPTCodeBucketColor(cptCode.bucket)} vt-cpt-program-block text-center mb-2`">
                            <span class="text-body-1 text-high-emphasis mx-1 font-weight-black">{{ cptCode.code
                              }}</span>
                            <v-tooltip :text="cptCode.description" location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" icon="mdi-information-box" />
                              </template>
                            </v-tooltip>
                          </v-chip>
                          <v-number-input class="mt-4 mb-0" base-color="transparent" :inset="true"
                            v-model="codeValues[cptCode.code]" control-variant="split" density="compact" :min="0"
                            :max="determineMaxCount(cptCode)" @input="checkValue(cptCode.code, cptCode.maxCount)"
                            color="secondary" />

                          <v-chip color="primary" class="text-center" style="font-size: 12px">
                            <span class="text-body-2 text-high-emphasis mx-1 font-weight-black"> ${{ cptCode.unitRate
                              }} / {{
                                formatCPTCodeUnit(cptCode.unitSlabMins) }} </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-row>
              </template>

            </v-container>
          </v-row>
        </v-container>
      </v-col>

    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, PropType, watch, computed } from "vue";
import {
  getAllProgramConfiguration,
  getPatientBillingConfig,
} from "@/services/billing.service";
import { IPatientProgramConfiguration } from "@/interfaces/econsent.interface";
import {
  IBillingCPTDistribution,
  IProgramBucketsBillingDetailsProps,
  IExtendedCPTCode,
  IPatientBillingCycleCustomizer,
} from "@/interfaces/billing.interface";
import { convertMillisecondsToReadableFormat } from "@/composables/DateUtility";
import { patientActiveSubscribedProgramKey } from "@/events/bus-keys/billing-program-subscription-event.bus-keys";
import { map, set, flatMap, pick } from 'lodash';
import { useEventBus } from "@vueuse/core";
import { Program } from "@/enums/patient-program.enum";

const props = defineProps({
  generatedBilling: {
    type: Object as PropType<IPatientBillingCycleCustomizer>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  propBucketDetails: {
    type: Object as PropType<IProgramBucketsBillingDetailsProps>,
    required: true,
  },
  originalBucketDetails: {
    type: Object as PropType<IProgramBucketsBillingDetailsProps>,
    required: true,
  },
});

const { generatedBilling, originalBucketDetails, propBucketDetails } = props;

const emits = defineEmits(["customizedDistributedCode"]);

const programCodes = ref<Record<string, IExtendedCPTCode[]>>({
  RPM: [],
  PCM: [],
  CCM: [],
  CCCM: [],
});
const activeSubscribedPrograms = ref<string[]>([]);
const patientActiveSubscribedPrograms = useEventBus(
  patientActiveSubscribedProgramKey,
);

const tab = ref("RPM");
const loading = ref(false);
const codeValues = ref<{ [key: string]: number }>({});
const bucketDetails = ref({} as IProgramBucketsBillingDetailsProps);
const unusedTimeConvertRange = ref(0);

const checkValue = (code: string, maxCount: number) => {
  const minValue = 0;
  const currentValue = codeValues.value[code];

  if (currentValue < minValue || currentValue > maxCount) {
    codeValues.value[code] = 0;
  }
};

const rpmCodes = computed(() => programCodes.value["RPM"]);
const pcmCodes = computed(() => programCodes.value["PCM"]);
const ccmCodes = computed(() => programCodes.value["CCM"]);
const cccmCodes = computed(() => programCodes.value["CCCM"]);

const billingEligiblePrograms = computed(() => {
  return props.generatedBilling.programsSelectedForBilling.map((billableProgram) => billableProgram.program);
});

const qhcpBilledTime = computed(() => {
  const rpmQHCP = bucketDetails.value.RPM?.QHCPBillingTime ?? 0;
  const pcmQHCP = bucketDetails.value.PCM?.QHCPBillingTime ?? 0;
  const ccmQHCP = bucketDetails.value.CCM?.QHCPBillingTime ?? 0;
  const cccmQHCP = bucketDetails.value.CCCM?.QHCPBillingTime ?? 0;

  return rpmQHCP + pcmQHCP + ccmQHCP + cccmQHCP;
});

const staffBilledTime = computed(() => {
  const rpmStaff = bucketDetails.value.RPM?.staffBillingTime ?? 0;
  const pcmStaff = bucketDetails.value.PCM?.staffBillingTime ?? 0;
  const ccmStaff = bucketDetails.value.CCM?.staffBillingTime ?? 0;
  const cccmStaff = bucketDetails.value.CCCM?.staffBillingTime ?? 0;

  return rpmStaff + pcmStaff + ccmStaff + cccmStaff;
});

const originalStaffBilledTime = computed(() => {
  const rpmStaff = originalBucketDetails.RPM?.staffBillingTime ?? 0;
  const pcmStaff = originalBucketDetails.PCM?.staffBillingTime ?? 0;
  const ccmStaff = originalBucketDetails.CCM?.staffBillingTime ?? 0;
  const cccmStaff = originalBucketDetails.CCCM?.staffBillingTime ?? 0;

  return rpmStaff + pcmStaff + ccmStaff + cccmStaff;
});

const qhcpUnbilledTimeAllocation = computed(() => {
  const rpm = bucketDetails.value.RPM?.QHCPUnbilledTimeAllocation ?? 0;
  const pcm = bucketDetails.value.PCM?.QHCPUnbilledTimeAllocation ?? 0;
  const ccm = bucketDetails.value.CCM?.QHCPUnbilledTimeAllocation ?? 0;
  const cccm = bucketDetails.value.CCCM?.QHCPUnbilledTimeAllocation ?? 0;

  return rpm + pcm + ccm + cccm;
});

const originalStaffUnbilledTimeAllocation = computed(() => {
  const rpm = originalBucketDetails.RPM?.staffUnbilledTimeAllocation ?? 0;
  const pcm = originalBucketDetails.PCM?.staffUnbilledTimeAllocation ?? 0;
  const ccm = originalBucketDetails.CCM?.staffUnbilledTimeAllocation ?? 0;
  const cccm = originalBucketDetails.CCCM?.staffUnbilledTimeAllocation ?? 0;

  return rpm + pcm + ccm + cccm;
});

const staffUnbilledTimeAllocation = computed(() => {
  const rpm = bucketDetails.value.RPM?.staffUnbilledTimeAllocation ?? 0;
  const pcm = bucketDetails.value.PCM?.staffUnbilledTimeAllocation ?? 0;
  const ccm = bucketDetails.value.CCM?.staffUnbilledTimeAllocation ?? 0;
  const cccm = bucketDetails.value.CCCM?.staffUnbilledTimeAllocation ?? 0;

  return rpm + pcm + ccm + cccm;
});


const staffTimePreShiftedFromQHCP = computed(() => {
  const staffRecordedTime = generatedBilling.timeSpent.staff;
  const originalBilledStaffTime = originalStaffBilledTime.value;
  const origStaffUnbilledAllocation = originalStaffUnbilledTimeAllocation.value;
  const originalTotalStaffTimeUtilized = originalBilledStaffTime + origStaffUnbilledAllocation;
  const preShiftedTime = originalTotalStaffTimeUtilized > staffRecordedTime ? originalTotalStaffTimeUtilized - staffRecordedTime : 0;

  return preShiftedTime;
});

const determineQHCPUnusedTime = () => {
  const { qhcp: qhcpTimeRecorded } = generatedBilling.timeSpent;
  const billedQHCPTime = qhcpBilledTime.value;
  const unusedQHCPTime = qhcpTimeRecorded - billedQHCPTime - qhcpUnbilledTimeAllocation.value - staffTimePreShiftedFromQHCP.value;

  return unusedQHCPTime;
};

const qhcpUnusedTime = computed(() => {
  const unusedQHCPTime = determineQHCPUnusedTime();
  return unusedQHCPTime - qhcpUnusedTimeTransfer.value;
});

const staffUnusedTime = computed(() => {

  const { staff: staffTimeRecorded } = generatedBilling.timeSpent;
  const calculatedUnusedTime = (staffTimeRecorded + staffTimePreShiftedFromQHCP.value - staffUnbilledTimeAllocation.value - staffBilledTime.value) + qhcpUnusedTimeTransfer.value;
  const unusedStaffTime = calculatedUnusedTime > 0 ? calculatedUnusedTime : 0;
  return unusedStaffTime;
});

const qhcpUnusedTimeTransfer = computed(() => {
  return (determineQHCPUnusedTime() * (unusedTimeConvertRange.value / 100));
});

const borrowedStaffTime = computed(() => {
  const staffTimePreShifted = staffTimePreShiftedFromQHCP.value;
  const qhcpUnusedTransferred = qhcpUnusedTimeTransfer.value;
  return staffTimePreShifted + qhcpUnusedTransferred;
});

const recoverableStaffUnusedTime = computed(() => {
  const staffUnused = staffUnusedTime.value;
  const qhcpUnusedTransferred = qhcpUnusedTimeTransfer.value;
  return qhcpUnusedTransferred - staffUnused;
});

const transferableQHCPTimeRemaining = computed(() => {
  return qhcpUnusedTime.value - recoverableStaffUnusedTime.value;
});

const minTransferableQHCPTime = computed(() => {
  return qhcpUnusedTime.value - transferableQHCPTimeRemaining.value;
});

const minTransferableQHCPTimePercentage = computed(() => {
  if (minTransferableQHCPTime.value <= 0) {
    return Math.ceil(((minTransferableQHCPTime.value + Math.abs(minTransferableQHCPTime.value)) / determineQHCPUnusedTime()) * 100);
  }
  return Math.ceil((minTransferableQHCPTime.value / determineQHCPUnusedTime()) * 100);
});

const formatCPTCodeUnit = (unit: number) => {
  return unit > 0 ? `${unit} mins` : "count";
};

const determineMaxCount = (cptCode: IExtendedCPTCode) => {

  const { primaryProcedureCode, code } = cptCode;

  const currCodeValues = codeValues.value;
  if (primaryProcedureCode) {
    const primaryCodeAllocated = currCodeValues[primaryProcedureCode] > 0;
    if (!primaryCodeAllocated) {
      currCodeValues[code] = 0;
      return 0;
    }
  }

  const maxCountForTimedCode = (cptCode: IExtendedCPTCode) => {
    const currentCount = currCodeValues[cptCode.code] || 0;
    const unusedTime = cptCode.bucket === "QHCP" ? qhcpUnusedTime.value : staffUnusedTime.value;
    const countPossible = Math.floor(((unusedTime / 1000) / 60) / cptCode.unitSlabMins);
    const potentialAdditionalCount = Math.min(countPossible, cptCode.maxCount - currentCount);
    const maxCount = potentialAdditionalCount + currentCount;
    return maxCount;
  };
  return cptCode.unitSlabMins > 0 ? maxCountForTimedCode(cptCode) : cptCode.maxCount;
};

watch(
  () => props.propBucketDetails,
  (newValue) => {
    bucketDetails.value = { ...newValue };
  },
  { deep: true, immediate: true },
);

const populateCodeValues = () => {
  const allProgramCodes = [
    ...rpmCodes.value,
    ...pcmCodes.value,
    ...ccmCodes.value,
    ...cccmCodes.value,
  ];
  const bucket = (distribution: IBillingCPTDistribution) => {
    return (
      distribution.cccmBucket ||
      distribution.pcmBucket ||
      distribution.rpmBucket ||
      distribution.ccmBucket
    );
  };
  const billingDistributions = props.generatedBilling.billingDistributions;

  const newCodeValues: { [key: string]: number } = {};

  allProgramCodes.forEach((cptCode) => {
    const matchingDistribution = billingDistributions.find(
      (distribution) => distribution.cptCode.code === cptCode.code,
    );
    newCodeValues[cptCode.code] = matchingDistribution ? bucket(matchingDistribution)?.billingCount ?? 0 : 0;
  });
  codeValues.value = newCodeValues;
};

watch(
  () => codeValues.value,
  (newValue) => {
    const subscribedCodes = flatMap(activeSubscribedPrograms.value
      .map(program => programCodes.value[program]?.map(p => p.code))
      .filter(Boolean));

    const filteredValue = pick(newValue, subscribedCodes)

    emits("customizedDistributedCode", filteredValue);
  },
  { deep: true, immediate: true },
);

const allProgramConfigurations = async () => {
  const response = await getAllProgramConfiguration();
  updateProgramCodes(response);
};

const updateProgramCodes = (programs: IPatientProgramConfiguration[]) => {
  const updatedCodes = programs.reduce(
    (acc, program) => {
      if (program.codes) {
        set(acc, program.shortCode, program.codes);
      }
      return acc;
    },
    {} as Record<string, IExtendedCPTCode[]>,
  );

  programCodes.value = updatedCodes;
};

const getCPTCodeBucketColor = (bucket: string) => {
  const cptCodeBucketColorHandler: Record<string, string> = {
    QHCP: "text-info",
    STAFF: "text-success",
    OTHER: "text-brown",
    DEFAULT: "text-blue-grey-darken-2",
  };
  const cptCodeBucketColor =
    cptCodeBucketColorHandler[bucket] || cptCodeBucketColorHandler["DEFAULT"];

  return cptCodeBucketColor;
};

const patientActiveProgramDetails = async () => {
  try {
    loading.value = true;
    const response = await getPatientBillingConfig(props.patientId);
    activeSubscribedPrograms.value = map(response.programsInCycle, (prg) => {
      return prg.program;
    });
    patientActiveSubscribedPrograms.emit(activeSubscribedPrograms.value);
    tab.value = activeSubscribedPrograms.value[0];
    loading.value = false;
  } catch (error) {
    loading.value = false;
    return;
  }
};

onMounted(async () => {
  allProgramConfigurations().then(() => {
    patientActiveProgramDetails().then(() => {
      populateCodeValues();
    });
  });


});
</script>
<style>
.single-digit-input {
  width: 60px;
  height: 30px;
  text-align: center;
}
</style>
