export enum CommentStatus {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    RESOLVED = 'RESOLVED',
  }
  
  export enum CommentContentType {
    TEXT = 'TEXT',
  }
  
  export enum CommentType {
    COMMENT = 'COMMENT',
  }
  
  export enum CommentEntityType {
    TASK = 'TASK'
  }

  export enum CommentContextType {
    TASK_INFO = 'TASK_INFO'
  }
