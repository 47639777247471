import gql from "graphql-tag";

export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CommentInput!){
    createComment(input: $input){
            orgId
            commentId
            bucketId
            commentContext {
              type
              id
            }
            author {
              firstName
              lastName
              email
              userId
            }
            access {
              read
              delete
              resolve
            }
            isPinned
            tags
            type
            content {
              title
              contentType
              message
              source
              sourceContext
              currentState {
                state
                actionAt
                actionBy
              }
              stateHistory {
                state
                actionAt
                actionBy
              }
            }
            createdAt
            lastModified
          }
     }
`;
