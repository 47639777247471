import { replace, reduce } from "lodash";
import moment from "moment";
import { reactive } from "vue";

const isNotEmpty = (value: string) => {
  if (value) return true;
  return false;
};

const validateMaxLength = (value: string, maxLength: number): boolean => {
  return !value || value.length < maxLength;
};

const validateAlphabetWithSpace = (value: string) => {
  if (/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)) return true;
  return false;
};

const validateFirstLetterAlphabet = (value: string) => {
  if (/^[a-zA-Z].*/.test(value)) return true;
  return false;
};

const isValidName = (value: string) => {
  if (value?.length <= 20 && /^$|^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value))
    return true;
  return false;
};

const isValidOrgName = (value: string): boolean =>
  value?.length <= 75 && /^$|^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/.test(value);


const isValidNPI = (value: string) => {
  if (/^\d{10}$/.test(value)) return true;
  return false;
};

const isNonNumeric = (value: string) => {
  if (/^$|^[^0-9]+$/.test(value)) return true;
  return false;
};

const isValidZip = (value: string) => {
  if (/^(\d{5})?$/.test(value)) return true;
  return false;
};

const isValidEmail = (value: string) => /^[^\s](\s*[\w.+-]+)*@\w+(\.\w+)+[^\s]$/.test(value) && !/\s{1,}/.test(value);


const isValidPhoneNumber = (value: string) => {
  if (/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) return true;
  return false;
};

const isValidDEANumber = (value: string) => {
  if (/^[A-Z]{2}[0-9]{7}$/.test(value)) return true;
  return false;
};
const isAlphaNumeric = (value: string) => {
  if (/^[a-zA-Z0-9]{1,32}$/.test(value)) return true;
  return false;
};

const isNumeric = (value: string) => /^[0-9]+$/.test(value);

const minimumLengthValidation = (value: string) => {
  if (/^.{2,}$/.test(value)) return true;
  return false;
};

const minimumLengthValidationCity = (value: string) => {
  if (/^.{3,}$/.test(value)) return true;
  return false;
};
const minimumLengthValidationOrgName = (value: string) => {
  if (/^.{5,}$/.test(value)) return true;
  return false;
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const initialName = (str: string) => {
  return str.charAt(0).toUpperCase();
};

const isValidPassword = (value: string) => {
  if (
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      value
    )
  )
    return true;
  return false;
};

const isWhitespaceOrHasContent = (value: string) => /^(| *[^ ].*)$/.test(value);

const extractTextFromQuill = (delta: { ops: { insert: any }[] }): string => {
  return reduce(
    delta.ops,
    (text: string, op: { insert: any }) => {
      if (typeof op.insert === "string") {
        text += op.insert;
      }
      return text;
    },
    ""
  ).trim();
};
const extractErrorMessage = (errorMessage: string) => {
  const cleanedErrorMessage = replace(errorMessage, "ApolloError: ", "");
  return cleanedErrorMessage;
};

const formatTimestamp = (timestampString: string): string => {
  const timestamp = parseInt(timestampString, 10);
  const date = moment(timestamp);
  const timeZoneAbbr = moment.tz.guess();
  return date.format(`MM/DD/YY hh:mmA`);
};

const isAddressValid = (value: string) => {
  if (
    /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/.test(value)
  )
    return true;
  return false;
};

const dateValidationMask = reactive({
  mask: "##/##/####",
  lazy: false,
});

const isValidDate = (date: string) => {
  const inputDate = new Date(date);
  const currentDate = new Date();
  return inputDate <= currentDate;
};

const dateValidationRules = [
  (v: string) => !!v || "Date is required",
  (v: string) => isValidDate(v) || "Date is invalid",
];

const formatDate = (date: string) => {
  const convertedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return convertedDate;
};

const formatDatestamp = (timeStamp: string): string => {
  const timestamp = parseInt(timeStamp, 10);
  const date = moment(timestamp);
  return timeStamp === null ? "N/A" : date.format("MM/DD/YY");
};

const getCurrentDate = () => {
  const currentDate = moment();
  return currentDate.format("YYYY-MM-DD");
};

const getPastOneDay = () => moment().subtract(1, "days").format("YYYY-MM-DD");

const getMinDate = () => {
  const currentDate = moment();
  const dateBefore = currentDate.subtract(1, "days");
  return dateBefore.format("YYYY-MM-DD");
};

const getTomorrowDate = () => {
  const currentDate = moment();
  const tomorrowDate = currentDate.add(1, "days");
  return tomorrowDate.format("YYYY-MM-DD");
};

const formatMobilePhoneNumber = (phoneNumber: string) => {
  const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return formattedNumber;
};

const urlPathValidation = (urlPath: string) => {
  const urlRegex =  /^\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*$/;
  return urlRegex.test(urlPath);
};

const validateEmailsFormat = (emails: string[]) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emails.every(email => emailRegex.test(email));
};

const isValidGatewayId = (gatewayId: string) => {
  const gatewayIdRegex = /^[a-zA-Z0-9]{12}$/;
  return gatewayIdRegex.test(gatewayId);
}

const isValidExternalId = (externalId: string) => {
  const externalIdRegex = /^(?!.*\s).{2,20}$/;
  return externalIdRegex.test(externalId);
};
const hasOnlyAlphabets = (value: string) => {
  if (/^[a-zA-Z]+$/.test(value)) return true;
  return false;
};

export {
  isNotEmpty,
  isValidName,
  isValidNPI,
  isValidZip,
  isValidEmail,
  isValidPhoneNumber,
  isValidDEANumber,
  isAlphaNumeric,
  validateMaxLength,
  validateFirstLetterAlphabet,
  validateAlphabetWithSpace,
  isNonNumeric,
  capitalizeFirstLetter,
  initialName,
  extractErrorMessage,
  extractTextFromQuill,
  isValidPassword,
  formatTimestamp,
  isAddressValid,
  dateValidationMask,
  dateValidationRules,
  isValidDate,
  formatDate,
  formatDatestamp,
  getCurrentDate,
  isWhitespaceOrHasContent,
  getTomorrowDate,
  isNumeric,
  minimumLengthValidation,
  minimumLengthValidationCity,
  minimumLengthValidationOrgName,
  getMinDate,
  getPastOneDay,
  formatMobilePhoneNumber,
  urlPathValidation,
  validateEmailsFormat,
  isValidOrgName,
  isValidGatewayId,
  isValidExternalId,
  hasOnlyAlphabets,
};
