export enum PATIENT_PRIORITY_GROUPS {
    HIGH_SEV,
    MOD_SEV,
    NORM_SEV,
    LATE_REPORT,
    VERY_LATE_REPORT,
    NOT_REVIEWED,
}

export enum PATIENT_DATA_CATEGORIES {
    VITALS,
    DETAILS,
    COMPLIANCE,
    ACTIONS,
    INTEGRATIONS,
    CRITERIA,
    SUBSCRIPTIONS,
    UPLOADS
}

export enum PATIENT_VITALS {
  BP = "Blood Pressure",
  Pulse = "Pulse",
  SPO2 = "SPO2"
}

export enum PATIENT_DETAILS {
    DEMOGRAPHICS="Demographics",
    PROVIDERS="Providers",
    AUTHORIZED = "Linked Contacts",
    BILLING_CONFIG="Billing Configuration",
}

export enum PATIENT_CONFIGS {
    VITALS = "Vitals Config",
    DETAILS = "Details Config",
    COMPLIANCE = "Adherence Config",
    ACTIONS = "Actions Config",
    INTEGRATIONS = "Integrations Config",
}

export enum PATIENT_ACTIONS {
    TASKS = "Tasks",
    NOTES = "Notes",
    TIME_LOG = "Time Log",
}

export enum PATIENT_COMPLIANCE {
    CURRENT = "Current Cycle",
    ALL_TIME = "All Time",
}

export enum PATIENT_INTEGRATIONS {
    RPM = "RPM",
}

export enum PATIENT_STATUS {
    ACTIVATE = 'Activate',
    DEACTIVATE = 'Deactivate',
    DECEASED = 'Deceased'
}

export enum EXTERNAL_IDENTITIES {
   EMR_ID = 'EMR_ID',
}

export enum EXTERNAL_IDENTITY_LABELS {
   EMR_ID = 'EMR Id'
}

export enum Relationship {
  Child = 'CHILD',
  Other = 'OTHER',
  ParentOrGuardian = 'PARENT_OR_GUARDIAN',
  Self = 'SELF',
  Spouse = 'SPOUSE',
}
