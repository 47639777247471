<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card outlined class="signature-pad-container">
          <v-toolbar density="compact" color="surface" class="pa-2">
            <v-card-title class="text-center text-onSurfaceVar">Patient Consent</v-card-title>
            <v-spacer></v-spacer>
            <v-btn :icon="'mdi-close'" variant="text" class="mx-1" @click="closeTemplateViewer" />
          </v-toolbar>

          <v-card-text class="mx-5">
            <div v-html="templateContent" />
            <div class="initials-text-container flex justify-between align-center">
              <div>
                <v-radio-group v-model="eConsentAck" column>
                  <v-radio color="primary" :value="EConsentAcknowledgement.PHONE_CONSENT"
                    label="Verbal Consent"></v-radio>
                  <v-radio color="primary" :value="EConsentAcknowledgement.IN_PERSON_CONSENT"
                    label="In Person Consent"></v-radio>
                </v-radio-group>
              </div>
              <div class="txt-patient-initials">
                <v-text-field variant="outlined" v-model="patientInitial" density="compact" color="primary"
                  @input="patientInitial = patientInitial.toUpperCase()" :rules="[value => !!value || 'Initials are required', value => value.length <= 4 || 'Cannot exceed 4 characters',
                  value => hasOnlyAlphabets(value) || 'Only alphabets are allowed']
                    " label="Patient initials"></v-text-field>
              </div>
            </div>
            <div class="signature-pad-holder flex justify-end"
              v-if="eConsentAck === EConsentAcknowledgement.PHONE_CONSENT">
              <v-checkbox v-model="acknowledgedPatientConsent" color="primary"
                :label="verbalConsentMessage"></v-checkbox>
            </div>
            <template v-if="eConsentAck === EConsentAcknowledgement.IN_PERSON_CONSENT">
              <div class="signature-pad-holder flex justify-end w-100">
                <VueSignaturePad width="100%" :options="signaturePadOptions" ref="signaturePad"
                  class="signature-pad rounded-sm" />
              </div>
              <div class="text-right mt-2">
                <v-btn size="small" variant="tonal" elevation="3" @click="resetSignature()"
                  rounded="false">clear</v-btn>
              </div>
            </template>
          </v-card-text>
          <v-card-actions class="ma-5">
            <v-spacer></v-spacer>
            <v-btn class="px-10 mx-2" variant="tonal" @click="closeTemplateViewer" elevation="3" rounded="false">
              Cancel </v-btn>
            <v-btn class="px-10 mx-2" :disabled="!enableSubmitButton" elevation="3" @click="submitConsent()"
              rounded="false" variant="flat">
              Submit </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar color="error" class="text-white" v-model="showErrorMessage" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  <Loader :overlay="loading" />
</template>
<script setup lang="ts">
import { IEConsentTemplate, TemplateParams } from '@/interfaces/econsent-template.interface';
import { IPatientProgram } from '@/interfaces/econsent.interface';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { PropType, computed, onMounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import Loader from "../common/Loader.vue";
import moment from 'moment';
import { hasOnlyAlphabets } from '@/composables/FormUtility';
import { isEmpty } from 'lodash';
import { EConsentAcknowledgement } from '@/enums/patient-program.enum';
import { watch } from 'vue';
import { ISignaturePad } from '@/interfaces/signature-pad';

const { dataLoader, econsentTemplateId, templateParams, patientProfile, getConsentUploadUrl, signPatientProgramConsent, selectedProgramId } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  templateParams: {
    type: Object as PropType<TemplateParams>,
    required: true
  },
  econsentTemplateId: {
    type: String,
    required: true,
  },
  dataLoader: {
    type: Function as PropType<(econsentTemplateId: string, templateParamsInput: TemplateParams) => Promise<IEConsentTemplate>>,
    required: true,
  },
  getConsentUploadUrl: {
    type: Function as PropType<(
      patientId: string,
      fileName: string) => Promise<string>>,
    required: true,
  },
  signPatientProgramConsent: {
    type: Function as PropType<(
      patientId: string,
      eConsentTemplateId: string, eConsentFileName: string, selectedProgramId: string,
      acknowledgment: EConsentAcknowledgement,) => Promise<IPatientProgram[]>>,
    required: true,
  },
  selectedProgramId: {
    type: String,
    required: true,
  }
});

const showErrorMessage = ref(false);
const errorMessage = ref('');

const emit = defineEmits(["closeTemplateViewer", "reloadPatientProgramConsents"]);
const templateContent = ref('');
const signaturePad = ref(null as null | ISignaturePad);
const signaturePadOptions = {
  penColor: 'black',
  dotSize: (0.5 + 2.5) / 2,
  minWidth: 0.2,
  maxWidth: 1,
  onEnd: () => {
    userSigned.value = true;
  }
}
const fileName = ref('');
const loading = ref(false);
const userSigned = ref(false);
const patientInitial = ref(`${patientProfile.patientInfo?.firstName.charAt(0)}${patientProfile.patientInfo?.lastName.charAt(0)}`);
const eConsentAck = ref(EConsentAcknowledgement.IN_PERSON_CONSENT);
const acknowledgedPatientConsent = ref(false);
const verbalConsentMessage = `The patient has provided verbal consent for the care program discussed. The risks, benefits and alternatives have been explained and the patient has had the opportunity to ask questions and express any concerns. This verbal consent is considered valid and will be documented in the patient's relevant records.`;

const enableSubmitButton = computed(() => {
  const isPatientInitialValid = patientInitial.value.length <= 4 && !isEmpty(patientInitial.value) && hasOnlyAlphabets(patientInitial.value);
  return isPatientInitialValid && ((eConsentAck.value === EConsentAcknowledgement.IN_PERSON_CONSENT && userSigned.value) || (eConsentAck.value === EConsentAcknowledgement.PHONE_CONSENT && acknowledgedPatientConsent.value));
})

watch(eConsentAck, () => {
  if (eConsentAck.value === EConsentAcknowledgement.IN_PERSON_CONSENT) {
    signaturePad.value = null;
    userSigned.value = false;
  } else if (eConsentAck.value === EConsentAcknowledgement.PHONE_CONSENT) {
    acknowledgedPatientConsent.value = false;
  }
})

const loadTemplate = async () => {
  const eConsentTemplate = await dataLoader(econsentTemplateId, templateParams);
  if (eConsentTemplate) {
    templateContent.value = eConsentTemplate.template;
  }
}

const submitConsent = async () => {
  loading.value = true;
  fileName.value = uuidv4() + ".html";

  const fileUploadUrl = await getConsentUploadUrl(patientProfile.patientId, fileName.value)

  const patientInitials = `Patient Initials: ${patientInitial.value}`;
  const parser = new DOMParser();
  const doc = parser.parseFromString(templateContent.value, 'text/html');
  const patientInitialElement = doc.createElement('div');
  patientInitialElement.setAttribute("style", "display:flex;justify-content:end; align-items:end");
  patientInitialElement.innerHTML = patientInitials;

  const divContainer = doc.getElementById("templateContainer")
  divContainer?.appendChild(patientInitialElement);

  if (eConsentAck.value === EConsentAcknowledgement.IN_PERSON_CONSENT) {
    const { data } = signaturePad.value ? signaturePad.value.saveSignature() : { data: '' };

    const imgElement = doc.createElement('img');
    imgElement.src = data;
    imgElement.setAttribute("style", "width:300px")

    const signatureContainer = doc.createElement('div');
    signatureContainer.setAttribute("style", "display:flex;justify-content:end; align-items:end;");
    signatureContainer.appendChild(imgElement);
    divContainer?.appendChild(signatureContainer);

  } else if (eConsentAck.value === EConsentAcknowledgement.PHONE_CONSENT) {
    const ackMessageContainer = doc.createElement('div');
    ackMessageContainer.setAttribute("style", "display:flex;justify-content:start; align-items:start; margin-top:10px; margin-bottom: 10px");

    const checkboxContainer = doc.createElement('div');
    checkboxContainer.setAttribute('style', 'margin-right:5px; margin-top:10px')
    const checkbox = doc.createElement('input');
    checkbox.setAttribute('type', 'checkbox');
    checkbox.setAttribute('id', 'verbalConsentCheckbox');
    checkbox.setAttribute('checked', 'checked');
    checkboxContainer.appendChild(checkbox);

    const label = doc.createElement('label');
    label.innerText = verbalConsentMessage;

    ackMessageContainer.appendChild(checkboxContainer);
    ackMessageContainer.appendChild(label);

    divContainer?.appendChild(ackMessageContainer);
  }

  addDateToTemplate(doc);
  const modifiedHtmlString = new XMLSerializer().serializeToString(doc);
  const blob = new Blob([modifiedHtmlString], { type: 'text/html' });

  await uploadFile(blob, fileUploadUrl);
  await signPatientProgramConsent(patientProfile.patientId, econsentTemplateId, fileName.value, selectedProgramId, eConsentAck.value);

  loading.value = false;
  emit("reloadPatientProgramConsents");
}

const addDateToTemplate = (doc: Document) => {

  const signedAtContainer = document.createElement('div')
  signedAtContainer.setAttribute("style", "display:flex;justify-content:end; align-items:end");

  const sginedAtDate = document.createElement("p");
  sginedAtDate.innerHTML = `Date: ${moment().tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A z')}`

  signedAtContainer.appendChild(sginedAtDate);
  const divContainer = doc.getElementById("templateContainer")

  divContainer?.append(signedAtContainer);
}

const resetSignature = () => {
  signaturePad.value?.clearSignature();
  userSigned.value = false;
}

const uploadFile = async (consentBlob: Blob, signedUrl: string) => {
  const fileUploadHeaders = new Headers();
  fileUploadHeaders.append("Content-Type", "text/html");

  const requestOptions = {
    method: 'PUT',
    headers: fileUploadHeaders,
    body: consentBlob,
    redirect: 'follow',
  } as RequestInit;

  await fetch(signedUrl, requestOptions).catch((error) => {
    errorMessage.value = (error as Error).message;
    showErrorMessage.value = true;
    throw new Error("Error during signed consent submit:", error);
  });
}

const closeTemplateViewer = () => {
  emit('closeTemplateViewer');
}

onMounted(async () => {
  loading.value = true;
  await loadTemplate();
  loading.value = false;
})

</script>
<style scoped lang="scss">
.signature-pad {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid gray;
}

.signature-pad-holder {
  width: 100% !important;
  height: 100% !important;
}

.signature-pad-container {
  margin: auto;
  max-width: 800px;
  padding-bottom: 20px;
}

.initials-text-container {
  margin-top: 10px;
  width: 100%;
}

.txt-patient-initials {
  width: 150px;
}
</style>
