<template>
  <v-card class="mx-auto my-8" max-width="554">
    <v-card-item>
      <v-card-title class="text-primary text-center">
        Start Onboarding
      </v-card-title>
      <v-select label="Service provider" v-model="serviceProviderId" :items="serviceProviders" class="px-3 pt-5"
        variant="outlined" color="primary" @update:model-value="getSelectedServiceProvider"></v-select>
    </v-card-item>
    <v-card-item>

      <v-radio-group color="primary" v-model="addDeviceOpt" class="px-2">
        <v-radio label="Assign device on-site" :value="Onboarding.ONSITE">
        </v-radio>
        <template v-if="shortCode === DeviceShortCode.TENOVI">
          <v-radio label="Ship device to organization" :disabled="true" :value="Onboarding.SHIP_TO_ORG"></v-radio>
          <v-radio label="Direct Device Shipment To Patient" :disabled="true"
            :value="Onboarding.SHIP_TO_PATIENT"></v-radio>
        </template>
      </v-radio-group>
    </v-card-item>
    <v-card-actions class="my-2">
      <v-spacer />
      <v-btn color="primary" v-if="!isPatientProfile" class="font-weight-bold px-10 mx-3"
         variant="tonal" elevation="3" @click="cancelOnboarding" rounded="false">Cancel</v-btn>
      <v-btn color="primary" class="text-white mx-5 px-8" variant="flat" type="submit" rounded="false"
        @click="deviceOnboarding" :disabled="isEmpty(serviceProviderId)">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { IServiceProviderOption, IServiceProvidersInput } from '@/interfaces/InventoryItem.interface';
import { ref, defineProps, PropType, onMounted } from 'vue';
import router from "@/router";
import { isEmpty } from 'lodash';
import { IPatientInfoExtended } from '@/interfaces/patient.interface';
import { useUserStore } from '../../../../store/modules/User';
import { DeviceShortCode } from '@/enums/serviceprovider.enum';
import { Onboarding } from '@/enums/deviceTypes';
import { IOrgServiceProvider } from '@/interfaces/IOrganization';
import { patientCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';

const addDeviceOpt = ref(Onboarding.ONSITE);
const serviceProviders = ref([] as IServiceProviderOption[]);
const serviceProviderId = ref('');
const shortCode = ref('');

const emit = defineEmits(['deviceShipment']);

const props = defineProps({
  listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },
  patientDetail: {
    type: Object as PropType<IPatientInfoExtended>,
    required: true,
  },
  isPatientProfile: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const deviceOnboarding = () => {
  if (serviceProviderId.value && addDeviceOpt.value === Onboarding.ONSITE) {
    router.push({
      name: "inventory",
      params: {
        orgId: useUserStore().$state.user.organizationId,
      },
      query: {
        serviceProviderId: serviceProviderId.value
      }
    });
  } else {
    const deviceShipment = {
      serviceProviderId: serviceProviderId.value,
      clientWillFullFill: addDeviceOpt.value === Onboarding.SHIP_TO_ORG,
    };
    emit('deviceShipment', deviceShipment);
  }
}

const getServiceProviders = async () => {
  const orgId = useUserStore().$state.user.organizationId;
  const serviceProviderDetails = await props.listOrgServiceProviders(orgId);
  serviceProviders.value = serviceProviderDetails.map((serviceProviderDetail) => ({
    title: serviceProviderDetail.serviceProviderName,
    value: serviceProviderDetail.serviceProviderId!,
    shortCode: serviceProviderDetail.shortCode,
  }));
};

const getSelectedServiceProvider = (serviceProviderId: string) => {
  const selectedServiceProvider = serviceProviders.value.find(serviceProvider => {
    return serviceProvider.value === serviceProviderId
  })

  shortCode.value = selectedServiceProvider?.shortCode!;
}

const cancelOnboarding = () => {
  router.push({ name: "ListPatient" });
  patientCreated.value = ShowSnackbar.PATIENT_CREATED;
}

onMounted(() => {
  getServiceProviders();
})

</script>
