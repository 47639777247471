<template>
  <div class="text-center">
    <v-chip
      class="ma-2"
      v-if="$props.filteredPatientNameChip"
      closable
      rounded
      @click:close="closePatientChips"
    >
      Patient name: {{ patientName }}
    </v-chip>
    <v-chip
      class="ma-2"
      v-if="$props.filteredProviderNameChip"
      closable
      rounded
      @click:close="closeProviderChips"
    >
      Provider name: {{ providerName }}
    </v-chip>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";

const patientName = ref("");
const providerName = ref("");

const emit = defineEmits(["closePatientChip", "closeProviderChip"]);
const closePatientChips = () => {
  patientName.value = "";
  emit("closePatientChip", patientName.value);
};

const closeProviderChips = () => {
  providerName.value = "";
  emit("closeProviderChip", providerName.value);
};

const props = defineProps({
  filteredPatientNameChip: String,
  filteredProviderNameChip: String,
});

watchEffect(() => {
  patientName.value = props.filteredPatientNameChip ?? "";
  providerName.value = props.filteredProviderNameChip ?? "";
});

onMounted(() => {
  if (props.filteredPatientNameChip) {
    patientName.value = props.filteredPatientNameChip;
  }
  if (props.filteredProviderNameChip) {
    providerName.value = props.filteredProviderNameChip;
  }
});
</script>
<style scoped></style>
