import { apolloClient } from "../plugins/graphqlclient";
import { GET_SERVICE_PROVIDERS } from "../graphql/resources/ServiceProviderRepository";
import {
  LIST_ORG_DEVICES,
  SOURCE_EXTERNAL_IDS,
  ADD_PATIENT_SOURCE,
  LIST_PATIENT_SOURCES,
  LIST_SOURCE_DATA,
} from "../graphql/resources/PatientSourceRepository";
import { LIST_SOURCE_DEFINITIONS } from "../graphql/resources/SourceDefinitionRepository";
import { IAddSoureInput, IListSourceInput } from "@/interfaces/source.interface";
import { GET_PATIENT_DATA_SOURCE } from "@/graphql/resources/PatientRepository";
import { SourceType } from "@/enums/deviceTypes";
import { VITAL } from "@/enums/readings";

export const getServiceProviders = async () => {
  const { data } = await apolloClient.query({
    query: GET_SERVICE_PROVIDERS,
    variables: {
      limit: 0,
      offset: 0,
      filter: {
        sourceTypes: SourceType.RPM,
      },
    },
  });

  const { serviceProviders } = data.serviceProviders;

  return serviceProviders;
};

export const listPatientSources = async (patientId: string, vital?: VITAL) => {
  const { data } = await apolloClient.query({
    query: LIST_PATIENT_SOURCES,
    variables: {
      patientId,
      vital,
    },
    fetchPolicy: 'network-only'
  });

  const { listPatientSources } = data.source;

  return listPatientSources;
};

export const getOrgDevicesFromQardio = async (serviceProviderId: string) => {
  const { data } = await apolloClient.query({
    query: LIST_ORG_DEVICES,
    variables: {
      serviceProviderId,
    },
  });
  const { listOrgDevices } = data.source;

  return listOrgDevices;
};

export const getPatientSourceExternalIds = async (
  sourceDefinitionId: string,
  patientId: string
) => {
  const {
    data: { source },
  } = await apolloClient.query({
    query: SOURCE_EXTERNAL_IDS,
    variables: {
      patientId,
      sourceDefinitionId,
    },
    fetchPolicy: "network-only",
  });

  const { getSourceIdentities } = source;

  return getSourceIdentities;
};

export const listSourceDefinitions = async (serviceProviderId: string) => {
  const {
    data: { sourceDefinition },
  } = await apolloClient.query({
    query: LIST_SOURCE_DEFINITIONS,
    variables: {
      serviceProviderId,
    },
  });

  const { listSourceDefinitionsByServiceProviderId } = sourceDefinition;

  return listSourceDefinitionsByServiceProviderId;
};

export const savePatientSource = async (source: IAddSoureInput) => {
  const addSource = await apolloClient.mutate({
    mutation: ADD_PATIENT_SOURCE,
    variables: {
      source,
    },
  });

  const response = addSource.data;

  return response;
};

export const listPatientSource = async (patientId: string) => {
  const { data } = await apolloClient.query({
    query: GET_PATIENT_DATA_SOURCE,
    variables: {
      patientId,
      filter: {
        sourceType: SourceType.RPM,
      },
    },
    fetchPolicy: "network-only",
  });

  const { listPatientDataSources } = data.source;

  return listPatientDataSources;
}

export const listSources = async (input: IListSourceInput, signal: unknown) => {
  const response = await apolloClient.query({
    query: LIST_SOURCE_DATA,
    variables :{
      input : input,
    },
    fetchPolicy : 'network-only',
    context: {
      fetchOptions: {
        signal,
      },
    },
  })
  return response.data.source.sources;

}
