<template>
  <v-card width="500px" height="250px" class="pa-2">
    <h5 class="text-h5 text-center text-onSurfaceVar font-weight-black w-100 py-2 bg-surfContainerHigh">
      Add Patient Last Appointment</h5>
    <v-form class="px-5 pt-10" @submit.prevent="recordPatientLastAppointmentEvent">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <VueDatePicker class="vt-date-time-picker-btn" v-model="patientLastAppointment.appointmentDate" teleport-center :is-24="false"
            utc time-picker-inline :max-date="new Date()">
            <template #trigger>
              <v-text-field v-model="appointmentDateFormatted.date" label="Last Appointment Date and Time*" :readonly="true"
                variant="outlined" hint="Enter date and time" dense clearable
                @click:clear="patientLastAppointment.appointmentDate = ''" density="compact" color="primary" />
            </template>
          </VueDatePicker>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn variant="tonal" rounded="false" elevation="3" class="mx-2" @click="$emit('close')">
            Cancel
          </v-btn>
          <v-btn :disabled="!appointmentDateFormatted.date" variant="elevated" rounded="false" class="mx-1" type="submit"
            elevation="3">
            Save Appointment
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script setup lang="ts">
import { computed, reactive } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';

import moment from 'moment';
import { useUserStore } from '../../../store/modules/User';
import { PatientEventName } from '../../../enums/patient-event.enum';

const emits = defineEmits(["close", "saveAppointmentEvent"]);

const patientLastAppointment = reactive({
  appointmentDate: "",
  appointmentWith: useUserStore().$state.user.userId,
  eventName: PatientEventName.appointment,
});

const appointmentDateFormatted = computed(() => {
  if (!patientLastAppointment.appointmentDate) {
    return { date: '', daysUntilDue: 0 };
  }
  const currentDate = moment();
  const localTime = moment(patientLastAppointment.appointmentDate);
  const dueDate = localTime.format('MMM/DD/YYYY,  h:mm A');
  const daysUntilDue = localTime.diff(currentDate, 'days');
  return { date: `${dueDate}`, daysUntilDue };
});


const recordPatientLastAppointmentEvent = () => {
  emits('saveAppointmentEvent', patientLastAppointment)
};

</script>
