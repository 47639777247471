<template>
  <organization-user-form :org-user-detail="userDetails" :organizationId="orgId" :is-stepper-form="false"
    @cancel-org-creation="cancelOrgUserCreation()" :is-edit-form=true></organization-user-form>
  <Loader :overlay="loading" />
  <v-snackbar color="error" class="text-white mt-16" v-model="showErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { GET_ORG_USER_BY_ID } from "@/graphql/resources/OrganizationUserRepository";
import { IOrganizationUserDetails } from "@/interfaces/IOrganization";
import { apolloClient } from "@/plugins/graphqlclient";
import router from "@/router";
import { useUserStore } from "@/store/modules/User";
import { onMounted, ref } from "vue";
import OrganizationUserForm from "./OrganizationUserForm.vue";
import Loader from "./common/Loader.vue";

const userDetails = ref<IOrganizationUserDetails>();
const orgId = useUserStore().$state.user.organizationId;
const loading = ref(false);
const errorMessage = ref('');
const showErrorMessage = ref(false);

const props = defineProps({
  orgUserId: {
    type: String,
    required: true,
  },
});

onMounted(async () => {
  await getOrgUserDetail(props.orgUserId as string);
});

const cancelOrgUserCreation = () => {
  router.push({
    name: "org-users",
    params: {
      orgId: orgId,
    },
  });
};

const getOrgUserDetail = async (orgUserId: string) => {
  loading.value = true;
  try {
    const userDetail = await apolloClient.mutate({
      mutation: GET_ORG_USER_BY_ID,
      fetchPolicy: "network-only",
      variables: { orgUserId },
    });
    userDetails.value = userDetail.data.getOrgUserById;
    loading.value = false;
  } catch (error) {
    loading.value = false;
    errorMessage.value = 'Error while getting organization user detail'
    showErrorMessage.value = true;
  }
};
</script>
