import gql from "graphql-tag";
import { CREATE_PATIENT } from "./PatientRepository";

export const GET_VITALS_BP = gql`
  query getVitalsThresholdConfig($vital: Vital!) {
    getVitalsThresholdConfig(vital: $vital) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on BPThresholdConfig {
          systolic {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          diastolic {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          ihr {
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;

export const GET_VITALS_SPO2 = gql`
  query getVitalsThresholdConfig($vital: Vital!) {
    getVitalsThresholdConfig(vital: $vital) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on SPO2ThresholdConfig {
          spO2 {
            thresholds {
              critical
              warningMin
              warningMax
              normal
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;

export const GET_VITALS_PULSE = gql`
  query getVitalsThresholdConfig($vital: Vital!) {
    getVitalsThresholdConfig(vital: $vital) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
       ... on PulseThresholdConfig {
          pulse {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;

export const CREATE_VITAL_BP = gql`
  mutation SaveVitalsThresholdConfig($input: VitalsThresholdConfigInput!) {
    saveVitalsThresholdConfig(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on BPThresholdConfig {
          systolic {
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
            thresholds {
              criticalMax
              criticalMin
              normalMax
              normalMin
              warnMax
              warnMin
            }
          }
          diastolic {
            thresholds {
              criticalMax
              criticalMin
              normalMax
              normalMin
              warnMax
              warnMin
            }
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
              critical {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          ihr {
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
              critical {
                measurement
                measurementUnit
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;
export const CREATE_VITAL_PULSE = gql`
  mutation SaveVitalsThresholdConfig($input: VitalsThresholdConfigInput!) {
    saveVitalsThresholdConfig(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on PulseThresholdConfig {
          pulse {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;

export const CREATE_VITAL_SPO2 = gql`
  mutation SaveVitalsThresholdConfig($input: VitalsThresholdConfigInput!) {
    saveVitalsThresholdConfig(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on SPO2ThresholdConfig {
          spO2 {
            thresholds {
              critical
              warningMin
              warningMax
              normal
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;

export const CREATE_PATIENT_VITAL_BP = gql`
  mutation saveVitalsThresholdConfigPatient(
    $input: PatientVitalsThresholdConfigInput!
  ) {
    saveVitalsThresholdConfigPatient(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on BPThresholdConfig {
          systolic {
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
            thresholds {
              criticalMax
              criticalMin
              normalMax
              normalMin
              warnMax
              warnMin
            }
          }
          diastolic {
            thresholds {
              criticalMax
              criticalMin
              normalMax
              normalMin
              warnMax
              warnMin
            }
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
              critical {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          ihr {
            criteria {
              warning {
                measurementUnit
                measurement
                timeWindow
              }
              critical {
                measurement
                measurementUnit
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;
export const CREATE_PATIENT_VITAL_PULSE = gql`
  mutation saveVitalsThresholdConfigPatient(
    $input: PatientVitalsThresholdConfigInput!
  ) {
    saveVitalsThresholdConfigPatient(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on PulseThresholdConfig {
          pulse {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;

export const CREATE_PATIENT_VITAL_SPO2 = gql`
  mutation saveVitalsThresholdConfigPatient(
    $input: PatientVitalsThresholdConfigInput!
  ) {
    saveVitalsThresholdConfigPatient(input: $input) {
      id
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on SPO2ThresholdConfig {
          spO2 {
            thresholds {
              critical
              warningMin
              warningMax
              normal
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          cutoffDays
          minReportingDays
        }
        veryLate {
          cutoffDays
          minReportingDays
        }
      }
    }
  }
`;

export const GET_PATIENT_VITALS_BP = gql`
  query getVitalsThresholdConfigForPatient(
    $patientId: String!
    $vital: Vital!
  ) {
    getVitalsThresholdConfigForPatient(patientId: $patientId, vital: $vital) {
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on BPThresholdConfig {
          systolic {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          diastolic {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
          ihr {
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;

export const GET_PATIENT_VITALS_PULSE = gql`
  query getVitalsThresholdConfigForPatient(
    $patientId: String!
    $vital: Vital!
  ) {
    getVitalsThresholdConfigForPatient(patientId: $patientId, vital: $vital) {
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on PulseThresholdConfig {
          pulse {
            thresholds {
              criticalMin
              warnMin
              normalMin
              normalMax
              warnMax
              criticalMax
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
        ... on SPO2ThresholdConfig {
          spO2 {
            thresholds {
              critical
              warningMin
              warningMax
              normal
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }
      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;

export const GET_PATIENT_VITALS_SPO2 = gql`
  query getVitalsThresholdConfigForPatient(
    $patientId: String!
    $vital: Vital!
  ) {
    getVitalsThresholdConfigForPatient(patientId: $patientId, vital: $vital) {
      orgId
      patientId
      vital
      isDefault
      isActive
      config {
        ... on SPO2ThresholdConfig {
          spO2 {
            thresholds {
              critical
              warningMin
              warningMax
              normal
            }
            criteria {
              critical {
                measurementUnit
                measurement
                timeWindow
              }
              warning {
                measurementUnit
                measurement
                timeWindow
              }
            }
          }
        }
      }

      reportingFrequency {
        late {
          minReportingDays
          cutoffDays
        }
        veryLate {
          minReportingDays
          cutoffDays
        }
      }
    }
  }
`;
