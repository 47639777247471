import {
  EConsentAcknowledgement,
  EPatientProgramStatus,
  Program,
} from "@/enums/patient-program.enum";
import {
  GET_CONSENT_DOWNLOAD_URL,
  GET_CONSENT_TEMPLATE,
  GET_CONSENT_UPLOAD_URL,
  PATIENT_PROGRAM_CONSENT_LIST,
  SIGN_CONSENT,
  SUBSCRIBE_PROGRAMS,
  SUBSCRIBE_PROGRAM_USING_SHORT_CODE,
  UN_SUBSCRIBE_PROGRAM,
  ALL_PATIENT_CONSENT_LIST,
  RESET_PATIENT_PROGRAM_TEMPLATE,
} from "@/graphql/resources/PatientProgramRepository";
import {
  SubscribeProgramUsingShortCodeInput,
  TemplateParams,
} from "@/interfaces/econsent-template.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import { getLoggedInUserInfo } from "./common.service";
import momenttz from "moment-timezone";
import { IPatientProgramCycleInfo } from "@/interfaces/patient-program-cycle.interface";
import { CycleType } from "@/enums/cycle.enum";

export const getPatientProgramConsents = async (patientId: string) => {
  const getPatientProgramConsentList = await apolloClient.query({
    query: PATIENT_PROGRAM_CONSENT_LIST,
    variables: { patientId },
    fetchPolicy: "network-only",
  });

  return getPatientProgramConsentList.data.patientProgramConsents;
};

export const getAllPatientConsents = async (
  limit: number,
  offset: number,
  filter: {
    patientName: string;
    providerName: string;
    programStatus: EPatientProgramStatus;
    activeSubscribedPrograms: string[];
    pendingProgramConsents: string[];
    activeStatus: boolean[];
    fromDate: string;
    toDate: string;
  },
) => {
  const getAllPatientConsentsList = await apolloClient.query({
    query: ALL_PATIENT_CONSENT_LIST,
    variables: {
      limit,
      offset,
      filter,
    },
    fetchPolicy: "no-cache",
  });

  return getAllPatientConsentsList.data.getAllPatientConsents;
};

export const getProgramEConsentUploadUrl = async (
  patientId: string,
  fileName: string,
) => {
  const eConsentUploadUrl = await apolloClient.query({
    query: GET_CONSENT_UPLOAD_URL,
    variables: { patientId, fileName },
    fetchPolicy: "network-only",
  });

  return eConsentUploadUrl.data.getProgramEConsentUploadUrl;
};

export const getProgramEConsentDownloadUrl = async (eConsentId: string) => {
  const eConsentDownloadUrl = await apolloClient.query({
    query: GET_CONSENT_DOWNLOAD_URL,
    variables: { eConsentId },
    fetchPolicy: "network-only",
  });

  return eConsentDownloadUrl.data.getProgramEConsentDownloadUrl;
};

export const getProgramEConsentTemplate = async (
  econsentTemplateId: string,
  templateParamsInput: TemplateParams,
) => {
  const eConsentTemplate = await apolloClient.query({
    query: GET_CONSENT_TEMPLATE,
    variables: { econsentTemplateId, templateParamsInput },
    fetchPolicy: "network-only",
  });

  return eConsentTemplate.data.getProgramEConsentTemplate;
};

export const signPatientProgramConsent = async (
  patientId: string,
  eConsentTemplateId: string,
  eConsentFileName: string,
  selectedProgramId: string,
  acknowledgment: EConsentAcknowledgement,
) => {
  const signedPatientPrograms = await apolloClient.mutate({
    mutation: SIGN_CONSENT,
    variables: {
      patientId,
      eConsentTemplateId,
      eConsentFileName,
      selectedProgramId,
      acknowledgment,
    },
  });

  return signedPatientPrograms.data.signPatientProgramConsent;
};

export const subscribePrograms = async (
  patientId: string,
  programIds: string[],
) => {
  const subscribedPrograms = await apolloClient.mutate({
    mutation: SUBSCRIBE_PROGRAMS,
    variables: { subscribeProgramInput: { patientId, programIds } },
  });

  return subscribedPrograms.data.subscribePrograms;
};

export const unSubscribeProgram = async (
  patientId: string,
  programId: string,
) => {
  const subscribedPrograms = await apolloClient.mutate({
    mutation: UN_SUBSCRIBE_PROGRAM,
    variables: { unSubscribeProgramInput: { patientId, programId } },
  });

  return subscribedPrograms.data.subscribePrograms;
};

export const resetPatientProgramConsent = async (
  patientId: string,
  eConsentTemplateId: string,
) => {
  const resetConsent = await apolloClient.mutate({
    mutation: RESET_PATIENT_PROGRAM_TEMPLATE,
    variables: {
      resetPatientProgramConsentInput: { patientId, eConsentTemplateId },
    },
  });

  return resetConsent.data.resetPatientProgramConsent;
};

export const subscribeProgramUsingShortCode = async (
  subscribeProgramInput: SubscribeProgramUsingShortCodeInput,
) => {
  const subscribedProgramUsingShortCode = await apolloClient.mutate({
    mutation: SUBSCRIBE_PROGRAM_USING_SHORT_CODE,
    variables: {
      subscribeProgramInput,
    },
  });

  return subscribedProgramUsingShortCode;
};

export const getMonthlyCycle = (): IPatientProgramCycleInfo => {
  const orgTimeZone = getLoggedInUserInfo().organizationTimeZone;
  const monthStartDate = momenttz.tz(orgTimeZone).startOf("month").utc();
  const monthEndDate = momenttz.tz(orgTimeZone).endOf("month").utc();
  const cycleDays = momenttz.tz(orgTimeZone).daysInMonth();

  const patientProgramCycle: IPatientProgramCycleInfo = {
    cycleStart: monthStartDate.toDate().toISOString(),
    cycleEnd: monthEndDate.toDate().toISOString(),
    cycleType: CycleType.MONTHLY,
    cycleDays,
  };

  return patientProgramCycle;
};
