export enum TaskPriority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    CRITICAL = "CRITICAL"
}

export enum AddTaskEvents {
    CLOSED = "add-task-closed",
    SUCCESS = "show-success-message"
}

export enum TaskStatusCategory {
    OPEN = "OPEN",
    INPROGRESS = "INPROGRESS",
    DONE = "DONE"
}

export enum TaskDateContext {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DUE = "DUE",    
}

export enum TaskDetailsEvents {
    CLOSED = "task-details-closed"
}

export enum TaskActivities {
    COMMENTS="comments"
}