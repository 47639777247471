<template>
  <v-card class="org-list-card h-100 mx-2" flat color="surfContainer" elevation="2">
    <v-card-item class="bg-surface pb-0 mb-4">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-account-group</v-icon>
          <div class="d-flex flex-wrap">
            <span class="ml-4">Organization Users</span>
            <span class="ml-4 v-card-subtitle flex-1-1-100"> Add and manage users in your organization</span>
          </div>
          <v-spacer />
          <v-btn variant="elevated" rounded="false" :loading="loading" @click="navigateToOrgUserForm">
            <v-icon size="large">mdi-plus</v-icon>
            <span>{{ createButtonName }}</span>
          </v-btn>
        </div>
        <v-divider thickness="1" class="my-2" />
      </template>
    </v-card-item>
    <v-card-item class="px-4 py-0">
      <v-skeleton-loader v-if="loading" class="mx-auto" type="table" color="surface" />
      <v-table density="default" v-else>
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.key" class="text-left font-weight-bold " scope="row">
              {{ column.label }}
            </th>
            <th class="text-left font-weight-bold" scope="row">
              Actions
            </th>
          </tr>
        </thead>
        <!-- Table Body -->
        <tbody v-if="organizationUsers.length > 0">
          <tr v-for="(organization, index) in organizationUsers" :key="organization.id">
            <td v-for="column in columns" :key="index" class="text-left font-weight-bold table-data-overflow">
              {{ organization[column.key] }}
              <v-tooltip v-if="column.key === 'emailAddress'" activator="parent" location="bottom">
                {{ organization[column.key] }}
              </v-tooltip>
            </td>
            <td class="text-left tableData" v-if="true">
              <div class="pl-3" :style="{ 'pointer-events': currentUserId === organization.userId ? 'none' : 'auto' }">
                <v-switch @click="openConfirmationDialog(organization, index)" class="toggle-btn-style mr-5"
                  v-model="organization.isActive" :value="true" density="compact" color="primary" hide-details
                  :disabled="currentUserId === organization.userId">
                </v-switch>
                <v-tooltip activator="parent" location="bottom">
                  {{ organization.isActive ? 'Deactivate' : 'Activate' }}
                </v-tooltip>
              </div>
              <v-btn :color="primaryColor" class="text-none font-weight-bold mr-2" rounded="false" variant="tonal"
                @click="navigateToEditOrgUserForm(organization)" size="small">
                <v-icon>mdi-pencil</v-icon>
                <v-tooltip activator="parent" location="bottom">
                  Edit
                </v-tooltip>
              </v-btn>
            </td>
            <td v-else class="text-left tableData">
              N/A
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="centered-cell">
            <td :colspan="columns.length">
              <p>
                No data available !
              </p>

            </td>
          </tr>
        </tbody>

      </v-table>
    </v-card-item>
    <v-divider thickness="1" class="my-2" />
    <v-card-actions>
      <v-spacer />
      <!-- Pagination -->
      <div class="paginationContainer">
        <v-pagination v-model="currentPage" rounded="circle" :length="totalPages" :density="'comfortable'"
          color="primary" :total-visible="$vuetify.display.mdAndUp ? totalVisiblePageNo : $vuetify.display.sm ? 6 : 2"
          @input="updateCurrentPage" class="my-4 px-5 custom-pagination"></v-pagination>
      </div>
    </v-card-actions>
    <v-divider thickness="1" class="my-4" />
    <v-snackbar color="green" class="text-white mt-16" v-model="showOrgUserCreatedMessage" location="top right">
      {{ successMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" class="text-white mt-16" v-model="showOrgUserErrorMessage" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-dialog v-model="showConfirmationPopup" persistent width="500">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface pl-5">{{ confirmationTitle }} User</v-card-title>
        <v-card-text class="text-h6">
          Do you want to {{ confirmationMessage }} {{ userName }}?
        </v-card-text>
        <v-card-actions class="px-4 py-5">
          <v-row justify="end">
            <v-col sm="12" md="4" class="text-center">
              <v-btn color="primary" class="text-white px-8" variant="tonal" elevation="3" rounded="false"
                @click="closeConfirmationDialog">
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="4" class="text-center">
              <v-btn type="submit" color="primary" class="text-white " variant="flat" elevation="3" rounded="false"
                block @click="updateUserActiveStatus">
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script lang="ts">
import { ref, computed, inject, onMounted } from 'vue';
import { ApolloClient } from '@apollo/client';
import router from '../router';
import { GET_ALL_ORG_USERS, PATCH_ORG_USER } from '../graphql/resources/OrganizationUserRepository';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { ITableHeader } from '@/interfaces/utility.interface';
import { orgDetailsCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { formatMobilePhoneNumber } from '../composables/FormUtility'
import { IOrganizationUserDetails } from "@/interfaces/IOrganization";
import { useUserStore } from '@/store/modules/User';

export default {
  name: 'OrganizationUserListTable',
  props: {
    columns: {
      type: [Object],
      default: () => [
        { key: 'name', label: 'Name' },
        { key: 'emailAddress', label: 'Email' },
        { key: 'npi', label: 'NPI' },
        { key: 'deaNumber', label: 'DEA Number' },
        { key: 'mobilePhoneNumber', label: 'Phone Number' }
      ] as ITableHeader[],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    primaryColor: {
      type: String,
      default: 'primary',
    },
    createButtonName: {
      type: String,
      default: 'Add User',
    },
  },
  setup(props) {
    const organizationUsers = ref([] as any[]);
    const route = useRoute();
    const orgId = ref(route.params['orgId']);
    const searchText = ref('');
    const currentPage = ref(1);
    const totalCount = ref(0);
    const totalVisiblePageNo = import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO;
    const totalPages = computed(() => Math.ceil(totalCount.value / props.itemsPerPage));
    const loading = ref(false);
    const updateCurrentPage = (page: number) => {
      currentPage.value = page;
    };
    const apolloClient = inject('apolloClient') as ApolloClient<any>;
    const showOrgUserCreatedMessage = ref(false);
    const orgUser = ref({
      organizationId: "",
      isActive: true,
      orgUserId: "",
    } as IOrganizationUserDetails);
    const showConfirmationPopup = ref(false);
    const switchOriginalState = ref(false);
    const orgUserIndexValue = ref(0);
    const userName = ref('');
    const successMessage = ref('');
    const showOrgUserErrorMessage = ref(false);
    const errorMessage = ref('');
    const currentUserId = useUserStore().$state.user.userId;

    onMounted(async () => {
      if (orgDetailsCreated.value === ShowSnackbar.ORG_USER_CREATED) {
        successMessage.value = 'Organization user created successfully';
        showOrgUserCreatedMessage.value = true;
        orgDetailsCreated.value = '';
      }

      if (orgDetailsCreated.value === ShowSnackbar.ORG_USER_EDITED) {
        successMessage.value = 'Organization User details edited successfully';
        showOrgUserCreatedMessage.value = true;
        orgDetailsCreated.value = '';
      }

      loading.value = true;
      await getAllOrgUsers();
      loading.value = false;
    });

    const openConfirmationDialog = async (
      orgUserDetail: IOrganizationUserDetails, index: number
    ) => {
      showConfirmationPopup.value = true;
      switchOriginalState.value = orgUserDetail.isActive
      orgUserIndexValue.value = index;
      orgUser.value.isActive = !orgUserDetail.isActive;
      orgUser.value.orgUserId = orgUserDetail.orgUserId;
      orgUser.value.organizationId = orgUserDetail.organizationId;
      userName.value = `${orgUserDetail.firstName} ${orgUserDetail.lastName}`
    };

    const closeConfirmationDialog = async () => {
      organizationUsers.value[orgUserIndexValue.value].isActive = switchOriginalState.value;
      showConfirmationPopup.value = false;
    }

    const confirmationTitle = computed(() => {
      return orgUser.value.isActive ? "Activate" : "Deactivate";
    });

    const confirmationMessage = computed(() => {
      return orgUser.value.isActive ? "activate" : "deactivate";
    });

    const updateUserActiveStatus = async () => {
      patchOrgUser(orgUser.value);
    };

    const navigateToEditOrgUserForm = (organization: IOrganizationUserDetails) => {
      router.push({ name: "editOrgUser", params: { 'orgUserId': organization.orgUserId } });
    };

    const navigateToOrgUserForm = () => {
      router.push({ name: "createOrgUser", params: { 'orgId': orgId.value } });
    };
    const getAllOrgUsers = async () => {
      await apolloClient.query({
        query: GET_ALL_ORG_USERS,
        fetchPolicy: 'network-only',
        variables: {
          filter: { organizationId: orgId.value, tenantId: import.meta.env.VITE_TENANT_ID },
          limit: props.itemsPerPage,
          offset: props.itemsPerPage * (currentPage.value - 1)
        }
      }).then((data) => {
        if (data.data) {
          const orgUsers: any[] = [];
          totalCount.value = data.data.listOrganizationUsers.count;
          data.data.listOrganizationUsers.results.map((orgUser: any) => {
            orgUsers.push({
              ...orgUser,
              name: `${orgUser.firstName} ${orgUser.lastName}`,
              npi: orgUser.npi ? orgUser.npi : 'N/A',
              deaNumber: orgUser.deaNumber ? orgUser.deaNumber : 'N/A',
              mobilePhoneNumber: formatMobilePhoneNumber(orgUser.mobilePhoneNumber),
              isActive: orgUser.isActive,
            });
          });
          organizationUsers.value = orgUsers;
        }
      }).catch((err: any) => {
        console.log('Error in fetching user info', err);
      });
    }


    const patchOrgUser = async (
      patchOrgUserInput: IOrganizationUserDetails
    ) => {
      showConfirmationPopup.value = false;
      loading.value = true;
      await apolloClient
        .mutate({
          mutation: PATCH_ORG_USER,
          fetchPolicy: "network-only",
          variables: {
            input: patchOrgUserInput,
          },
        })
        .then((data) => {
          successMessage.value = `Organization user ${patchOrgUserInput.isActive ? 'activated' : 'deactivated'} successfully`;
          showOrgUserCreatedMessage.value = true;
          loading.value = false;
        })
        .catch((error) => {
          const err = error as Error;
          errorMessage.value = err.message;
          showOrgUserErrorMessage.value = true;
          loading.value = false;
        });
    };

    watch(currentPage, async () => {
      loading.value = true;
      await getAllOrgUsers();
      loading.value = false;
    });
    return {
      ...props,
      searchText,
      currentPage,
      totalPages,
      organizationUsers,
      updateCurrentPage,
      navigateToOrgUserForm,
      loading,
      totalVisiblePageNo,
      showOrgUserCreatedMessage,
      updateUserActiveStatus,
      openConfirmationDialog,
      showConfirmationPopup,
      closeConfirmationDialog,
      confirmationTitle,
      confirmationMessage,
      userName,
      successMessage,
      showOrgUserErrorMessage,
      errorMessage,
      currentUserId,
      navigateToEditOrgUserForm
    };
  },
};
</script>

<style scoped>
.cardContainer {
  width: 100%;
}

.filter-box {
  display: flex;
  justify-content: flex-end;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
}

.tableData {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-data-overflow {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.org-list-card {
  overflow: auto;
  height: calc(100vh - var(--v-layout-top) - 30px);
}

.toggle-btn-style {
  flex: none;
}

.centered-cell {
  text-align: center;
}
</style>
