import { mockOrgCycleActivityLogs } from "@/components/organization/reports/activity/mock-data/OrgCycleActivity.mock";
import { ICycle, ICycleInput } from "@/interfaces/cycle.interface";
import { CycleType, OrgCycleType } from "../enums/cycle.enum";
import { apolloClient } from "@/plugins/graphqlclient";
import {
  GET_PATIENT_BILLING_REPORT,
  GET_STAFF_ACTIVITY_REPORT,
} from "@/graphql/resources/ReportRepository";
import moment from "moment";
import { getOrgCycles } from "./org-cycle.service";
import {
  IOrgBillingConfiguration,
  IOrgBillingConfigurationDependencies,
} from "@/interfaces/billing.interface";
import {
  GET_BILLING_CONFIGURATION,
  GET_EMULATED_BILLING,
  PERSIST_EMULATED_BILLING,
} from "@/graphql/resources/BillingRepository";

const cycleSelectorProps = {
  dataLoader: (
    orgId: string,
    orgCycleType: OrgCycleType,
  ): Promise<ICycle[]> => {
    return getOrgCycles(orgId, orgCycleType);
  },
};

export const activityReportLoader = async (
  orgId: string,
  selectedCycle: ICycle,
) => {
  if (selectedCycle) {
    const { cycleStart, cycleEnd, cycleType } = selectedCycle;
    const startMoment = moment(cycleStart, "YYYY-MM-DD");
    const endMoment = moment(cycleEnd, "YYYY-MM-DD");
    const cycleDays = endMoment.diff(startMoment, "days") + 1;
    const activityReports = await apolloClient.query({
      query: GET_STAFF_ACTIVITY_REPORT,
      variables: {
        orgId,
        cycle: {
          cycleDays,
          cycleStart,
          cycleType,
        },
      },
      fetchPolicy: "network-only",
    });
    return activityReports.data.orgStaffActivityReport;
  }
};

export const getOrgReportsDependencies = () => {
  return {
    billingReportDependencies: {
      dataLoader: async (
        orgId: string,
        selectedCycle: ICycle,
        billingType: string,
      ) => {
        if (selectedCycle) {
          const { cycleStart, cycleEnd, cycleType } = selectedCycle;
          const startMoment = moment(cycleStart, "YYYY-MM-DD");
          const endMoment = moment(cycleEnd, "YYYY-MM-DD");
          const cycleDays = endMoment.diff(startMoment, "days") + 1;
          const billingReports = await apolloClient.query({
            query: GET_PATIENT_BILLING_REPORT,
            variables: {
              orgId,
              cycle: {
                cycleDays,
                cycleStart,
                cycleType,
              },
              filters: {
                billingType: billingType,
              },
            },
            fetchPolicy: "network-only",
          });
          return billingReports.data.orgPatientBillingReport;
        }
      },
      cycleSelectorProps,
    },
    activityReportDependencies: {
      dataLoader: activityReportLoader,
      cycleSelectorProps,
    },
    billingConfigurationDependencies: {
      emulateOrgBillingWithCustomSettings: async (
        cycle: ICycle,
        billingConfigurationInput: IOrgBillingConfiguration,
      ) => {
        const { cycleEnd, cycleStart, cycleType } = cycle;
        const cycleDays = moment(cycleEnd).diff(moment(cycleStart), "days") + 1;
        const cycleInput: ICycleInput = {
          cycleDays,
          cycleStart,
          cycleType,
        };
        const emulateOrgBillingWithCustomSettings = await apolloClient.query({
          query: GET_EMULATED_BILLING,
          variables: {
            cycle: cycleInput,
            billingCustomizationInput: billingConfigurationInput,
          },
          fetchPolicy: "network-only",
        });
        return emulateOrgBillingWithCustomSettings.data
          .emulateOrgBillingWithCustomSettings;
      },

      persistEmulatedOrgBilling: async (
        cycle: ICycle,
        billingConfigurationInput: IOrgBillingConfiguration,
        isAcknowledged: Boolean,
      ) => {
        const { cycleEnd, cycleStart, cycleType } = cycle;
        const cycleDays = moment(cycleEnd).diff(moment(cycleStart), "days") + 1;
        const cycleInput: ICycleInput = {
          cycleDays,
          cycleStart,
          cycleType,
        };
        const persistedOrgBilling = await apolloClient.mutate({
          mutation: PERSIST_EMULATED_BILLING,
          variables: {
            cycle: cycleInput,
            billingCustomizationInput: billingConfigurationInput,
            isAcknowledged,
          },
          fetchPolicy: "network-only",
        });
        return !!persistedOrgBilling.data.persistEmulatedOrgBilling;
      },

      getCurrentBillingConfiuration: async (cycle: ICycle) => {
        const { cycleEnd, cycleStart, cycleType } = cycle;
        const cycleDays = moment(cycleEnd).diff(moment(cycleStart), "days") + 1;
        const cycleInput: ICycleInput = {
          cycleDays,
          cycleStart,
          cycleType,
        };
        const billingConfiguration = await apolloClient.query({
          query: GET_BILLING_CONFIGURATION,
          variables: {
            cycle: cycleInput,
          },
          fetchPolicy: "network-only",
        });
        return billingConfiguration.data.getCurrentConfiguration;
      },
    } as IOrgBillingConfigurationDependencies,
  };
};
