import {
  GET_DEFAULT_TASK_BOARD,
  GET_TASK_BOARD,
} from "../graphql/resources/tasks/taskRepository";
import {
  ITask,
  ITaskBoard,
  ITaskQueueItem,
  ListPatientTaskInput,
  RequestorTask,
  UpdateTaskInput,
  UpdateTaskStatusInput,
} from "../interfaces/task.interface";
import { apolloClient } from "../plugins/graphqlclient";
import {
  ICreateTaskInput,
  ITaskQueueResponse,
} from "@/interfaces/task.interface";
import { GET_ALL_PATIENTS, SEARCH_PATIENTS } from "../graphql/resources/PatientRepository";
import {
  LIST_TASK_QUEUE,
  CREATE_TASK,
  LIST_PATIENT_TASK,
  UPDATE_TASK_STATUS,
  TASK_STATUS_STATE,
  EDIT_TASK,
} from "../graphql/resources/TaskRepository";
import { GET_ALL_ORG_USERS } from "../graphql/resources/OrganizationUserRepository";
import { IPatientResponse } from "@/interfaces/patient.interface";
import { IOrgUser } from "@/interfaces/user.interface";
import { map } from "lodash";
import { TaskStatusState } from "@/interfaces/notes.interface";
import { IOrganizationUserFilter } from "@/interfaces/IOrganization";

export const listTaskQueueItems = async (): Promise<ITaskQueueItem[]> => {
  const listQueues = await apolloClient.query({
    query: LIST_TASK_QUEUE,
    variables: {},
    fetchPolicy: "network-only",
  });

  const {
    data: { listTaskQueue },
  } = listQueues;
  const taskQueueLabels = listTaskQueue.map((taskQueue: ITaskQueueResponse) => {
    const { title, color, taskQueueDefinitionId: id } = taskQueue;
    return {
      label: title,
      value: {
        title,
        color,
        id,
      },
    };
  });
  return taskQueueLabels;
};

export const createTask = async (input: ICreateTaskInput) => {
  const createTask = await apolloClient.mutate({
    mutation: CREATE_TASK,
    variables: {
      input,
    },
  });

  const response = createTask.data;
  return response;
};

export const listAssignees = async (search: IOrganizationUserFilter) => {
  const listAssignees = await apolloClient.query({
    query: GET_ALL_ORG_USERS,
    variables: {
      filter: search,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: {
      listOrganizationUsers: { results },
    },
  } = listAssignees;

  const orgUserList = results.map((orgUser: IOrgUser) => {
    const { firstName, lastName, organizationId: orgId, userId: id } = orgUser;
    const name = `${firstName} ${lastName}`;
    return {
      label: `${firstName} ${lastName}`,
      value: {
        id,
        orgId,
        name,
      },
    };
  });

  return orgUserList;
};

export const searchPatientsList = async (filter: string) => {
  const listPatients = await apolloClient.query({
    query: SEARCH_PATIENTS,
    variables: {
      patientFilter: {
        textSearch: filter,
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: {
      searchPatients: { results },
    },
  } = listPatients;
  const patientList = results.map((patient: IPatientResponse) => {
    const { firstName, lastName, orgId, patientId: id } = patient;
    const name = `${firstName} ${lastName}`;
    return {
      label: `${firstName} ${lastName}`,
      value: {
        id,
        orgId,
        name,
      },
    };
  });
  return patientList;
};

export const getTaskBoard = async ({
  dateContexts,
  fromDate,
  toDate,
}: {
  dateContexts?: string[];
  fromDate?: string;
  toDate?: string;
}): Promise<ITaskBoard> => {
  const response = await apolloClient.query({
    query: GET_TASK_BOARD,
    variables: {
      filter: {
        dateContexts,
        fromDate,
        toDate,
      },
    },
    fetchPolicy: "network-only",
  });

  const taskBoardDetails = response.data.taskBoard;

  const transformedTaskBoardData = {
    ...taskBoardDetails,
    columns: map(taskBoardDetails.columns, (column) => {
      return {
        ...column,
        tasks: map(column.tasks, (task) => {
          const taskData: ITask = {
            ...task,
            id: task.taskId,
          };
          return taskData;
        }),
      };
    }),
  };
  return transformedTaskBoardData;
};

export const getDefaultTaskBoardId = async () => {
  const response = await apolloClient.query({
    query: GET_DEFAULT_TASK_BOARD,
    variables: {},
    fetchPolicy: "network-only",
  });

  const taskBoardId = response.data.getDefaultTaskBoard.taskBoardId;

  return taskBoardId;
};

export const listPatientTask = async (
  listPatientTaskInput: ListPatientTaskInput
): Promise<RequestorTask[]> => {
  const response = await apolloClient.query({
    query: LIST_PATIENT_TASK,
    variables: { ...listPatientTaskInput },
    fetchPolicy: "network-only",
  });

  const { listTasksByRequestorId: tasks } = response.data;

  return tasks;
};

export const updateTaskStatus = async (inputData: UpdateTaskStatusInput) => {
  const updatedTaskStatus = await apolloClient.mutate({
    mutation: UPDATE_TASK_STATUS,
    variables: {
      input: inputData,
    },
  });
  return updatedTaskStatus.data.updateTaskStatus;
};

export const getOrgTaskStatusStates = async (): Promise<TaskStatusState[]> => {
  const taskStatusStates = await apolloClient.query({
    query: TASK_STATUS_STATE,
    fetchPolicy: "network-only",
  });

  return taskStatusStates.data.orgTaskStatusStates;
};

export const editTaskDetails = async (updateInput: UpdateTaskInput) => {
  const updatedTaskDetails = await apolloClient.mutate({
    mutation: EDIT_TASK,
    variables: {
      updateInput :updateInput
    }
  });
  return updatedTaskDetails;
}
