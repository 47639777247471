<template>
  <div class="w-100 p-20 flex justify-center">
    <v-row v-if="hasComplianceData">
      <v-col cols="12" class="flex justify-center">
        <VueApexCharts class="compliance-bar-chart" type="bar" :options="chartOptions" :series="daySeries">
        </VueApexCharts>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="flex justify-center" cols="12">
        <div>
          No adherence insights found
        </div>
      </v-col>
    </v-row>
  </div>
  <Loader :overlay="loading" />
</template>
<script setup lang="ts">
import { PatientProgramCyclePeriod } from '@/enums/patient-program-cycle.enum';
import { IPatientProgramCyclesResponse } from '@/interfaces/patient-program-cycle.interface';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import moment from 'moment';
import { onMounted, ref } from 'vue';
import { PropType } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import Loader from '../../../common/Loader.vue';
import { isEmpty } from 'lodash';
import { Program } from '@/enums/patient-program.enum';

const { dataLoader, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  dataLoader: {
    type: Function as PropType<(patientId: string, period: PatientProgramCyclePeriod, program: string) => Promise<IPatientProgramCyclesResponse>>,
    required: true,
  },
})

const loading = ref(false)
const chartOptions = ref({});
const monthLabels = ref([] as string[][]);
const daySeries = ref([{
  name: 'Days Active',
  color: "#4caf50",
  data: [] as number[]
}, {
  name: 'Days Missed',
  color: "#B00020",
  data: [] as number[]
}, {
  name: 'Days Remaining',
  color: "#e2e8f0",
  strokeColor: '#C23829',
  data: [] as number[]
}])
const hasComplianceData = ref(false);

const getPatientCompliance = async () => {
  loading.value = true;
  try {
    const response: IPatientProgramCyclesResponse = await dataLoader(
      patientProfile.patientId,
      PatientProgramCyclePeriod.ALL_TIME,
      Program.RPM
    );
    const daysActive: number[] = [];
    const daysMissed: number[] = [];
    const daysRemaining: number[] = [];
    if (!isEmpty(response.results)) {
      response.results.forEach((cycle) => {
        if (cycle) {
          const { cycleStatus: { daysActive: active, daysMissed: missed, daysRemaining: remaining }, cycleStart, cycleDays, cycleIdentifier } = cycle;
          const startDate = moment(cycleStart);
          const endDate = startDate.clone().add((cycleDays - 1), 'days');
          monthLabels.value.push([`Cycle ${cycleIdentifier}`, `${startDate.format('MM/DD/YYYY')}`, `-${endDate.format('MM/DD/YYYY')}`])
          daysActive.push(active);
          daysMissed.push(missed);
          daysRemaining.push(remaining);
        }
      });

      hasComplianceData.value = true;
    }

    setChartOptions();
    daySeries.value[0].data = daysActive;
    daySeries.value[1].data = daysMissed;
    daySeries.value[2].data = daysRemaining;
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};


const setChartOptions = () => {
  chartOptions.value = {
    chart: {
      type: 'bar',
      height: 700,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: monthLabels,
      title: {
        text: "Days"
      },
    },
    yaxis: {
      title: {
        text: "Cycles"
      },
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      style: {
        colors: ['#ffffff', '#ffffff', '#000000']
      }
    }

  }
}

onMounted(async () => {
  await getPatientCompliance();
})

</script>

<style scoped lang="scss">
.compliance-bar-chart {
  width: 100%;
  max-width: 1024px
}
</style>
