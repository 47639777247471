<template>
    <v-card :class="$vuetify.display.mdAndUp ? 'device-add-form mx-auto' : ''">
        <v-card-title class="text-center py-5">
            <span class="text-h6 text-primary font-weight-bold">{{isDeviceEditForm ? "Edit Qardio Device" : "Add Qardio Device"}}</span>
        </v-card-title>
        <v-container>
        <v-form ref="deviceInventoryForm" @submit.prevent="handleAddDevice" class="px-4">
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-select variant="outlined" color="primary" :items="serviceProviderDetails"
                        v-model="deviceFormInput.serviceProviderId" @update:model-value="getInventoryItemTypes(deviceFormInput.serviceProviderId)" :rules="[(v) => !!v || 'Select a service provider']"
                        label="Service Provider*" required></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                    <v-select :items="inventoryItemTypeDetails" variant="outlined" color="primary" :disabled="isDeviceEditForm"
                        v-model="selectedItemType" return-object :rules="[(v) => !!v || 'Select an item type']"
                        label="Device Type*" required></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field :label="`${serviceProviderName} ID*`" variant="outlined" color="primary" :disabled="isDeviceEditForm"
                        v-model="deviceFormInput.externalItemId" :rules="[(v) => !!v || `${serviceProviderName} id is required`]"
                        required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Name*" variant="outlined" :disabled="!isNameFieldDisabled" color="primary" v-model="deviceFormInput.name" :loading="isLoading" @input="loadDeviceName"
                            :rules="[(v) => !!v || 'Name is required',nameValidation]"  required></v-text-field>
                </v-col>
            </v-row>
            <v-card-actions class="pt-3">
                <v-row justify="end">
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn color="primary" class="text-white rounded-xl text-none" @click="cancelAdding"
                         variant="tonal" elevation="3" block >
                            Cancel
                        </v-btn>
                    </v-col>
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn v-if="!isDeviceEditForm" color="primary" class="text-white rounded-xl text-none" variant="flat" type="submit" block>
                            Add
                        </v-btn>
                        <v-btn v-else color="primary" class="text-white rounded-xl text-none" variant="flat" @click="editInventoryDevice" block>
                            Edit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-form>
    </v-container>
  </v-card>

  <v-snackbar color="error" v-model="showErrorMessage" class="mt-16" location="top right">{{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
</template>
<script setup lang="ts">
import { ref, onMounted, PropType, computed, watchEffect, watch } from 'vue';
import {
    IDeviceInput,
    IServiceProviderOption,
    IInventoryItemTypeOption,
    IInventoryItemInput,
    IInventoryItemData,
} from "../../interfaces/InventoryItem.interface";
import { generateRandomNumber } from '@/composables/DateUtility';
import { debounce, find, get, isEmpty, map } from 'lodash';
import { checkDeviceNameExists } from '@/services/inventory.service';

const deviceFormInput = ref({
    serviceProviderId: "",
    name: "",
    externalItemId: "",
});

const { serviceProviders, inventoryItemInput, inventoryItemData } = defineProps({
    serviceProviders: {
        type: Array as () => IServiceProviderOption[],
        required: true,
    },
    inventoryItemInput: {
        type: Object as PropType<IInventoryItemInput>,
        required: true,
    },
    inventoryItemData:{
      type: Object as PropType<IInventoryItemData>,
      required: false,
    }
});
const deviceInventoryForm = ref();
const serviceProviderDetails = ref([] as IServiceProviderOption[]);
const inventoryItemTypeDetails = ref([] as IInventoryItemTypeOption[]);
const selectedItemType = ref(null as IInventoryItemTypeOption | null);
const serviceProviderName = ref("");
const isDeviceEditForm = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('')

const emit = defineEmits(["deviceAdded", "addDeviceCancelled", "editInventoryDevice"]);

const isValidForm = async () => {
    const isFormValid: boolean = (await deviceInventoryForm.value.validate())
        .valid;
    return isFormValid;
};

const isNameFieldDisabled = computed(() => {
  return deviceFormInput.value.serviceProviderId && selectedItemType.value;
});

const handleAddDevice = async () => {
    const checkAllFields = await isValidForm();
    if (!checkAllFields) return;
    const { title, value } = selectedItemType.value as IInventoryItemTypeOption;
    const { externalItemId, name, serviceProviderId } = deviceFormInput.value;
    const deviceData = {
        serviceProviderId,
        deviceInput: {
            itemType: {
                label: title,
                type: value,
            },
            externalItemId,
            name
        }
    };
    emit("deviceAdded", deviceData);
};

const cancelAdding = () => {
    emit("addDeviceCancelled");
};

const editInventoryDevice = async () => {
  const checkAllFields = await isValidForm();
  if(checkAllFields){
      const editDetails = {
          sourceId : inventoryItemData?.id,
          name: deviceFormInput.value.name,
      }
      emit("editInventoryDevice", editDetails)
  }
}

const isLoading = ref(false);
const isNameExist = ref(false);

const isNameExists = async (name: string) => {
    isLoading.value = true;
    isNameExist.value = await checkDeviceNameExists(name);
    isLoading.value = false;
    await isValidForm();
}

watch(() => deviceFormInput.value.name, () => {
  if(!isDeviceEditForm.value){
    loadDeviceName();
  }
});

const loadDeviceName = debounce(async () => {
    await isNameExists(deviceFormInput.value.name);
}, 500);

const nameValidation = computed(() => {
    const isDuplicate =  ((!(deviceFormInput.value.name === inventoryItemData?.name)))?isNameExist.value? 'name is already Exists': true :true;
   return isDuplicate;
})

const getInventoryItemTypes = async (serviceProviderId: string) => {
  const { dataLoader } = inventoryItemInput;
  await dataLoader(serviceProviderId).then((response) => {
    const inventoryItemTypeOptions = map(
      response,
      (inventoryItemTypeDetail) => ({
        title: inventoryItemTypeDetail.label,
        value: inventoryItemTypeDetail.type,
      })
    );
    inventoryItemTypeDetails.value = inventoryItemTypeOptions;
  }).catch((error: Error) => {
    errorMessage.value = error.message;
    showErrorMessage.value = true;
    throw error;
  });
};

watchEffect(() => {
    const {serviceProviderId} = deviceFormInput.value;
    if(serviceProviderId && selectedItemType.value && !isDeviceEditForm.value) {
        const formattedName = `${serviceProviderName.value}_${selectedItemType.value.title}_${generateRandomNumber ()}`
        deviceFormInput.value.name = formattedName;
    }
})

onMounted(() => {
  if (inventoryItemData) {
    const { name, externalItemId, type } = inventoryItemData;
    isDeviceEditForm.value = true;
    deviceFormInput.value = {
      name,
      externalItemId,
      serviceProviderId: !isEmpty(serviceProviders) ? serviceProviders[0].value : '',
    };
    selectedItemType.value = {
      title: type.label,
      value: type.type
    };
  }
  serviceProviderDetails.value = serviceProviders;
  serviceProviderName.value = get(serviceProviderDetails.value, '[0].title', '');
  deviceFormInput.value.serviceProviderId = get(serviceProviderDetails.value, '[0].value', '');
  getInventoryItemTypes(deviceFormInput.value.serviceProviderId)
});
</script>
<style scoped>
.device-add-form {
    width: 800px;
}
</style>
