<template>
    <v-card flat>
        <v-stepper flat v-model="step">
            <v-stepper-header class="elevation-0">
                <v-stepper-item v-if="!isPatientProfile" color="primary" complete title="Add Patient" value="0">
                </v-stepper-item>
                <v-stepper-item color="primary" title="Onboard Device" icon="mdi-devices" value="1"></v-stepper-item>
                <v-stepper-item color="primary" title="Delivery details" icon="mdi mdi-truck-delivery-outline"
                    value="2"></v-stepper-item>
            </v-stepper-header>
            <v-divider thickness="1" />
            <v-stepper-window>
                <v-stepper-window-item>
                    <CreatePatient @patient-created="confirmDeviceOnboarding" class="mb-5"
                        :list-org-service-providers="listOrgServiceProviders" />
                </v-stepper-window-item>
                <v-stepper-window-item>
                    <PatientDeviceShippingSelectionForm @device-shipment="handleDeviceShipment"
                        :list-org-service-providers="listOrgServiceProviders" :patient-detail="patientDetail"
                        @client-will-full-fill="checkClientFullFill" :is-patient-profile="isPatientProfile" />
                </v-stepper-window-item>
                <v-stepper-window-item>
                    <PatientTenoviDeviceShippingForm @cancel-shipment="navigateToPatientOnBoarding"
                        @shipment-created="shipmentCreated" :list-org-service-providers="listOrgServiceProviders"
                        :service-provider-id="serviceProviderId" :inventory-item-input="inventoryItemInput"
                        :is-patient-profile="isPatientProfile"
                        :patient-detail="clientWillFullFill ? orgDetail : patientDetail"
                        :client-will-full-fill="clientWillFullFill!" :ship-inventory-item="shipInventoryItem" />
                </v-stepper-window-item>
            </v-stepper-window>
        </v-stepper>
        <v-dialog v-model="showConfirmationPopup" persistent width="auto">
            <v-card min-height="200px">
                <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Onboard Patient</v-card-title>
                <v-card-text class="text-h6">
                    Do you want to onboard patient to a service provider?
                </v-card-text>
                <v-card-actions class="px-4 py-5">
                    <v-row justify="end">
                        <v-col sm="12" md="2" class="text-center">
                            <v-btn color="primary" class="text-white" density="compact"
                                @click="navigateToPatientList" variant="tonal" elevation="3" rounded="false">
                                No
                            </v-btn>
                        </v-col>
                        <v-col sm="12" md="3" class="text-center">
                            <v-btn color="primary" class="text-white" variant="flat" type="submit"
                                density="compact" elevation="3" rounded="false" @click="navigateToPatientOnBoarding">
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref, watch } from 'vue';
import CreatePatient from './CreatePatient.vue';
import router from "../router";
import PatientDeviceShippingSelectionForm from "./patient/data-components/integrations/PatientDeviceShippingSelectionForm.vue";
import PatientTenoviDeviceShippingForm from "./patient/data-components/integrations/PatientTenoviDeviceShippingForm.vue";
import { IInventoryItemData, IInventoryItemInput, IServiceProvidersInput, IShipInventoryItemInput } from '@/interfaces/InventoryItem.interface';
import { IPatientInfoExtended, IPatientProfileProps } from '@/interfaces/patient.interface';
import { IDeviceDeliveryInfo } from '@/interfaces/source.interface';
import { patientCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { apolloClient } from '@/plugins/graphqlclient';
import { GET_ORG_BY_ID } from '@/graphql/resources/organization/Organization';
import { IOrgServiceProvider, Organization } from '@/interfaces/IOrganization';
import { useUserStore } from "@/store/modules/User";
import { IPatientSubscribeProgram, SubscribeProgramUsingShortCodeInput } from '@/interfaces/econsent-template.interface';

const props = defineProps({
    listOrgServiceProviders: {
        type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
        required: true,
    },
    inventoryItemInput: {
        type: Object as PropType<IInventoryItemInput>,
        required: true,
    },
    shipInventoryItem: {
        type: Function as PropType<(input: IShipInventoryItemInput) => Promise<IInventoryItemData>>,
        required: true,
    },
    isPatientProfile: {
        type: Boolean,
        required: false,
        default: false,
    },
    patientProfile: {
        type: Object as PropType<IPatientInfoExtended>,
        required: false,
    },
});

const { listOrgServiceProviders, inventoryItemInput, patientProfile, isPatientProfile } = props;

const step = ref(0);
const showConfirmationPopup = ref(false);
const serviceProviderId = ref('');
const patientDetail = ref({} as IPatientInfoExtended);
const clientWillFullFill = ref<boolean>();
const orgId = useUserStore().$state.user.organizationId;
const email = useUserStore().$state.user.emailAddress;
const firstName = useUserStore().$state.user.firstName;
const lastName = useUserStore().$state.user.lastName;
const orgDetail = ref({} as IDeviceDeliveryInfo);
const loading = ref(false);
const emit = defineEmits(['shipmentCreated']);
const serviceProviderName = ref('');

const confirmDeviceOnboarding = (patientData: IPatientInfoExtended) => {
    patientDetail.value = patientData
    showConfirmationPopup.value = true;
};

const navigateToPatientOnBoarding = () => {
    showConfirmationPopup.value = false;
    step.value = isPatientProfile ? 0 : 1;
}

const handleDeviceShipment = async (deviceShipment: {
    serviceProviderId: string;
    clientWillFullFill: boolean;
}) => {
    if (deviceShipment.clientWillFullFill) {
        await getOrgById(orgId as string);
    }
    navigateToShippingDetailForm(deviceShipment.serviceProviderId);
    checkClientFullFill(deviceShipment.clientWillFullFill);
};

const navigateToShippingDetailForm = (selectedServiceProviderId: string) => {
    serviceProviderId.value = selectedServiceProviderId;
    showConfirmationPopup.value = false;
    step.value = isPatientProfile ? 1 : 2;
}

const navigateToPatientList = () => {
    patientCreated.value = ShowSnackbar.PATIENT_CREATED;
    showConfirmationPopup.value = false;
    routeTo('ListPatient');
};

const checkClientFullFill = (clientWill: boolean) => {
    clientWillFullFill.value = clientWill;
};

const shipmentCreated = () => {
    emit('shipmentCreated');
}

const routeTo = (routeName: string) => {
    router.push({
        name: routeName,
    });
};


const getOrgById = async (orgId: string) => {
    loading.value = true;
    if (!orgId) {
        return;
    }
    const { data } = await apolloClient.query({
        query: GET_ORG_BY_ID,
        variables: {
            orgId,
        },
        fetchPolicy: "network-only",
    });
    const orgDetails = data?.getOrganizationById as Organization;

    if (orgDetails) {
        const { addressLine1, city, zip, state } = orgDetails;
        const { patientId } = patientDetail.value;

        orgDetail.value = {
            addressLine1,
            city,
            zip,
            state,
            email,
            firstName,
            lastName,
            patientId,
        };
    }
    loading.value = false;
};

watch(() => props.patientProfile, (newValue) => {
    patientDetail.value = newValue as IPatientInfoExtended;
})

onMounted(() => {
    if (patientProfile) {
        patientDetail.value = patientProfile as IPatientInfoExtended;
    }
})
</script>
