<template>
    <v-card class="delivery-details-card  rounded-lg mb-10">
        <v-card-text class="text-h6 text-center font-weight-bold text-primary">Delivery Details</v-card-text>
        <v-form ref="form" class="pa-5" @submit.prevent="submitDeliveryDetails">
            <v-row>
                <v-col cols="6">
                    <v-select label="Device Type*" v-model="selectedItemType" :items="inventoryItemTypeDetails"
                        variant="outlined" color="primary" density="compact"
                        :rules="[(v) => !!v || 'Select an device type']" return-object></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="deliveryDetailForm.shippingName"
                        :rules="[value => isNotEmpty(value) || 'Name is required']" label="Name*" variant="outlined"
                        density="compact" color="primary" hint="Enter name" class="text-left"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="deliveryDetailForm.shippingAddress"
                        :rules="[value => isNotEmpty(value) || 'Address is required', (value) => isWhitespaceOrHasContent(value) || 'Enter valid address']"
                        label="Address*" variant="outlined" density="compact" color="primary" hint="Enter address"
                        class="text-left"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="deliveryDetailForm.shippingZipCode" label="Zip Code*"
                        :rules="[value => isNotEmpty(value) || 'Zip code is required', value => isValidZip(value) || 'Enter valid 5 digits zip code',]"
                        variant="outlined" density="compact" color="primary" hint="Enter zip"
                        class="text-left"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="State*" v-model="deliveryDetailForm.shippingState"
                        :rules="[value => isNotEmpty(value) || 'State is required', value => isAddressValid(value) || 'State name can only consist of alphabets, spaces, and hyphens in between.', value => minimumLengthValidation(value) || 'State must have minimum two characters']"
                        variant="outlined" density="compact" color="primary" hint="Enter state"
                        class="text-left"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="City*" v-model="deliveryDetailForm.shippingCity"
                        :rules="[value => isNotEmpty(value) || 'City is required', value => isAddressValid(value) || 'City name can only consist of alphabets, spaces, and hyphens in between.', value => minimumLengthValidationCity(value) || 'City must have minimum three characters']"
                        variant="outlined" density="compact" color="primary" hint="Enter city"
                        class="text-left"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">

                    <v-combobox v-model="deliveryDetailForm.notifyEmails" :items="notifyEmailLists" label="Notify Emails"
                        density="compact" multiple chips closable-chips
                        :rules="[ v => validateEmailsFormat(v) || 'One or more emails have invalid format']"
                        color="primary" variant="outlined" clearable>
                        type="email"
                        ></v-combobox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox v-model="deliveryDetailForm.requireSignature" color="primary"
                        label="Require Signature"></v-checkbox>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row>
                    <v-spacer> </v-spacer>
                    <v-col cols="12" md="3">
                        <v-btn block color="primary" class="text-none px-10" variant="outlined"
                            @click="cancelOnboarding">Back</v-btn>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn color="primary" block class="text-none" type="submit" variant="flat"
                            :disabled="isSubmitDisabled">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-form>
    <v-snackbar color="error" class="text-white mt-16" v-model="shoErrorMessage" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="shoErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
  <Loader :overlay="loading" />
</template>
<script setup lang="ts">
import { IInventoryItemData, IInventoryItemInput, IInventoryItemTypeOption, IShipInventoryItemInput } from '@/interfaces/InventoryItem.interface';
import { map } from 'lodash';
import { PropType, computed, onMounted, reactive, ref, watch } from 'vue';
import { isValidZip, isAddressValid, isWhitespaceOrHasContent, minimumLengthValidation, minimumLengthValidationCity, isNotEmpty, validateEmailsFormat } from "@/composables/FormUtility";
import { patientCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import router from "@/router";
import Loader from "@/components/common/Loader.vue";
import { IDeviceDeliveryInfo } from '@/interfaces/source.interface';
import { IPatientInfoExtended } from '@/interfaces/patient.interface';
import { useUserStore } from '@/store/modules/User';
import { IOrgServiceProvider } from '@/interfaces/IOrganization';
import { generateRandomNumber } from '@/composables/DateUtility';

const props = defineProps({
    serviceProviderId: {
        type: String,
        required: true,
    },
    inventoryItemInput: {
        type: Object as PropType<IInventoryItemInput>,
        required: true,
    },
    patientDetail: {
        type: Object as PropType<IPatientInfoExtended | IDeviceDeliveryInfo>,
        required: true,
    },
    clientWillFullFill: {
        type: Boolean,
        required: true
    },
    shipInventoryItem: {
        type: Function as PropType<(input: IShipInventoryItemInput) => Promise<IInventoryItemData>>,
        required: true,
    },
    isPatientProfile: {
        type: Boolean,
        required: false,
        default: false,
    },
    listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },
});

const inventoryItemTypeDetails = ref([] as IInventoryItemTypeOption[]);
const form = ref();
const selectedItemType = ref(null as IInventoryItemTypeOption | null);
const selectedServiceProviderId = ref('');
const notifyEmailLists = ref([] as string[]);
const loading = ref(false);
const shoErrorMessage = ref(false);
const errorMessage = ref('');
const serviceProviderName = ref('');

const deliveryDetailForm = reactive({
    shippingName: '',
    shippingAddress: '',
    shippingCity: '',
    shippingState: '',
    shippingZipCode: '',
    requireSignature: true,
    notifyEmails: [] as string[],
    clientWillFulfill: true,
});

const isSubmitDisabled = computed(() => {
    const form = deliveryDetailForm;

    const isDeviceTypeValid = !!selectedItemType.value;
    const isNameValid = isNotEmpty(form.shippingName);
    const isValidAddress = isNotEmpty(form.shippingAddress) && isWhitespaceOrHasContent(form.shippingAddress);
    const isZipCodeValid = isNotEmpty(form.shippingZipCode) && isValidZip(form.shippingZipCode);
    const isStateValid = isNotEmpty(form.shippingState) && isAddressValid(form.shippingState) && minimumLengthValidation(form.shippingState);
    const isCityValid = isNotEmpty(form.shippingCity) && isAddressValid(form.shippingCity) && minimumLengthValidationCity(form.shippingCity);
    const isNotifyEmailsValid = validateEmailsFormat(form.notifyEmails);

    return !(isDeviceTypeValid && isNameValid && isValidAddress && isZipCodeValid && isStateValid && isCityValid && isNotifyEmailsValid);
});


const emit = defineEmits(['cancelShipment', 'shipmentCreated']);

const cancelOnboarding = () => {
    emit('cancelShipment');
}

const getInventoryItemTypes = async (serviceProviderId: string) => {
    const { dataLoader } = props.inventoryItemInput;
    const inventoryItemTypes = await dataLoader(serviceProviderId);
    const inventoryItemTypeOptions = map(
        inventoryItemTypes,
        (inventoryItemTypeDetail) => ({
            title: inventoryItemTypeDetail.label,
            value: inventoryItemTypeDetail.type,
        })
    );
    inventoryItemTypeDetails.value = inventoryItemTypeOptions;
};

watch(() => props.serviceProviderId, (newValue) => {
    getInventoryItemTypes(newValue);
    selectedServiceProviderId.value = newValue;
});

watch(() => props.clientWillFullFill, (newValue) => {
    deliveryDetailForm.clientWillFulfill = newValue;
});

watch (() => props.patientDetail, (newValue) => {
    prepareDeliveryDetailFormData(newValue);
})

const submitDeliveryDetails = async () => {
    try {
        loading.value = true;
        const { title, value } = selectedItemType.value as IInventoryItemTypeOption;
        const formattedName = `${serviceProviderName.value}_${title}_${generateRandomNumber()}`;

        const itemAssociationInput = {
            patientId: props.patientDetail.patientId,
            patientIdentityAtSource: props.patientDetail.patientId
        };

        const deviceInput = {
            name: formattedName,
            itemType: {
                label: title,
                type: value,
            }
        };

        const shipInventoryItemInput: IShipInventoryItemInput = {
            serviceProviderId: selectedServiceProviderId.value,
            itemAssociation: itemAssociationInput,
            device: deviceInput,
            fulfillmentInfo: {
                ...deliveryDetailForm,
                notifyEmails: deliveryDetailForm.notifyEmails.join(',')
            },
        };

        await props.shipInventoryItem(shipInventoryItemInput);
        loading.value = false;

        if (props.isPatientProfile) {
            emit('shipmentCreated', true);
        } else {
            router.push({ name: "ListPatient" });
            patientCreated.value = ShowSnackbar.PATIENT_CREATED;
        }

   } catch (error) {
        const { message } = error as Error;
        loading.value = false;
        shoErrorMessage.value = true;
        errorMessage.value = message;
    }
};

const fetchServiceProviders = async () => {
  const orgId = useUserStore().$state.user.organizationId;
  const serviceProviderDetails = await props.listOrgServiceProviders(orgId);
  const selectedServiceProvider = serviceProviderDetails.find(provider => provider.serviceProviderId === selectedServiceProviderId.value);
  serviceProviderName.value = selectedServiceProvider?.serviceProviderName!;
};

onMounted(() => {
    getInventoryItemTypes(props.serviceProviderId);
    deliveryDetailForm.clientWillFulfill = props.clientWillFullFill;
    selectedServiceProviderId.value = props.serviceProviderId;
    prepareDeliveryDetailFormData(props.patientDetail);
    fetchServiceProviders();
})

const prepareDeliveryDetailFormData= (patientDetail: IPatientInfoExtended | IDeviceDeliveryInfo) =>{
    notifyEmailLists.value=[]
    deliveryDetailForm.notifyEmails=[]
    notifyEmailLists.value.push(patientDetail.email!);
    deliveryDetailForm.notifyEmails.push(notifyEmailLists.value[0]);
    const {
        firstName,
        lastName,
        addressLine1,
        city,
        state,
        zip
    } = patientDetail;

    deliveryDetailForm.shippingName = `${firstName} ${lastName}`;
    deliveryDetailForm.shippingAddress = addressLine1!;
    deliveryDetailForm.shippingCity = city!;
    deliveryDetailForm.shippingState = state!;
    deliveryDetailForm.shippingZipCode = zip!;
}

</script>
<style scoped>
.delivery-details-card {
    width: 70%;
    margin: 0 auto;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 2%;
}
</style>
