export enum Gender {
    GENDER_AMBIGUOUS = 'a',
    GENDER_MALE = 'm',
    GENDER_FEMALE = 'f',
    GENDER_NON_BINARY = 'o',
    GENDER_DISCLOSED = 'u',
  }

export const genderOptions = [
    {
      title: 'Ambiguous',
      value: Gender.GENDER_AMBIGUOUS,
    },
    {
      title: 'Male',
      value: Gender.GENDER_MALE,
    },
    {
      title: 'Female',
      value: Gender.GENDER_FEMALE,
    },
    {
      title: 'Non-Binary',
      value: Gender.GENDER_NON_BINARY,
    },
    {
      title: 'Disclosed',
      value: Gender.GENDER_DISCLOSED,
    },
  ];

  export const GenderLabel = {
    a: "Ambiguous",
    m: "Male",
    f: "Female" ,
    o: "Non-Binary",
    u: "Disclosed",
  }