<template>
  <v-card>
    <v-data-table :items="items" :headers="headers" :hide-default-footer="true" :header-props="{ class: 'bg-surface'}">
      <template v-slot:item="{ item }">
        <tr :class="`bg-${rowClass(item.spO2Criteria)}`">
          <td>{{ item.spO2Criteria }}</td>
          <td>{{ item.spO2Range }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script lang="ts" setup>
import { defineProps, watch, PropType, ref, computed } from 'vue';
import { ISPO2VitalConfigThresholds } from '@/interfaces/IVitalConfigure';

const props = defineProps({
  spO2ValuesRange: {
    type: Object as PropType<ISPO2VitalConfigThresholds>,
    required: true,
  },
});

const spO2VitalRange = ref<ISPO2VitalConfigThresholds>();

const headers = [
  { title: 'Risk Category', value: 'spO2Criteria' },
  { title: 'SPO2 %', value: 'spO2Range' },
]

watch(() => props.spO2ValuesRange, (newValue: ISPO2VitalConfigThresholds) => {
  spO2VitalRange.value = newValue;
})

const items = computed(() => {

  return[
    {
    spO2Criteria: 'Critical',
    spO2Range: spO2VitalRange.value?.critical,
    },
  {
    spO2Criteria: 'Warning',
    spO2Range: spO2VitalRange.value?.warning,
  },
  {
    spO2Criteria: 'Normal',
    spO2Range: spO2VitalRange.value?.normal,
    },
  ]
})

const rowClass = (spO2Criteria: string) => {
  switch (spO2Criteria) {
    case 'Normal':
      return 'success';
    case 'Warning':
      return 'warning';
    case 'Critical':
      return 'error';
  }
};

</script>
<style scoped>
</style>
