<template>
  <v-card color="surface">
    <v-card-item>
      <QuillEditor :style="{ height: '110px' }" theme="snow" @ready="onEditorReady($event)" :options="editorOptions"
        v-model:content="content" />
    </v-card-item>
    <v-card-actions class="mr-4 mb-3">
      <v-spacer></v-spacer>
      <v-btn color="primary mx-2" variant="tonal" rounded="false" @click="emitClose">
        Cancel
      </v-btn>
      <v-btn color="primary mx-2" variant="flat" rounded="false" @click="emitSave" :disabled="isInvalidContent">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { extractTextFromQuill } from "@/composables/FormUtility";
import { Delta, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref, watch } from "vue";

const quillEditorInstance = ref();
const emit = defineEmits(['closeEditor', 'saveComment']);
const content = ref();
const isInvalidContent = ref(true);

const onEditorReady = (editor: null) => {
  quillEditorInstance.value = editor;
};

const emitClose = () => {
  quillEditorInstance.value.setContents([]);
  emit('closeEditor', false);
};

const editorOptions = {
  modules: {
    toolbar: false,
  },
};

const emitSave = () => {
  const commentContent = extractTextFromQuill(quillEditorInstance.value.getContents());
  emit('saveComment', commentContent);
  quillEditorInstance.value.setContents([]);
};

watch(content, (newContent: { ops: { insert: string }[] }) => {
  isInvalidContent.value = newContent.ops.every((op: { insert: string; }) => op.insert.trim() === '');
})

</script>

<style scoped></style>
