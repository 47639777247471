import gql from "graphql-tag";

export const CREATE_PATIENT = gql`
  mutation createPatient($patient: CreatePatientInput!) {
    createPatient(patient: $patient) {
      patientId
      firstName
      lastName
      middleName
      dob
      gender
      email
      mobilePhone
      addressLine1
      addressLine2
      city
      state
      zip
    }
  }
`;
export const GET_ALL_PATIENTS = gql`
  query listPatients($limit: Int, $offset: Int, $filter: patientFilterInput, $patientIds: [String]) {
    listPatients(limit: $limit, offset: $offset, filter: $filter, patientIds: $patientIds) {
      count
      results {
        patientId
        firstName
        lastName
        middleName
        dob
        gender
        email
        mobilePhone
        addressLine1
        addressLine2
        isActive
        isDeceased
        city
        state
        zip
        providers {
          firstName
          lastName
          isPrimaryProvider
        }
        createdTimestamp
        lastCall
        lastAppointment
      }
    }
  }
`;

export const GET_ALL_PATIENT_DEVICES = gql`
  query listPatientDevices($patientId: String!) {
    listPatientDevices(patientId: $patientId) {
      patientDeviceId
      deviceId
      deviceType
      patientDetails
      isActive
    }
  }
`;

export const GET_PATIENT_READINGS = gql`
  query patientReadings(
    $patientId: String!
    $pagination: DefaultPaginationInput
    $filter: PatientVitalsFilter
    $sort: PatientVitalsSortOptions
  ) {
    patientReadings(
      patientId: $patientId
      pagination: $pagination
      filter: $filter
      sort: $sort
    ) {
      patientReadings {
        dataSourceRef {
          patientId
          sourceDefinitionId
          sourceId
          vital
        }
        dataPoints {
          collectedTimestamp
          collectedTimeZone
          collectedBy
          reading {
            ... on BPReading {
              diastolic
              rawData
              systolic
              heartRate
              ihb
            }
            ... on PulseReading {
              rawData
              heartRate
              ihb
            }
            ... on SPO2Reading {
              rawData
              heartRate
              spO2
              perfusionIndex
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_PATIENT_DATA_SOURCES = gql`
  query patientDataSources($patientId: String!) {
    patientDataSources(patientId: $patientId) {
      patientId
      dataSource {
        sourceName
        sourceId
      }
      sourceCount
    }
  }
`;

export const GET_PATIENT_DATA_SOURCE = gql`
  query patientDataSource($patientId: UUID!, $filter: SourceFilterInput) {
    source {
      listPatientDataSources(patientId: $patientId, filter: $filter) {
        sourceId
        sourceDefinitionId
        ownerType
        orgId
        identityAtSource
        active
        sourceMeta
        patientId
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query getPatientByIds($patientIds: [String]) {
    getPatientByIds(patientIds: $patientIds) {
      patientId
      firstName
      lastName
      middleName
      dob
      gender
      email
      mobilePhone
      addressLine1
      addressLine2
      city
      state
      zip
      mrn
      isActive
      isDeceased
      orgId
      createdTimestamp
      providers {
        userId
        isPrimaryProvider
        firstName
        lastName
      }
      externalIds {
        key
        value
        serviceProviderId
        label
      }
    }
  }
`;

export const GET_PATIENT_BY_ID = gql`
  query getPatientById($patientId: String!) {
    getPatientById(patientId: $patientId) {
      patientId
      firstName
      lastName
      middleName
      dob
      gender
      email
      mobilePhone
      addressLine1
      addressLine2
      city
      state
      zip
      isActive
      isDeceased
      mrn
      providers {
        userId
        isPrimaryProvider
        firstName
        lastName
      }
      createdTimestamp
      externalIds {
      key
      value
      serviceProviderId
      label
    }
    }
  }
`;

export const GET_PATIENT_PROVIDERS = gql`
  query getPatientProviders($patientId: String!) {
    getPatientProviders(patientId: $patientId) {
      userId
      orgId
      firstName
      lastName
      mobilePhone
      email
      npi
      active
      isPrimaryProvider
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation updatePatient($updatePatient: UpdatePatientInput!) {
    updatePatient(updatePatient: $updatePatient) {
      patientId
      firstName
      lastName
      middleName
      dob
      gender
      email
      mobilePhone
      addressLine1
      addressLine2
      city
      state
      zip
      isActive
      isDeceased
      mrn
      orgId
      providers {
        userId
        isPrimaryProvider
        firstName
        lastName
      }
      createdTimestamp
    }
  }
`;

export const DOES_PATIENT_EMAIL_EXIST = gql`
  query doesPatientEmailExist($email: String!) {
    doesPatientEmailExist(email: $email)
  }
`;

export const ADD_PATIENT_EVENT = gql`
 mutation addPatientEvent($input: PatientEventInput){
  addPatientEvent(input: $input){
    patientEventId
    patientId
    eventTimeStamp
    eventName
    eventData
  }
 }
`
export const SEARCH_PATIENTS = gql`
  query searchPatients($limit: Int, $offset: Int, $patientFilter: patientFilterInput){
    searchPatients(limit: $limit, offset: $offset, patientFilter: $patientFilter) {
      count
      results {
        patientId
        firstName
        lastName
        middleName
        dob
        gender
        email
        mobilePhone
        addressLine1
        addressLine2
        isActive
        isDeceased
        city
        state
        zip
        providers {
          firstName
          lastName
          isPrimaryProvider
        }
        createdTimestamp
        lastCall
        lastAppointment
      }
    }
  }
`
