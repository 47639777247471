<template>
    <v-card flat :class="hasAccess.viewPatientInsightsFilter(viewPatientInsightsFilterRoles) ? 'filter-min ma-2 pb-2' : 'filter-minimum ma-2 pb-2'">
        <v-card-title class="mt-4">
            <h6 class="text-h6">Filter Patients</h6>
        </v-card-title>
        <v-card-subtitle class="px-4 mt-n2">
            <span class="text-subtitle">Use the filters below to narrow your search</span>
        </v-card-subtitle>

        <v-card-item>
            <form @submit.prevent="submit">
                <v-row>
                    <v-col class="mt-3">
                        <v-text-field label="Name" v-model="priorityPatientFilter.patientName" variant="underlined"
                            density="compact" />
                    </v-col>
                </v-row>
                <v-row    v-if="hasAccess.viewPatientInsightsFilter(viewPatientInsightsFilterRoles)">
                    <v-col>
                        <v-select label="Provider" variant="underlined" v-model="priorityPatientFilter.provider"
                            :items="providerOptions" density="compact"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex justify-end mb-2">
                    <v-btn color="primary" variant="tonal" elevation="3" size="small" class="mx-2" rounded="false"
                      @click="closeFilter"> cancel </v-btn>
                    <v-btn variant="elevated" elevation="3" class="mx-2" type="submit" rounded="false" size="small">
                      Apply
                    </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-card-item>
    </v-card>
</template>
<script setup lang="ts">
import { GET_ALL_ORG_USERS } from "@/graphql/resources/OrganizationUserRepository";
import { IOrgUserProvider } from "@/interfaces/provider.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import { find } from "lodash";
import { ref, onMounted } from "vue";
import hasAccess, { viewPatientInsightsFilterRoles } from "../../../composables/roleAccess";

const providerOptions = ref([]);
const priorityPatientFilter = ref({
    patientName: "",
    provider: null,
});

const emits = defineEmits(["severityPatient","close"])

const getAllOrgUser = async () => {
    try {
        const { data } = await apolloClient.query({
            query: GET_ALL_ORG_USERS,
            variables: {
                filter: { roles: "ORG_PROVIDER", tenantId: import.meta.env.VITE_TENANT_ID },
                limit: 0,
                offset: 0,
            },
            fetchPolicy: "network-only",
        });
        if (data && data.listOrganizationUsers.results) {
            providerOptions.value = data.listOrganizationUsers.results.map(
                (provider: IOrgUserProvider) => {
                    return {
                        title: `${provider.firstName} ${provider.lastName}`,
                        value: provider.userId,
                    };
                }
            );
        }
    } catch (error) {
        const { message } = error as Error;
    }
};

const closeFilter = () => {
    emits('close')
}

const submit = () => {
    const selectedProvider = priorityPatientFilter.value.provider;
    const selectedProviderInfo = find(providerOptions.value, { value: selectedProvider });
    const patientName = priorityPatientFilter.value.patientName;
        emits('severityPatient', { patientName, provider: selectedProviderInfo || '' });
};

onMounted(() => {
    getAllOrgUser();
});
</script>
<style scoped>
.filter-min {
    min-width: 280px;
    min-height: 350px;
}
.filter-minimum {
   min-width: 280px;
   min-height: 250px;
}
</style>
