import gql from "graphql-tag";

export const GET_TASK_BOARD = gql`
  query taskBoard($filter: TaskBoardFilter) {
    taskBoard(filter: $filter) {
      taskBoardId
      orgId
      name
      columns {
        id
        orgId
        category
        name
        tasks {
          taskId
          orgId
          taskIdentifier
          requestorId
          reporterId
          assignees
          status {
            taskStatusStateId
            orgId
            category
            name
          }
          title
          description
          dueDate
          priority
          commentIds
          customFieldData {
            customFieldId
            data
          }
          currentQueue {
            taskQueueTransitionId
            taskId
            taskQueueId
            taskBoardId
            orgId
            entryAt
            exitAt
            entryFrom
            exitTo
            entryBy
            exitBy
          }
        }
      }
      queues {
        displayOrder
        queueDef {
          taskQueueDefinitionId
          title
          customFields
          actionDefinitions
          orgId
          color
        }
      }
      actors {
        id
        orgId
        name
        active
      }
      swimlaneInfo {
        current {
          strategy
          name
          description
        }
        options {
          strategy
          name
          description
        }
      }
    }
  }
`;

export const GET_DEFAULT_TASK_BOARD = gql`
  query getDefaultTaskBoard {
    getDefaultTaskBoard {
      taskBoardId
      orgId
      name
      allowedStatusStates
      taskQueueDefinitions
      isDefault
    }
  }
`;
