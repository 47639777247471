import { onMounted } from 'vue';
<template>
  <v-card flat>
    <v-table>
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.key" scope="row" class="text-left font-weight-bold">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="organizationServiceProviders.length === 0">
          <td :colspan="columns.length">
            <div class="noDataContainer mt-16">
              <div class="text-h6 font-weight-bold">No Data Found !</div>
              <img src="../../assets/empty.png" alt="No data found" class="noDataImage" />
            </div>
          </td>
        </tr>
        <tr v-for="orgServiceProvider in organizationServiceProviders" :key="orgServiceProvider.orgServiceProviderId"
          class="text-left">
          <td>{{ orgServiceProvider.serviceProviderName }}</td>
          <td>{{ orgServiceProvider.sourceTypes }}</td>
          <td>{{ orgServiceProvider.isActive ? "Active" : "Inactive" }}</td>
          <td>
            <v-btn color="primary" class="text-none font-weight-bold mr-2" rounded variant="tonal" size="small"
              @click="edit(orgServiceProvider)" icon="mdi-pencil" />
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script setup lang="ts">
import { IOrgServiceProvider } from '@/interfaces/IOrganization';


const columns = [
  {
    key: "serviceProviderName",
    label: "Name",
  },
  {
    key: "sourceType",
    label: "Source Type",
  },
  {
    key: "isActive",
    label: "Status",
  },
  {
    key: "actions",
    label: "Actions",
  },

];

defineProps({
  organizationServiceProviders: {
    type: Array as () => IOrgServiceProvider[],
    default: () => [],
  },
});
const emit = defineEmits(["edit-org-service-provider"]);
const edit = (orgServiceProvider: IOrgServiceProvider) => {
  emit("edit-org-service-provider", orgServiceProvider);
};
</script>

<style scoped>
.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataImage {
  width: 250px;
  height: 250px;
}
</style>
