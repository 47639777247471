import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import OrganizationList from "../components/ListOrganization.vue";
import HomeLayoutWithoutRightNavBar from "../layouts/HomeLayoutWithoutRightNavBar.vue";
import CreateOrganizationForm from "../components/CreateOrganizationForm.vue";
import OrganizationUserForm from "../components/OrganizationUserForm.vue";
import OrganizationProfile from "../components/OrganizationProfile.vue";
import OrganizationForm from "../components/OrganizationForm.vue";
import OrganizationUserListTable from "../components/OrganizationUserListTable.vue";
import LogginFormComp from "../views/LogginFormComp.vue";
import CreatePatientStepper from "../components/CreatePatientStepper.vue";
import ListPatient from "../components/ListPatient.vue";
import ListMultiPatientConsents from "../components/ListMultiPatientConsents.vue";
import UpdatePassword from "../components/UpdatePassword.vue";
import PublicLayout from "../layouts/PublicLayout.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import MailConfirmation from "../components/MailConfirmation.vue";
import {
  isAdmin,
  isAuthorizedUser,
  isOrgAdmin,
  isOrgUser,
  isSystemAndOrgAdmin,
} from "../composables/SecurityService";
import ForbiddenError from "../components/ForbiddenError.vue";
import ClockDriftError from "../components/ClockDriftError.vue";
import { useUserStore } from "../store/modules/User";
import ResetPassword from "../components/ResetPassword.vue";
import OrgServiceProviderConfigure from "../components/serviceProvider/OrgServiceProviderConfigure.vue";
import SetPassword from "../components/setPassword/SetPasswordComp.vue";
import PrivacyPolicy from '../components/Policy-info/PrivacyPolicy.vue';
import TermsAndCondition from '../components/Policy-info/TermsAndCondition.vue';
import PatientMonitor from "../components/patient/profile/PatientMonitor.vue";
import VitalSelectionComp from "../components/vital/VitalSelectionComp.vue";
import Inventory from "../components/inventory/Inventory.vue";
import OrgReports from "@/components/organization/reports/OrgReports.vue";
import EditOrganizationUser from "../components/EditOrganizationUser.vue";
import DynamicDashboard from "@/components/dashboard/DynamicDashboard.vue";
import {
  getDefaultTaskBoardId,
  getTaskBoard,
  listTaskQueueItems,
  createTask,
  listAssignees,
  searchPatientsList,
  editTaskDetails,
} from "../services/task.service";
import {
  getLoggedInUserInfo,
  getLoginDetails,
} from "../services/common.service";
import {
  ITaskQueueItem,
  ICreateTaskInput,
  IAssigneeItem,
  IRequestorItem,
  UpdateTaskInput,
} from "../interfaces/task.interface";
import { getPatientMonitorDependencies } from "../services/PatientMonitor.service";
import { VitalsInput } from "@/interfaces/IVitalConfigure";
import { createVital, getVital } from "@/services/vital.service";
import {
  assignInventoryItem,
  createInventoryDevice,
  enableInventoryItem,
  getInventoryItemTypesByServiceProviderId,
  getInventoryItems,
  removeItemFromInventory,
  unAssignDevice,
  getOrgServiceProvidersByOrgId,
  createShipInventoryItem,
  editInventoryItem,
} from "@/services/inventory.service";
import {
  EditDeviceInput,
  IAssignPatientInput,
  IDeviceInput,
  IListInventoryFilter,
  IShipInventoryItemInput,
} from "@/interfaces/InventoryItem.interface";
import { CommentInput } from "@/interfaces/comment.interface";
import { createComment } from "@/services/comment.service";
import { listActivities } from "@/services/activity.service";
import { getOrgReportsDependencies } from "../services/report.service";
import { subscribeProgramUsingShortCode } from "@/services/patient-progam.service";
import { SubscribeProgramUsingShortCodeInput } from "@/interfaces/econsent-template.interface";
import { getMultiPatientConsentListDependencies } from "@/services/eConsent.service";
import { userPool } from "@/composables/CognitoService";
import { getAllOrgServiceProviders } from "@/services/org-service-provider.service";
import EditPatient from "../components/EditPatient.vue";
import {
  getPatientById,
  getPatientByPatientId,
} from "@/services/patient.service";
import ProgramInsightsDashboard, {
  PROGRAM_INSIGHTS_TABS,
} from "@/components/dashboard/ProgramInsightsDashboard.vue";
import { IOrganizationUserFilter } from "@/interfaces/IOrganization";
import BPRangeCriteria from "@/components/vital/config/bp/BPRangeCriteria.vue";
import { createAuditEvent } from "@/services/auditEvent.service";
import { AuditEvent } from "@/interfaces/audit-event.interface";
import { createCUstomizedBillingDistribution } from "@/services/billing.service";
import { ICustomCPTDistributionInput } from "@/interfaces/billing.interface";
import { VITAL } from "@/enums/readings";

const checkDuplicateLoginAttempt = () => {
  const existingLoginUser = localStorage.getItem("loginUser");
  if (existingLoginUser && userPool.getCurrentUser()) {
    getLoginDetails();
    return false;
  } else {
    return true;
  }
};

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/",
    component: PublicLayout,
    children: [
      {
        path: "test",
        component: BPRangeCriteria,
      },
      {
        path: "login",
        name: "login",
        component: LogginFormComp,
        beforeEnter: checkDuplicateLoginAttempt,
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "mail-confirmation",
        name: "mail-confirmation",
        component: MailConfirmation,
      },
      {
        path: "/forbidden",
        name: "forbidden",
        component: ForbiddenError,
      },
      {
        path: "/clock-drift",
        name: "clock-drift",
        component: ClockDriftError,
      },
      {
        path: "reset-password",
        name: "resetPassword",
        component: ResetPassword,
      },
      {
        path: "set-password",
        name: "setPassword",
        component: SetPassword,
      },
    ],
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },

  {
    path: "/Terms-Conditions",
    name: "termsandConditions",
    component: TermsAndCondition,
  },
  {
    path: "/home",
    name: "home",
    component: HomeLayoutWithoutRightNavBar,
    beforeEnter: isAuthorizedUser,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "default-dashboard",
        component: DynamicDashboard,
        beforeEnter: isOrgUser,
      },
      {
        name: "dashboard",
        path: "dashboard",
        component: DynamicDashboard,
        beforeEnter: isOrgUser,
      },
      {
        name: "program-insights",
        path: "program-insights",
        component: ProgramInsightsDashboard,
        beforeEnter: isOrgUser,
      },
      {
        name: "program-insights-focused",
        path: "program-insights/:startTab",
        component: ProgramInsightsDashboard,
        props: (route) => {
          return {
            startTab:
              PROGRAM_INSIGHTS_TABS[
                `${route.params.startTab}`.toUpperCase() as keyof typeof PROGRAM_INSIGHTS_TABS
              ],
          };
        },
      },
      {
        name: "task-board",
        path: "task-board",
        component: () => import("../components/common/kanban/TaskBoard.vue"),
        beforeEnter: isOrgUser,
        props: () => {
          return {
            currentUserId: async () => {
              const userInfo = getLoggedInUserInfo();
              return userInfo.userId;
            },
            boardDataLoader: async (
              boardId: string,
              {
                dateContext,
                fromDate,
                toDate,
              }: { dateContext: string[]; fromDate: string; toDate: string },
            ) => {
              const taskBoardData = await getTaskBoard({
                dateContexts: dateContext,
                fromDate,
                toDate,
              });
              return taskBoardData;
            },
            requestorInput: {
              label: "Patient",
              description: "Enter Patient",
              dataLoader: async (search: string) => {
                const requestors = await searchPatientsList(search);
                return requestors;
              },
              validationRules: [
                (v: IRequestorItem) => !!v || "Requestor is required",
              ],
            },
            assigneeInput: {
              label: "Assignees",
              description: "Enter Assignees",
              dataLoader: async (
                OrganizationUserFilterInput: IOrganizationUserFilter,
              ) => {
                const assignees = await listAssignees(
                  OrganizationUserFilterInput,
                );
                return assignees;
              },
              validationRules: [
                (v: IAssigneeItem[]) =>
                  (!!v && v.length >= 1) || "At least one assignee is required",
              ],
            },
            taskQueueInput: {
              label: "TaskQueue Label",
              description: "TaskQueue Description",
              dataLoader: async () => {
                const taskQueues = await listTaskQueueItems();
                return taskQueues;
              },
              validationRules: [
                (v: ITaskQueueItem) => !!v || "Please select a task queue",
              ],
            },
            addTaskHandler: (createTaskInput: ICreateTaskInput) => {
              const createdTask = createTask(createTaskInput);
              return createdTask;
            },
            activityLoader: async () => {
              const activityLists = await listActivities();
              return activityLists;
            },
            getTaskDetailDependencies: getPatientMonitorDependencies,
            addCommentHandler: (createCommentInput: CommentInput) => {
              const createdComment = createComment(createCommentInput);
              return createdComment;
            },
            editTaskDetail: (updateInput: UpdateTaskInput) => {
              const response = editTaskDetails(updateInput);
              return response;
            },
          };
        },
      },
      {
        path: "create-org",
        name: "createOrg",
        component: CreateOrganizationForm,
        beforeEnter: isAdmin,
      },
      {
        path: "org-profile",
        name: "orgProfile",
        component: OrganizationProfile,
        beforeEnter: isSystemAndOrgAdmin,
        children: [
          {
            path: "view/:orgId?",
            name: "org-profile-view",
            component: OrganizationForm,
            beforeEnter: isSystemAndOrgAdmin,
          },
          {
            path: ":orgId/orgusers",
            name: "org-users",
            component: OrganizationUserListTable,
            beforeEnter: isSystemAndOrgAdmin,
          },
          {
            path: ":orgId/org-service-providers",
            name: "org-service-providers",
            component: OrgServiceProviderConfigure,
            beforeEnter: isOrgAdmin,
          },
          {
            path: ":orgId/vital-configuration",
            name: "vital-configuration",
            component: VitalSelectionComp,
            beforeEnter: isSystemAndOrgAdmin,
            props: () => {
              return {
                addVitalHandler: (vitalInput: VitalsInput) => {
                  const createdVital = createVital(vitalInput);
                  return createdVital;
                },
                dataLoader: async (vital: VITAL) => {
                  const vitalConfig = await getVital(vital);
                  return vitalConfig;
                },
              };
            },
          },
          {
            path: ":orgId/inventory",
            name: "inventory",
            component: Inventory,
            beforeEnter: isOrgAdmin,
            props: () => {
              return {
                serviceProvidersInput: {
                  dataLoader: async () => {
                    const { organizationId } = getLoggedInUserInfo();
                    const getOrgServiceProviders =
                      await getOrgServiceProvidersByOrgId(organizationId);
                    return getOrgServiceProviders;
                  },
                },
                createInventoryDevice: async (input: IDeviceInput) => {
                  const createdInventory = await createInventoryDevice(input);
                  return createdInventory;
                },
                inventoryItemInput: {
                  dataLoader: async (serviceProviderId: string) => {
                    const inventoryItemTypeDetail =
                      await getInventoryItemTypesByServiceProviderId(
                        serviceProviderId,
                      );
                    return inventoryItemTypeDetail;
                  },
                },
                getInventoryItems: async (
                  serviceProviderId: string,
                  filter: IListInventoryFilter,
                  signal: unknown
                ) => {
                  const inventoryItemResponse = await getInventoryItems(
                    serviceProviderId,
                    filter,
                    signal
                  );
                  return inventoryItemResponse;
                },
                patientInput: {
                  label: "Patient",
                  description: "Patient assignation",
                  dataLoader: async (search: string) => {
                    const requestors = await searchPatientsList(search);
                    return requestors;
                  },
                },
                assignInventoryDevice: async (
                  itemId: string,
                  input: IAssignPatientInput,
                ) => {
                  const assignedInventoryItemResponse =
                    await assignInventoryItem(itemId, input);
                  return assignedInventoryItemResponse;
                },

                removeItemFromInventory: async (itemId: string) => {
                  const removedItem = await removeItemFromInventory(itemId);
                  return removedItem;
                },

                enableInventoryItem: async (itemId: string) => {
                  const enabledItem = await enableInventoryItem(itemId);
                  return enabledItem;
                },

                unAssignDeviceHandler: async (itemId: string) => {
                  const unAssignDeviceResponse = await unAssignDevice(itemId);

                  return unAssignDeviceResponse;
                },

                disableAddDeviceHandler: async (orgId: String) => {
                  const disableAddDeviceResponse =
                    await getOrgServiceProvidersByOrgId(orgId);
                  return disableAddDeviceResponse;
                },
                editInventoryDevice: async (input: EditDeviceInput) => {
                  const editResponse = await editInventoryItem(input);
                  return editResponse;
                },
                patientDependencies: getPatientMonitorDependencies,
              };
            },
          },
          {
            path: ":orgId/reports",
            name: "org-reports",
            component: OrgReports,
            beforeEnter: isOrgAdmin,
            props: (route) => {
              return {
                orgId: route.params.orgId,
                ...getOrgReportsDependencies(),
                auditEventCreation: async (auditEventInput: AuditEvent) => await createAuditEvent(auditEventInput),
                saveCustomizedBillingDistribution: async (customCPTDistributionInput: ICustomCPTDistributionInput) => await createCUstomizedBillingDistribution(customCPTDistributionInput),
              };
            },
          },
        ],
      },
      {
        name: "OrganizationList",
        path: "organizations",
        component: OrganizationList,
        beforeEnter: isAdmin,
      },
      {
        name: "CreatePatientStepper",
        path: "create-patient",
        component: CreatePatientStepper,
        beforeEnter: isOrgUser,
        props: () => {
          return {
            subscribeProgramUseByShortCode: async (
              subscribeProgramUsingShortCodeInput: SubscribeProgramUsingShortCodeInput,
            ) => {
              return await subscribeProgramUsingShortCode(
                subscribeProgramUsingShortCodeInput,
              );
            },
            listOrgServiceProviders: async (orgId: string) => {
              const getServiceProvider = await getAllOrgServiceProviders(orgId);
              return getServiceProvider;
            },
            inventoryItemInput: {
              dataLoader: async (serviceProviderId: string) => {
                const inventoryItemTypeDetail =
                  await getInventoryItemTypesByServiceProviderId(
                    serviceProviderId,
                  );
                return inventoryItemTypeDetail;
              },
            },
            shipInventoryItem: async (input: IShipInventoryItemInput) => {
              const response = await createShipInventoryItem(input);
              return response;
            },
          };
        },
      },
      {
        name: "ListPatient",
        path: "list-patient",
        component: ListPatient,
        beforeEnter: isOrgUser,
      },
      {
        name: "EditPatient",
        path: ":patientId?/edit-patient",
        component: EditPatient,
        beforeEnter: isOrgAdmin,
        props: () => {
          return {
            listOrgServiceProviders: async (orgId: string) => {
              const getServiceProvider = await getAllOrgServiceProviders(orgId);
              return getServiceProvider;
            },
            patientDetail: async (patientId: string) => {
              const patientDetails = await getPatientById(patientId);
              return patientDetails;
            },
          };
        },
      },
      {
        name: "PatientConsents",
        path: "patient-consents",
        component: ListMultiPatientConsents,
        beforeEnter: isOrgUser,
        props: (route) => {
          return {
            getDependencies: getMultiPatientConsentListDependencies,
          };
        },
      },
      {
        path: ":orgId?/create-org-user",
        name: "createOrgUser",
        component: OrganizationUserForm,
        beforeEnter: isSystemAndOrgAdmin,
      },
      {
        path: ":orgUserId?/edit-org-user",
        name: "editOrgUser",
        component: EditOrganizationUser,
        beforeEnter: isSystemAndOrgAdmin,
        props: (route) => {
          return {
            orgUserId: route.params.orgUserId,
          };
        },
      },
      {
        path: "update-password",
        name: "updatePassword",
        component: UpdatePassword,
      },
    ],
  },
  {
    path: "/patient",
    component: HomeLayoutWithoutRightNavBar,
    beforeEnter: isAuthorizedUser,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListPatient,
        beforeEnter: isOrgUser,
      },
      {
        path: ":patientId",
        name: "patientProfile",
        component: PatientMonitor,
        beforeEnter: isOrgUser,
        props: (route) => {
          return {
            patientProfile: {
              patientId: route.params.patientId,
              patientInfo: null,
            },
            routeOnExit: "ListPatient",
            getDependencies: getPatientMonitorDependencies,
          };
        },
      },
      {
        path: ":patientId/:patient",
        name: "patientProfileWithInfo",
        component: PatientMonitor,
        beforeEnter: isOrgUser,
        props: (route) => {
          return {
            patientProfile: {
              patientId: route.params.patientId,
              patientInfo: null,
              patientInfoLoader: getPatientByPatientId,
            },
            routeOnExit: "ListPatient",
            getDependencies: getPatientMonitorDependencies,
          };
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authUserStore = useUserStore();
  const isLoggedIn = authUserStore.$state.user.isLogged;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!isLoggedIn && requiresAuth && !userPool.getCurrentUser()) {
    next({ name: "login" });
  } else if (isLoggedIn && to.name === "login") {
    router.replace({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
