import gql from "graphql-tag";

export const PATIENT_PROGRAM_CYCLES = gql`
  query patientProgramCycles(
    $patientId: String!
    $activityPeriod: PatientProgramCyclePeriod!
    $program: String
  ) {
    PatientProgramCycles(
      patientId: $patientId
      activityPeriod: $activityPeriod
      program: $program
    ) {
      count
      results {
        id

        patientProgramId
        programStartDate
        cycleStart
        cycleDays
        cycleType
        cycleNumber
        isCurrentCycle
        cycleIdentifier
        cycleStatus {
          daysActive
          daysMissed
          daysRemaining
          activityHistory {
            date
            activity {
              vital
            }
          }
        }
      }
    }
  }
`;
