<template>
  <v-container fluid>
    <v-form @submit.prevent="saveThreshold">
    <v-row>
      <v-col cols="12">
        <v-card color="surfContainerHigh" rounded="false">
          <v-card-item class="bg-surface">
            <template v-slot:title>
              <div class="d-flex align-center">
                <div class="d-flex flex-wrap vt-widget-title-wrap-lg">
                  <span class="ml-2 text-primary">Pulse Criteria Configuration</span>
                  <span class="ml-2 v-card-subtitle flex-1-1-100 text-primary">Configure Pulse criteria for {{ patientId ? 'the patient'
                    : 'organization' }}</span>
                </div>
                <v-spacer />
              </div>
            </template>
          </v-card-item>
          <v-card-item>
            <v-container>
              <v-row no-gutters>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="pb-2">
                  <v-card class="mx-auto w-100 text-onSurfaceVar elevation-1 my-2 mb-4" color="surface">
                    <v-card-tile>
                          <div class="d-flex flex-wrap pa-2 bg-primary">
                            <span class=" text-subtitle-1 ">Pulse Configuration</span>
                          </div>
                    </v-card-tile>
                    <v-card-text class="px-8">
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <div class="py-8">
                              <Slider v-model="pulseRangeThresholds" :step="stepSize" :lazy="false" :classes="classes"
                                :min="SELECTION_RANGE_DEFAULT.min" :max="SELECTION_RANGE_DEFAULT.max"
                                :format="toolTipFormatFn" @update:model-value="emitUpdatedThresholds()" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="d-flex align-center justify-center">
                  <pulse-range-criteria-visualizer :pulseValuesRange="pulseValuesRange" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-item>
          <v-divider />
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions style="float: right;">
        <v-btn class="px-8 mt-2" variant="elevated" rounded="false" type="submit">Save
          Configuration</v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import Slider from "@vueform/slider";
import { ref, onMounted, PropType, computed, defineEmits, watch } from 'vue';
import {
  IVitalRangeThresholds,
} from "@/interfaces/vitals-configuration.interface";
import { IVitalConfigure, PulseVitalThresholdCriteria } from "@/interfaces/IVitalConfigure";
import { Vitals } from '@/enums/patientInsights.enum';
import PulseRangeCriteriaVisualizer from "./PulseRangeCriteriaVisualizer.vue";

const props = defineProps({
  vitalPulseConfigurationData: {
    type: Object as PropType<IVitalConfigure>,
    required: true,
  },
  patientId: {
    type: String,
    required: false
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const pulseRangeThresholds = ref([0, 0, 0, 0]);

const vitalConfig = ref<IVitalConfigure>();
const selectionRangeMin = Number(import.meta.env.VITE_PULSE_CONFIG_THRESHOLD_MIN || 0);
const selectionRangeMax = Number(import.meta.env.VITE_PULSE_CONFIG_THRESHOLD_MAX || 400);
const stepSize = 1;

const emits = defineEmits(["update:rangeSelection"]);

const classes = {
  target: "slider-target",
  ltr: "slider-ltr",
  rtl: "slider-rtl",
  horizontal: "slider-horizontal",
  vertical: "slider-vertical",
  textDirectionRtl: "slider-txt-dir-rtl",
  textDirectionLtr: "slider-txt-dir-ltr",
  base: "slider-base bg-critical vt-bp-config-slider",
  connects: "slider-connects",
  connect: "slider-connect",
  origin: "slider-origin",
  handle: "slider-handle",
  handleLower: "slider-handle-lower",
  handleUpper: "slider-handle-upper",
  touchArea: "slider-touch-area",
  tooltip: "slider-tooltip bg-surface",
  tooltipTop: "slider-tooltip-top",
  tooltipBottom: "slider-tooltip-bottom",
  tooltipLeft: "slider-tooltip-left",
  tooltipRight: "slider-tooltip-right",
  active: "slider-active",
  draggable: "slider-draggable",
  tap: "slider-state-tap",
  drag: "slider-state-drag",
};

const SELECTION_RANGE_DEFAULT = {
  min: selectionRangeMin,
  max: selectionRangeMax,
};

const createThresholdObject = (range: number[]) => ({
  criticalMin: Number(SELECTION_RANGE_DEFAULT.min),
  warnMin: range[0],
  normalMin: range[1],
  normalMax: range[2],
  warnMax: range[3],
  criticalMax: Number(SELECTION_RANGE_DEFAULT.max),
});

const bpReadingData = ref([
  createThresholdObject(pulseRangeThresholds.value),
]);

const vitalUnit = computed(() => {
  return "bpm";
});

const pulseRange = computed(() => {
  const config = vitalConfig.value?.config as PulseVitalThresholdCriteria;
  const pulseThresholds = config?.pulse?.thresholds as
    | IVitalRangeThresholds
    | undefined;
  return {
    min: pulseThresholds?.criticalMin ?? SELECTION_RANGE_DEFAULT.min,
    max: pulseThresholds?.criticalMax ?? SELECTION_RANGE_DEFAULT.max,
  };
});

const pulseValuesRange = computed(() => {
  const criticalMaxFrom = pulseRangeThresholds.value[3] === pulseRange.value.max ? pulseRangeThresholds.value[3] : pulseRangeThresholds.value[3] + stepSize;

  return {
    normal: `${pulseRangeThresholds.value[1] + stepSize} - ${pulseRangeThresholds.value[2]}`,
    warningMin: `${pulseRangeThresholds.value[0] + stepSize} - ${pulseRangeThresholds.value[1]}`,
    warningMax: `${pulseRangeThresholds.value[2] + stepSize} - ${pulseRangeThresholds.value[3]}`,
    criticalMin: `${pulseRange.value.min} - ${pulseRangeThresholds.value[0]}`,
    criticalMax: `${criticalMaxFrom} - ${pulseRange.value.max}`,
  };
});

const toolTipFormatFn = (val: number) => {
  return `${val} ${vitalUnit.value}`;
};

const emitUpdatedThresholds = () => {
  bpReadingData.value = [
    createThresholdObject(pulseRangeThresholds.value),
  ];
};

const saveThreshold = () => {
  emits("update:rangeSelection", bpReadingData.value, Vitals.BP);
}

watch(() => props.vitalPulseConfigurationData, (newValue) => {
  vitalConfig.value = newValue;

  const config = vitalConfig.value.config as PulseVitalThresholdCriteria;
  if (config.pulse?.thresholds) {
    const {
      normalMax: pulseNormalMax,
      normalMin: pulseNormalMin,
      warnMax: pulseWarnMax,
      warnMin: pulseWarnMin,
    } = config.pulse!.thresholds;
    pulseRangeThresholds.value = [
      pulseWarnMin,
      pulseNormalMin,
      pulseNormalMax,
      pulseWarnMax,
    ];

    emitUpdatedThresholds();
  }
}, {deep: true, immediate: true});
</script>

<style src="@vueform/slider/themes/default.css">
.vt-bp-config-slider:first-child {
  background-color: yellow !important;
}
</style>
../thresholds/reportingFrequency/VitalReportingFrequencyConfig.vue
