<template>
  <v-card :class="$vuetify.display.lgAndUp
    ? 'rounded-lg py-8 parentContainer'
    : 'rounded-lg py-8'
    ">
    <div class="text-center">
      <v-card-text id="cardTitle" class="font-weight-bold text-h5 py-8 text-primary">
        {{ cardTitle }}
      </v-card-text>
    </div>

    <v-card-text class="text-left">
      <v-form @submit.prevent="handleSubmit" id="resetForm">
        <v-text-field id="oldPassword" class="col-12 mb-5" required variant="outlined" v-model="formData.oldPassword"
          :type="showOldPassword ? 'text' : 'password'" :rules="oldPasswordRules" :color="primaryColor"
          :label="oldPasswordLabel" :hint="olPasswordHint"
          :append-inner-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showOldPassword = !showOldPassword" />
        <v-text-field id="password" class="col-12 mb-5" required variant="outlined" v-model="formData.password"
          :type="showPassword ? 'text' : 'password'" :color="primaryColor" :rules="passwordRules" :label="passwordLabel"
          :hint="passwordHint" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showPassword = !showPassword" />
        <v-text-field id="confirmPassword" class="col-12 mb-5" variant="outlined" required
          v-model="formData.confirmPassword" :type="showConfirmPassword ? 'text' : 'password'" :color="primaryColor"
          :rules="confirmPasswordRules" :hint="confirmPasswordHint" :label="confirmPasswordLabel"
          :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showConfirmPassword = !showConfirmPassword" />

        <div>
          <v-btn id="submitButton" block class="mb-5 text-white rounded-xl text-none" rounded="lg" :color="primaryColor"
            size="large" variant="flat" type="submit" :disabled="!isPasswordFormValid">
            {{ buttonName }}
          </v-btn>
          <v-btn id="cancelButton" block class="mb-5 text-white rounded-xl text-none" rounded="lg" variant="outlined"
            :color="primaryColor" size="large" @click="cancelResetPassword">
            Cancel
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
    <v-dialog v-model="showResetPasswordSuccessMessage" persistent :class="$vuetify.display.smAndUp
      ? 'rounded-lg py-8 dialog-MaxWidth'
      : 'rounded-lg py-8'
      ">
      <v-card>
        <div class="d-flex align-center justify-center mt-7">
          <v-icon size="3rem" color="green">mdi mdi-check</v-icon>
        </div>
        <v-card-title class="text-h6 text-center text-success font-weight-bold ">
          Password updated successfully
        </v-card-title>
        <v-card-text class="text-end mb-5 mt-3 font-weight-bold">
          <router-link class="text-end text-primary "
            :to="hasAccess.viewDashBoard(viewDashboardRoles) ? 'dashboard' : 'organizations'">
            Go to Home
          </router-link>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar color="error" class="text-white mt-16" v-model="showResetPasswordErr" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showResetPasswordErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import router from "../router";
import { resetPassword } from "../composables/CognitoService";
import Loader from "./common/Loader.vue";
import hasAccess, { viewDashboardRoles } from "../composables/roleAccess";
import { isValidPassword } from "@/composables/FormUtility";
import { every } from "lodash";

const loading = ref(false);

defineProps({
  cardTitle: {
    type: String,
    required: false,
    default: "Reset Password",
  },
  primaryColor: {
    type: String,
    required: false,
    default: "primary",
  },
  buttonName: {
    type: String,
    required: false,
    default: "Reset Password",
  },
  passwordLabel: {
    type: String,
    required: false,
    default: "New Password*",
  },
  oldPasswordLabel: {
    type: String,
    required: false,
    default: "Old Password*",
  },
  olPasswordHint: {
    type: String,
    required: false,
    default: "Enter your old password",
  },
  passwordHint: {
    type: String,
    required: false,
    default: "Enter your new password",
  },
  confirmPasswordHint: {
    type: String,
    required: false,
    default: "Re-enter your new password",
  },
  confirmPasswordLabel: {
    type: String,
    required: false,
    default: "Confirm Password*",
  },
  homeLink: {
    type: String,
    default: "/home",
  },
});

const showPassword = ref(false);
const showConfirmPassword = ref(false);
const showOldPassword = ref(false);

const showResetPasswordSuccessMessage = ref(false);
const showResetPasswordErr = ref(false);
const errorMessage = ref("");

const passwordRules = [
  (v: string) => !!v || "New password is required",
  (v: string) => isValidPassword(v) || "*Password must include at least 8 characters with one uppercase letter, one lowercase letter, one number, and one special symbol from !, @, $, %, *, ? "
];
const oldPasswordRules = [
  (v: string) => !!v || "Please provide your old Password",
  (v: string) => v.length >= 8 || "Password must be at least 8 characters",
];

const confirmPasswordRules = [
  (v: string) => !!v || "Confirm password is required",

];

const formData = ref({
  password: "",
  confirmPassword: "",
  oldPassword: "",
});

const isPasswordFormValid = computed(() => {
  return every([formData.value.password, formData.value.oldPassword, formData.value.confirmPassword], Boolean) &&
    isValidPassword(formData.value.password) && isValidPassword(formData.value.confirmPassword) &&
    formData.value.oldPassword.length >= 8;
});

const handleSubmit = async () => {
  const { oldPassword, password, confirmPassword } = formData.value;

  if (password === confirmPassword) {
    try {
      showPassword.value = false;
      showOldPassword.value = false;
      showConfirmPassword.value = false;

      loading.value = true;
      await resetPassword(oldPassword, password);
      loading.value = false;
      showResetPasswordSuccessMessage.value = true;
    } catch (error) {
      loading.value = false;
      errorMessage.value =
        "Unable to reset password. Please contact support or try again later.";
      showResetPasswordErr.value = true;
    }
  } else {
    loading.value = false;
    errorMessage.value =
      "Passwords do not match";
    showResetPasswordErr.value = true;
  }
};

const cancelResetPassword = () => {
  router.push({
    name: hasAccess.viewDashBoard(viewDashboardRoles)
      ? "dashboard"
      : "OrganizationList"
  });
}

</script>

<style scoped>
.parentContainer {
  width: 70%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 20%;
}

.dialog-MaxWidth {
  max-width: 700px;
}
</style>
