<template>
  <v-container fluid>
    <v-row class="mb-n10">
      <v-col cols="2" v-if="!isNoSubscriptionTab">
        <v-select color="primary" class="vt-single-line-multi-select" multiple chips clearable closable-chips
          density="compact" variant="solo" elevation="3" label="Subscribed Programs" hide-details
          v-model="filteredActiveSubscribedPrograms" @update:model-value="applyFilter" :items="programList"
          item-value="id" item-title="shortCode">
        </v-select>
      </v-col>
      <v-col cols="2" v-if="isPendingTab && !isNoSubscriptionTab">
        <v-select color="primary" class="vt-single-line-multi-select" multiple chips clearable closable-chips
          density="compact" variant="solo" elevation="3" label="Pending Consents" hide-details
          v-model="filteredPendingConsents" @update:model-value="applyFilter" :items="programList" item-value="id"
          item-title="shortCode">
        </v-select>
      </v-col>
      <v-col cols="2" v-if="isPendingTab && !isNoSubscriptionTab"><v-select color="primary" v-model="filteredActiveStatus"
          class="vt-single-line-multi-select" multiple chips clearable closable-chips density="compact" variant="solo"
          elevation="3" label="Subscription status" :items="statusOptions" item-title="name" item-value="value"
          hide-details @update:model-value="applyFilter">
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filteredPatientName" label="Patient Name" variant="solo" elevation="3" density="compact"
          color="primary" hide-detail append-inner-icon="mdi-magnify" @keyup.enter="applyFilter">
        </v-text-field>
      </v-col>
      <v-col cols="2" v-if="hasAccess.viewMultiPatientConsentsFilte(viewMultiPatientConsentsFilterRoles)">
        <v-text-field v-model="filteredProviderName" label="Provider Name" variant="solo" elevation="3"
          density="compact" color="primary" append-inner-icon="mdi-magnify" @keyup.enter="applyFilter">
        </v-text-field>
      </v-col>
      <v-col cols="1"> <v-tooltip location="bottom"> <template v-slot:activator="{ props }"> <v-btn v-bind="props"
              rounded prepend-icon="mdi-calendar" size="x-small" variant="text" elevation="3"
              @click="datePickerOpen = true" icon="mdi-calendar" /> </template>Filter by Date</v-tooltip> </v-col>
      <v-col cols="1" class="d-flex align-center mt-n5 ml-auto"> <v-tooltip location="bottom"> <template
            v-slot:activator="{ props }"> <v-btn v-bind="props" rounded prepend-icon="mdi-filter-remove" size="x-small"
              variant="text" elevation="3" @click="removedValue" icon="mdi-filter-remove" /> </template>Clear
          Filters</v-tooltip> </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="datePickerOpen" transition="dialog-bottom-transition">
    <v-card class="mx-auto" :class="$vuetify.display.smAndUp ? 'date-range-bigscreen ' : 'date-range-smallscreen '">
      <v-btn variant="text" color="primary" size="small" @click="datePickerOpen = false" class="ml-auto">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form>
        <v-col>
          <v-col cols="12">
            <h4>Date Range: </h4>
          </v-col>
          <v-col cols="12">
            <VueDatePicker :show-time="false" :inline="{ input: true }" :enable-time-picker="false"
              :format="'yyyy/MM/dd'" range :partial-range="false" v-model="dateRangePicker"
              :disabled-dates="disableFutureDates" />
          </v-col>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { watchEffect, ref, watch } from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from "moment";
import { isEmpty } from "lodash";
import { IProgram } from "@/interfaces/econsent.interface";
import hasAccess, {
  viewMultiPatientConsentsFilterRoles,
} from "../../composables/roleAccess";

const filteredPatientName = ref("");
const filteredProviderName = ref("");
const filteredPendingConsents = ref<string[]>();
const filteredActiveSubscribedPrograms = ref<string[]>();
const datePickerOpen = ref(false);
const filteredActiveStatus = ref<boolean[]>();
const isDateSelected = ref(false);
const startDate = new Date();
startDate.setMonth(startDate.getMonth() - 1);
const endDate = new Date();
const dateRangePicker = ref([startDate, endDate]);
const dateRange = ref([startDate, endDate]);
const props = defineProps<{
  removedPatientNameValue: string;
  removedProviderNameValue: string;
  programList: IProgram[];
  isPendingTab: boolean;
  isNoSubscriptionTab: boolean;
  resetFilters: { resetPatientFilter?: boolean; resetProviderFilter?: boolean };
}>();
const emit = defineEmits(["applyFilter"]);
const statusOptions = [
  { name: "Active", value: true },
  { name: "Inactive", value: false },
];

watchEffect(() => {
  if (props.resetFilters?.resetPatientFilter) {
    filteredPatientName.value = "";
  }

  if (props.resetFilters?.resetProviderFilter) {
    filteredProviderName.value = "";
  }
});

watch(dateRangePicker, (newDateRange) => {
  dateRange.value = newDateRange;
  datePickerOpen.value = false;
  isDateSelected.value = !isEmpty(newDateRange);
  applyFilter();
});

const applyFilter = () => {
  const [fromDate, toDate] = dateRange.value || ['', ''];
  emit("applyFilter", {
    patientName: filteredPatientName.value,
    providerName: filteredProviderName.value,
    pendingProgramConsents: filteredPendingConsents.value,
    activeSubscribedPrograms: filteredActiveSubscribedPrograms.value,
    activeStatus: filteredActiveStatus.value,
    fromDate: isDateSelected.value ? moment(fromDate).startOf('day').toISOString()! : '',
    toDate: isDateSelected.value ? moment(toDate).endOf('day').toISOString()! : '',
  });
};

const disableFutureDates = (date: Date) => {
  return date >= new Date();
};

const removedValue = () => {
  filteredPatientName.value = props.removedPatientNameValue as string;
  filteredProviderName.value = props.removedProviderNameValue as string;
  filteredPendingConsents.value = [];
  filteredActiveSubscribedPrograms.value = [];
  filteredActiveStatus.value = [];
  isDateSelected.value = false;
  applyFilter();
};
</script>

<style scoped>
.dp__flex_display {
  display: block !important;
}

.date-range-bigscreen {
  height: 500px;
  width: 500px
}

.date-range-smallscreen {
  height: 550px;
  width: 300px;
}
</style>
