<template>
  <v-card class="grap-card" flat color="surface">
    <div id="chart">
      <div class="ml-5 text-subtitle-2 font-weight-bold">HR&nbsp;<span class="font-weight-light">(bpm)</span>
      </div>
      <VueApexCharts type="line" height="150px" class="mr-8 ml-3" :options="hrChartOptions" :series="sortedHRDataPoints">
      </VueApexCharts>
    </div>
    <v-snackbar color="error" class="text-white mt-16" v-model="showLoadThresholdError" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadThresholdError = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import VueApexCharts from "vue3-apexcharts";
import { PropType, ref, onMounted, computed } from 'vue';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { VITAL } from '@/enums/readings';
import { IPatientVitalConfigureResponse, IVitalConfigure } from "@/interfaces/IVitalConfigure";


const props = defineProps({
  hrDataPoints: {
    type: Object as PropType<IGraphDataPoint[]>,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true

  },
})

const fetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const showLoadThresholdError = ref(false);
const errorMessage = ref("");

const loadVitalsThresholdConfig = async () => {
  try {
    const thresholdConfigRes = await props.patientVitalConfigLoader(props.patientId, VITAL.PULSE);
    if (thresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      fetchVitalsConfigurationData.value = thresholdConfigRes.getVitalsThresholdConfigForPatient;
    }
  } catch (error) {
    const { message } = error as Error;
    showLoadThresholdError.value = true;
    errorMessage.value = message;
  }
}

const sortedHRDataPoints = computed(() => {
  return props.hrDataPoints.map(dataPoint => ({
    ...dataPoint,
    data: dataPoint.data.sort((readingA, readingB) => {
      const readingAEpoch = readingA[0];
      const readingBEpoch = readingB[0];
      return readingAEpoch - readingBEpoch;
    })
  }));
});

const hrChartOptions = ref({
  chart: {
    id: props.id ?? uuidv4(),

    type: 'line',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD') }
    },
    tooltip: {
      formatter: (date: moment.MomentInput) => { return moment(date).format('MMM-DD (hh:mm a)') }
    }
  },
  markers: {
    size: [4, 6]
  },
  colors: ['#F75E38'],
} as ApexChart);

onMounted(async () => {
  await loadVitalsThresholdConfig()
});

</script>

<style lang="scss" scoped>
#chart {
  margin-right: 15px;
}
</style>
