<template>
  <v-dialog v-model="showDialog" persistent max-width="1600">
    <v-card flat class="px-4 h-100 w-100" color="surface">
      <v-card-item>
        <template v-slot:title>
          <div class="d-flex align-center items-center text-primary">
            <v-icon size="large">mdi-auto-fix</v-icon>
            <div class="d-flex flex-wrap">
              <span class="ml-4">Optimized Reimbursement</span>
              <span class="ml-4 v-card-subtitle flex-1-1-100">
                Review billing reimbursement optimized by AI</span>
            </div>
            <v-spacer />
          </div>
        </template>
      </v-card-item>
      <v-divider thickness="1" class="mt-4" />
      <v-card-text class="bg-surfContainer">
        <v-container fluid style="max-height: 75vh; overflow-y: auto">
          <v-row no-gutters>
            <v-col md="5" class="d-flex align-center justify-center">
              <v-skeleton-loader v-if="loading" type="card, heading, list-item-three-line" class="w-75" />
              <v-card v-else class="pa-2 text-primary" flat color="surface" variant="flat" elevation="3">
                <v-card-title>
                  <h6 class="text-h6 text-center font-weight-black">
                    AI Optimized Reimbursement
                  </h6>
                  <v-divider thickness="1" class="my-1" />
                </v-card-title>
                <v-card-text class="d-flex justify-start">
                  <v-row>
                    <v-col class="d-flex flex-column align-center">
                      <div>
                        <div class="d-flex justify-start align-center my-2">
                          <div :class="`text-${totalRevenueTrendColor}  text-left`">
                            <h3 :class="`text-h3 text-${totalRevenueTrendColor} font-weight-black`">
                              ${{ emulatedTotalRevenue.toFixed(2) }}
                            </h3>
                            <span class="text-subtitle-1 font-weight-black">Optimized Billable</span>
                          </div>
                          <v-icon size="x-large" class="font-weight-black text-h2" :color="totalRevenueTrendColor">{{
                            totalRevenueTrendIcon }}</v-icon>
                        </div>
                        <v-divider thickness="1" class="my-2" />
                        <div class="d-flex justify-start align-center my-2">
                          <div :class="`text-${basicRevenueTrendColor}  text-left`">
                            <h3 :class="`text-h3 text-${basicRevenueTrendColor} font-weight-black`">
                              ${{ currentBillingInfo.totalPotentialRevenue.toFixed(2) }}
                            </h3>
                            <span class="text-subtitle-1 font-weight-black">Basic Billable</span>
                          </div>
                          <v-icon size="x-large" class="font-weight-black text-h2" :color="basicRevenueTrendColor">{{
                            basicRevenueTrendIcon }}</v-icon>
                        </div>

                      </div>
                    </v-col>
                    <v-divider thickness="1" inset class="mt-6" vertical></v-divider>
                    <v-col class="d-flex flex-column align-center">
                      <div class="text-h5 font-weight-black my-2">
                        <div class="d-flex align-center justify-start">
                          <span :class="`text-h6 text-${totalRevenueTrendColor} font-weight-black`">Billable</span>
                          <v-chip :color="totalRevenueTrendColor" class="mx-2">
                            <span class="text-h6 font-weight-bold">
                              {{ totalRevenueTrendDelta }}
                            </span>
                          </v-chip>
                          <v-icon size="x-large" class="font-black" :color="totalRevenueTrendColor">{{
                            totalRevenueTrendIcon }}</v-icon>
                        </div>
                        <v-divider thickness="1" class="my-1" />
                        <div class="d-flex align-center justify-start">
                          <span :class="`text-h6 text-${staffRevenueTrendColor} font-weight-black`">Staff</span>
                          <v-chip :color="staffRevenueTrendColor" class="mx-2">
                            <span class="text-h6 font-weight-bold">
                              {{ staffRevenueTrendDelta }}
                            </span>
                          </v-chip>
                          <v-icon size="x-large" class="font-black" :color="staffRevenueTrendColor">{{
                            staffRevenueTrendIcon }}</v-icon>
                        </div>
                        <v-divider thickness="1" class="my-1" />
                        <div class="d-flex align-center justify-start">
                          <span :class="`text-h6 text-${qhcpRevenueTrendColor} font-weight-black`">QHCP</span>
                          <v-chip :color="qhcpRevenueTrendColor" class="mx-2">
                            <span class="text-h6 font-weight-bold">
                              {{ qhcpRevenueTrendDelta }}
                            </span>
                          </v-chip>
                          <v-icon size="x-large" class="font-black" :color="qhcpRevenueTrendColor">{{
                            qhcpRevenueTrendIcon }}</v-icon>
                        </div>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="7" class="d-flex justify-center">
              <v-skeleton-loader v-if="loading" type="card, heading,  image" width="650" />
              <v-card v-else color="surface" variant="flat" elevation="3" max-width="650">
                <v-card-title>
                  <h6 class="text-h6 text-center font-weight-black">
                    Optimization Breakdown
                  </h6>
                  <v-divider thickness="1" class="my-1" />
                </v-card-title>
                <v-card-item class="w-100 d-flex justify-center align-center">
                  <div style="width: 600px;">
                    <apexchart width="580" class="compliance-bar-chart " type="bar" :options="chartOptions"
                      :series="chartDataSeries">
                    </apexchart>
                  </div>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider thickness="1" class="my-4" />
      <v-skeleton-loader v-if="loading" type="button, sentences, actions" class="w-75" />
      <v-card-actions v-else class="d-flex justify-end align-center">
        <v-checkbox color="primary" v-model="isOptimisedBillingAcknowledged">
          <template v-slot:label>
            I accept the results of auto optimized billing. These results will
            be reviewed by our practice before submission for reimbursement.
          </template>
        </v-checkbox>
        <v-spacer />
        <v-btn density="default" rounded="false" class="mx-2" variant="outlined" @click="closeBillingConfigForm">
          Cancel
        </v-btn>
        <v-btn density="default" rounded="false" class="mx-2 px-4" variant="elevated"
          :disabled="!isOptimisedBillingAcknowledged" @click="persistSelectedConfiguration">
          Use Optimization
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { BillingCalculationStrategy } from "@/enums/billing.enum";
import { Program } from "@/enums/patient-program.enum";
import {
  CPTCode,
  IBillingCPTDistribution,
  ICurrentBillingInfo,
  IEmulatedOrgCycleBilling,
  IOrgBillingConfiguration,
  IOrgBillingConfigurationDependencies,
  ProgramBucketDistribution,
} from "@/interfaces/billing.interface";
import { ICycle } from "@/interfaces/cycle.interface";
import { IAdditionalBillingInfo } from "@/interfaces/patient-program-cycle.interface";
import { filter, flatten, forEach, includes, map, round } from "lodash";
import moment from "moment";
import { computed, onMounted, PropType, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";



const chartOptions = {
  colors: ['#D0BCFF', '#B3261E', '#6750A4', '#4caf50', '#49454F'],
  chart: {
    type: 'bar',
    toolbar: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: true,
          position: 'top',
        },
      }
    },
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 2,
      right: 4,
      blur: 10,
      opacity: 0.2
    },

  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1, 3],
    offsetY: -20,
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    background: {
      enabled: true,
      foreColor: '#6750A4',
      borderRadius: 2,
      padding: 2,
      opacity: 0.8,
      borderWidth: 2,

    },
    formatter: function (val: number, opt: { seriesIndex: number }) {
      return opt.seriesIndex === 1 || opt.seriesIndex === 3 ? `$${val.toFixed(2)}` : `${val.toFixed(1)}`;
    }
  },
  stroke: {
    curve: 'smooth',
    width: [4, 8, 4, 8],
  },
  grid: {
    borderColor: '#49454f',
    row: {
      colors: ['#F5F5F5', '#F2F2F7'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  markers: {
    size: [0, 4, 0, 4]
  },
  xaxis: {
    categories: ["QHCP", "STAFF", "PRACTICE"],
  },
  yaxis: [{
    seriesName: 'Basic Billable Time',
    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
    },
    labels: {
      formatter: function (val: number) {
        return `${val.toFixed(1)} mins`
      }
    }, title: {
      text: "Time Spent (minutes)",
    },

  }, {
    seriesName: 'Basic Reimbursement',
    opposite: true,
    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
    },
    labels: {
      formatter: function (val: number) {
        return `$${val.toFixed(2)}`
      }
    },
    title: {
      text: "Reimbursement (US$)",
    },

  },
  {
    seriesName: 'Basic Billable Time',
    show: false,
    labels: {
      formatter: function (val: number) {
        return `${val.toFixed(1)} mins`
      }
    },
  },
  {
    seriesName: 'Basic Reimbursement',
    opposite: true,
    show: false,
    labels: {
      formatter: function (val: number) {
        return `$${val.toFixed(2)}`
      }
    },
  },
  {
    seriesName: 'Basic Billable Time',
    show: false,
    labels: {
      formatter: function (val: number) {
        return `${val.toFixed(1)} mins`
      }
    },
  },
  ],
  tooltip: {
    shared: true,
    intersect: false
  },
};

const showDialog = ref(true);
const billingConfiguration = ref({
  billingStrategy:
    BillingCalculationStrategy.REDISTIBUTE_UNBILLABLE_QHCP_AND_STAFF_MINUTES,
} as IOrgBillingConfiguration);
const emulatedQHCPRevenue = ref(0);
const emulatedSTAFFRevenue = ref(0);
const emulatedOTHERRevenue = ref(0);
const emulatedTotalRevenue = ref(0);
const emulatedTotalTimeSpentQHCP = ref(0);
const emulatedTotalTimeSpentStaff = ref(0);
const loading = ref(false);
const isOptimisedBillingAcknowledged = ref(true);

const chartDataSeries = computed(() => {
  const optQHCPMins = Number(moment.duration(emulatedTotalTimeSpentQHCP.value).asMinutes().toFixed(1));
  const optStaffMins = Number(moment.duration(emulatedTotalTimeSpentStaff.value).asMinutes().toFixed(1));
  const optTotalMins = Number((optQHCPMins + optStaffMins).toFixed(1));
  const basicQHCPMins = Number(moment.duration(currentBillingInfo.totalBillableTimeSpentQHCP).asMinutes().toFixed(1));
  const basicStaffMins = Number(moment.duration(currentBillingInfo.totalBillableTimeSpentStaff).asMinutes().toFixed(1));
  const basicTotalMins = Number((basicQHCPMins + basicStaffMins).toFixed(1));

  const recordedQHCPMins = Number(moment.duration(currentBillingInfo.totalTimeSpentQHCP).asMinutes().toFixed(1));
  const recordedStaffMins = Number(moment.duration(currentBillingInfo.totalTimeSpentStaff).asMinutes().toFixed(1));
  const recordedTotalMins = Number(moment.duration(currentBillingInfo.totalTimeSpent).asMinutes().toFixed(1));

  const optQHCPRevenue = Number(emulatedQHCPRevenue.value).toFixed(2);
  const optStaffRevenue = Number(emulatedSTAFFRevenue.value).toFixed(2);
  const optTotalRevenue = Number(emulatedTotalRevenue.value).toFixed(2);
  const basicQHCPRevenue = Number(currentBillingInfo.totalPotentialQHCPRevenue).toFixed(2);
  const basicStaffRevenue = Number(currentBillingInfo.totalPotentialSTAFFRevenue).toFixed(2);
  const basicTotalRevenue = Number(currentBillingInfo.totalPotentialRevenue).toFixed(2);

  return [{
    name: 'Basic Billable Time',
    type: 'bar',
    data: [basicQHCPMins, basicStaffMins, basicTotalMins]
  },
  {
    name: 'Basic Reimbursement',
    type: 'line',

    data: [basicQHCPRevenue, basicStaffRevenue, basicTotalRevenue]
  },
  {
    name: 'Optimized Billable Time',
    type: 'bar',
    group: 'time',
    data: [optQHCPMins, optStaffMins, optTotalMins]
  },
  {
    name: 'Optimized Reimbursement',
    type: 'line',
    data: [optQHCPRevenue, optStaffRevenue, optTotalRevenue]
  },
  {
    name: 'Total Recorded Time',
    type: 'bar',
    group: 'time',
    data: [recordedQHCPMins, recordedStaffMins, recordedTotalMins]
  },
  ];
})

const emit = defineEmits(["billingConfigFormClosed"]);
const {
  billingConfigurationDependencies,
  cycle,
  currentBillingInfo,
} = defineProps({
  billingConfigurationDependencies: {
    type: Object as PropType<IOrgBillingConfigurationDependencies>,
    required: true,
  },
  orgId: {
    type: String,
    required: true,
  },
  cycle: {
    type: Object as PropType<ICycle>,
    required: true,
  },
  currentBillingInfo: {
    type: Object as PropType<ICurrentBillingInfo>,
    required: true,
  },
});

const {
  emulateOrgBillingWithCustomSettings,
  persistEmulatedOrgBilling,
} = billingConfigurationDependencies;

const closeBillingConfigForm = () => {
  showDialog.value = false;
  emit("billingConfigFormClosed");
};

const emulateBillingWithSelectedConfiguration = async () => {
  loading.value = true;
  const emulatedOrgCycleBilling = await emulateOrgBillingWithCustomSettings(
    cycle,
    billingConfiguration.value,
  );
  setTotalEmulatedRemibursement(emulatedOrgCycleBilling, currentBillingInfo);
  loading.value = false;
};

const persistSelectedConfiguration = async () => {
  loading.value = true;
  await persistEmulatedOrgBilling(
    cycle,
    billingConfiguration.value,
    isOptimisedBillingAcknowledged.value,
  );
  loading.value = false;
  closeBillingConfigForm();
};

const isStaffEmulatedAmountHigher = computed(() => {
  return (
    emulatedSTAFFRevenue.value > currentBillingInfo.totalPotentialSTAFFRevenue
  );
});

const isQHCPEmulatedAmountHigher = computed(() => {
  return (
    emulatedQHCPRevenue.value > currentBillingInfo.totalPotentialQHCPRevenue
  );
});

const staffDeltaAmount = computed(() => {
  return round(
    emulatedSTAFFRevenue.value - currentBillingInfo.totalPotentialSTAFFRevenue,
    2,
  );
});

const qhcpDeltaAmount = computed(() => {
  return round(
    emulatedQHCPRevenue.value - currentBillingInfo.totalPotentialQHCPRevenue,
    2,
  );
});

const totalAmountDelta = computed(() => {
  return round(
    emulatedTotalRevenue.value - currentBillingInfo.totalPotentialRevenue,
    2,
  );
});

const isTotalEmulatedAmountHigher = computed(() => {
  return emulatedTotalRevenue.value > currentBillingInfo.totalPotentialRevenue;
});

const totalRevenueTrendColor = computed(() => {
  return totalAmountDelta.value === 0
    ? 'onSurfaceVar'
    : isTotalEmulatedAmountHigher.value
      ? 'success'
      : 'error'
});

const totalRevenueTrendIcon = computed(() => {
  return isTotalEmulatedAmountHigher.value && totalAmountDelta.value !== 0
    ? 'mdi-arrow-up-thin'
    : totalAmountDelta.value !== 0
      ? 'mdi-arrow-down-thin'
      : 'mdi-equal';
});


const basicRevenueTrendColor = computed(() => {
  return totalAmountDelta.value === 0
    ? 'onSurfaceVar'
    : !isTotalEmulatedAmountHigher.value
      ? 'success'
      : 'error'
});

const basicRevenueTrendIcon = computed(() => {
  return !isTotalEmulatedAmountHigher.value && totalAmountDelta.value !== 0
    ? 'mdi-arrow-up-thin'
    : totalAmountDelta.value !== 0
      ? 'mdi-arrow-down-thin'
      : 'mdi-equal';
});


const totalRevenueTrendDelta = computed(() => {
  const totatlDelta = totalAmountDelta.value;
  return `${totatlDelta >= 0 ? "$" : "-$"}${(totatlDelta >
    0
    ? totatlDelta
    : totatlDelta * -1
  ).toFixed(2)}`;
});

const staffRevenueTrendColor = computed(() => {
  return staffDeltaAmount.value === 0
    ? 'onSurfaceVar'
    : isStaffEmulatedAmountHigher.value
      ? 'success'
      : 'error'
});

const staffRevenueTrendIcon = computed(() => {
  return isStaffEmulatedAmountHigher.value && staffDeltaAmount.value !== 0
    ? 'mdi-arrow-up-thin'
    : staffDeltaAmount.value !== 0
      ? 'mdi-arrow-down-thin'
      : 'mdi-equal';
});

const staffRevenueTrendDelta = computed(() => {
  const staffDelta = staffDeltaAmount.value;
  return `${staffDelta >= 0 ? "$" : "-$"}${(staffDelta >
    0
    ? staffDelta
    : staffDelta * -1
  ).toFixed(2)} `;
});


const qhcpRevenueTrendColor = computed(() => {
  return qhcpDeltaAmount.value === 0
    ? 'primary'
    : isQHCPEmulatedAmountHigher.value
      ? 'success'
      : 'error'
});

const qhcpRevenueTrendIcon = computed(() => {
  return isQHCPEmulatedAmountHigher.value && qhcpDeltaAmount.value !== 0
    ? 'mdi-arrow-up-thin'
    : qhcpDeltaAmount.value !== 0
      ? 'mdi-arrow-down-thin'
      : 'mdi-equal';
});

const qhcpRevenueTrendDelta = computed(() => {
  const qhcpDelta = qhcpDeltaAmount.value;
  return `${qhcpDelta >= 0 ? "$" : "-$"}${(qhcpDelta >
    0
    ? qhcpDelta
    : qhcpDelta * -1
  ).toFixed(2)} `;
});


const setTotalEmulatedRemibursement = (
  data: IEmulatedOrgCycleBilling[],
  existingBillingInfo: ICurrentBillingInfo,
) => {
  let totalQHCPRevenue = 0;
  let totalSTAFFRevenue = 0;
  let totalOTHERRevenue = 0;
  let totalQHCPTime = 0;
  let totalSTAFFTime = 0;
  let totalRevenue = 0;
  forEach(data, (patientBilling) => {
    const { billingDistributions, additionalBillingInfo } = patientBilling;
    const {
      patientTotalQHCPRevenue,
      patientTotalSTAFFRevenue,
      patientTotalOTHERRevenue,
      patientQHCPTime,
      patientSTAFFTime,
    } = getTotalRevenue(
      existingBillingInfo.billingProgramCodes,
      billingDistributions,
      additionalBillingInfo,
    );

    totalQHCPRevenue += patientTotalQHCPRevenue;
    totalSTAFFRevenue += patientTotalSTAFFRevenue;
    totalOTHERRevenue += patientTotalOTHERRevenue;
    totalRevenue += patientTotalQHCPRevenue + patientTotalSTAFFRevenue + patientTotalOTHERRevenue;
    totalQHCPTime = totalQHCPTime + patientQHCPTime;
    totalSTAFFTime = totalSTAFFTime + patientSTAFFTime;
  });
  emulatedQHCPRevenue.value = totalQHCPRevenue;
  emulatedTotalRevenue.value = totalRevenue;
  emulatedSTAFFRevenue.value = totalSTAFFRevenue;
  emulatedOTHERRevenue.value = totalOTHERRevenue;
  emulatedTotalTimeSpentQHCP.value = totalQHCPTime;
  emulatedTotalTimeSpentStaff.value = totalSTAFFTime;
};

const getTotalRevenue = (
  programCodes: { [key in Program]: CPTCode[] },
  billingDistributions: IBillingCPTDistribution[],
  additionalBillingInfo: IAdditionalBillingInfo,
) => {
  let patientTotalQHCPRevenue = 0;
  let patientTotalSTAFFRevenue = 0;
  let patientTotalOTHERRevenue = 0;
  let patientQHCPTime = 0;
  let patientSTAFFTime = 0;

  const progDataCells = flatten(
    map(programCodes, (codes, program) => {
      const knownCodes = map(codes, "code");
      const distBucketKeys = {
        [Program.CCM]: "ccmBucket",
        [Program.CCCM]: "cccmBucket",
        [Program.PCM]: "pcmBucket",
        [Program.RPM]: "rpmBucket",
      };
      const prgDistBucketKey = distBucketKeys[program as Program];
      const qualifyingDistributions = filter(
        billingDistributions,
        (distribution) => {
          return (
            !!distribution[prgDistBucketKey as keyof IBillingCPTDistribution] &&
            includes(knownCodes, distribution.cptCode.code)
          );
        },
      );
      let progRevenueQHCPTotal = 0;
      let progRevenueSTAFFTotal = 0;
      let progRevenueOTHERTotal = 0;
      let progQHCPTime = 0;
      let progSTAFFTime = 0;
      forEach(qualifyingDistributions, (distribution) => {
        const { cptCode } = distribution;
        const prgBucket = distribution[
          prgDistBucketKey as keyof IBillingCPTDistribution
        ] as ProgramBucketDistribution;
        const billableBucket = additionalBillingInfo[program]
          ? additionalBillingInfo[program].billableBucket
          : "";
        if (cptCode.bucket === "QHCP") {
          progQHCPTime =
            progQHCPTime +
            (billableBucket === "QHCP" ? prgBucket.timeInCycle : 0);
          progRevenueQHCPTotal =
            progRevenueQHCPTotal +
            (billableBucket === "QHCP" ? prgBucket.revenue : 0);
        } else if (cptCode.bucket === "STAFF") {
          progSTAFFTime =
            progSTAFFTime +
            (billableBucket === "STAFF" ? prgBucket.timeInCycle : 0);
          progRevenueSTAFFTotal =
            progRevenueSTAFFTotal +
            (billableBucket === "STAFF" ? prgBucket.revenue : 0);
        } else if (cptCode.bucket === "OTHER") {
          progRevenueOTHERTotal += prgBucket.revenue;
        }
        return {
          [`${program.toLowerCase()}${cptCode.code}`]: prgBucket.billingCount,
        };
      });
      patientTotalQHCPRevenue += progRevenueQHCPTotal;
      patientTotalSTAFFRevenue += progRevenueSTAFFTotal;
      patientTotalOTHERRevenue += progRevenueOTHERTotal;
      patientQHCPTime = patientQHCPTime + progQHCPTime;
      patientSTAFFTime = patientSTAFFTime + progSTAFFTime;
    }),
  );
  return {
    progDataCells,
    patientTotalQHCPRevenue,
    patientTotalSTAFFRevenue,
    patientTotalOTHERRevenue,
    patientQHCPTime,
    patientSTAFFTime,
  };
};


onMounted(() => {
  emulateBillingWithSelectedConfiguration();
});
</script>
<style>
.compliance-bar-chart {
  width: 400px;
}

.total-time-bar-chart {
  height: 100px;
}
</style>
