<template>
  <div class="text-center chips-container">
    <v-chip class="mr-1 mb-1" v-if="$props.filteredPatientChip.patientName" closable rounded @click:close="closeChip">
      Name: {{ name }}
    </v-chip>
    <v-chip class="mr-1 mb-1" v-if="$props.filteredPatientChip.providerName" closable rounded
      @click:close="closeProviderNameChip">
      Primary Provider Name: {{ providerName }}
    </v-chip>
    <v-chip class="mr-1 mb-1" v-if="$props.filteredPatientChip.dob" closable rounded @click:close="closeDOBChip">
      DOB: {{ dob }}
    </v-chip>
    <v-chip class="mr-1 mb-1" v-if="patientEvent.startDate !== ''" closable rounded @click:close="closeEventChip">
      Date Range: {{ patientEvent.startDate }} - {{ patientEvent.endDate }}
    </v-chip>
    <v-chip class="mr-1 mb-1" v-if="$props.filteredPatientChip.patientWithoutDevice" closable rounded
      @click:close="closePatientDeviceChip">
      Patients without a device
    </v-chip>
  </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref, watch } from "vue";
import { IPatientFilterChip } from "../interfaces/patient.interface";
import moment from "moment";
import { assign } from "lodash";

const name = ref("");
const providerName = ref('');
const dob = ref('');
const patientFilterChip = reactive({} as IPatientFilterChip);
const patientEvent = reactive({
  eventName: "",
  startDate: "",
  endDate: "",
});

const emit = defineEmits(["closePatientChip", "closePatientEventChip", "closePatientDeviceChip", "closeProviderNameChip", "closeDOBChip"]);

const closeChip = () => {
  patientFilterChip.patientName = "";
  emit("closePatientChip", patientFilterChip.patientName);
};

const closePatientDeviceChip = () => {
  patientFilterChip.patientWithoutDevice = false;
  emit("closePatientDeviceChip", patientFilterChip.patientWithoutDevice);
};

const closeEventChip = () => {
  assign(patientEvent, { eventName: "", startDate: "", endDate: "" });
  emit("closePatientEventChip", patientEvent);
};

const closeProviderNameChip = () => {
  patientFilterChip.providerName = "";
  emit("closeProviderNameChip", patientFilterChip.providerName);
};

const closeDOBChip = () => {
  patientFilterChip.dob = "";
  emit("closeDOBChip", patientFilterChip.dob);
};

const props = defineProps({
  filteredPatientChip: {
    type: Object as PropType<IPatientFilterChip>,
    required: true,
  },
});

watch(
  () => props.filteredPatientChip,
  (newValue) => {
    name.value = newValue.patientName ?? "";
    providerName.value = newValue.providerName ?? "";
    dob.value = newValue.dob ?? "";
    patientEvent.eventName = newValue.eventName ?? "";
    patientEvent.startDate = newValue.startDate ?
      moment(newValue.startDate).format('MM/DD/YYYY  hh:mmA') : "";
    patientEvent.endDate = newValue.endDate ?
      moment(newValue.endDate).format('MM/DD/YYYY  hh:mmA') : "";
  },
);

onMounted(() => {
  if (props.filteredPatientChip) {
    name.value = props.filteredPatientChip.patientName;
    providerName.value = props.filteredPatientChip.providerName;
    dob.value = props.filteredPatientChip.dob
  }
});
</script>

<style scoped>
.chips-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: flex;
}
</style>
