import { ProgramCycleType } from "@/enums/patient-program-cycle.enum";

export const getCurrentCycleDates = (cycleType?: ProgramCycleType) => {
    if (cycleType === ProgramCycleType.MONTHLY) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const currentMonthStartDate = new Date(currentYear, currentMonth, 1);
        const currentMonthEndDate = new Date(
            currentYear,
            currentMonth + 1,
            0
        );
        const currentMonthStartEpoch = currentMonthStartDate.getTime();
        const currentMonthEndEpoch = currentMonthEndDate.getTime();
        const currentMonthStart = new Date(currentMonthStartEpoch);
        const currentMonthEnd = new Date(currentMonthEndEpoch);
        const currentMonthStartString = currentMonthStart.toLocaleDateString();
        const currentMonthEndString = currentMonthEnd.toLocaleDateString();
        const currentMonthStartEpochString = currentMonthStartEpoch.toString();
        const currentMonthEndEpochString = currentMonthEndEpoch.toString();

        const currentCycleDates = {
            currentMonthStart,
            currentMonthEnd,
            currentMonthStartString,
            currentMonthEndString,
            currentMonthStartEpochString,
            currentMonthEndEpochString,
        };

        return currentCycleDates;
    } else {
        throw new Error('Custom cycle dates not implemented yet');
    }

};