<script setup lang="ts">

import { ref } from 'vue';
import OrganizationUserForm from './OrganizationUserForm.vue';
import OrganizationUserList from './OrganizationUserList.vue';
import OrganizationForm from './OrganizationForm.vue';
import { watch } from 'vue';
import router from '../router';
import { IOrganizationUserDetails } from '@/interfaces/IOrganization';

const step = ref(1);
const btnLoader = ref(false);
const showOrgForm = ref(true);
const showOrgUserForm = ref(false);
const showOrgUserList = ref(false);

const orgUserList = ref([] as any[]);
const orgUserSelected = ref<IOrganizationUserDetails>();
const orgUserSelectedIndex = ref(-1);
const organizationId = ref()

function onOrgCreated(orgId:string){
    organizationId.value = orgId;
    showOrgForm.value = false;
    showOrgUserForm.value = true;
}

function onUserAdded(orgUser: any) {
    showOrgUserForm.value = false;
    showOrgUserList.value = true;
    orgUserList.value.push(orgUser.value);
}


function onUserEdited(orgUser: any) {
    if(orgUserSelectedIndex.value>=0) {
        orgUserList.value[orgUserSelectedIndex.value] = orgUser.value
        showOrgUserForm.value = false;
        showOrgUserList.value = true;
    }
}

function editOrgUser(orgUserIndex: any) {
    const user = {...orgUserList.value[orgUserIndex]}
    orgUserSelectedIndex.value = orgUserIndex;
    orgUserSelected.value = user;
    showOrgUserForm.value = true;
    showOrgUserList.value = false;
}

function deleteOrguser(orgUserIndex: any) {
    orgUserSelected.value = undefined;
    orgUserList.value.splice(orgUserIndex, 1);
}

function cancelOrgUserCreation() {
    if(orgUserList.value.length>0) {
        showOrgUserForm.value = false;
        showOrgUserList.value = true;
    } else {
        router.push({name:"OrganizationList"});
    }
}

function cancelOrgUserCreationFromList() {
    router.push({name:"OrganizationList"});
}

const backToOrgUserForm = () => {
    orgUserSelected.value = undefined;
    showOrgUserForm.value = true;
    showOrgUserList.value = false;
    showOrgForm.value = false;
}

defineExpose({
    step,
    btnLoader
});

watch(orgUserList.value,(newList)=>{
    if(newList.length===0 && !showOrgForm.value) {
        showOrgUserList.value = true;
        showOrgUserForm.value = true;
    }
});
</script>


<template>
    <div>
        <OrganizationForm v-if="showOrgForm" :is-stepper-form="true" @organization-created="onOrgCreated" />
        <OrganizationUserForm v-else-if="showOrgUserForm"  :orgUserList="orgUserList" :orgUserDetail="orgUserSelected" :organizationId="organizationId" :is-stepper-form="true"
            @user-added="onUserAdded" @user-edited="onUserEdited" @cancel-org-creation="cancelOrgUserCreation()" />
        <OrganizationUserList v-else-if="showOrgUserList" :orgUserList="orgUserList" @to-org-user-form="backToOrgUserForm()"
            @edit-org-user="editOrgUser" @delete-org-user="deleteOrguser" @cancel-user-creation="cancelOrgUserCreationFromList()"/>
    </div>
</template>

<style scoped>
.org-profile-card {
    width: 70%;
    margin: 0 auto;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 5%;
}

.btn-container {
    width: 300px;
    margin: 0 auto;
}
</style>
