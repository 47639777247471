import gql from 'graphql-tag';

export const LIST_SOURCE_DEFINITIONS = gql`
    query listSourceDefinitionsByServiceProviderId($serviceProviderId: String!){
        sourceDefinition{
            listSourceDefinitionsByServiceProviderId(serviceProviderId: $serviceProviderId){
                sourceDefinitionId
                sourceType
            }
        } 
    }
`;