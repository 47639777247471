<template>
  <v-card flat class="w-100 mx-2" color="surfContainer" elevation="2">
    <v-card-item class="bg-surface pb-0 mb-2">
      <template v-slot:title>
        <div>
          <v-row class="d-flex align-center items-center text-primary">
            <v-col cols="3" class="d-flex align-center items-center text-primary">
              <v-icon size="large">mdi-warehouse</v-icon>
              <div class=" d-flex flex-wrap">
                <span class="ml-4"> Inventory </span>
                <span class="ml-4 v-card-subtitle flex-1-1-100">Add and manage devices for your organization</span>
              </div>
            </v-col>
            <v-col cols="7">
              <div class=" d-flex justify-end align-center">
                <inventory-filters :service-providers="serviceProviders" @inventory-items="handleInventoryItemFilter"
                  @get-inventory-item="getInventoryItem(serviceProviderId)" />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex justify-end align-center my-2">
                <v-tooltip location="bottom"> <template v-slot:activator="{ props }"> <v-btn v-bind="props" rounded
                      prepend-icon="mdi-filter-remove" size="x-small" variant="tonal" elevation="3"
                      @click="clearFilters" icon="mdi-filter-remove" class="mx-4" /> </template>Clear
                  Filters</v-tooltip>

                <v-dialog v-model="showAddDeviceForm" persistent>
                  <template v-slot:activator>
                    <v-btn color="primary" @click="openDeviceForm" :disabled="isAddDeviceButtonDisabled"
                      rounded="false">
                      <v-icon size="large">mdi-plus</v-icon>
                      <span>Add Device</span>
                    </v-btn>
                  </template>
                  <component :is="inventoryDeviceForm" @device-added="createDevice"
                    @add-device-cancelled="closeCreationForm" @edit-inventory-device="editInventoryDevice"
                    :inventory-item-input="inventoryItemInput" :service-providers="selectedServiceProvider"
                    :inventory-item-data="inventoryItemData" />
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider thickness="1" class="my-2" />
      </template>
    </v-card-item>
    <v-card-text>
      <InventoryList :inventory-items="inventoryItems" :patient-input="patientInput"
        :assign-inventory-device="assignInventoryDevice" @get-inventory-items="handleInventoryItem"
        :un-assign-device-handler="unAssignDeviceHandler" :remove-item-from-inventory="removeItemFromInventory"
        :enable-inventory-item="enableInventoryItem" :short-code="shortCode" @edit-device="showDeviceEditForm"
        :patient-details-dependencies="patientDependencies" :inventory-loading="loading" />
    </v-card-text>
    <v-snackbar color="success" v-model="showSuccessMessage" class="mt-16" location="top right">{{ successMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" v-model="showErrorMessage" class="mt-16" location="top right">{{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import { ref, PropType, onMounted, shallowRef } from 'vue';
import {
  IInventoryItemInput,
  IServiceProviderOption,
  IServiceProvidersInput,
  IInventoryItemData,
  IDeviceInput,
  IInventoryDeviceInput,
  IAssignPatientInput,
  EditDeviceInput,
  IListInventoryFilter,
} from "../../interfaces/InventoryItem.interface";
import InventoryList from './InventoryList.vue'
import { debounce, get, isEmpty, pick } from 'lodash';
import { IRequestorInput } from '@/interfaces/task.interface';
import { useRoute } from "vue-router";
import { IOrgServiceProvider } from '@/interfaces/IOrganization';
import { getInventoryDeviceAddForm } from '@/composables/serviceprovider.composable';
import InventoryFilters from './InventoryFilter.vue';
import { IPatientData } from '@/interfaces/Service.interface';

const showAddDeviceForm = ref(false);
const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('')
const successMessage = ref('');
const loading = ref(false);
const route = useRoute();
const orgId = ref(route.params["orgId"] as string);
const inventoryDeviceForm = shallowRef();
const shortCode = ref('');
const inventoryItemFilter = ref({ deviceDetails: '', patientName: '' } as IListInventoryFilter);

const props = defineProps({
  serviceProvidersInput: {
    type: Object as PropType<IServiceProvidersInput>,
    required: true,
  },
  getInventoryItems: {
    type: Function as PropType<(serviceProviderId: string, inventoryItemFilter: IListInventoryFilter, signal: unknown) => Promise<IInventoryItemData[]>>,
    required: true,
  },
  inventoryItemInput: {
    type: Object as PropType<IInventoryItemInput>,
    required: true,
  },
  createInventoryDevice: {
    type: Function as PropType<(deviceInput: IDeviceInput) => Promise<IInventoryDeviceInput>>,
    required: true,
  },
  unAssignDeviceHandler: {
    type: Function as PropType<(itemId: string) => Promise<IInventoryItemData>>,
    required: true,
  },
  patientInput: {
    type: Object as PropType<IRequestorInput>,
    required: true,
  },
  assignInventoryDevice: {
    type: Function as PropType<(itemId: string, input: IAssignPatientInput) => Promise<IInventoryDeviceInput>>,
    required: true,
  },
  removeItemFromInventory: {
    type: Function as PropType<(itemId: string) => Promise<boolean>>,
    required: true,
  },
  enableInventoryItem: {
    type: Function as PropType<(itemId: string) => Promise<boolean>>,
    required: true,
  },
  disableAddDeviceHandler: {
    type: Function as PropType<(orgId: String) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },
  editInventoryDevice: {
    type: Function as PropType<(input: EditDeviceInput) => Promise<IInventoryItemData>>,
    required: true,
  },
  patientDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
});

const serviceProviders = ref([] as IServiceProviderOption[]);
const selectedServiceProvider = ref([] as IServiceProviderOption[]);
const inventoryItems = ref([] as IInventoryItemData[])
const serviceProviderId = ref('');
const isAddDeviceButtonDisabled = ref(true);
const queryServiceProviderId = ref(route.query.serviceProviderId as string);
const inventoryItemData = ref<IInventoryItemData>();
let controller: AbortController | null = null;

const handleInventoryItemFilter = debounce(async (data: IListInventoryFilter) => {
  inventoryItemFilter.value = data;
  getInventoryItem(serviceProviderId.value);
}, 600);

const clearFilters = () => {
  inventoryItemFilter.value.deviceDetails = '';
  inventoryItemFilter.value.patientName = '';
  getInventoryItem(serviceProviderId.value);
}

const getServiceProviders = async () => {
  const { dataLoader } = props.serviceProvidersInput;
  const serviceProviderDetails = await dataLoader();
  if (serviceProviderDetails) {
    serviceProviders.value = serviceProviderDetails.map((serviceProviderDetail) => ({
      title: serviceProviderDetail.serviceProviderName,
      value: serviceProviderDetail.serviceProviderId,
      shortCode: serviceProviderDetail.shortCode,
    }));
  }
};

const createDevice = async (deviceInput: IDeviceInput) => {
  try {
    showAddDeviceForm.value = false;
    loading.value = true;
    await props.createInventoryDevice(deviceInput);
    loading.value = false;
    successMessage.value = 'Device added successfully';
    showSuccessMessage.value = true;
    getInventoryItem(serviceProviderId.value);
  } catch (error) {
    loading.value = false;
    showAddDeviceForm.value = false;
    const err = error as Error;
    errorMessage.value = err.message;
    showErrorMessage.value = true;
  }
};

const handleInventoryItem = () => {
  getInventoryItem(serviceProviderId.value);
}

const getInventoryItem = async (serviceProviderId: string) => {
  selectedServiceProvider.value = [];
  if (!isEmpty(serviceProviders.value)) {
    const selectedService = serviceProviders.value.find(item => item.value === serviceProviderId) as IServiceProviderOption;
    selectedServiceProvider.value.push(selectedService);
    shortCode.value = selectedService!.shortCode;

    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    loading.value = true;
    const inventoryResponse = await props.getInventoryItems(serviceProviderId, inventoryItemFilter.value!, controller.signal);
    inventoryItems.value = inventoryResponse;
    loading.value = false;
  }
  controller = new AbortController();

  loading.value = true;
   await props.getInventoryItems(serviceProviderId, inventoryItemFilter.value!, controller.signal).then((data => {
    inventoryItems.value = data;
  })).catch((error) => {
     console.log(error.message);
  });

  loading.value = false;
}


const openDeviceForm = async () => {
  showAddDeviceForm.value = true;
  inventoryDeviceForm.value = getInventoryDeviceAddForm(shortCode.value);
  inventoryItemData.value = undefined;
};

const closeCreationForm = () => {
  showAddDeviceForm.value = false;
};

const disableAddDevice = async () => {
  const disableAddDeviceResponse = await props.disableAddDeviceHandler(orgId.value);
  isAddDeviceButtonDisabled.value = !disableAddDeviceResponse.length;
}

const showDeviceEditForm = (data: { shortCode: string, item: IInventoryItemData }) => {
  showAddDeviceForm.value = true;
  inventoryDeviceForm.value = getInventoryDeviceAddForm(data.shortCode);
  inventoryItemData.value = data.item;
}

const editInventoryDevice = async (input: EditDeviceInput) => {
  try {
    loading.value = true;
    showAddDeviceForm.value = false;
    const response = await props.editInventoryDevice(input);
    loading.value = false;
    showSuccessMessage.value = true;
    successMessage.value = 'Device details edited successfully'
    getInventoryItem(serviceProviderId.value);
    return response;
  }
  catch (error) {
    loading.value = false;
    const err = error as Error;
    showErrorMessage.value = true;
    errorMessage.value = err.message;
  }
}

onMounted(async () => {
  await getServiceProviders();
  serviceProviderId.value = queryServiceProviderId.value ? queryServiceProviderId.value : get(serviceProviders.value, '[0].value', '');
  if (serviceProviderId.value) {
    getInventoryItem(serviceProviderId.value);
  }
  await disableAddDevice();
})
</script>
<style scoped>
.service-provider-field {
  max-width: 25rem;
}
</style>
