import gql from "graphql-tag";
export const GET_ORGS = gql`
  query ($limit: Int, $offset: Int, $filter: OrganizationFilterInput) {
    listOrganizations(limit: $limit, offset: $offset, filter: $filter) {
      count
      organizations {
        orgId
        name
        npi
        orgType
        addressLine1
        addressLine2
        zip
        state
        city
        country
      }
    }
  }
`;

export const CHECK_NPI_EXITS_ORG = gql`
  query checkNPIExist($npi: String!) {
    checkNPIExist(npi: $npi)
  }
`;
