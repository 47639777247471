import {
  ACTIVITY_LIST,
  ADD_TIMELOG_CREATION,
  DELETE_TIMELOG,
  GET_USER_TIME_LOGS,
} from "@/graphql/resources/TimeLogRepository";
import {
  IAddTimeLog,
  ITimeLog,
  ITimeLogFilter,
  IUserTimeLogResponse,
} from "@/interfaces/timelog.interface";
import { IPaginationInput } from "@/interfaces/utility.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import moment from "moment";

export const createAddTimeLog = async (input: IAddTimeLog) => {
  const payload = {
    ...input,
    endTime: moment(input.endTime).toISOString()
  }
  const response = await apolloClient.mutate({
    mutation: ADD_TIMELOG_CREATION,
    variables: {
      input: payload,
    },
  });
  return response;
};

export const listActivities = async () => {
  const activityResponse = await apolloClient.query({
    query: ACTIVITY_LIST,
    variables: {},
  });

  return activityResponse.data.activities;
};

export const getUserTimeLogs = async (
  patientId: string,
  filter: ITimeLogFilter | null,
  pagination: IPaginationInput | null
): Promise<IUserTimeLogResponse> => {
  const response = await apolloClient.query({
    query: GET_USER_TIME_LOGS,
    variables: {
      patientId,
      filter,
      pagination,
    },
    fetchPolicy: "network-only",
  });
  return response.data.timeLogs;
};

export const deleteTimeLog = async (
  timeLogId: string,
  isAcknowledged: boolean
): Promise<ITimeLog> => {
  const response = await apolloClient.mutate({
    mutation: DELETE_TIMELOG,
    fetchPolicy: "network-only",
    variables: { timeLogId: timeLogId, isAcknowledged: isAcknowledged },
  });
  return response.data
}
