<template>
  <div>
    <v-data-table :class="isSnapshot ? 'bg-white' : ''" :page="currentPage" :items-per-page="itemsPerPage" :headers="dataTableHeaders"
      :items-length="totalCount" :items="vitalTableData"  :hide-default-footer="isSnapshot"  density="compact" >
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import { IPatientVitalTableData } from '@/interfaces/patient.interface';
import _ from 'lodash';
import { onMounted } from 'vue';
import { PropType } from 'vue';
import { ref, watch } from 'vue';

const props = defineProps({
  vitalData: {
    type: Object as PropType<IPatientVitalTableData[]>,
    required: true,
  },
  dataTableHeaders: {
    type: Object as PropType<any[]>,
    required: true,
  },
  isSnapshot: {
    type: Boolean,
    required: false,
    default: false
  }
})


const itemsPerPage = ref(Number(import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO) || 10);
const currentPage = ref(1);
const totalCount = ref(props.vitalData.length);
const vitalTableData = ref([] as IPatientVitalTableData[]);

onMounted(() => {
  vitalTableData.value = props.vitalData;
})

watch(() => props.vitalData, (vitalData: IPatientVitalTableData[]) => {
  if (!_.isEmpty(vitalData)) {
    vitalTableData.value = vitalData;
  }
}, { deep: true });

</script>

<style scoped></style>
