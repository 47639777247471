import { computed } from "vue";
import { PATIENT_PRIORITY_GROUPS } from "../../enums/patient.enum";
import { Ref } from "vue";
import { IPatientInfo } from "@/interfaces/patient.interface";
import { pick } from "lodash";

export const usePatientListGroupHeaders = (
  currentGroup: Ref<PATIENT_PRIORITY_GROUPS>,
) => {
  const availableHeaders = [
    {
      title: "First Name",
      align: "start",
      sortable: true,
      key: "firstName",
    },
    {
      title: "Last Name",
      align: "start",
      sortable: true,
      key: "lastName",
    },
    {
      title: "Provider",
      align: "start",
      sortable: true,
      key: "providerName",
    },
    { title: "SYS (mmHg)", key: "systolic", align: "center", sortable: true },
    { title: "DIA (mmHg)", key: "diastolic", align: "center", sortable: true },
    {
      title: "Last Sync (Time)",
      key: "lastDataSyncLabel",
      align: "end",
      sortable: true,
    },
    {
      title: "Days Active",
      key: "daysActive",
      align: "center",
      sortable: true,
    },
    {
      title: "Days Missed",
      key: "daysMissed",
      align: "center",
      sortable: true,
    },
    {
      title: "Days Left",
      key: "daysLeft",
      align: "center",
      sortable: true,
    },
    {
      title: "Minutes Spent",
      key: "minutesSpent",
      align: "center",
      sortable: true,
    },
    {
      title: "Minutes Left",
      key: "minutesLeft",
      align: "center",
      sortable: true,
    },
  ];

  return computed(() => {
    const basicHeaderKeys = ["firstName", "lastName"];
    const severityHeaderKeys = [
      ...basicHeaderKeys,
      "providerName",
      "lastDataSyncLabel",
    ];
    const reportingHeaderKeys = [
      ...basicHeaderKeys,
      "daysActive",
      "daysMissed",
      "daysLeft",
    ];
    const reviewHeaderKeys = [
      ...basicHeaderKeys,
      "minutesSpent",
      "minutesLeft",
      "lastDataSyncLabel",
    ];
    const availableKeyGroups = {
      [PATIENT_PRIORITY_GROUPS.HIGH_SEV]: severityHeaderKeys,
      [PATIENT_PRIORITY_GROUPS.MOD_SEV]: severityHeaderKeys,
      [PATIENT_PRIORITY_GROUPS.NORM_SEV]: severityHeaderKeys,
      [PATIENT_PRIORITY_GROUPS.LATE_REPORT]: reportingHeaderKeys,
      [PATIENT_PRIORITY_GROUPS.VERY_LATE_REPORT]: reportingHeaderKeys,
      [PATIENT_PRIORITY_GROUPS.NOT_REVIEWED]: reviewHeaderKeys,
      default: ["name", "providerName"],
    };

    const selectedKeys =
      availableKeyGroups[currentGroup.value] || availableKeyGroups["default"];
    const filteredHeaders = availableHeaders.filter((header) =>
      selectedKeys.includes(header.key),
    );

    return filteredHeaders;
  });
};

export const encodePatientInfo = (patient: IPatientInfo) => {
  return btoa(
    encodeURIComponent(
      JSON.stringify(
        pick(patient, ["firstName", "lastName", "dob", "patientId"]),
      ),
    ),
  );
};
