import gql from 'graphql-tag';

export const ADD_PATIENT_SOURCE = gql`
      mutation addSource($source: SourceInput!) {
        source{
            addSource(source: $source) {
                sourceId
                sourceDefinitionId
                ownerType
                orgId
                identityAtSource
                active
                sourceMeta
                patientId
            }
        }
  }
`;

export const SOURCE_EXTERNAL_IDS = gql`
    query getSourceIdentities($patientId: String!, $sourceDefinitionId: String!){
        source{
            getSourceIdentities(patientId: $patientId, sourceDefinitionId: $sourceDefinitionId){
                identitiesAtSource
            }
        }
    }
`;

export const LIST_ORG_DEVICES = gql`
    query listOrgDevices($serviceProviderId: String!){
        source{
            listOrgDevices(serviceProviderId: $serviceProviderId){
                totalCount
                records
                    {
                        deviceId
                        type
                        shippingStatus
                        trackingCode
                        ownByClinic
                        imei
                    }
            }
        }
    }
`;

export const LIST_PATIENT_SOURCES = gql`
  query listPatientSources($patientId: String!, $vital: VITAL) {
    source {
      listPatientSources(patientId: $patientId, vital: $vital) {
        sourceId
        sourceDefinitionId
        ownerType
        orgId
        identityAtSource
        active
        sourceMeta
        patientId
        inventoryItemType {
          label
          type
        }
        serviceProvider {
          serviceProviderId
          isActive
          serviceProviderName
          shortCode
          sourceTypes
          customFieldDefinitions
        }
      }
    }
  }
`;

export const LIST_SOURCE_DATA = gql`
query sources($input:ListSourceInput!){
    source{
        sources(input:$input){
            sourceId
            sourceDefinitionId
            ownerType
            orgId
            identityAtSource
            active
            sourceMeta
            patientId
            inventoryItemType{
              label
              type
            }
          }
    }
}
`
