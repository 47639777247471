<template>
    <v-select color="primary" bg-color="surfaceBright" v-model="selectedCycle" chips :label="label"
        variant="solo-filled" elevation="3" class="mt-2" :density="density" :items="availableCycles"
        :loading="loading" />
</template>
<script setup lang="ts">
import { ref, defineProps, onMounted, PropType, watch } from 'vue';
import { ICycle } from '../../interfaces/cycle.interface';
import { isEmpty, map } from 'lodash';
import { OrgCycleType } from '@/enums/cycle.enum';
const { orgId, orgCycleType, dataLoader } = defineProps({
    orgId: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: "Select a cycle",
    },
    density: {
        type: String as PropType<any>,
        default: "compact",
    },
    dataLoader: {
        type: Function as PropType<(orgId: string, orgCycleType: OrgCycleType) => Promise<ICycle[]>>,
        required: true,
    },
    orgCycleType: {
        type: String,
        required: true,
    }
});

const emit = defineEmits(["cycleChange"]);

const availableCycles = ref<{ title: string, value: ICycle }[]>();
const selectedCycle = ref<ICycle>();
const loading = ref(true);

const loadData = () => {
    loading.value = true;
    dataLoader(orgId, orgCycleType as OrgCycleType).then((cycles) => {
        if (!isEmpty(cycles)) {
            selectedCycle.value = cycles[cycles.length - 1];
        }
        availableCycles.value = map(cycles, cycle => {
            return {
                title: cycle.label,
                value: cycle,
            };
        });
        loading.value = false;
    });
};

watch(selectedCycle, (newValue) => {
    emit('cycleChange', newValue);
});

onMounted(() => {
    loadData();
});

</script>