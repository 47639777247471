<script setup lang="ts">

export interface Loader {
   overlay: boolean
}
const props = defineProps<Loader>();


</script>

<template>
    <div>
        <v-overlay :model-value="props.overlay" class="align-center justify-center" :persistent="true">
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
    </div>
</template>

<style scoped></style>
