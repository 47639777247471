<template>
  <v-card id="card" elevation="0" class="w-100 d-flex justify-center align-center" color="surfContainer">
    <v-card class="w-100" max-width="800" max-height="800" elevation="3">
      <v-card-title class="bg-surface d-flex justify-center">
        <img src="/vitatraq.svg" class="align-center  pa-4" alt="Vitatraq" height="46" contain />
      </v-card-title>
      <v-card-text class="bg-surface d-flex align-center flex-column">
        <div class="text-center text-primary">
          <v-card-text class="font-weight-bold text-h5 text-primary">
            {{ cardTitle }}
          </v-card-text>
          <p id="caption" class="text-body-2 font-weight-bold">
            {{ caption }}
          </p>
        </div>
        <v-form ref="setPasswordForm" @submit.prevent="handleSubmit" class="w-100 pa-4">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field required variant="outlined" v-model="formData.userName" :rules="emailRules"
                  color="primary" label="Username" hint="Enter your username" />
              </v-col>
              <v-col>
                <v-text-field required variant="outlined" v-model="formData.temporaryPassword"
                  :type="showTemporaryPassword ? 'text' : 'password'" color="primary" :rules="temporaryPasswordRules"
                  :label="temporaryPassword" :hint="temporaryPasswordHint" :append-inner-icon="showTemporaryPassword ? 'mdi-eye' : 'mdi-eye-off'
                    " @click:append-inner="
                      showTemporaryPassword = !showTemporaryPassword
                      " />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field required variant="outlined" v-model="formData.password"
                  :type="showPassword ? 'text' : 'password'" color="primary" :rules="newPasswordRules"
                  :label="newPasswordLabel" :hint="newPasswordHint"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword" />
              </v-col>
              <v-col>
                <v-text-field variant="outlined" color="primary" required v-model="formData.confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'" :rules="confirmPasswordRules"
                  :hint="confirmPasswordHint" :label="confirmPasswordLabel" :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    " @click:append-inner="showConfirmPassword = !showConfirmPassword" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block class="text-white" rounded="false" size="large" variant="flat" type="submit" elevation="3"
                  :color="btnColor">
                  {{ buttonName }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="ma-4">
        <v-spacer />
        <div class="text-center">

          <router-link id="routeToLogin" class="login-link font-weight-bold text-deep-purple-darken-1" :to="'/login'">
            <v-icon class="mr-2 black">mdi-arrow-left</v-icon>Back to Login </router-link>
        </div>
      </v-card-actions>
      <v-snackbar color="error" class="text-white" v-model="showResetPasswordErr" location="top right">
        {{ errorMessage }}
        <template v-slot:actions>
          <v-icon class="ml-3" @click="showResetPasswordErr = false">mdi-close</v-icon>
        </template>
      </v-snackbar>
      <Loader :overlay="loading" />
    </v-card>
  </v-card>
</template>

<script setup lang="ts">
import { resetPasswordWithTemporaryPassword } from "../../composables/CognitoService";
import Loader from "../common/Loader.vue";
import {
  isNotEmpty,
  isValidEmail,
  isValidPassword,
} from "../../composables/FormUtility";
import { ref, } from 'vue';
import router from '../../router/index';

const showTemporaryPassword = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const loading = ref(false);
const setPasswordForm = ref();

const newPasswordRules = [
  (v: string) => !!v || "New password is required",
  (v: string) =>
    isValidPassword(v) || "*Password must include at least 8 characters with one uppercase letter, one lowercase letter, one number, and one special symbol from !, @, $, %, *, ? ",

];
const showResetPasswordErr = ref(false);
const errorMessage = ref("");

const temporaryPasswordRules = [
  (v: string) => !!v || "Temporary password is required",
  (v: string) =>
    v.length >= 8 || "Temporary password should be minimum 8 characters",
];

const emailRules = [
  (v: string) => isNotEmpty(v) || 'Username is required',
  (v: string) => isValidEmail(v) || 'Enter a valid username',
];

const confirmPasswordRules = [
  (v: string) => !!v || "Confirm password is required",
];

const formData = ref({
  userName: "",
  temporaryPassword: "",
  password: "",
  confirmPassword: "",
});

defineProps({
  cardTitle: {
    type: String,
    required: false,
    default: "Set Password",
  },
  btnColor: {
    type: String,
    required: false,
    default: "primary",
  },
  caption: {
    type: String,
    required: false,
    default: "Please set a new password",
  },
  buttonName: {
    type: String,
    required: false,
    default: "Set Password",
  },
  logoSrc: {
    type: String,
    required: false,
    default: "/vitatraq.svg",
  },
  temporaryPassword: {
    type: String,
    required: false,
    default: "Temporary Password",
  },
  temporaryPasswordHint: {
    type: String,
    required: false,
    default: "Enter your temporary password",
  },
  newPasswordLabel: {
    type: String,
    required: false,
    default: "New Password",
  },
  newPasswordHint: {
    type: String,
    required: false,
    default: "Enter your New password",
  },
  confirmPasswordHint: {
    type: String,
    required: false,
    default: "Re-enter your new password",
  },
  confirmPasswordLabel: {
    type: String,
    required: false,
    default: "Confirm Password",
  },
});

const isValidForm = async () => {
  const isFormValid: boolean = (await setPasswordForm.value.validate()).valid;
  return isFormValid;
};

const handleSubmit = async () => {
  const { userName, temporaryPassword, password, confirmPassword } =
    formData.value;

  const checkAllFields = await isValidForm();

  if (!checkAllFields) {
    loading.value = false;
    errorMessage.value = "All fields are required";
    showResetPasswordErr.value = true;
    return;
  }

  if (password !== confirmPassword) {
    loading.value = false;
    errorMessage.value = "Passwords do not match";
    showResetPasswordErr.value = true;
    return;
  }

  try {
    loading.value = true;
    await resetPasswordWithTemporaryPassword(
      userName,
      temporaryPassword,
      password
    );
    loading.value = false;
    router.push("/login");

  } catch (err) {
    loading.value = false;
    errorMessage.value =
      "Unable to set password. Please contact support or try again later.";
    showResetPasswordErr.value = true;
  }
};

</script>
<style scoped>
.setPasswordContainer {
  height: 100%;
  width: 100%;
}

.cardContainer-md {
  width: 55%;
}

.cardContainer-sm {
  width: 90%;
}
</style>
