import gql from "graphql-tag";

export const PATIENT_LINKED_USERS = gql`
  query pePatientLinkedUsers($patientId: String!) {
    pePatientLinkedUsers(patientId: $patientId) {
      userId
      patientId
      firstName
      lastName
      relationship
      isActive
      createdTimeStamp
      updatedTimeStamp
    }
  }
`;

export const UPDATE_USER_PATIENT_LINK_STATUS = gql`
mutation updateUserPatientLinkActiveStatus($updateUserPatientLinkStatusInput: UpdateUserPatientLinkStatusInput!){
  updateUserPatientLinkActiveStatus(updateUserPatientLinkStatusInput: $updateUserPatientLinkStatusInput)
}
`
