import { IPatientData } from "@/interfaces/Service.interface";
import {
  getProgramEConsentDownloadUrl,
  getProgramEConsentTemplate,
  getProgramEConsentUploadUrl,
  signPatientProgramConsent,
  subscribePrograms,
  unSubscribeProgram,
  getAllPatientConsents,
} from "./patient-progam.service";

import { getAllPrograms } from "./program.service";
export const getMultiPatientConsentListDependencies = {
  getProgramEConsentDownloadUrl,
  getProgramEConsentUploadUrl,
  signPatientProgramConsent,
  getProgramEConsentTemplate,
  subscribePrograms,
  unSubscribeProgram,
  getAllPatientConsents,
  getAllPrograms,
} as IPatientData;
