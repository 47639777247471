import { defineStore } from "pinia";
import { socket } from "@/socket";
import { useEventBus } from "@vueuse/core";
import { socketEventBusKey } from "@/events/bus-keys/socket-events.bus-keys";
import { userSessionEventBusKey } from "@/events/bus-keys/user-events.bus-keys";
import { getAccessToken } from "@/composables/CognitoService";
import { ISocketSession } from "@/interfaces/socket.interface";
import { useTaskActivityStore } from "./task-activity.store";
import { usePatientMonitorStore } from "./patient-monitor.store";
import { useServerTimeSyncStore } from "./server-time-sync.store";

const socketEventBus = useEventBus(socketEventBusKey);
const userSessionEventBus = useEventBus(userSessionEventBusKey);

export const useSocketStore = defineStore("socket", {
  state: () => ({
    connected: false,
    disConnected: false,
    socket,
    session: null as ISocketSession | null,
  }),
  actions: {
    connect() {
      getAccessToken()
        .then((token) => {
          const serverTimeSyncStore = useServerTimeSyncStore();
          socket.auth = { token };
          socket.connect();
          serverTimeSyncStore.clockDrift = 0;
          serverTimeSyncStore.initServerTimeSyncRequest(socket);

          socket.on("SYNC_SERVER_TIME", (response) => {
            serverTimeSyncStore.syncServerTime(response);
          });
          this.listenOnEventServer();
        })
        .catch((error) =>
          console.error(
            `Failed to establish connection to the event server`,
            error,
          ),
        );
    },
    disconnect() {
      socket.disconnect();
    },
    listenOnEventServer() {
      const taskActivityStore = useTaskActivityStore();
      const patientMonitorStore = usePatientMonitorStore();
      socket.on("connect", () => {
        socketEventBus.emit({
          event: "CONNECTED",
          connected: this.connected,
        });
      });

      socket.on("disconnect", () => {
        this.connected = false;
        socketEventBus.emit({
          event: "DISCONNECTED",
          connected: this.connected,
        });
      });

      socket.on("authenticated", (session: ISocketSession) => {
        this.connected = true;
        this.session = session;
        taskActivityStore.listenForRemoteTaskActivity(socket);
        patientMonitorStore.listenForRemotePatientMonitorTrackerEvents(socket);
        patientMonitorStore.listenForOrgPatientProgramInsightsEvents(
          socket,
          session.currentUser.userId,
        );
        patientMonitorStore.listenForUserLinkedToPatientEvents(
          socket,
          session.currentUser.userId
        )
      });
    },

    listenOnLocalBus() {
      const taskActivityStore = useTaskActivityStore();
      const patientMonitorStore = usePatientMonitorStore();

      taskActivityStore.listenForLocalTaskActivity();
      patientMonitorStore.listenForPatientMonitorTrackerEvents();
      userSessionEventBus.on((e) => {
        const socketStore = this;
        const eventHandlers = {
          LOG_IN: socketStore.connect,
          LOG_OUT: socketStore.disconnect,
        };
        eventHandlers[e.event]();
      });
    },
  },
});
