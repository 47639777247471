<script setup lang="ts">

import { inject, ref } from 'vue';
import { Designation } from '../enums/Roles';
import _ from 'lodash';
import { ApolloClient } from '@apollo/client';
import { CREATE_ORG_USER } from '../graphql/resources/OrganizationUserRepository';
import router from '../router';
import Loader from './common/Loader.vue';
import { orgDetailsCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { forEach } from 'lodash';
import { IOrganizationUser } from '@/interfaces/IOrganization';

const loading = ref(false);
const showOrgUserCreatedErr = ref(false);
const showOrgUserCreatedMessage = ref(false);
const orgUserCreationError = ref('');

const emit = defineEmits([
  'to-org-user-form',
  'edit-org-user',
  'delete-org-user',
  'cancel-user-creation'
])

const addNewOrgUser = () => {
  emit('to-org-user-form');
}

const deleteOrgUser = (index: string) => {
  emit('delete-org-user', index);
}

const editOrgUser = (index: string) => {
  emit('edit-org-user', index);
}

const cancel = () => {
  emit('cancel-user-creation')
}

const getDesignationLabel = (value: String) => {
  return _.first(
    Designation.filter(designation => {
      return designation.value === value
    }))?.title
}

const props = defineProps({
  orgUserList: {
    type: Object,
    required: true
  }
})

const apolloClient = inject('apolloClient') as ApolloClient<any>;

const createOrgUser = async () => {
  loading.value = true;
  props.orgUserList.forEach((orgUser: IOrganizationUser) => {
    orgUser.tenantId = import.meta.env.VITE_TENANT_ID
  })
  await apolloClient.mutate({
    mutation: CREATE_ORG_USER,
    variables: {
      orgUser: props.orgUserList
    },
    fetchPolicy: 'network-only'
  }).then(() => {
    loading.value = false;
    showOrgUserCreatedMessage.value = true;
    orgDetailsCreated.value = ShowSnackbar.ORG_USER_CREATED;
    router.push({ name: "OrganizationList" });
  }).catch((error) => {
    loading.value = false;
    const { message } = error;
    orgUserCreationError.value = message;
    showOrgUserCreatedErr.value = true;
  });
}

</script>


<template>
  <v-card class="org-profile-card  rounded-lg">
    <v-card-text class="text-h6 text-center font-weight-bold text-primary">
      Create Organization Users
    </v-card-text>
    <v-list>
      <v-list-item v-for="(orgUser, idx) in orgUserList" :key="idx" class="box-shadow ma-1 pa-3 app-border-radius">
        <v-row class="v-row">
          <v-col cols="12" sm="6" md="3" class="d-flex align-center">
            <v-avatar rounded color="primary" class="mr-2 text-uppercase">
              {{ `${orgUser.firstName.charAt(0)} ${orgUser.lastName.charAt(0)}` }}
            </v-avatar>
            <div style="position: relative;">
              <div class='text-left'>
                Name
              </div>
              <div class="text-left">
                {{ `${orgUser.firstName} ${orgUser.lastName}` }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="d-flex align-center">
            <div style="position: relative;">
              <div class='text-left'>
                Designation
              </div>
              <div class="text-left">
                {{ getDesignationLabel(orgUser.designation) }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="d-flex align-center">
            <div class="email-container">
              <div class='text-left'>
                Email
              </div>
              <div class="text-left email-container">
                {{ orgUser.emailAddress }}
                <v-tooltip activator="parent" location="bottom">
                  {{ orgUser.emailAddress }}</v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="d-flex align-center">
            <v-btn class="ma-2 mr-1" variant="tonal" rounded="false" elevation="3" color="primary"
              @click="editOrgUser(idx)">Edit</v-btn>
            <v-btn class="ma-2 ml-1" variant="tonal" rounded="false" elevation="3" color="primary"
              @click="deleteOrgUser(idx)">Delete</v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
    <v-card-actions class="mt-5 justify-end">
      <v-btn color="primary" elevation="3" variant="flat" class="text-none px-md btn-style" rounded="false"
        @click="addNewOrgUser()">
        Add org user
      </v-btn>
    </v-card-actions>
    <v-card-actions class="mt-5 justify-end">
      <v-btn :variant="'tonal'" elevation="3" color="primary" rounded="false" @click="cancel()"
        class="text-none btn-style">
        Cancel
      </v-btn>

      <v-btn color="primary" elevation="3" variant="flat" rounded="false" @click="createOrgUser()"
        class="text-none btn-style">
        Save all
      </v-btn>
    </v-card-actions>
    <v-snackbar color="green" class="text-white" v-model="showOrgUserCreatedMessage" location="top right">
      Organization users created successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" class="text-white" v-model="showOrgUserCreatedErr" location="top right">
      {{ orgUserCreationError }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showOrgUserCreatedErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-card>
</template>

<style scoped>
.org-profile-card {
  width: 70%;
  min-height: 90%;
  margin: 0 auto;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: 5%;
}

.title-text {
  position: absolute;
  left: 20px;
  top: -22px;
}

.sub-title-text {
  position: absolute;
  left: 20px;
  top: -2px;
}


.email-container {
  position: relative;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-style {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
