import type { EventBusKey } from '@vueuse/core';
import { ITaskActionEvent } from '../../interfaces/task.interface';
import { IAppActivityTimerState } from '@/interfaces/app-activity-tracker.interface';

const taskActionEventBusKey: EventBusKey<ITaskActionEvent> = Symbol('task-action-event-bus');
const taskTimerControlEventBusKey: EventBusKey<{
    activity?: ITaskActionEvent,
    timerState: IAppActivityTimerState,
    timeToRecord?: number
}> = Symbol('task-timer-control-event-bus');

const taskActionFromTaskPopupEventBusKey: EventBusKey<ITaskActionEvent> =
  Symbol('task-action-from-task-popup-event-bus-key');

export {
    taskActionEventBusKey,
    taskTimerControlEventBusKey,
    taskActionFromTaskPopupEventBusKey,
};
