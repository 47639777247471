<template>
  <v-card :rounded="false" :color="toastColor.secondaryColor" class="pa-2 ma-0" variant="flat">
    <v-card-item class="card-content">
      <v-row no-gutters>
        <v-col class="d-flex justify-center flex-col">
          <div style="white-space: nowrap; overflow-x: clip;">
            <span class="text-h6 font-weight-black text-center" :style="{ color: toastColor.mainColor }">
              {{ patientDetail.firstName }}
              {{ patientDetail.lastName }}
            </span>
          </div>
          <div class="text-subtitle-2" :style="{ color: toastColor.mainColor }">
            <span>
              {{ age(patientDetail.dob!) }} years, {{ patientDetail.gender
              }}
            </span>
          </div>
        </v-col>
        <v-col class="d-flex text-right flex-col">
          <div>
            <span class="text-h5 reading-style text-right" :style="{ color: toastColor.mainColor }">
              {{ vitalDisplay.displayReadings }}
            </span>
            <span class="text-caption text-right" :style="{ color: toastColor.mainColor }">
              {{ vitalDisplay.unit }}
            </span>
          </div>
          <div :style="{ color: toastColor.mainColor }">
            <span class="text-subtitle-2"> {{ vitalDisplay.displayName }} </span>
          </div>
        </v-col>
      </v-row>
    </v-card-item>
    <v-card-item
      :style="{ backgroundColor: toastColor.mainColor, color: 'white', height: !patientDetail.isOutDated ? '47px' : '65px' }">
      <v-row>
        <v-col cols="1" v-if="patientDetail.isOutDated" class="pl-n6 d-flex align-center">
          <v-icon color="surface">mdi-information</v-icon>
        </v-col>
        <v-col class="mt-1 mb-1">
          <span v-show="patientDetail.isOutDated">
            This reading is outdated as it was captured at {{ new
              Date(patientDetail.dataPoint.collectedTimestamp).toLocaleDateString() }} {{ new
              Date(patientDetail.dataPoint.collectedTimestamp).toLocaleTimeString() }}. Hence, it is not classified.
          </span>
          <span class="text-subtitle-2" v-show="!patientDetail.isOutDated">
            {{ patientDetail.vital === VITAL.BP ? 'BP' : vitalDisplay.displayName }} rating
          </span>
          <span class=" mx-2" v-show="!patientDetail.isOutDated">
            {{ patientDetail.patientSeverity }}
          </span>
        </v-col>
        <v-col cols="2" class="d-flex justify-end align-center">
          <router-link :to="{
            name: 'patientProfileWithInfo',
            params: {
              patientId: patientDetail.patientId,
              patient: encodePatientInfo(patientEncodeDetails),
            },
            query: {
              name: 'PatientConsents'
            }
          }">
            <v-btn rounded size="x-small" :color="toastColor.secondaryColor" icon="mdi-account-eye" variant="outlined">
              <v-icon icon="mdi-account-eye" :color="toastColor.secondaryColor"></v-icon>
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>
<script lang="ts" setup>
import { ref, PropType, computed } from 'vue';
import { age } from '../helpers/date.helpers';
import { encodePatientInfo } from '../composables/patient/view-utility.composable';
import { IPatientInfo } from '../interfaces/patient.interface';
import { IPatientReadingDetail, IBPReading, ISPO2Reading, IPulseReading } from '../interfaces/patientInsights.interface';
import { VITAL } from '../enums/readings';

const props = defineProps({
  patientDetail: {
    type: Object as PropType<IPatientReadingDetail>,
    required: true,
  },
  toastColor: {
    type: Object as PropType<{ mainColor: string, secondaryColor: string, bgColor: string }>,
    required: true,
  }
});

const patientEncodeDetails = ref({
  firstName: props.patientDetail.firstName,
  lastName: props.patientDetail.lastName,
  dob: props.patientDetail.dob,
  patientId: props.patientDetail.patientId
} as IPatientInfo);

const vitalDisplay = computed(() => {
  const { vital, formattedReading } = props.patientDetail;

  const vitalDisplayMap: Record<VITAL, () => { displayReadings: string; displayName: string, unit: string }> = {
    [VITAL.BP]: () => {
      const reading = formattedReading as IBPReading;
      const { systolic, diastolic } = reading;
      return {
        displayReadings: `${systolic}/${diastolic}`,
        displayName: 'Blood Pressure',
        unit: 'mmHg',
      };
    },
    [VITAL.SPO2]: () => {
      const reading = formattedReading as ISPO2Reading;
      const { spO2 } = reading;
      return {
        displayReadings: `${spO2}`,
        displayName: 'SPO2',
        unit: '%',
      };
    },
    [VITAL.PULSE]: () => {
      const reading = formattedReading as IPulseReading;
      const { heartRate } = reading;
      return {
        displayReadings: `${heartRate}`,
        displayName: 'Pulse',
        unit: 'bpm',
      };
    }
  };

  const unknownVital = {
    displayReadings: 'N/A',
    displayName: 'Unknown',
    unit: ''
  };

  return vitalDisplayMap[vital] ? vitalDisplayMap[vital]() : unknownVital;
});

</script>

<style scoped>
.card-content {
  height: 60px;
}

.reading-style {
  font-weight: bolder;
}
</style>
