<template>
  <div v-if="isLoading" class="ma-10">
    <v-skeleton-loader :elevation="3" style=" height: 800px;" class="mx-auto"
      type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
  </div>

  <v-card v-else class="bg-white">
    <div class="px-2 pt-6">
      <v-row>
        <v-col cols="12" class="text-right text-h5 pa-4">
          <v-btn :rounded="false" :loading="isExportPdf || isVitalsGraphExecute" @click="() => { isShorterSnapshot = true; exportToPDF() }"
            elevation="3">Export Summary Snapshot</v-btn>
          <v-btn :rounded="false" :loading="isExportPdf || isVitalsGraphExecute" class="mx-5" @click="exportToPDF" elevation="3">Export Full
            Snapshot</v-btn>
          <v-btn :rounded="false" @click="$emit('close')" variant="tonal" elevation="3">Close</v-btn>
        </v-col>
      </v-row>
    </div>


    <v-card-item style="color: black;" ref="document" id="element-to-convert">
      <div v-if="!isExportPdf">
        <div class="text-h5 mb-5">
          {{ patientSnapshotInfo.patientName }}
        </div>
        <v-divider :thickness="4" opacity="100" color="primary"></v-divider>
      </div>

      <div class="pa-3">
        <v-row>
          <v-col v-if="!isExportPdf" cols="2">
            <div class="text-subtitle-1">D.O.B</div>
            <div class="text-body-2">{{ patientSnapshotInfo.dob }}</div>
          </v-col>
          <v-col cols="2">
            <div class="text-subtitle-1">Gender</div>
            <div class="text-body-2">{{ patientSnapshotInfo.gender }}</div>
          </v-col>
          <v-col :cols="isExportPdf ? '3' : '2'">
            <div class="text-subtitle-1">Report generated on</div>
            <div class="text-body-2">{{ currentDate }}</div>
          </v-col>
          <v-col cols="3">
            <div class="text-subtitle-1">Report period</div>
            <div class="text-body-2">
              {{ patientSnapshotInfo.reportPeriod }}
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-subtitle-1">Primary Provider</div>
            <div class="text-body-2">
              {{ patientSnapshotInfo.primaryProvider }}
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="pt-5 mt-4">
        <v-divider :thickness="10" opacity="100" color="primary"></v-divider>
        <v-row class="pb-4 mt-5">
          <v-col cols="1" class="pl-6">
            <div class="pb-4 pt-14">Avg.</div>
            <div class="py-8">Max.</div>
            <div class="pt-8">Min.</div>
          </v-col>

          <v-col cols="4">
            <div class="text-h5">Blood Pressure</div>
            <div class="py-4"><span class="text-h6">{{ patientSnapshotInfo.bpAverage || 'N/A' }}</span> mmHg</div>

            <v-row class="pt-4">
              <v-col>
                <p class="text-h6">Sys: {{ patientSnapshotInfo.bpMax || 'N/A' }}</p>
                <p class="text-body-2">{{ patientSnapshotInfo.bpMaxDate || 'N/A' }}</p>
              </v-col>
              <v-col>
                <p class="text-h6">Dia: {{ patientSnapshotInfo.diaMax || 'N/A' }}</p>
                <p class="text-body-2">{{ patientSnapshotInfo.diaMaxDate || 'N/A' }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p class="text-h6">Sys: {{ patientSnapshotInfo.bpMin || 'N/A' }}</p>
                <p class="text-body-2">{{ patientSnapshotInfo.bpMinDate || 'N/A' }}</p>
              </v-col>
              <v-col>
                <p class="text-h6">Dia: {{ patientSnapshotInfo.diaMin || 'N/A' }}</p>
                <p class="text-body-2">{{ patientSnapshotInfo.diaMinDate || 'N/A' }}</p>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical :thickness="1" opacity="100" color="primary"></v-divider>

          <v-col cols="3" class="page-break">
            <div class="text-h5">Heart Rate</div>
            <div class="pt-4 mb-6"><span class="text-h6">{{ patientSnapshotInfo.heartRateAverage  || 'N/A' }}</span> bpm</div>

            <div>
              <p class="text-h6">{{ patientSnapshotInfo.heartRateMax || 'N/A'}}</p>
              <p class="text-body-2">{{ patientSnapshotInfo.heartRateMaxDate || 'N/A' }}</p>
            </div>

            <div class="pt-8">
              <p class="text-h6">{{ patientSnapshotInfo.heartRateMin || 'N/A' }}</p>
              <p class="text-body-2">{{ patientSnapshotInfo.heartRateMinDate || 'N/A' }}</p>
            </div>
          </v-col>

          <v-divider vertical :thickness="1" opacity="100" color="primary"></v-divider>

          <v-col cols="2">
            <div class="text-h5 mb-5">Measurements</div>
            <div>
              <span class="text-h4">{{ patientSnapshotInfo.bpMeasurementCount }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div>
        <div class="text-center font-weight-bold text-h6 mt-16" v-if="isEmpty(patientBPDataPoints)">No Blood Pressure data available in current cycle</div>
        <div v-else  style="max-width: 800px" class="pa-5 mt-10">
          <PatientVitalsBPGraph :is-snapshot="true" :bp-data-points="patientBPDataPoints"
            :hr-data-points="patientHRDataPoints" :patient-vital-config-loader="patientVitalConfigLoader"
            :patient-id="patientId" :ihb-value="patientReadingTableData" @is-graph-mounted="isVitalsGraphExecute = false" />
        </div>
      </div>

      <div class="page-break px-4 pt-10">
        <v-divider :thickness="10" opacity="100" color="primary"></v-divider>
        <div class="text-h5 font-weight-bold my-4">
          Blood Pressure Measurements
        </div>
        <div class="pa-5">
          <PatientVitalsTable :vital-data="patientReadingTableData" :data-table-headers="patientReadingTableHeaders"
            :is-snapshot="true" />
        </div>
      </div>
      <div class="px-4 pt-10 no-break" v-if="!isShorterSnapshot">
        <v-divider :thickness="10" opacity="100" color="primary"></v-divider>
        <div class="text-h5 font-weight-bold my-6">Patient Time Logs</div>
        <div class="pa-5">
          <v-data-table class="bg-white" :page="currentPage" :items-per-page="itemsPerPage"
            :headers="timeLogTableHeaders" :items="patientTimeLogs" :hide-default-footer="true" density="compact">
            <template v-slot:[`item.activity`]="{ item }">
              <tr class="mt-2">
                <td class="py-1">{{ item.activity }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>

      <div class="page-break px-4 pt-10 no-break" v-if="!isShorterSnapshot">
        <v-divider :thickness="10" opacity="100" color="primary"></v-divider>
        <div class="text-h5 font-weight-bold my-6">Patient Notes</div>
        <div class="pa-5 mb-10">
          <v-data-table class="bg-white" :page="currentPage" :headers="patientNoteHeaders" :items-length="totalCount"
            :hide-default-footer="true" :items="patientNotes" density="compact">
            <template v-slot:[`item.noteContent`]="{ item }">
              <tr class="mt-2">
                <td class="py-6">{{ item.noteContent }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card-item>
  </v-card>

</template>
<script setup lang="ts">
import { ref, defineProps, PropType, onMounted } from "vue";
import moment from "moment";
import * as momenttz from "moment-timezone";

import {
  BPReading,
  IPatientBPTableData,
  IPatientSnapshotFilterInput,
  IPatientSnapshotPayload,
  PatientReading,
VitalReading,
} from "@/interfaces/patient.interface";
import { Gender, GenderLabel } from "@/enums/genderType";
import { isEmpty, maxBy, minBy, filter } from 'lodash';
import { IGraphDataPoint } from "@/interfaces/utility.interface";
import { IPatientVitalConfigureResponse } from "@/interfaces/IVitalConfigure";
import PatientVitalsBPGraph from "../data-components/vitals/bp/PatientVitalsBPGraph.vue";
import PatientVitalsTable from "../data-components/vitals/PatientVitalsTable.vue";
import { PatientVitalBPTableHeader, VITAL } from "@/enums/readings";
import {
  IPatientTimeLog,
  IUserTimeLogResponse,
} from "@/interfaces/timelog.interface";
import { formatTimestamp } from "@/composables/FormUtility";
import { ListNotes } from "@/interfaces/notes.interface";

//@ts-ignore
import html2pdf from "html2pdf.js";
import first from 'lodash';


const props = defineProps({
  snapshotPatientInfo: {
    type: Function as PropType<(patientId: string, patientSnapshotFilter: IPatientSnapshotFilterInput,) => Promise<IPatientSnapshotPayload>>,
    required: true,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['closeAndThrowError', 'close'])

const { snapshotPatientInfo, patientId } = props;

const currentDate = ref(moment().format("MM/DD/YYYY"));
const isLoading = ref(false);
const isExportPdf = ref(false);
const isShorterSnapshot = ref(false);
const isVitalsGraphExecute = ref(false);

const patientBPDataPoints = ref([] as IGraphDataPoint[]);
const patientHRDataPoints = ref([] as IGraphDataPoint[]);
const patientReadingTableData = ref([] as IPatientBPTableData[]);
const patientReadingTableHeaders = ref(PatientVitalBPTableHeader);

const itemsPerPage = ref(-1);
const currentPage = ref(1);
const totalCount = ref(0);
const patientTimeLogs = ref([] as IPatientTimeLog[]);
const timeLogTableHeaders = [
  { title: "Activity", key: "activity", sortable: false },
  { title: "Auto Tracked", key: "isAutoLogged", sortable: false },
  { title: "Start", key: "startTime", sortable: false },
  { title: "End", key: "endTime", sortable: false },
  { title: "Duration", key: "timeSpent", sortable: false },
  { title: "Added By", key: "loggedBy", sortable: false },
  { title: "Notes", key: "noteContent", sortable: false, width: "200px" },
];

const patientNoteHeaders = [
  { title: "Author", key: "author" },
  { title: "Notes", key: "noteContent" },
  { title: "Created at", key: "createdAt" },
  { title: "Edited at", key: "editAt" },
];
const patientNotes = ref([] as ListNotes[]);

const patientSnapshotInfo = ref({
  patientName: "",
  dob: "",
  gender: "",
  reportPeriod: "",
  bpAverage: "",
  bpMax: "",
  bpMaxDate: "",
  bpMin: "",
  bpMinDate: "",
  diaMax: "",
  diaMaxDate: "",
  diaMin: "",
  diaMinDate: "",
  heartRateAverage: "",
  heartRateMax: "",
  heartRateMaxDate: "",
  heartRateMin: "",
  heartRateMinDate: "",
  bpMeasurementCount: 0,
  primaryProvider: "",
});

const handleSnapshotInfo = async () => {
  try {
    isLoading.value = true;
    const monthStartDate = moment().startOf("month");
    const currentDate = moment();

    const patientSnapshotFilter = {
      fromDate: monthStartDate.toDate(),
      toDate: currentDate.toDate(),
    };
    const response = await snapshotPatientInfo(patientId, patientSnapshotFilter);

    isLoading.value = false;
    isVitalsGraphExecute.value = true;

    const provider = response.patient.providers.find(
      (provider) => provider.isPrimaryProvider === true,
    );

    patientSnapshotInfo.value.patientName = `${response.patient.firstName} ${response.patient.lastName}`;
    patientSnapshotInfo.value.dob = response.patient.dob;
    patientSnapshotInfo.value.gender =
      GenderLabel[response.patient.gender as Gender];
    patientSnapshotInfo.value.reportPeriod = `${monthStartDate.format("MM/DD/YYYY")} - ${currentDate.format("MM/DD/YYYY")}`;
    patientSnapshotInfo.value.primaryProvider = `${provider?.firstName} ${provider?.lastName}`;

    loadBPVitalData(response.patientVitalReading.patientReadings);

    patientTimeLogs.value = prepareTableData(response.timeLog);

    patientNotes.value = response.patientNote.map((data) => {
      return {
        id: data.mpi,
        author: `${data.note.author.firstName}  ${data.note.author.lastName}`,
        createdAt: formatTimestamp(data.note.createdAt),
        editAt: formatTimestamp(data.note.lastModifiedAt),
        noteContent: data.note.noteContent.message,
        actionPop: false,
      };
    });
    isEmpty(response.patientVitalReading.patientReadings) && (isVitalsGraphExecute.value = false);
  }
  catch (error) {
    emits('closeAndThrowError', (error as Error).message);
  }
};

const loadBPVitalData = (patientVitalData: PatientReading[]) => {
  const patientReadingArray = filter(patientVitalData, vitalData =>
    vitalData.dataSourceRef.vital === VITAL.BP
  );

  const patientReading = patientReadingArray[0];

  const patientBPReadings = patientReading.dataPoints;

  if (patientBPReadings) {
    const allSysReadings = [] as number[];
    const allDiaReadings = [] as number[];
    const allHrReading = [] as number[];
    const sysGraphDataPoint = [] as [number, number][];
    const diaGraphDataPoint = [] as [number, number][];
    const hrGraphDataPoint = [] as [number, number][];
    patientReadingTableData.value = [];

    patientBPReadings.forEach((patientBPReading: VitalReading) => {
      const {
        collectedTimestamp,
      } = patientBPReading;


      const { systolic, diastolic, heartRate } = patientBPReading.reading as BPReading
      allSysReadings.push(systolic);
      allDiaReadings.push(diastolic);
      allHrReading.push(heartRate);
      sysGraphDataPoint.push([Number(collectedTimestamp), systolic]);
      diaGraphDataPoint.push([Number(collectedTimestamp), diastolic]);
      hrGraphDataPoint.push([Number(collectedTimestamp), heartRate]);
      patientReadingTableData.value.push(
        getFormattedBPVitalData(patientBPReading),
      );
    });

    patientBPDataPoints.value = [
      { name: "Systolic", data: sysGraphDataPoint },
      { name: "Diastolic", data: diaGraphDataPoint }
    ];

    patientHRDataPoints.value = [
      { name: "Heart Rate", data: hrGraphDataPoint }
    ];

    type BPReadingKeys = 'systolic' | 'diastolic' | 'heartRate';

    const getReadingValue = (reading: VitalReading, key: BPReadingKeys) => (reading?.reading as BPReading)[key];

    const sysMax = maxBy(patientBPReadings, (reading) => getReadingValue(reading, 'systolic'));
    const sysMin = minBy(patientBPReadings, (reading) => getReadingValue(reading, 'systolic'));

    const diaMax = maxBy(patientBPReadings, (reading) => getReadingValue(reading, 'diastolic'));
    const diaMin = minBy(patientBPReadings, (reading) => getReadingValue(reading, 'diastolic'));

    const hrMax = maxBy(patientBPReadings, (reading) => getReadingValue(reading, 'heartRate'));
    const hrMin = minBy(patientBPReadings, (reading) => getReadingValue(reading, 'heartRate'));


    patientSnapshotInfo.value.bpAverage = `${calculateAvg(allSysReadings)} / ${calculateAvg(allDiaReadings)}`;
    patientSnapshotInfo.value.heartRateAverage = `${calculateAvg(allHrReading)}`;

    patientSnapshotInfo.value.bpMax = !isEmpty(allSysReadings)
      ? `${(sysMax?.reading as BPReading).systolic}`
      : "N/A";

    patientSnapshotInfo.value.bpMaxDate = formatDate(sysMax?.collectedTimestamp!);
    patientSnapshotInfo.value.bpMinDate = formatDate(sysMin?.collectedTimestamp!);
    patientSnapshotInfo.value.diaMaxDate = formatDate(diaMax?.collectedTimestamp!);
    patientSnapshotInfo.value.diaMinDate = formatDate(diaMin?.collectedTimestamp!);
    patientSnapshotInfo.value.heartRateMaxDate = formatDate(hrMax?.collectedTimestamp!);
    patientSnapshotInfo.value.heartRateMinDate = formatDate(hrMin?.collectedTimestamp!);


    patientSnapshotInfo.value.bpMin = !isEmpty(allSysReadings)
      ? `${(sysMin?.reading as BPReading).systolic} `
      : "N/A";


    patientSnapshotInfo.value.diaMax = !isEmpty(allDiaReadings)
      ? `${(diaMax?.reading as BPReading).diastolic}`
      : "N/A";


    patientSnapshotInfo.value.diaMin = !isEmpty(allDiaReadings)
      ? `${(diaMin?.reading as BPReading).diastolic}`
      : "N/A";

    patientSnapshotInfo.value.heartRateMax = hrMax?.reading.heartRate
      ? `${(hrMax?.reading as BPReading).heartRate}`
      : "0";

    patientSnapshotInfo.value.heartRateMin = hrMin?.reading.heartRate
      ? `${(hrMin?.reading as BPReading).heartRate}`
      : "0";

    patientSnapshotInfo.value.bpMeasurementCount = patientBPReadings.length;
  }
};

const formatDate = (timestamp: string) => {
  return timestamp ? moment(parseInt(timestamp)).format("MM/DD/YYYY hh:mm a") : "";
};

const getFormattedBPVitalData = (dataPoint: VitalReading) => {
  const { collectedTimestamp } = dataPoint;
  const reading = dataPoint.reading as BPReading;
  const formattedDateObject = momenttz.unix(Number(collectedTimestamp) / 1000);
  const formattedDate = formattedDateObject.format("Do MMM YYYY");
  const formattedTime = formattedDateObject.format("hh:mm:ss A");

  return {
    date: formattedDate,
    time: formattedTime,
    dia: reading.diastolic,
    sys: reading.systolic,
    hr: reading.heartRate,
    ihb: reading.ihb == true ? "Yes" : reading.ihb == false ? "No" : "N/A",
    details: reading.rawData.note ? reading.rawData.note : "N/A",
  };
};

const calculateAvg = (readings: number[]) => {
  if (isEmpty(readings)) {
    return 0;
  }

  const average =
    readings.reduce((sum, reading) => sum + reading, 0) / readings.length;

  const roundedAverage = Math.round(parseFloat(average.toFixed(1)));

  return roundedAverage;
};

const prepareTableData = (patientTimeLogRes: IUserTimeLogResponse) => {
  return patientTimeLogRes.results.map((timeLog) => {
    const {
      activity,
      isAutoLogged,
      loggedBy,
      endTime,
      startTime,
      note,
      timeSpent,
    } = timeLog;
    const timeSpentDuration = moment.duration(timeSpent, "milliseconds");
    return {
      timeLogId: timeLog.timeLogId,
      endTime: moment(endTime).format("MM/DD/YYYY hh:mm a"),
      startTime: moment(startTime).format("MM/DD/YYYY hh:mm a"),
      loggedBy: `${loggedBy.firstName} ${loggedBy.lastName}`,
      activity: activity.label,
      isAutoLogged: isAutoLogged ? "Yes" : "No",
      noteContent: note?.note?.noteContent?.message || "N/A",
      timeSpent: `${Math.floor(timeSpentDuration.asMinutes())} min ${timeSpentDuration.seconds()} secs`,
    } as IPatientTimeLog;
  });
};



const exportToPDF = async () => {
  isExportPdf.value = true;
  const element = document.getElementById("element-to-convert");

  const opt = {
    margin: [28, 2, 20, 2],
    filename: `${patientSnapshotInfo.value.patientName}_${isShorterSnapshot.value ? 'summary' : 'full'}_snapshot.pdf`,
    pagebreak: { mode: ['avoid-all', 'css'] },
    html2canvas:  { scale: 1 },
    jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
  };

  const loadImageAsBase64 = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = src;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = reject;
    });
  };

  const imageBase64 = await loadImageAsBase64('/vitatraq.svg');

  await html2pdf()
    .from(element)
    .set(opt)
    .toPdf()
    .get('pdf')
    .then((pdf: any) => {
      const totalPages = pdf.internal.getNumberOfPages();

      const pageWidth = pdf.internal.pageSize.getWidth();
      const marginRight = pageWidth - 70;

      const patientName = patientSnapshotInfo.value.patientName || "";
      const patientDOB = patientSnapshotInfo.value.dob || "";

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        pdf.setFontSize(14);
        pdf.text(`Name: ${patientName}`, 10, 15);

        pdf.setFontSize(11);
        pdf.text(`DOB: ${patientDOB}`, 10, 23);

        pdf.addImage(imageBase64, 'PNG', marginRight, 10, 55, 15);

      }
    })
    .save();
  isExportPdf.value = false;
  isShorterSnapshot.value = false;
};


onMounted(() => {
  handleSnapshotInfo();
});
</script>
<style>
#element-to-convert {
  box-shadow: none;
  border: none;
}

.no-break {
  page-break-inside: auto;
}
</style>
