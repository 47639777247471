import router from "@/router";
import { useAuthUserStore } from "../store/modules/AuthUser";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { forEach, map, some } from 'lodash';
import { UserRoles } from "@/enums/Roles";
import { IUser, RolesData } from "@/interfaces/user.interface";
import { useUserStore } from "@/store/modules/User";
import { apolloClient } from "@/plugins/graphqlclient";
import { GET_USER_INFO } from "@/graphql/resources/UserRepository";

export const checkUserRoles = (userRoles: RolesData[]) => {

  const roles = map(userRoles, 'role');
  const authUserStore = useAuthUserStore();
  forEach(roles, (role) => {
    switch (role) {
      case UserRoles.SUPER_USER:
        authUserStore.isSuperUser = true;
        break;
      case UserRoles.SYSTEM_ADMIN:
        authUserStore.isAdmin = true;
        break;
      case UserRoles.ORG_ADMIN:
        authUserStore.isOrgAdmin = true;
        break;
      case UserRoles.ORG_STAFF:
        authUserStore.isOrgStaff = true;
        break;
      case UserRoles.ORG_PROVIDER:
        authUserStore.isOrgProvider = true;
        break;
      case UserRoles.PATIENT:
        authUserStore.isPatient = true;
        break;
      default:
        break;
    }
  });

  authUserStore.saveAuthUser(authUserStore.$state);
}

const redirectToForbidden = () => {
  router.push({ name: "forbidden" });
};

const isAuthorizedUser = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {

  if (!(useUserStore().$state.user.emailAddress)) {
    await getLoginDetails();
  }

  const authUserStore = useAuthUserStore();
  const conditions = [
    authUserStore.$state.isSuperUser,
    authUserStore.$state.isAdmin,
    authUserStore.$state.isOrgAdmin,
    authUserStore.$state.isOrgStaff,
    authUserStore.$state.isOrgProvider
  ];

  const isAuthorized = some(conditions);

  if (isAuthorized) {
    next();
  } else {
    redirectToForbidden();
  }
}

export const getLoginDetails = async () => {
  try {
    const store = useUserStore();
    const response = await apolloClient.query({
      query: GET_USER_INFO,
      fetchPolicy: "network-only",
    });

    const { getUserInfo } = response.data;
    const { emailAddress, firstName, lastName, roles, userId, organizationId } = getUserInfo;

    const user = {
      emailAddress,
      firstName,
      lastName,
      roles,
      userId,
      organizationId,
      isLogged: true,
    } as IUser;

    store.$state.user = user;
    checkUserRoles(roles);

    return true;
  } catch (error) {
    return false;
  }
};

const authorize = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  conditions: boolean[]
) => {
  if (!useUserStore().$state.user.emailAddress) {
    await getLoginDetails();
  }

  const isAuthorized = some(conditions);

  if (isAuthorized) {
    next();
  } else {
    redirectToForbidden();
  }
};

const isSystemAndOrgAdmin = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authUserStore = useAuthUserStore();
  const conditions = [
    authUserStore.$state.isSuperUser,
    authUserStore.$state.isAdmin,
    authUserStore.$state.isOrgAdmin,
  ];

  await authorize(to, from, next, conditions);
};

const isOrgUser = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authUserStore = useAuthUserStore();

  if (authUserStore.$state.isAdmin) {
    router.push({ name: 'OrganizationList' })
  } else {
    const conditions = [
      authUserStore.$state.isOrgAdmin,
      authUserStore.$state.isOrgStaff,
      authUserStore.$state.isOrgProvider,
    ];

    await authorize(to, from, next, conditions);
  }
};

const isOrgAdmin = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authUserStore = useAuthUserStore();
  const conditions = [authUserStore.$state.isOrgAdmin];

  await authorize(to, from, next, conditions);
};

const isAdmin = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authUserStore = useAuthUserStore();
  const conditions = [authUserStore.$state.isAdmin];

  await authorize(to, from, next, conditions);
};

const isOrgAdminAndStaff = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const authUserStore = useAuthUserStore();
  const conditions = [
    authUserStore.$state.isOrgAdmin,
    authUserStore.$state.isOrgStaff,
  ];

  await authorize(to, from, next, conditions);
};

export { isAuthorizedUser, isSystemAndOrgAdmin, isOrgUser, isOrgAdmin, isAdmin, isOrgAdminAndStaff };
