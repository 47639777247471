import { IPatientInfo } from '@/interfaces/patient.interface';
import type { EventBusKey } from '@vueuse/core';

const patientActiveStatusEventBusKey: EventBusKey<string> = Symbol('patient-active-status-event-bus');
const patientDetailsEventBusKey: EventBusKey<IPatientInfo> = Symbol('patient-details-event-bus');

export {
    patientActiveStatusEventBusKey,
    patientDetailsEventBusKey
};
