<template>
  <div :id="data.id" :gs-id="data.id" :gs-x="data.grid.x" :gs-y="data.grid.y" :gs-w="data.grid.w" :gs-h="data.grid.h">
    <v-divider thickness="1" />
    <div
      class="grid-stack-item-content group relative  bg-surface highlight-white/5 rounded-md shadow-md flex items-start justify-center"
      :class="{ 'cursor-move border-2 rounded-md border-slate-300 border-dashed shadow-none ': isEditing }">
      <component :is="widgetComp" :widget-data="data" :isEditing="isEditing" class="px-1" />
      <DeleteButton v-if="isEditing" class="hidden group-hover:block absolute top-2 right-2" @click="deleteWidget" />
    </div>
    <v-divider thickness="1" />
  </div>
</template>
<script lang="ts" setup>
import DeleteButton from "@/components/basic/DeleteButton.vue";
import { defineAsyncComponent, onMounted, shallowRef } from "vue";
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isEditing: Boolean,
});

const { data } = props;

const widgetComp = shallowRef();
const emit = defineEmits(["delete"]);

onMounted(() => {
  widgetComp.value = defineAsyncComponent(
    () => import(`@/components/widgets/custom/${data.name}.vue`).catch(() => import(`@/components/widgets/ErrorWidget.vue`))
  );

});

function deleteWidget() {
  emit("delete", props.data);
}
</script>
