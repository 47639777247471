<template>
  <v-footer color="surface" :absolute="true">
    <v-row>
      <v-col cols="12" md="7" sm="7" class="text-caption text-primary font-weight-bold d-flex justify-start">
        <span class="text-primary mx-2">
          <v-icon icon="mdi mdi-copyright" size="medium" />
          {{ currentYear }} VitaTraq Inc., All rights reserved.
        </span>
      </v-col>
      <v-col md="5" sm="5" cols="12" class="text-caption text-primary font-weight-bold d-flex justify-end">
        <v-spacer />
        <span class="cursor-pointer text-decoration-underline" @click="routeTo('privacyPolicy')">
          Privacy Policy
        </span>
        &nbsp;
        <v-divider thickness="1" class="mx-2 border-opacity-100" vertical />
        &nbsp;
        <span class="cursor-pointer text-decoration-underline" @click="routeTo('termsandConditions')">Terms and
          Conditions</span>

      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup lang='ts'>
import moment from "moment";
import { ref } from "vue";
import router from "../router";

const currentYear = ref(moment().year());

const routeTo = (routeName: string) => {
  router.push({
    name: routeName,
  });
};

</script>
