<template>
    <v-card flat color="surfContainer" class="w-100 h-100">
        <div class="d-flex flex-column">
            <v-tabs v-model="dataTab" color="primary" class="bg-surface text-primary" density="compact">
                <v-tab :value="PATIENT_VITALS.BP">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_VITALS.BP  }} </span>
                </v-tab>
                <v-tab :value="PATIENT_VITALS.Pulse">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_VITALS.Pulse }} </span>
                </v-tab>
                <v-tab :value="PATIENT_VITALS.SPO2">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_VITALS.SPO2 }} </span>
                </v-tab>
            </v-tabs>
            <div>
                <PatientBPView
                    v-if="dataTab === PATIENT_VITALS.BP"
                    :patient-id="patientProfile.patientId"
                    :patient-data-source-input="getPatientDataDependencies.listPatientSources"
                    :data-loader="getPatientDataDependencies.getPatientReadings"
                    :look-back-range-input="getPatientDataDependencies.getLookBackRanges"
                    :patientVitalConfigLoader="getPatientDataDependencies.getPatientVitalsThresholdConfig"
                />

                <PatientsPulseView
                    v-if="dataTab === PATIENT_VITALS.Pulse"
                    :patient-id="patientProfile.patientId"
                    :patient-data-source-input="getPatientDataDependencies.listPatientSources"
                    :data-loader="getPatientDataDependencies.getPatientReadings"
                    :look-back-range-input="getPatientDataDependencies.getLookBackRanges"
                    :patientVitalConfigLoader="getPatientDataDependencies.getPatientVitalsThresholdConfig"
                />

                <PatientsSPO2View
                    v-if="dataTab === PATIENT_VITALS.SPO2"
                    :patient-id="patientProfile.patientId"
                    :patient-data-source-input="getPatientDataDependencies.listPatientSources"
                    :data-loader="getPatientDataDependencies.getPatientReadings"
                    :look-back-range-input="getPatientDataDependencies.getLookBackRanges"
                    :patientVitalConfigLoader="getPatientDataDependencies.getPatientVitalsThresholdConfig"
                />
            </div>
        </div>
    </v-card>
</template>
<script setup lang="ts">
import { PATIENT_VITALS } from '../../../../enums/patient.enum';
import { ref } from 'vue';
import PatientBPView from './bp/PatientBPView.vue';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { PropType } from 'vue';
import { IPatientMonitor } from '@/interfaces/Service.interface';
import PatientsPulseView from './pulse/PatientsPulseView.vue';
import PatientsSPO2View from './spO2/PatientSPO2View.vue';

const dataTab = ref(PATIENT_VITALS.BP);

const { getPatientDataDependencies, patientProfile } = defineProps({
    patientProfile: {
        type: Object as PropType<IPatientProfileProps>,
        required: true
    },
    getPatientDataDependencies: {
        type: Object as PropType<IPatientMonitor>,
        required: true
    }
})

</script>
