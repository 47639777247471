<template>
  <div class="w-100 my-2 px-8 py-4">
    <template v-if="isEmpty(sourceList)">
      <div class="d-flex flex-column align-center justify-center">
        <label class="text-h6 font-weight-bold">No data available</label>
        <img src="@/assets/empty.png" alt="No data available" class="no-data-available" />
      </div>
    </template>
    <template v-else>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-card flat class="w-100 h-100 mx-2 text-primary " variant="tonal">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Last Reading </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2" />
              <div v-if="!isVitalDataEmpty">
                <v-skeleton-loader v-if="isEmpty(latestSystolicColor) || isEmpty(latestDiastolicColor)"
                  color="surface" type="text"></v-skeleton-loader>
                <div v-else>
                  <h3 class="text-h3 font-weight-black my-4" v-if="lastReading">
                    <span>
                      <span :style="{ color: latestSystolicColor }">{{ first(split(lastReading, '/')) }}</span> /
                      <span :style="{ color: latestDiastolicColor }">{{ last(split(lastReading, '/')) }}</span>
                    </span>
                  </h3>
                  <v-divider thickness="1" class="my-2" />
                  <span class="text-body-1 font-weight-black no-wrap-text"><strong>{{ lastReadTime }}</strong></span>
                </div>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex align-start justify-center">
          <v-card flat class="w-100 h-100 mx-2 text-primary" variant="tonal">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Reading Average </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2" />
              <div v-if="!isVitalDataEmpty">
                <h3 class="text-h3 text-primary font-weight-black  my-4">{{ sysAvg }}/{{ diaAvg }} </h3>
                <v-divider thickness="1" class="my-2" />
                <span class="text-body-1 text-primary font-weight-black no-wrap-text"><strong>{{
                  lookBackTitle(selectedLookBackRange) }} Average </strong></span>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat variant="tonal" class="w-100 h-100 mx-2 text-primary">
            <v-card-title>
              <h6 class="text-h6 text-center mb-n2 font-weight-black"> Variation </h6>
            </v-card-title>
            <v-card-text class="text-center">
              <v-divider thickness="1" class="my-2 mb-4" />
              <div v-if="!isVitalDataEmpty">
                <div class="d-flex justify-center align-center my-2">
                  <v-chip size="x-large">
                    <h5 class="text-h5 font-weight-black no-wrap-text mx-2">Systolic</h5>
                    <v-icon class="text-h4 font-weight-black" :icon=getTrendIcon(sysTrend) />
                  </v-chip>
                  <v-tooltip activator="parent" location="top">Systolic {{ sysTrend }} by {{ sysPerc }}% from {{
                    lookBackShort(selectedLookBackRange) }} average ({{ sysAvg }}) </v-tooltip>
                </div>
                <div class="d-flex justify-center align-center my-2">
                  <v-chip size="x-large">
                    <h5 class="text-h5 font-weight-black no-wrap-text mx-2">Diastolic</h5>
                    <v-icon class="text-h4 font-weight-black" :icon=getTrendIcon(diaTrend) />
                  </v-chip>
                  <v-tooltip activator="parent" location="bottom">Diastolic {{ diaTrend }} by {{ diaPerc }}% from {{
                    lookBackShort(selectedLookBackRange) }} average ({{ diaAvg }})</v-tooltip>
                </div>
              </div>
              <div v-else class="text-h3 text-primary font-weight-black  my-4">N/A</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-select v-model="selectedLookBackRange" @update:modelValue="reloadVitalData()"
            :label="lookBackRangeInput.label" variant="outlined" density="compact" :items="lookBackRangeList"
            class="pa-2"></v-select>
        </v-col>
        <v-col>
          <v-select v-model="selectedSource" :loading="loadingDataSources" @update:modelValue="reloadVitalData()"
            variant="outlined" density="compact" :items="sourceList" class="pa-2"></v-select>
        </v-col>
        <v-col cols="2" class="d-flex justify-end my-2">
          <v-btn-toggle v-model="selectedView" density="compact" elevation="1" class="my-1">
            <v-btn size="small" color="surface">
              <v-icon class="text-primary">mdi-chart-line</v-icon>
            </v-btn>
            <v-btn size="small" color="surface">
              <v-icon class="text-primary">mdi-table</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div class="w-100">
        <template
          v-if="(!enableGetPatientReadingsQuery && loadingDataSources) || (enableGetPatientReadingsQuery && loading)">
          <div class="mt-8 loading-container">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </template>
        <template v-else-if="isEmpty(sourceList)">
          <div class="w-100 text-center my-10">
            No devices associated with this patient
          </div>
        </template>
        <template v-else-if="isVitalDataEmpty">
          <div class="d-flex flex-column mt-10 align-center justify-center">
            <label class="text-h6 font-weight-bold">No data available</label>
            <img src="@/assets/empty.png" alt="No data available" class="no-data-available" />
          </div>
        </template>
        <template v-else-if="selectedView == 0">
          <PatientVitalsBPGraph :bp-data-points="patientBPDataPoints" :hr-data-points="patientHRDataPoints"
            :patient-vital-config-loader="patientVitalConfigLoader" :patient-id="patientId" :ihb-value="patientReadingTableData"
            @colorUpdated="handleColorUpdate" />
        </template>
        <template v-else-if="selectedView == 1">
          <PatientVitalsTable :vital-data="patientReadingTableData" :data-table-headers="patientReadingTableHeaders" />
        </template>
      </div>
    </template>
    <v-snackbar color="red" class="text-white" v-model="showFetchPatientReadingsErrMsg" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showFetchPatientReadingsErrMsg = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="showLoading" />
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch, watchEffect } from 'vue';
import { ILookBackRangeInput, IPatientDataSourceItem, ILookBackRangeItem, PatientReading, BPReading, PatientReadingAvgAndTrendBP, IPatientBPTableData, VitalReading } from '@/interfaces/patient.interface';
import { onMounted } from 'vue';
import { LookBackRange, PatientVitalBPTableHeader, ReadingTrend, VITAL } from '@/enums/readings';
import * as momenttz from 'moment-timezone';
import { IGraphDataPoint } from '@/interfaces/utility.interface';
import { useQuery } from "@tanstack/vue-query";
import { Ref } from 'vue';
import PatientVitalsBPGraph from "./PatientVitalsBPGraph.vue";
import PatientVitalsTable from '../PatientVitalsTable.vue';
import { computed } from 'vue';
import { isEmpty, maxBy ,first, last ,split } from 'lodash';
import { ISource } from '@/interfaces/source.interface';
import { useEventBus } from '@vueuse/core';
import { deviceListEventBusKey } from '../../../../../events/device-list-event.bus';
import Loader from '@/components/common/Loader.vue';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';
import { IPatientVitalConfigureResponse } from '@/interfaces/IVitalConfigure';
import { IBPReading } from '@/interfaces/patientInsights.interface';

const { patientId, dataLoader, patientDataSourceInput, lookBackRangeInput, } = defineProps({
  patientId: {
    type: String,
    required: true
  },
  dataLoader: {
    type: Function as PropType<(patientId: string, lookBackRange: string, sourceId?: string, vital?: VITAL) => Promise<PatientReading[]>>,
    required: true,
  },
  patientDataSourceInput: {
    type: Function as PropType<(patientId: string, vital?: VITAL) => Promise<ISource[]>>,
    required: true,
  },
  lookBackRangeInput: {
    type: Object as PropType<ILookBackRangeInput>,
    required: true,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string, vital: VITAL) => Promise<IPatientVitalConfigureResponse>>,
    required: true,
  },
});

const lookBackRangeList = ref([] as ILookBackRangeItem[]);
const sourceList = ref([] as IPatientDataSourceItem[])

const selectedLookBackRange = ref('ONE_MONTH');
const selectedSource = ref(null as null | string);
const selectedView = ref(0);
const showLoading = ref(false);

const loadingDataSources = ref(false);
const showFetchPatientReadingsErrMsg = ref(false);
const errorMessage = ref('');
const sysTrend = ref('')
const sysPerc = ref(0)
const diaPerc = ref(0)
const diaTrend = ref('')
const sysAvg = ref(0)
const diaAvg = ref(1)
const lastReadTime = ref('')
const lastReading = ref('')
const patientBPDataPoints = ref([] as IGraphDataPoint[])
const patientHRDataPoints = ref([] as IGraphDataPoint[])
const patientReadingTableData = ref([] as IPatientBPTableData[])
const patientReadingTableHeaders = ref(PatientVitalBPTableHeader)
const enableGetPatientReadingsQuery = computed(() => !!selectedSource.value);
const deviceListEventBus = useEventBus(deviceListEventBusKey);
const isVitalDataEmpty = ref(true);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const latestSystolicColor  = ref<string>('');
const latestDiastolicColor = ref<string>('');
const updatedBpColors = ref<boolean>(false);

const getTrendIcon = (trend: string) => {
  if (trend === "DOWN") {
    return 'mdi-arrow-down';
  }
  return 'mdi-arrow-up';
}

const { data: vitalData, isLoading: loading, refetch: reloadVitalData } = useQuery<PatientReading[]>({
  queryKey: ['load-bp-reading', patientId],
  enabled: enableGetPatientReadingsQuery,
  queryFn: async () => {
    showLoading.value = true;
    let patientReadings = [] as PatientReading[];
    await dataLoader(patientId, selectedLookBackRange.value, selectedSource.value as string, VITAL.BP).then((patientReadingsRes) => {
      patientReadings = patientReadingsRes;
      isVitalDataEmpty.value = !!isEmpty(patientReadings);
      showLoading.value = false;
    }).catch(() => {
      showFetchPatientReadingsErrMsg.value = true;
      errorMessage.value = "Error in fetching patient readings";
    });
    return patientReadings;
  },
});

watch(vitalData as Ref<PatientReading[]>, async (updatedVitalData: PatientReading[]) => {
  if (updatedVitalData) {
    loadVitalData(updatedVitalData);
  }
});

const handleColorUpdate = (colors: { systolic: string; diastolic: string; }) => {
  latestSystolicColor.value = colors.systolic;
  latestDiastolicColor.value = colors.diastolic;
  updatedBpColors.value = true;
};

const loadVitalData = (patientVitalData: PatientReading[]) => {
  patientVitalData.forEach((patientReading) => {

    const patientBPReadings = patientReading.dataPoints;

    if (patientBPReadings) {
      const allSysReadings = [] as number[];
      const allDiaReadings = [] as number[];
      const sysGraphDataPoint = [] as [number, number][];
      const diaGraphDataPoint = [] as [number, number][];
      const hrGraphDataPoint = [] as [number, number][];
      patientReadingTableData.value = [];

      patientBPReadings.forEach((patientBPReading: VitalReading) => {
        const { systolic, diastolic, heartRate } = patientBPReading.reading as BPReading;
        const { collectedTimestamp } = patientBPReading;
        allSysReadings.push(systolic);
        allDiaReadings.push(diastolic);
        sysGraphDataPoint.push([Number(collectedTimestamp), systolic]);
        diaGraphDataPoint.push([Number(collectedTimestamp), diastolic]);
        hrGraphDataPoint.push([Number(collectedTimestamp), heartRate]);
        patientReadingTableData.value.push(getFormattedBPVitalData(patientBPReading))
      });

      patientBPDataPoints.value = [{
        name: 'Systolic',
        data: sysGraphDataPoint
      }, {
        name: 'Diastolic',
        data: diaGraphDataPoint
      },]
      patientHRDataPoints.value = [{
        name: 'Heart Rate',
        data: hrGraphDataPoint
      }]

      const latestReading = maxBy(patientBPReadings, 'collectedTimestamp') as VitalReading;

      const { diastolic, systolic } = latestReading.reading as BPReading;
      watchEffect(() => {
        showReadingTrend({
          dia: calculateAvgTrend(allDiaReadings), sys: calculateAvgTrend(allSysReadings),
          latestReading: {
            dateTime: latestReading.collectedTimestamp,
            reading: `${systolic}/${diastolic}`,
            timeZone: latestReading.collectedTimeZone
          }
        });
      });
    }
  });
}

const getFormattedBPVitalData = (dataPoint: VitalReading) => {
  const { reading, collectedTimestamp } = dataPoint as { reading: BPReading; collectedTimestamp: string };
  const formattedDateObject = momenttz.unix(Number(collectedTimestamp) / 1000);
  const formattedDate = formattedDateObject.format('Do MMM YYYY');
  const formattedTime = formattedDateObject.format('hh:mm:ss A');

  return {
    date: formattedDate,
    time: formattedTime,
    dia: reading.diastolic,
    sys: reading.systolic,
    hr: reading.heartRate,
    ihb: reading.ihb == true ? "Yes" : reading.ihb == false ? "No" : 'N/A',
    details: reading.rawData.note ? reading.rawData.note : 'N/A'
  }
}

const showReadingTrend = (readingAvg: PatientReadingAvgAndTrendBP) => {
  sysTrend.value = ReadingTrend[readingAvg.sys.trend];
  diaTrend.value = ReadingTrend[readingAvg.dia.trend];
  sysPerc.value = Math.round((readingAvg.sys.percentage < 0 ? -readingAvg.sys.percentage : readingAvg.sys.percentage) * 100) / 100;
  diaPerc.value = Math.round((readingAvg.dia.percentage < 0 ? -readingAvg.dia.percentage : readingAvg.dia.percentage) * 100) / 100;
  sysAvg.value = Math.round(parseFloat(readingAvg.sys.average.toFixed(1)));
  diaAvg.value = Math.round(parseFloat(readingAvg.dia.average.toFixed(1)));
  const formattedDateObject = momenttz.tz(Number(readingAvg.latestReading.dateTime), readingAvg.latestReading.timeZone);
  lastReadTime.value = formattedDateObject.fromNow();
  lastReading.value = readingAvg.latestReading.reading;

}
const calculateAvgTrend = (readings: number[]) => {
  if (readings.length === 0) {
    return { trend: ReadingTrend.NONE, average: 0, percentage: 0 };
  }

  const average = readings.reduce((sum, reading) => sum + reading, 0) / readings.length;
  const trendIndex =
    readings[0] > average
      ? ReadingTrend.UP
      : readings[0] < average
        ? ReadingTrend.DOWN
        : ReadingTrend.NONE;

  const percentageDifference = ((readings[0] - average) / average) * 100;

  return { trend: trendIndex, average, percentage: percentageDifference };
};

const lookBackShort = (lbRange: string) => {
  return LookBackRange.find(range => (range.value === lbRange))?.short;
}

const lookBackTitle = (lbRange: string) => {
  return LookBackRange.find(range => (range.value === lbRange))?.title;
}

const loadDataSources = async () => {
  loadingDataSources.value = true;
  const sources = await patientDataSourceInput(patientId, VITAL.BP);
  if (!isEmpty(sources)) {
    sourceList.value = sources.map((source) => {
      return {
        title: source.sourceMeta?.name as string,
        value: source.sourceId,
        label: source.sourceMeta?.name as string,
      };
    });
    selectedSource.value = sourceList.value[0].value;
  }
  loadingDataSources.value = false;
}

onMounted(async () => {
  if (vitalData.value) {
    loadVitalData(vitalData.value);
  }
  await loadDataSources();
  lookBackRangeInput.dataLoader().then((lookBackRanges) => {
    lookBackRangeList.value = lookBackRanges;
  });
  deviceListEventBus.on(loadDataSources);

  patientProgramInsightsEvent.on((response) => {
    const parsedResponse = JSON.parse(response);
    if (parsedResponse.patientId === patientId) {
      reloadVitalData();
    }
  })
});

</script>

<style lang="scss" scoped>
.no-wrap-text {
  white-space: nowrap;
}

.loading-container {
  min-height: 40px;
  text-align: center;
}

.no-data-available {
  width: 10%;
  height: auto;
}
</style>
