import gql from "graphql-tag";

export const LIST_TASK_QUEUE = gql`
  query listTaskQueue {
    listTaskQueue {
      taskQueueDefinitionId
      title
      orgId
      color
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      taskId
      orgId
      taskIdentifier
      requestorId
      reporterId
      assignees
      status {
        taskStatusStateId
        orgId
        category
        name
      }
      title
      description
      dueDate
      priority
      commentIds
      customFieldData {
        customFieldId
        data
      }
      currentQueue {
        taskQueueTransitionId
        taskId
        taskQueueId
        taskBoardId
        orgId
        entryAt
        exitAt
        entryFrom
        exitTo
        entryBy
        exitBy
      }
    }
  }
`;

export const LIST_PATIENT_TASK = gql`
  query listTasksByRequestorId($requestorId: UUID!, $filter: TaskFilter) {
    listTasksByRequestorId(requestorId: $requestorId, filter: $filter) {
      task {
        taskId
        orgId
        taskIdentifier
        requestorId
        reporterId
        assignees
        status {
          taskStatusStateId
          orgId
          category
          name
        }
        title
        description
        dueDate
        priority
        commentIds
        customFieldData {
          customFieldId
          data
        }
        currentQueue {
          taskQueueTransitionId
          taskId
          taskQueueId
          taskBoardId
          orgId
          entryAt
          exitAt
          entryFrom
          exitTo
          entryBy
          exitBy
        }
      }
      actors {
        id
        orgId
        name
        active
      }
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
mutation updateTaskStatus( $input: UpdateTaskStatusInput!){
  updateTaskStatus(input : $input) {
    taskId
    orgId
    taskIdentifier
    requestorId
    reporterId
    assignees
    status {
      taskStatusStateId
      orgId
      category
      name
    }
    title
    description
    dueDate
    priority
    commentIds
    customFieldData {
      customFieldId
      data
    }
    currentQueue {
      taskQueueTransitionId
      taskId
      taskQueueId
      taskBoardId
      orgId
      entryAt
      exitAt
      entryFrom
      exitTo
      entryBy
      exitBy
    }
  }
}
`
export const TASK_STATUS_STATE = gql`
  query orgTaskStatusStates {
    orgTaskStatusStates {
      taskStatusStateId
      orgId
      category
      name
      order
    }
  }
`;

export const EDIT_TASK = gql`
mutation updateTaskDetails($updateInput: UpdateTaskInput!){
  updateTaskDetails(updateInput: $updateInput){
    taskId
    requestorId
    assignees
    taskQueueId
    dueDate
    title
    priority
    description
  }
}
`
