import {
  GET_ALL_PROGRAMS,
  GET_ALL_PROGRAMS_CONFIG,
} from "@/graphql/resources/ProgramRepository";
import { apolloClient } from "@/plugins/graphqlclient";

export const getAllPrograms = async () => {
  const getAllPrograms = await apolloClient.query({
    query: GET_ALL_PROGRAMS,
    variables: {},
    fetchPolicy: "network-only",
  });

  return getAllPrograms.data.getAllPrograms;
};

export const getAllProgramsConfiguration = async () => {
  const getAllProgramsConfig = await apolloClient.query({
    query: GET_ALL_PROGRAMS_CONFIG,
    variables: {},
    fetchPolicy: "network-only",
  });

  return getAllProgramsConfig.data.getAllProgramsConfiguration;
};
