export const OrganizationType = [
    {
      title: 'Hospital',
      value: 'HOSPITAL',
    },
    {
      title: 'Clinic',
      value: 'CLINIC',
    },
    {
      title: 'Other',
      value: 'OTHER',
    },
  ];
