<script setup lang="ts">
import { ref } from 'vue';
import { setIdleTimeout } from './composables/idleCalculation';
import { useRouter, useRoute } from 'vue-router';
import { getLoginDetails } from './services/common.service';
import { userDetailEventBusKey } from './events/bus-keys/user-details-event.bus-key';
import { useEventBus } from '@vueuse/core';
import { logoutUser } from './composables/CognitoService';

const router = useRouter();
const route = useRoute();

router.afterEach(() => setIdleTimeout());

const loginExistingData = ref('' as string | null);
const userDetailEventBus = useEventBus(userDetailEventBusKey);

window.addEventListener('storage', async (event) => {
  if (event.key === 'loginUser') {
    loginExistingData.value = event.newValue;
    if (loginExistingData.value !== null) {
      const user = await getLoginDetails();
      userDetailEventBus.emit(user);
    }
  } else {
    router.push({
      path: '/login',
    });
    logoutUser();
  }
});


</script>

<template>
  <router-view :key="route.fullPath" />
</template>

<style lang="scss">
body {
  min-width: 100% !important;
}

#app {
  width: 100% !important;
  max-width: 100% !important;
}
</style>
