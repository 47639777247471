import gql from "graphql-tag";

export const INVENTORY_ITEM_TYPE = gql`
  query inventoryItemTypes($serviceProviderId: UUID!) {
    orgInventory {
      inventoryItemTypes(serviceProviderId: $serviceProviderId) {
        label
        type
      }
    }
  }
`;

export const INVENTORY_ITEM_CREATION = gql`
  mutation addItemToInventory($input: AddItemToInventoryInput!) {
    orgInventory {
      addItemToInventory(input: $input) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          meta
        }
      }
    }
  }
`;

export const GET_INVENTORY_ITEMS = gql`
  query inventoryItems($serviceProviderId: UUID!) {
    orgInventory {
      inventoryItems(serviceProviderId: $serviceProviderId) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          meta
          patient {
            name
            id
          }
        }
        meta
      }
    }
  }
`;

export const CHECK_NAME_EXISTS = gql`
query checkDeviceNameExists($name: String!) {
  orgInventory {
    checkDeviceNameExists(name: $name)
  }
}
`
export const PATIENT_WITHOUT_DEVICE = gql`
query patientsWithoutDeviceAssigned {
  orgInventory {
    patientsWithoutDeviceAssigned{
      count
      records
    }
  }
}
`

export const ASSIGN_DEVICE = gql`
  mutation assignInventoryItem($itemId: UUID!, $input: ItemAssociationInput!) {
    orgInventory {
      assignInventoryItem(itemId: $itemId, input: $input) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          patient {
            name
            id
          }
        }
      }
    }
  }
`;
export const REMOVE_ITEM_FROM_INVENTORY = gql`
  mutation removeItemFromInventory($itemId: UUID!) {
    orgInventory {
      removeItemFromInventory(itemId: $itemId)
    }
  }
`;

export const ENABLE_INVENTORY_ITEM = gql`
  mutation enableInventoryItem($itemId: UUID!) {
    orgInventory {
      enableInventoryItem(itemId: $itemId)
    }
  }
`;

export const SHIP_INVENTORY_ITEM = gql`
mutation shipInventoryItem($input: ShipInventoryItemInput!) {
  orgInventory {
    shipInventoryItem(input: $input) {
      id
      orgId
      type {
        label
        type
      }
      name
      serviceProviderId
      externalItemId
      ownerType
      active
      status
      shippingStatus
      association {
        orgId
        patientId
        patientIdentityAtSource
        associatedOn
        meta
        patient {
          name
          id
        }
      }
    }
  }
}
`
export const EDIT_INTEGRATE_DEVICE = gql`
  mutation editInventoryItem($input: InventoryItemUpdateInput !) {
    orgInventory {
      editInventoryItem(input: $input) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          meta
          patient {
            name
            id
          }
        }
        meta
      }
    }
  }
`
