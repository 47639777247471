<template>
  <v-sheet class="py-4">
    <v-card color="surfContainer pa-2" flat>
      <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-file-upload-outline</v-icon>
          <div class="d-flex flex-wrap">
            <span class="ml-4">Upload Documents</span>
            <span class="v-card-subtitle flex-1-1-100"> Upload patient related documents</span>
          </div>
        </div>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn elevation="3" @click="openFileUpload = true" rounded="false"> Upload</v-btn>
        </div>
      </v-card-title>
      <v-card-item>
        <v-data-table :loading="loading" v-model:items-per-page="itemsPerPage" :headers="headers" :items="documents"
          :items-length="totalItems" item-value="name"></v-data-table>
      </v-card-item>
    </v-card>
    <v-dialog width="1100" v-model="openFileUpload" persistent>
      <file-uploader :patient-id="patientId" :get-file-upload-token="getFileUploadToken" @close="openFileUpload = false"
        :create-patient-document="createPatientDocument"></file-uploader>
    </v-dialog>
  </v-sheet>

  <v-snackbar color="error" class="text-white" v-model="isErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import FileUploader from './FileUploader.vue';
import { DocumentFilter, DocumentInput, DocumentListResponse, IDocument, IDocumentList } from '@/interfaces/document-upload.interface';
import { useQuery } from "@tanstack/vue-query";
import moment from 'moment';
import { ActionStatus } from '@/enums/document-upload.enum';
import { isEmpty } from 'lodash';

const props = defineProps({
  getFileUploadToken: {
    type: Function as PropType<(patientId: string) => Promise<string>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  createPatientDocument: {
    type: Function as PropType<(documentInput: DocumentInput) => Promise<IDocument>>,
    required: true,
  },
  patientDocumentLists: {
    type: Function as PropType<(patientId: string, filter: DocumentFilter,) => Promise<DocumentListResponse>>,
    required: true,
  }
});

const { patientId, patientDocumentLists } = props;

const itemsPerPage = ref(10);
const totalItems = ref(0);
const openFileUpload = ref(false);

const loading = ref(false);
const isErrorMessage = ref(false);
const errorMessage = ref('');

const headers = ref([
  { title: "Document Name", key: "documentKey" },
  { title: "Document Title", key: "title" },
  { title: "Document Type", key: "type" },
  { title: "Uploaded By", key: "userName" },
  { title: "Uploaded At", key: "createdAt" },
]);

const documents = ref([] as IDocumentList[]);

const getPatientDocuments = (): Promise<IDocumentList[]> => {
  loading.value = true;
  const filter: DocumentFilter = {
    limit: 0,
    offset: 0,
  };
  return patientDocumentLists(patientId, filter)
    .then((data) => {
      const { documentData = [] } = data;

      if (isEmpty(documentData)) {
        return [];
      }

      const documentsData = transformDocumentData(documentData);
      documents.value = documentsData;
      totalItems.value = data?.totalCount || 0;

      return documents.value;
    })
    .catch((error) => {
      isErrorMessage.value = true;
      errorMessage.value = (error as Error).message || 'An error occurred';
      return [];
    }).finally(() => {
      loading.value = false;
    });
};

const transformDocumentData = (documentData: IDocument[]): IDocumentList[] => {
  const transformedData = documentData.map(({ metaData, createdAt, ...document }) => {
    const docMetaData = metaData.find((data) => data.action === ActionStatus.UPLOAD)!;
    const { firstName, lastName } = docMetaData.orgUser;
    const documentDetails = {
      ...document,
      userName: `${firstName} ${lastName}`.trim(),
      createdAt: createdAt ? moment(Number(createdAt)).format('MM/DD/YYYY hh:mm a') : 'N/A',
    };
    return documentDetails;
  });

  return transformedData;
};

const { data: docsResponse, isLoading } = useQuery({
  queryKey: ['patientDocuments'],
  queryFn: () => getPatientDocuments(),
});

</script>
