<template>
  <create-patient :patient-detail="patientDetail" :list-org-service-providers="listOrgServiceProviders"
  :is-patient-profile="isPatientProfile" :is-edit-form=true @patient-edited="onPatientEdited" :profile-patient-id="profilePatientId" >
  </create-patient>
</template>

<script lang="ts" setup>
import CreatePatient from '../components/CreatePatient.vue';
import { PropType, defineEmits } from 'vue';
import { IPatientInfo } from '@/interfaces/patient.interface';
import { IOrgServiceProvider } from '@/interfaces/IOrganization';

const emits = defineEmits(['patientEdited']);

const props = defineProps({
  patientDetail: {
    type: Function as PropType<(patientId: string) => Promise<IPatientInfo>>,
    required: true,
  },
  listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },
  isPatientProfile: {
    type: Boolean,
    default: false,
  },
  profilePatientId: {
    type: String,
    required: false,
  }
});

const onPatientEdited = () => {
  emits('patientEdited');
}
</script>
