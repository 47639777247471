<template>
  <v-skeleton-loader v-if="insightsLoading" type="card-avatar, actions" class="mx-auto w-100" color="transparent" />
  <v-card v-else class="mx-auto w-100 text-onSurfaceVar" color="transparent" flat>
    <v-card-item title="Patient Wellness Overview" :class="{ 'pa-0': onlyContent }">
      <template v-slot:title>
        <div v-if="!onlyContent" class="d-flex align-center">
          <v-icon color="critical" size="large">mdi-heart-flash</v-icon>
          <div class="d-flex flex-wrap vt-widget-title-wrap-lg">
            <span class="ml-2">Patient Wellness Overview</span>
            <span class="ml-2 v-card-subtitle flex-1-1-100">Quick peek into patient wellness</span>
          </div>
          <v-spacer />
          <v-btn variant="tonal" rounded="false" @click="routeTo('program-insights-focused')">
            See Full Wellness Insights
          </v-btn>
        </div>
        <div v-else>
          <v-tabs v-if="!insightsLoading" v-model="currentTab" bg-color="surface" grow mandatory>
            <v-tab :value="PATIENT_PRIORITY_GROUPS.HIGH_SEV"
              @click="getSeverCompliancePatientList(VitalsSeverity.HIGH)">
              <div class="text-high my-1">
                <span class="text-h4 font-weight-black">{{
                  patientGroups.high
                  }}</span>
                <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.HIGH)
                  }}</span>
                <v-tooltip activator="parent" location="top">Patients With Urgent Attention Needs</v-tooltip>
              </div>
            </v-tab>
            <v-tab :value="PATIENT_PRIORITY_GROUPS.MOD_SEV" @click="
              getSeverCompliancePatientList(VitalsSeverity.MODERATE)
              ">
              <div class="text-warning my-1">
                <span class="text-h4 text-warning font-weight-black">{{
                  patientGroups.moderate
                  }}</span>
                <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.MODERATE)
                  }}</span>
                <v-tooltip activator="parent" location="left">Patients With Moderate Concerns, Needing
                  Attention</v-tooltip>
              </div>
            </v-tab>

            <v-tab :value="PATIENT_PRIORITY_GROUPS.NORM_SEV"
              @click="getSeverCompliancePatientList(VitalsSeverity.NORMAL)">
              <div class="text-normal my-1">
                <span class="text-h4 font-weight-black">{{
                  patientGroups.normal
                  }}</span>
                <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.NORMAL)
                  }}</span>
                <v-tooltip activator="parent" location="bottom">Patients With No Immediate Concerns</v-tooltip>
              </div>
            </v-tab>
          </v-tabs>
          <v-divider thickness="1" class="mx-1 px-1" />
        </div>

      </template>
      <v-divider thickness="1" v-if="!onlyContent" class="py-1 my-1" />
    </v-card-item>
    <v-card-text class="pa-0">
      <v-container fluid class="pa-1">
        <v-row no-gutters>
          <v-col v-if="!onlyContent" cols="12" md="2">
            <div class="py-1 d-flex justify-center align-start h-100">
              <v-tabs v-if="!insightsLoading" v-model="currentTab" class="pb-1" bg-color="surface" direction="vertical"
                grow mandatory>
                <v-tab :value="PATIENT_PRIORITY_GROUPS.HIGH_SEV" class="my-1"
                  @click="getSeverCompliancePatientList(VitalsSeverity.HIGH)">
                  <div class="text-high my-1">
                    <span class="text-h3 font-weight-black">{{
                      patientGroups.high
                      }}</span>
                    <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.HIGH)
                      }}</span>
                    <v-tooltip activator="parent" location="top">Patients With Urgent Attention Needs</v-tooltip>
                  </div>
                </v-tab>
                <v-divider thickness="1" class="my-1 py-1" />
                <v-tab :value="PATIENT_PRIORITY_GROUPS.MOD_SEV" class="my-1" @click="
                  getSeverCompliancePatientList(VitalsSeverity.MODERATE)
                  ">
                  <div class="text-warning my-1">
                    <span class="text-h3 text-warning font-weight-black">{{
                      patientGroups.moderate
                      }}</span>
                    <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.MODERATE)
                      }}</span>
                    <v-tooltip activator="parent" location="left">Patients With Moderate Concerns, Needing
                      Attention</v-tooltip>
                  </div>
                </v-tab>
                <v-divider thickness="1" class="my-1 py-1" />
                <v-tab :value="PATIENT_PRIORITY_GROUPS.NORM_SEV" class="my-1"
                  @click="getSeverCompliancePatientList(VitalsSeverity.NORMAL)">
                  <div class="text-normal my-1">
                    <span class="text-h3 font-weight-black">{{
                      patientGroups.normal
                      }}</span>
                    <span class="font-weight-bold text-subtitle-2">&nbsp; {{ labelizeSeverity(VitalsSeverity.NORMAL)
                      }}</span>
                    <v-tooltip activator="parent" location="bottom">Patients With No Immediate Concerns</v-tooltip>
                  </div>
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
          <v-col cols="12" :md="onlyContent ? 12 : 10">
            <div :class="{ 'vt-widget-content-list': !onlyContent }">
              <v-divider thickness="1" class="mx-1 px-1" vertical />
              <GroupedPatientVitalsSeverityListVue :patientVitalType="patientVitalType"
                @item-selected="onPatientRowSelection" :show-filter="showFilter" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="showPatientMonitorDialog" persistent fullscreen :scrim="false" class="bg-surface"
      transition="dialog-bottom-transition">
      <patient-monitor :compact-view="true" :patient-profile="selectedPatientProfileProps"
        :get-dependencies="getPatientMonitorDependencies" @request-patient-monitor-exit="handlePatientMonitorExit"
        :full-screen-mode="true" />
    </v-dialog>
  </v-card>
</template>
<script setup lang="ts">
import GroupedPatientVitalsSeverityListVue from "@/components/patient/list/GroupedPatientVitalsSeverityList.vue";
import { Program } from "@/enums/patient-program.enum";
import { PATIENT_PRIORITY_GROUPS } from "@/enums/patient.enum";
import { VitalsSeverity } from "@/enums/patientInsights.enum";
import { VITAL } from "@/enums/readings";
import { getPatientInsightsOverview } from "@/services/patient-program-insight.service";
import { useQuery } from "@tanstack/vue-query";
import { get } from 'lodash';
import { onMounted, ref, computed } from "vue";
import { getPatientMonitorDependencies } from "@/services/PatientMonitor.service";
import { IPatientProfileProps } from "@/interfaces/patient.interface";
import PatientMonitor from "@/components/patient/profile/PatientMonitor.vue";
import { PatientInsight } from "@/interfaces/patientInsights.interface";
import router from "@/router";
import { labelizeSeverity } from '@/composables/utility.composable';


const { onlyContent } = defineProps({
  onlyContent: {
    type: Boolean,
    default: false,
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["insight-selection"]);

const currentTab = ref(PATIENT_PRIORITY_GROUPS.HIGH_SEV);
const patientVitalType = ref("");

const defaultPatientProfileProps: IPatientProfileProps = {
  patientId: "",
  patientInfoLoader: getPatientMonitorDependencies.getPatientByPatientId,
};
const showPatientMonitorDialog = ref(false);
const selectedPatientProfileProps = ref<IPatientProfileProps>(defaultPatientProfileProps);

const { data: programInsights, isLoading: insightsLoading } = useQuery({
  queryKey: ["patientInsightsOverview"],
  queryFn: () =>
    getPatientInsightsOverview({
      program: Program.RPM,
      vital: VITAL.BP,
    }),
});

const getSeverCompliancePatientList = (vitalsType: VitalsSeverity) => {
  patientVitalType.value = vitalsType;
};

const patientGroups = computed(() => {
  if (programInsights.value) {
    const data = get(programInsights.value, "programs.RPM.vitals", {} as {
      severity: {
        critical: number;
        moderate: number;
        normal: number;
      }
    });
    const {
      severity: { critical: high, moderate, normal },
    } = data;
    return {
      high,
      moderate,
      normal
    }
  } else {
    return {
      critical: 0,
      moderate: 0,
      normal: 0
    }
  }

});


const handlePatientMonitorExit = () => {
  selectedPatientProfileProps.value = defaultPatientProfileProps;
  showPatientMonitorDialog.value = false;
};

const onPatientRowSelection = (patientInsight: PatientInsight) => {
  selectedPatientProfileProps.value = {
    ...selectedPatientProfileProps.value,
    patientId: patientInsight.patientId,
  };

  if (!onlyContent) {
    showPatientMonitorDialog.value = true;
  } else {
    emits("insight-selection", patientInsight);
  }
};

const routeTo = (routeName: string) => {
  router.push({
    name: routeName,
    params: {
      startTab: "wellness"
    }
  });
};



onMounted(() => {
  getSeverCompliancePatientList(VitalsSeverity.HIGH);
});
</script>
