import { ACTIVITY_LIST } from "@/graphql/resources/ActivityRepository";
import { apolloClient } from "@/plugins/graphqlclient";

export const listActivities = async () => {
  const activityResponse = await apolloClient.query({
    query: ACTIVITY_LIST,
    variables: {},
    fetchPolicy : 'network-only'
  });
  
  return activityResponse.data.activities;
};
