import { gql } from "@apollo/client";

export const CREATE_ORG_USER = gql`
  mutation createOrgUser($orgUser: [CreateOrgUserInput!]!) {
    createOrgUser(orgUser: $orgUser) {
      orgUserId
      firstName
      lastName
      emailAddress
      mobilePhoneNumber
      homePhoneNumber
      npi
      deaNumber
      designation
      organizationId
    }
  }
`;

export const GET_ALL_ORG_USERS = gql`
  query listOrganizationUsers($filter: OrganizationUserFilterInput, $limit: Int, $offset: Int) {
    listOrganizationUsers(filter: $filter, limit: $limit, offset: $offset) {
      count
      results {
        orgUserId
        userId
        firstName
        lastName
        emailAddress
        mobilePhoneNumber
        homePhoneNumber
        npi
        deaNumber
        designation
        organizationId
        roles
        isActive
      }
    }
  }
`;

export const UPDATE_ORG_USER = gql`
  mutation updateOrgUser($input: UpdateOrgUserInput!) {
    updateOrgUser(input: $input) {
      orgUserId
      userId
      firstName
      lastName
      emailAddress
      mobilePhoneNumber
      homePhoneNumber
      npi
      deaNumber
      designation
      organizationId
      roles
      isActive
    }
  }
`;

export const PATCH_ORG_USER = gql`
  mutation patchOrgUser($input: PatchOrgUserInput!) {
    patchOrgUser(input: $input) {
      orgUserId
      userId
      firstName
      lastName
      emailAddress
      mobilePhoneNumber
      homePhoneNumber
      npi
      deaNumber
      designation
      organizationId
      roles
      isActive
    }
  }
`;

export const GET_ORG_USER_BY_ID = gql`
  query getOrgUserById($orgUserId: String!) {
    getOrgUserById(orgUserId: $orgUserId) {
      orgUserId
      userId
      firstName
      lastName
      emailAddress
      mobilePhoneNumber
      homePhoneNumber
      npi
      deaNumber
      designation
      organizationId
      roles
      isActive
    }
  }
`;
