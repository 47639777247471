import { defineStore } from "pinia";
import { Socket } from "socket.io-client";
import { inRange } from "lodash";
import router from "@/router";

export const useServerTimeSyncStore = defineStore("server-time-sync", {
  state: () => ({
    clockDrift: 0,
  }),
  actions: {
    initServerTimeSyncRequest(socket: Socket) {
      const handleServiceTimeSyncResponse = (time: number) => {
        const allowedDrift =
          import.meta.env.VITE_ALLOWED_CLOCK_DRIFT_PADDING || 60000;
        //@ts-ignore
        const clockDrift = time - new Date().getTime();

        if (inRange(clockDrift, -1 * allowedDrift, allowedDrift)) {
          this.$state.clockDrift = clockDrift;
        } else {
          this.$state.clockDrift = clockDrift;
          router.push({ name: "clock-drift" });
        }
      };

      const serverTimeSyncInterval =
        import.meta.env.VITE_SERVER_TIME_SYNC_INTERVAL || 10000;

      //@ts-ignore
      GoTime.setOptions({
        SyncInitialTimeouts: [0, 3000, 9000, 18000, 45000],
        SyncInterval: serverTimeSyncInterval,
        WhenSynced: function (time: number) {
          handleServiceTimeSyncResponse(time);
        },
        OnSync: function (time: number) {
          handleServiceTimeSyncResponse(time);
        },
      });

      //@ts-ignore
      GoTime.wsSend(function () {
        if (socket) {
          socket.emit("GET_SERVER_TIME");
          return true;
        }
        return false;
      });
    },

    syncServerTime(time: number) {
      //@ts-ignore
      GoTime.wsReceived(time);
    },
  },
});
