<template>
  <v-row>
    <v-col cols="12">
      <v-img src="/vitatraq.svg" alt="Vitatraq logo" height="65" />
    </v-col>
    <v-col cols="12">
      <div class="pa-10" v-if="htmlContent" v-html="htmlContent"></div>
      <p v-else>Loading...</p>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const htmlContent = ref(null);

onMounted(async () => {
  try {
    const response = await fetch('src/assets/vitatraq-tos.html');
    htmlContent.value = await response.text();
  } catch (error) {
    console.error('Failed to load HTML:', error);
  }
});
</script>
