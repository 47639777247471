import gql from 'graphql-tag';

export const GET_ALL_ORGANIZATION_USERS = gql`
query ListOrganizationUsers($filter: OrganizationUserFilterInput, $limit: Int, $offset: Int) {
  listOrganizationUsers(filter: $filter, limit: $limit, offset: $offset) {
    count
    results {
      orgUserId
      firstName
      lastName
      emailAddress
      mobilePhoneNumber
      homePhoneNumber
      npi
      deaNumber
      designation
      organizationId
      roles
    }
  }
}
`;


export const DOES_USER_DEA_EXIST = gql`
  query doesOrgUserDEAExists($deaNumber: String!) {
    doesOrgUserDEAExists(deaNumber: $deaNumber)
  }
`;

export const DOES_USER_NPI_EXIST = gql`
  query doesOrgUserNpiExists($npi: String!) {
    doesOrgUserNpiExists(npi: $npi)
  }
`