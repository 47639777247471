import { IComment, CommentInput } from "@/interfaces/comment.interface";
import { apolloClient } from "../plugins/graphqlclient";
import { LIST_COMMENTS } from "@/graphql/resources/comments/comment.Repository";
import { CREATE_COMMENT } from "@/graphql/resources/CommentRepository";

export const listComments = async (entityId: string): Promise<IComment[]> => {
  try {
    const result = await apolloClient.query({
      query: LIST_COMMENTS,
      variables: {
        entityId,
      },
      fetchPolicy: "network-only",
    });

    return result.data.listComments;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createComment = async (input: CommentInput) => {
  const createdComment = await apolloClient.mutate({
    mutation: CREATE_COMMENT,
    variables: {
      input: input,
    },
  });
  return createdComment;
};
