<template>
  <v-card class="w-100 h-100 pa-2" color="surfContainerHigh">
    <v-container>
      <v-table class="elevation-3">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.key" scope="row" class="font-weight-bold">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="providers.length === 0">
            <td :colspan="columns.length">
              <div class="noDataContainer mt-8">
                <div class="text-h5 font-weight-bold">Nothing found</div>
                <img src="../../../assets/empty.png" alt="No data found" class="noDataImage" />
              </div>
            </td>
          </tr>
          <tr v-else v-for="provider in providers" :key="provider.userId">
            <td class="text-left">{{ provider.firstName }} {{ provider.lastName }}</td>
            <td class="text-left">{{ formatMobilePhoneNumber(provider.mobilePhone) }}</td>
            <td class="text-left">{{ provider.email }}</td>
            <td class="text-left">{{ provider.npi }}</td>
            <td class="text-left">
              <v-chip :color="provider.active ? 'success' : 'error'" rounded text-color="white">
                {{ provider.active ? "Active" : "Inactive" }}
              </v-chip>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-container>
    <Loader :overlay="loadingProvidersInfo" />
  </v-card>
</template>

<script setup lang="ts">

import { PropType, ref, onMounted } from 'vue';
import { IPatientProfileProps, IPatientProviders } from '@/interfaces/patient.interface';
import Loader from '@/components/common/Loader.vue';
import { formatMobilePhoneNumber } from '@/composables/FormUtility'
import { patientDetailsEventBusKey } from '@/events/bus-keys/patient-active-event.bus-keys';
import { useEventBus } from '@vueuse/core'

const columns = [
  { key: 'name', label: 'Provider Name' },
  { key: 'mobilePhone', label: 'Phone' },
  { key: 'email', label: 'Email' },
  { key: 'npi', label: 'NPI' },
  { key: 'active', label: 'Active' },
];
const loadingProvidersInfo = ref(false);
const providers = ref([] as IPatientProviders[])

const { getPatientProviders, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  getPatientProviders: {
    type: Function as PropType<(PatientId: string) => Promise<IPatientProviders[]>>,
    required: true,
  }
});

const getProvidersByPatientId = async () => {
  loadingProvidersInfo.value = true;
  providers.value = await getPatientProviders(patientProfile.patientId);
  loadingProvidersInfo.value = false;
}

onMounted(async () => {
  getProvidersByPatientId();
  useEventBus(patientDetailsEventBusKey).on(() => {
    getProvidersByPatientId();
  })
})

</script>

<style scoped>
.headContainer {
  display: flex;
  justify-content: space-between;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataImage {
  width: 200px;
  height: 200px;
}
</style>

<script lang="ts">
export default { name: 'ProviderDetails' }
</script>
