import { LIST_PATIENT_NOTES } from "@/graphql/resources/notes/NoteRepository";
import { GET_PATIENT_BY_ID, GET_PATIENT_READINGS } from "@/graphql/resources/PatientRepository";
import { EXPORT_SNAPSHOT_PATIENT_INFO } from "@/graphql/resources/snapshot/PatientSnapshot";
import { GET_USER_TIME_LOGS } from "@/graphql/resources/TimeLogRepository";
import { IPatientSnapshotFilterInput, IPatientSnapshotPayload } from "@/interfaces/patient.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const snapshotPatientInfo = async (patientId: string, patientSnapshotFilter: IPatientSnapshotFilterInput): Promise<IPatientSnapshotPayload> => {
  try {
    const { fromDate, toDate } = patientSnapshotFilter;

    const [patientInfo, patientNotes, timeLogs, vitalReadings] =
      await Promise.all([
        apolloClient.query({
          query: GET_PATIENT_BY_ID,
          variables: { patientId },
          fetchPolicy: "network-only",
        }),
        apolloClient.query({
          query: LIST_PATIENT_NOTES,
          variables: {
            mpi: patientId,
            filter: {
              dateRange: {
                fromDate,
                toDate,
              },
            },
          },
          fetchPolicy: "network-only",
        }),
        apolloClient.query({
          query: GET_USER_TIME_LOGS,
          variables: {
            patientId,
            filter: { startTime: fromDate, endTime: toDate },
          },
          fetchPolicy: "network-only",
        }),
        apolloClient.query({
          query: GET_PATIENT_READINGS,
          variables: {
            patientId,
            filter: {
              fromDate,
              toDate,
            },
          },
          fetchPolicy: "network-only",
        }),
      ]);

     return {
       patient: patientInfo.data.getPatientById,
       patientNote: patientNotes.data.listPatientNotes,
       timeLog: timeLogs.data.timeLogs,
       patientVitalReading: vitalReadings.data.patientReadings,
     };
  } catch (err) {
    return Promise.reject(err);
  }
};
