<template>
  <div class="parentContainer">
    <v-card :class="$vuetify.display.smAndUp
      ? 'rounded-lg py-8 cardContainer'
      : 'rounded-lg py-8'
      ">
      <v-container class="container">
        <div class="text-center">
          <img id="logo" :src="logoSrc" class="align-center pr-12" :alt="alternativeText" height="65" contain />
        </div>
        <div class="text-center">
          <v-card-text id="cardTitle" class="font-weight-bold text-h5 py-8" :style="{ color: primaryColor }">
            {{ cardTitle }}
          </v-card-text>
          <div id="caption" class="text-body-2 font-weight-bold">
            {{ caption }}
          </div>
        </div>
        <v-card-text>
          <div class="text-left">
            <v-card-text>
              <v-form ref="resetFormRef" @submit.prevent="handleSubmit" id="resetForm">
                <v-text-field id="verificationCode" class="col-12 mb-5" required variant="outlined"
                  v-model="formData.verificationCode" :type="showVerificationPassword ? 'text' : 'password'"
                  :color="primaryColor" :rules="verificationCodeRules" label="Verification Code"
                  hint="Enter your verification code" :append-inner-icon="showVerificationPassword ? 'mdi-eye' : 'mdi-eye-off'
                    " @click:append-inner="
                      showVerificationPassword = !showVerificationPassword
                      " />
                <v-text-field id="password" class="col-12 mb-5" required variant="outlined" v-model="formData.password"
                  :type="showPassword ? 'text' : 'password'" :color="primaryColor" :rules="passwordRules"
                  :label="passwordLabel" :hint="passwordHint"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword" />
                <v-text-field id="confirmPassword" class="col-12 mb-5" variant="outlined" required
                  v-model="formData.confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                  :color="primaryColor" :rules="confirmPasswordRules" :hint="confirmPasswordHint"
                  :label="confirmPasswordLabel" :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    " @click:append-inner="
                      showConfirmPassword = !showConfirmPassword
                      " />

                <v-btn id="submitButton" block class="mb-5 text-white rounded-xl text-none" rounded="lg"
                  :color="primaryColor" size="large" variant="flat" type="submit" :disabled="isPasswordReset">
                  {{ buttonName }}
                </v-btn>
              </v-form>
            </v-card-text>
            <div class="text-center">
              <router-link id="routeTo" class="login-link" :to="routeToLogin">{{
                routeToName
                }}</router-link>
            </div>
          </div>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
  <v-snackbar color="green" class="text-white" v-model="resetPasswordSuccess" location="top right" min-width="10px">
    Password reset successfully
    <template v-slot:actions>
      <v-icon class="ml-3" @click="resetPasswordSuccess = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" class="text-white" v-model="resetPasswordError" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="resetPasswordError = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <Loader :overlay="loading" />
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";
import { completePasswordReset } from "../composables/CognitoService";
import Loader from "../components/common/Loader.vue";
import { isValidPassword } from "../composables/FormUtility";

defineProps({
  cardTitle: {
    type: String,
    required: false,
    default: "Reset Password",
  },
  primaryColor: {
    type: String,
    required: false,
    default: "primary",
  },
  caption: {
    type: String,
    required: false,
    default: "Please set a new password",
  },
  buttonName: {
    type: String,
    required: false,
    default: "Reset Password",
  },
  logoSrc: {
    type: String,
    required: false,
    default: "/vitatraq.svg",
  },
  alternativeText: {
    type: String,
    required: false,
    default: "Vitatraq logo",
  },
  passwordLabel: {
    type: String,
    required: false,
    default: "New Password",
  },
  passwordHint: {
    type: String,
    required: false,
    default: "Your new password",
  },
  confirmPasswordHint: {
    type: String,
    required: false,
    default: "Re-enter your new password",
  },
  confirmPasswordLabel: {
    type: String,
    required: false,
    default: "Confirm Password",
  },
  routeToLogin: {
    type: [String, Object],
    required: false,
    default: "/login",
  },
  routeToName: {
    type: String,
    required: false,
    default: "Login",
  },
});

const showVerificationPassword = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);

const resetPasswordError = ref(false);
const resetPasswordSuccess = ref(false);
const errorMessage = ref("");
const isPasswordReset = ref(false);

const loading = ref(false);
const resetFormRef: Ref<HTMLFormElement | null> = ref(null);

const passwordRules = [
  (v: string) => !!v || "New password is required",
  (v: string) =>
    isValidPassword(v) ||
    "*Password must include at least 8 characters with one uppercase letter, one lowercase letter, one number, and one special symbol from !, @, $, %, *, ? ",
];

const confirmPasswordRules = [
  (v: string) => !!v || "Confirm password is required",
];
const verificationCodeRules = [
  (v: string) => !!v || "Verification code is required",
];

const formData = ref({
  verificationCode: "",
  password: "",
  confirmPassword: "",
});

const handleSubmit = async () => {
  const { password, confirmPassword, verificationCode } = formData.value;

  try {
    if (password === confirmPassword) {
      loading.value = true;
      await completePasswordReset(verificationCode, confirmPassword);
      loading.value = false;
      resetPasswordSuccess.value = true;
      isPasswordReset.value = true;
      resetFormRef.value?.reset();
    } else {
      loading.value = false;
      errorMessage.value = "Passwords do not match";
      resetPasswordError.value = true;
    }
  } catch (error) {
    loading.value = false;
    errorMessage.value =
      "Unable to reset password, please contact support or try again later";
    resetPasswordError.value = true;
  }
};
</script>

<style scoped>
.cardContainer {
  width: 900px;
}

.container {
  max-width: 500px;
}

.parentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
