<template>
    <v-card flat color="surfContainer" class="w-100 h-100">
        <div class="d-flex flex-column">
            <v-tabs v-model="dataTab" color="primary" class="bg-surface text-primary" density="compact">
                <v-tab value="subscription">
                    <span class="text-subtitle-2 font-weight-bold"> Subscriptions </span>
                </v-tab>
            </v-tabs>
            <v-window v-model="dataTab">
                <v-window-item value="subscription">
                    <e-consent-card-list :patient-profile="patientProfile"
                        :consent-template-loader="getPatientProgramConsentDependencies.getProgramEConsentTemplate"
                        :get-consent-download-url="getPatientProgramConsentDependencies.getProgramEConsentDownloadUrl"
                        :get-consent-upload-url="getPatientProgramConsentDependencies.getProgramEConsentUploadUrl"
                        :sign-patient-program-consent="getPatientProgramConsentDependencies.signPatientProgramConsent"
                        :subscribe-programs="getPatientProgramConsentDependencies.subscribePrograms"
                        :un-subscribe-program="getPatientProgramConsentDependencies.unSubscribeProgram"
                        :data-loader="getPatientProgramConsentDependencies.patientProgramConsents"></e-consent-card-list>
                </v-window-item>
            </v-window>
        </div>
    </v-card>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { PATIENT_COMPLIANCE } from '../../../../enums/patient.enum'
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import EConsentCardList from '@/components/econsent/EConsentCardList.vue';
import { IPatientProgramConsentDependencies } from '@/interfaces/Service.interface';

const dataTab = ref(PATIENT_COMPLIANCE.CURRENT);

const { patientProfile } = defineProps({
    patientProfile: {
        type: Object as PropType<IPatientProfileProps>,
        required: true,
    },
    getPatientProgramConsentDependencies: {
        type: Object as PropType<IPatientProgramConsentDependencies>,
        required: true,
    },
})
</script>