import gql from "graphql-tag";

export const INVENTORY_ITEM_TYPE = gql`
  query inventoryItemTypes($serviceProviderId: UUID!) {
    orgInventory {
      inventoryItemTypes(serviceProviderId: $serviceProviderId) {
        label
        type
      }
    }
  }
`;

export const INVENTORY_ITEM_CREATION = gql`
  mutation addItemToInventory($input: AddItemToInventoryInput!) {
    orgInventory {
      addItemToInventory(input: $input) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          meta
        }
      }
    }
  }
`;

export const GET_INVENTORY_ITEMS = gql`
  query inventoryItems($serviceProviderId: UUID!, $filter: ListInventoryFilter) {
    orgInventory {
      inventoryItems(serviceProviderId: $serviceProviderId, filter: $filter) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          meta
          patient {
            name
            id
          }
        }
        meta
      }
    }
  }
`;

export const CHECK_NAME_EXISTS = gql`
query checkDeviceNameExists($name: String!) {
  orgInventory {
    checkDeviceNameExists(name: $name)
  }
}
`
export const UNASSIGN_DEVICE = gql`
  mutation unassignInventoryItem($itemId: UUID!) {
    orgInventory {
      unassignInventoryItem(itemId: $itemId) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          associatedOn
          meta
          orgId
          patientIdentityAtSource
          patient {
            id
            name
          }
        }
      }
    }
  }
`;

export const ASSIGN_DEVICE = gql`
  mutation assignInventoryItem($itemId: UUID!, $input: ItemAssociationInput!) {
    orgInventory {
      assignInventoryItem(itemId: $itemId, input: $input) {
        id
        orgId
        type {
          label
          type
        }
        name
        serviceProviderId
        externalItemId
        ownerType
        active
        status
        shippingStatus
        association {
          orgId
          patientId
          patientIdentityAtSource
          associatedOn
          patient {
            name
            id
          }
        }
      }
    }
  }
`;

