import {
  CREATE_PATIENT_DOCUMENT,
  FILE_UPLOAD_TOKEN,
  PATIENT_DOCUMENTS,
} from "@/graphql/resources/FileUplaodRepository";
import {
  DocumentFilter,
  DocumentInput,
  DocumentListResponse,
  IDocument,
} from "@/interfaces/document-upload.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const getFileUploadToken = async (
  patientId: string,
): Promise<string> => {
  const response = await apolloClient.mutate({
    mutation: FILE_UPLOAD_TOKEN,
    variables: { patientId },
  });

  return response.data.getFileUploadToken;
};

export const createPatientDocument = async (
  documentInput: DocumentInput,
): Promise<IDocument> => {
  const response = await apolloClient.mutate({
    mutation: CREATE_PATIENT_DOCUMENT,
    variables: { documentInput },
  });

  return response.data;
};

export const patientDocumentLists = async (
  patientId: string,
  filter: DocumentFilter,
): Promise<DocumentListResponse> => {
  const response = await apolloClient.query({
    query: PATIENT_DOCUMENTS,
    variables: { patientId, filter },
    fetchPolicy: "network-only",
  });

  return response.data.patientDocuments;
};
