<template>
    <v-card class="pa-2 my-2" variant="tonal" color="primary">
        <v-card-title class="d-flex justify-space-around">
            <div class="d-flex align-center">
                <v-avatar rounded :color="avatarColor" class="text-h6 mx-2 font-weight-black" size="60">
                    <h4 class="text-h4 font-weight-black">{{ patientInfo.firstName.charAt(0).toUpperCase()
                        }}{{ patientInfo.lastName.charAt(0).toUpperCase() }}
                    </h4>
                </v-avatar>
            </div>
            <div class="text-onSurfaceVar d-flex align-center flex-column">
                <h6 class="text-h6 font-weight-bold">{{ patientName }} </h6>
                <span class="text-subtitle-2"> {{ age(patientInfo.dob!) }} years, {{
                    patientInfo.gender
                    }}</span>
            </div>
            <div class="text-onSurfaceVar d-flex align-center flex-column">
                <h6 class="text-h6 font-weight-bold">{{ getPrimaryProviderName(patientInfo.providers) }} </h6>
                <span class="text-subtitle-2">Primary Provider</span>
            </div>
            <div class="text-onSurfaceVar d-flex align-center flex-column">
                <h6 class="text-h6 font-weight-bold">{{ patientInfo.city }} </h6>
                <span class="text-subtitle-2">{{ patientInfo.state }}</span>
            </div>
        </v-card-title>
    </v-card>

</template>
<script setup lang="ts">
import { IPatientInfo, Provider } from '@/interfaces/patient.interface';
import { computed, PropType } from 'vue';
import { age } from '@/helpers/date.helpers';
import { filter, head } from 'lodash';
import { IProvider } from '../../../interfaces/patient.interface';

const { patientInfo } = defineProps({
    patientInfo: {
        type: Object as PropType<IPatientInfo>,
        required: true,
    },
});

const patientName = computed(() => {
    return `${patientInfo.firstName} ${patientInfo.lastName}`;
});

const avatarColor = computed(() => {
    return patientInfo.isDeceased ? 'error' : patientInfo.isActive ? 'success' : 'warning';
});

const getPrimaryProviderName = (providers: IProvider[]): string => {
  const primaryProvider = head(filter(providers, (provider) => { return provider.isPrimaryProvider }));
  if (primaryProvider) {
    return `${primaryProvider.firstName} ${primaryProvider.lastName}`;
  }

  return 'N/A';
}

</script>
