<template>
  <v-card id="card" elevation="0" class="w-100 d-flex justify-center align-center" color="surfContainer">
    <v-card class="w-100" max-width="600" max-height="600" elevation="3">
      <v-card-title class="bg-surface d-flex justify-center">
        <img src="/vitatraq.svg" class="align-center  pa-4" alt="Vitatraq" height="46" contain />
      </v-card-title>
      <v-card-text class="bg-surface d-flex align-center">
        <v-container fluid>
          <v-row justify="center">
            <v-col>
              <div class="text-center mb-10">
                <v-card-text id="card-title" class="font-weight-bold text-h5 py-8" :style="{ color: primaryColor }">
                  {{ cardTitle }}
                </v-card-text>
                <div class="text-center">
                  <div class="captionStyle" id="caption">
                    {{ caption }}
                  </div>
                </div>
              </div>
              <div>
                <v-form class="text-center" @submit.prevent="handleSubmit">
                  <v-text-field v-model="email" id="email" class="col-12 mb-10 text-left" required variant="outlined"
                    :color="primaryColor" :label="emailLabel" :hint="emailHint" :rules="[
                  (v) => !!v || 'Email is required',
                  (v) => isValidEmail(v) || 'Email must be valid',
                ]" />
                  <v-btn block class="mb-5 text-white" rounded="lg" :color="primaryColor" :loading="btnLoading"
                    size="large" variant="flat" type="submit" elevation="3">
                    {{ buttonName }}
                  </v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="ma-4">
        <v-spacer />
        <div class="text-center">
          <router-link id="routeToLogin" class="login-link font-weight-bold text-deep-purple-darken-1"
            :to="routeToLogin">
            <v-icon class="mr-2 black">mdi-arrow-left</v-icon>Back to {{ routeToName }}</router-link>
        </div>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { submitForgotPasswordRequest } from "../composables/CognitoService";
import router from "../router";
import { isValidEmail } from "../composables/FormUtility";

const email = ref("");
const btnLoading = ref(false);

const handleSubmit = async () => {
  if (isValidEmail(email.value)) {
    try {
      btnLoading.value = true;
      await submitForgotPasswordRequest(email.value);
      btnLoading.value = false;
      router.push({ name: "mail-confirmation" });
    } catch (error) {
      console.error("Forgot password failed:", error);
      btnLoading.value = false;
    }
  }
};

defineProps({
  cardTitle: {
    type: String,
    default: "Forgot Password?",
  },
  caption: {
    type: String,
    default:
      "No worries, we will send you instructions to reset your password.",
  },
  primaryColor: {
    type: String,
    default: "primary",
  },
  buttonName: {
    type: String,
    default: "Send Email",
  },
  logoSrc: {
    type: String,
    default: "/vitatraq.svg",
  },
  alternativeText: {
    type: String,
    default: "Vitatraq logo",
  },
  emailLabel: {
    type: String,
    default: "Email Address",
  },
  emailHint: {
    type: String,
    default: "Your email address",
  },
  routeToLogin: {
    type: String,
    default: "/login",
  },
  routeToName: {
    type: String,
    default: "Login",
  },
});
</script>

<style scoped>
.cardContainer-md {
  width: 900px;
  height: 676px;
}

.cardContainer-sm {
  width: 100%;
}

.captionStyle {
  font: cabin;
  font-weight: 700;
  font-size: 14px;
}
</style>
