import { GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID } from "@/graphql/resources/OrganizationServiceProviderRepository";
import { apolloClient } from "@/plugins/graphqlclient";

export const getAllOrgServiceProviders = async (orgId: string) => {
    const response = await apolloClient.query({
      query: GET_ORG_SERVICE_PROVIDERS_BY_ORG_ID,
      variables: {
        orgId: orgId,
      },
      fetchPolicy: "network-only",
    });
  
    return response.data.orgServiceProviders.listOrgServiceProvidersByOrgId;
  };