<template>
  <v-card class="w-100 vital-config-card" flat color="surfContainer">
      <v-tabs v-model="selectedTab" color="primary" class="bg-surfContainerHigh text-primary" density="compact">
        <v-tab :value="Vitals.BP">
          <span class="text-subtitle-1 font-weight-bold"> Blood Pressure</span>
        </v-tab>
        <v-tab :value="Vitals.PULSE">
          <span class="text-subtitle-1 font-weight-bold"> Pulse</span>
        </v-tab>
        <v-tab :value="Vitals.SPO2">
          <span class="text-subtitle-1 font-weight-bold"> SPO2</span>
        </v-tab>
      </v-tabs>
      <v-window v-model="selectedTab">
        <v-window-item :value="Vitals.BP">
          <BPRangeCriteria :vitalBPConfigurationData="vitalBPConfigurationData" :patientId="patientId"
            :vital-criteria-compact-view="vitalCriteriaCompactView" @update:range-selection="emittedMeasurementData">
          </BPRangeCriteria>
        </v-window-item>
        <v-window-item :value="Vitals.PULSE">
          <PulseRangeCriteria :vitalPulseConfigurationData="vitalBPConfigurationData" :patientId="patientId"
            :vital-criteria-compact-view="vitalCriteriaCompactView" @update:range-selection="emittedMeasurementData">
          </PulseRangeCriteria>
        </v-window-item>
        <v-window-item :value="Vitals.SPO2">
          <SPO2RangeCriteria :vitalSPO2ConfigurationData="vitalBPConfigurationData" :patientId="patientId"
            :vital-criteria-compact-view="vitalCriteriaCompactView" @update:range-selection="emittedMeasurementData">
          </SPO2RangeCriteria>
        </v-window-item>
      </v-window>
  </v-card>
</template>
<script setup lang="ts">
import { ref, PropType, watch } from 'vue';
import { IVitalConfigure, IVitalSPO2Thresholds, IVitalThresholds } from '@/interfaces/IVitalConfigure';
import { isEmpty, first, last } from 'lodash';
import BPRangeCriteria from './config/bp/BPRangeCriteria.vue';
import PulseRangeCriteria from './config/pulse/PulseRangeCriteria.vue';
import SPO2RangeCriteria from './config/spO2/SPO2RangeCriteria.vue';
import { Vitals } from '../../enums/patientInsights.enum';
import { PulseVitalThresholdCriteria, BPVitalThresholdCriteria, IVitalReadingFrequency, SPO2VitalThresholdCriteria } from '../../interfaces/IVitalConfigure';

const props = defineProps({
  vitalBPConfigurationData: {
    type: Object as PropType<IVitalConfigure>,
    required: true,
  },
  patientId: {
    type: String,
    required: false
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['emitted:vitalBPConfigurationData', 'emitted:vitalType']);
const selectedTab = ref(Vitals.BP);

const vitalConfig = ref<BPVitalThresholdCriteria | PulseVitalThresholdCriteria | SPO2VitalThresholdCriteria>()

const emittedMeasurementData = (vitalReadingData: IVitalThresholds[] | IVitalSPO2Thresholds[]) => {

  if (selectedTab.value === Vitals.BP) {
    const bpReading = vitalReadingData as IVitalThresholds[];

    if (isEmpty(vitalConfig.value)) {
      vitalConfig.value = {
        systolic: { thresholds: {}, criteria: (props.vitalBPConfigurationData.config as BPVitalThresholdCriteria).systolic.criteria },
        diastolic: { thresholds: {}, criteria: (props.vitalBPConfigurationData.config as BPVitalThresholdCriteria).diastolic.criteria },
        ihr: (props.vitalBPConfigurationData.config as BPVitalThresholdCriteria).ihr
      } as BPVitalThresholdCriteria;
    }

    (vitalConfig.value as BPVitalThresholdCriteria).systolic.thresholds = first(bpReading) as IVitalThresholds;
    (vitalConfig.value as BPVitalThresholdCriteria).diastolic.thresholds = last(bpReading) as IVitalThresholds;
  }

  if (selectedTab.value === Vitals.PULSE) {
    const pulseReading = vitalReadingData as IVitalThresholds[];

    if (isEmpty(vitalConfig.value)) {
      vitalConfig.value = {
        pulse: { thresholds: {}, criteria: (props.vitalBPConfigurationData.config as PulseVitalThresholdCriteria).pulse.criteria },
      } as PulseVitalThresholdCriteria;
    }
      (vitalConfig.value as PulseVitalThresholdCriteria).pulse.thresholds = first(pulseReading) as IVitalThresholds;
  }

  if (selectedTab.value === Vitals.SPO2) {
    const spO2Reading = vitalReadingData as IVitalSPO2Thresholds[];

    if (isEmpty(vitalConfig.value)) {
      vitalConfig.value = {
        spO2: { thresholds: {}, criteria: (props.vitalBPConfigurationData.config as SPO2VitalThresholdCriteria).spO2.criteria },
      } as SPO2VitalThresholdCriteria;
    }
    (vitalConfig.value as SPO2VitalThresholdCriteria).spO2.thresholds = first(spO2Reading) as IVitalSPO2Thresholds;
  }

  const configData: IVitalConfigure = {
    config: vitalConfig.value as BPVitalThresholdCriteria | PulseVitalThresholdCriteria | SPO2VitalThresholdCriteria,
    reportingFrequency: props.vitalBPConfigurationData.reportingFrequency as IVitalReadingFrequency
  }

  const dataWithoutTypename = cleanVitalConfigurationData(configData);
  
  emit('emitted:vitalBPConfigurationData', dataWithoutTypename, selectedTab.value);

};

function cleanVitalConfigurationData(configData: IVitalConfigure): any {
  if (Array.isArray(configData)) {
    return configData.map(cleanVitalConfigurationData);
  } else if (typeof configData === 'object' && configData !== null) {
    return Object.fromEntries(
      Object.entries(configData)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => [key, cleanVitalConfigurationData(value)])
    );
  }
  return configData;
}

watch(selectedTab, (newValue) => {
  vitalConfig.value = {} as BPVitalThresholdCriteria | PulseVitalThresholdCriteria | SPO2VitalThresholdCriteria;
  emit('emitted:vitalType', newValue);
})

</script>
<style scoped>
.config-container {
  min-height: 200px;
}

.vital-config-card {
  min-width: 200px;
  padding: 10px !important;
}
</style>
