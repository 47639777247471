<template>
  <v-card color="surface" variant="flat" class="w-100 h-100">
    <v-container fluid v-if="showFilter" class="bg-surface">
      <v-row justify="start">
        <v-col cols="10">
          <template v-for="(value, key) in data" :key="key">
            <v-chip color="tertiary" class="ma-1" size="small" :disabled="loading" closable rounded
              @click:close="removeFilter(key)" v-if="value !== '' && value !== 0">
              <span class="text-caption">{{ key }}: {{ value }}</span>
            </v-chip>
          </template>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-center">
          <v-btn size="x-small" rounded variant="tonal" elevation="3" icon="mdi-filter">
            <v-icon icon="mdi-filter" />
            <v-dialog v-model="dialog" transition="dialog-top-transition" activator="parent" width="auto">
              <CompliancePatientFilter @compliance-patient="submitSeverityPatientFilter" @close="dialog = false">
              </CompliancePatientFilter>
            </v-dialog>
          </v-btn>
          <v-btn size="x-small" class="mx-4" variant="tonal" elevation="3" @click="clearFilters" v-if="hasFilters"
            rounded icon="mdi-filter-remove" />
        </v-col>
        <v-divider thickness="1" class="mb-2"></v-divider>
      </v-row>
    </v-container>
    <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items-length="totalItems"
      :items="severPatientLists" :loading="loading" :search="search" item-value="name"
      :items-per-page-options="itemsPerPageOptions" @update:options="paginationChanged" @click:row="onRowClick"
      @update:sort-by="updateSortBy" density="compact" />
  </v-card>
</template>
<script setup lang="ts">
import { ISortData, PatientInsight, PatientProgramVitalsFilter, PatientProgramVitalsSortBy, SeverityPatientFilter } from '@/interfaces/patientInsights.interface';
import { ref, defineProps, computed, onMounted, watch } from 'vue';
import CompliancePatientFilter from "../filter/CompliancePatientFilter.vue"
import { apolloClient } from '@/plugins/graphqlclient';
import { PATIENT_LISTS_BY_VITAL_COMPLIANCE } from '@/graphql/resources/patientInshights/patientProgramInsightsRepository';
import { PatientProgramVitalsSortKey, SortOrder, Vitals } from '../../../enums/patientInsights.enum';
import { daysFromNow } from '@/helpers/date.helpers';
import { defaultTo, map } from 'lodash';
import { useEventBus } from '@vueuse/core';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';

const props = defineProps({
  patientVitalType: {
    type: String,
    required: true,
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
})

const itemsPerPageOptions = [5, 10, 25, 50, 100, -1];
const isSorted = ref(false);
const itemsPerPage = ref(props.showFilter ? import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO : import.meta.env.VITE_DASHBOARD_TOTAL_VISIBLE_PAGE_NO);
const totalItems = ref(0);
const loading = ref(false);
const search = ref('');
const severPatientLists = ref([] as PatientInsight[]);
const dialog = ref(false);
const complianceType = ref();
const data = ref({});
const sortingData = ref();

type DataTableHeader = {
  key: string;
  value?: string;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  align?: 'start' | 'end';
  width?: number;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  sort?: (a: any, b: any) => number;
};
const headers: DataTableHeader[] = [
  {
    title: 'First Name',
    align: 'start',
    key: 'firstName',
  },
  { title: 'Last Name', align: 'start', key: 'lastName' },
  { title: 'Days Active', key: 'daysActive', align: 'start' },
  { title: 'Days Missed', key: 'daysMissed', align: 'start' },
  { title: 'Days Remaining', key: 'daysLeft', align: 'start' },
];

const emits = defineEmits(['item-selected'])
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const page = ref(1);
const hasFilters = computed(() => {
  return Object.keys(data.value).length > 0 && (Object.values(data.value).some(value => value));
});

const loadCompliancePatient = () => {
  loading.value = true;
  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;
  getCompliancePatientList(limit, offset, isSorted.value ? sortingData.value : undefined);
};

const paginationChanged = ({ page: updatedPage, itemsPerPage: updatedItemsPerPage }: { page: number; itemsPerPage: number }) => {
  page.value = updatedPage;
  itemsPerPage.value = updatedItemsPerPage;
  if (complianceType.value) {
    loadCompliancePatient();
  }
};

const submitSeverityPatientFilter = async (patientFiltrationData: SeverityPatientFilter) => {
  dialog.value = false;

  const PatientProgramVitalsFilter = {
    patientName: patientFiltrationData.patientName,
    daysActive: Number(patientFiltrationData.daysActive),
    daysMissed: Number(patientFiltrationData.daysMissed),
    daysLeft: Number(patientFiltrationData.daysLeft),

  } as PatientProgramVitalsFilter;
  data.value = PatientProgramVitalsFilter;

  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;

  await getCompliancePatientList(limit, offset, isSorted.value ? sortingData.value : undefined);

}

const removeFilter = (keyToRemove: string) => {
  data.value = Object.fromEntries(
    Object.entries(data.value).filter(([key]) => key !== keyToRemove)
  );
  loadCompliancePatient();
}

const clearFilters = () => {
  data.value = {};
  itemsPerPage.value = 10;
  loadCompliancePatient();
}

const updateSortBy = (sortedData: ISortData[]) => {
  if (sortedData && sortedData.length > 0) {
    isSorted.value = true;
    const [{ key, order }] = sortedData;

    const keyMapping: Record<string, PatientProgramVitalsSortKey> = {
      firstName: PatientProgramVitalsSortKey.patientFirstName,
      lastName: PatientProgramVitalsSortKey.patientLastName,
      daysActive: PatientProgramVitalsSortKey.daysActive,
      daysMissed: PatientProgramVitalsSortKey.daysMissed,
      daysLeft: PatientProgramVitalsSortKey.daysLeft,
    };

    const sortKey = defaultTo(
      keyMapping[key],
      PatientProgramVitalsSortKey.lastSync
    );

    const sortOrder = order.toUpperCase() as SortOrder;

    const sortBy: PatientProgramVitalsSortBy = {
      sortKey,
      sortOrder,
    };

    const offset = (page.value - 1) * itemsPerPage.value;
    const limit = itemsPerPage.value;

    sortingData.value = sortBy;

    getCompliancePatientList(limit, offset, sortBy);
  }
  else {
    isSorted.value = false;
  }
};

const getCompliancePatientList = async (limit: number, offset: number, sortBy?: PatientProgramVitalsSortBy) => {
  loading.value = true;
  const filter = {
    vital: Vitals.BP,
    vitalFilter: {
      compliance: complianceType.value,
    },
    ...data.value,
  } as PatientProgramVitalsFilter;
  const response = await apolloClient.query({
    query: PATIENT_LISTS_BY_VITAL_COMPLIANCE,
    variables: {
      limit: limit,
      offset: offset,
      filter: filter,
      sort: sortBy,
    },
    fetchPolicy: 'network-only'
  });
  const severityPatientListData = response.data.patientListByVitalsCompliance;
  loading.value = false;
  totalItems.value = severityPatientListData.count;
  severPatientLists.value = map(severityPatientListData.results, (patientData) => ({ ...patientData, lastSync: daysFromNow(patientData.lastSync) }));
}

const onRowClick = (
  _meta: unknown,
  event: { item: { raw: PatientInsight } }
) => {
  const data = event.item;
  emits('item-selected', data);
};


watch(() => props.patientVitalType, () => {
  complianceType.value = props.patientVitalType;
  loadCompliancePatient();
})

onMounted(() => {
  if (props.patientVitalType) {
    complianceType.value = props.patientVitalType;
    loadCompliancePatient();
  }

  patientProgramInsightsEvent.on(() => {
    loadCompliancePatient();
  })
})
</script>
