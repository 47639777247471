<template>
  <v-card color="surfContainer pa-2">
    <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
      <div class="d-flex align-center items-center text-primary">
        <v-icon size="large">mdi-timer</v-icon>
        <div class="d-flex flex-wrap">
          <span class="ml-4">Patient Time Logs</span>
          <span class="v-card-subtitle flex-1-1-100"> View and manage time logs for patient </span>
        </div>
      </div>
      <v-spacer />
      <TimeLog :patient-profile="patientProfile" :activity-input="getActionDependencies.listActivities"
        :add-time-log="getActionDependencies.createAddTimeLog" @created-time-log="createdTimeLog()" />
    </v-card-title>
    <v-card-item>
      <ListPatientTimeLog :data-loader="getActionDependencies.getPatientTimeLogs"
        :reload-patient-time-log="reloadPatientTimeLog" :patient-profile="patientProfile"
        :activityOption="getActionDependencies.listActivities" @reloaded-patient-time-log="reloadedTimeLog"
        :list-assignees="listAssignees" :deleteTimeLog="getActionDependencies.deleteTimeLog" />
    </v-card-item>

  </v-card>
</template>
<script setup lang="ts">
import { IActionDependencies } from '@/interfaces/Service.interface';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { PropType } from 'vue';
import ListPatientTimeLog from './ListPatientTimeLog.vue';
import TimeLog from './TimeLog.vue';
import { ref } from 'vue';
import { IAssigneeInput } from '@/interfaces/task.interface';

const { getActionDependencies, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  getActionDependencies: {
    type: Object as PropType<IActionDependencies>,
    required: true
  },
  listAssignees: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
})

const reloadPatientTimeLog = ref(false);

const createdTimeLog = () => {
  reloadPatientTimeLog.value = true;
}

const reloadedTimeLog = () => {
  reloadPatientTimeLog.value = false;
}
</script>
