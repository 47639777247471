import { map } from "lodash";
import {
  ICustomCPTDistributionInput,
  IPatientBillingCycle,
} from "../interfaces/billing.interface";
import { IPatientProgramCycle } from "../interfaces/patient-program-cycle.interface";
import {
  CREATE_CUSTOMIZED_BILLING_DISTRIBUTION,
  GET_PATIENT_PROGRAM_BILLING_CYCLE,
  PROGRAM_BILLING_CONFIGURATION,
  UPDATE_PATIENT_PROGRAM_BILLING_CYCLE,
} from "@/graphql/resources/BillingRepository";
import { apolloClient } from "@/plugins/graphqlclient";
import { BillingDistributionType } from "@/enums/patient-program-cycle.enum";

export const getPatientBillingConfig = async (
  patientId: string,
  filter?: BillingDistributionType,
) => {
  const patientBilling = await apolloClient
    .query({
      query: GET_PATIENT_PROGRAM_BILLING_CYCLE,
      variables: {
        patientId,
        filters: {
          billingType: filter,
        },
      },
      fetchPolicy: "network-only",
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
  return patientBilling.data.patientProgramBillingCycle;
};

export const savePatientBillingConfig = async (
  patientId: string,
  data: {
    patientBillingCycle: IPatientBillingCycle;
    programsForBilling: IPatientProgramCycle[];
  },
) => {
  const selectePrograms = map(data.programsForBilling, (selectedProgram) => {
    const { id, patientProgramId, program } = selectedProgram;
    return { id, patientProgramId, program };
  });
  const updatedPatientBilling = await apolloClient.mutate({
    mutation: UPDATE_PATIENT_PROGRAM_BILLING_CYCLE,
    variables: {
      input: {
        patientId,
        programsSelected: selectePrograms,
      },
    },
  });

  return {
    patientBillingCycle:
      updatedPatientBilling.data.savePatientProgramBillingCycle,
    programsForBilling:
      updatedPatientBilling.data.savePatientProgramBillingCycle
        .programsForBilling,
  };
};

export const createCUstomizedBillingDistribution = async (
  customCPTDistributionInput: ICustomCPTDistributionInput,
): Promise<boolean> => {
  const response = await apolloClient.mutate({
    mutation: CREATE_CUSTOMIZED_BILLING_DISTRIBUTION,
    variables: {
      customCPTDistributionInput: customCPTDistributionInput,
    },
  });

  return response.data;
};

export const getAllProgramConfiguration = async () => {
  const response = await apolloClient.query({
    query: PROGRAM_BILLING_CONFIGURATION,
  });
  return response.data.getAllProgramsConfiguration;
};
