import { defineStore } from 'pinia';
import { useGeolocation, useDevicePixelRatio, useDeviceOrientation, useNetwork } from '@vueuse/core'


export const useClientMetaStore = defineStore('clientMeta', {
state: () => ({
  meta:{
    geolocation: useGeolocation(),
    devicePixelRatio: useDevicePixelRatio(),
    deviceOrientation: useDeviceOrientation(),
    network: useNetwork(),
  }
}),
});