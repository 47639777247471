<template>
  <v-card flat style="overflow-y: hidden" class="mx-auto w-100">
    <v-row justify="center">
      <v-col class="px-10" cols="12">
        <v-card-text class="text-primary text-center py-12 px-16 text-h5 font-weight-bold">Service Provider
          Configuration</v-card-text>
        <v-form>
          <v-select class="text-left" v-model="selectedServiceProviderId" :items="serviceProviderOptions"
            @update:model-value="emitConfigureServiceProvider(selectedServiceProviderId)" item-text="title"
            item-value="value" label="Service Providers" outlined dense variant="outlined"
            :rules="[(v) => !!v || 'Please select a service provider']" color="primary" density="compact">
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :disabled="item.raw.disabled"></v-list-item>
            </template>
          </v-select>
          <v-row justify="end" v-if="!selectedServiceProviderId">
            <v-col sm="4" md="3" class="text-center">
              <v-btn color="primary" class="text-white px-12 my-6" @click="emitClose" variant="tonal"
                rounded="false" block>
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { ServiceProviderOption } from "@/interfaces/IOrganization";

const props = defineProps({
  serviceProviderOptions: {
    type: Array as PropType<ServiceProviderOption[]>,
    default: () => [],
  },
});

const selectedServiceProviderId = ref("");
const emit = defineEmits(["configure-service-provider", "close"]);

const emitConfigureServiceProvider = (serviceProviderId: string) => {

  const selectedServiceProvider = props.serviceProviderOptions.find(provider => {
    return provider.value === serviceProviderId
  })
  emit("configure-service-provider", {
    serviceProviderId: selectedServiceProvider?.value,
    shortCode: selectedServiceProvider?.shortCode
  });
};

const emitClose = () => {
  emit("close", false);
};
</script>

<style scoped></style>
