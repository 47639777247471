import { IPatientMonitorTimeTrackerEvent, IPatientTaskTimeTrackerEvent, IPatientTimerSyncRequestEvent, IPatientTimerSyncResponseEvent } from '@/interfaces/app-activity-tracker.interface';
import type { EventBusKey } from '@vueuse/core';


const patientMonitorTimeTrackerBusKey: EventBusKey<IPatientMonitorTimeTrackerEvent> = Symbol('patient-monitor-timer-event-bus');
const patientTaskTimeTrackerBusKey: EventBusKey<IPatientTaskTimeTrackerEvent> = Symbol('patient-task-timer-event-bus');
const patientTimerSyncRequestBusKey: EventBusKey<IPatientTimerSyncRequestEvent> = Symbol('patient-timer-sync-request-bus');
const patientTimerSyncResponseBusKey: EventBusKey<IPatientTimerSyncResponseEvent> = Symbol('patient-timer-sync-response-bus');
const patientManualTimetrackerBusKey: EventBusKey<string> = Symbol('patient-manual-time-tracker-bus');
const resetActivityStartTimeEventBusKey: EventBusKey<void> = Symbol('reset-activity-start-time-tracker-bus');
const newActivityStartedEventBusKey: EventBusKey<void> = Symbol('new-activity-started-event-bus');


export {
    patientMonitorTimeTrackerBusKey,
    patientTaskTimeTrackerBusKey,
    patientTimerSyncRequestBusKey,
    patientTimerSyncResponseBusKey,
    patientManualTimetrackerBusKey,
    resetActivityStartTimeEventBusKey,
    newActivityStartedEventBusKey
};
