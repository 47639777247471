export const getShippingDetails = (deviceMeta: Record<string, unknown>, deviceShortCode: string) => {
  const deviceTypeMapping: Record<string, (deviceData: Record<string, unknown>) => unknown> = {
    'QARDIO': extractQardioDeviceDetails,
    'TENOVI': extractTenoviDeviceDetails,
  };

  const extractDetailsMethod = deviceTypeMapping[deviceShortCode];

  return extractDetailsMethod ? extractDetailsMethod(deviceMeta) : null;
};

const extractQardioDeviceDetails = (deviceData: Record<string, unknown>) => {
  const shippingStatusKey = 'shippingStatus';
  const trackingCodeKey = 'trackingCode';
  const imeiKey = 'imei';
  const ownByClinicKey = 'ownByClinic';

  const qardioDeviceDetails = {
    shippingStatus: deviceData[shippingStatusKey],
    trackingCode: deviceData[trackingCodeKey],
    imei: deviceData[imeiKey],
    ownByClinic: deviceData[ownByClinicKey],
  };

  return [qardioDeviceDetails];
};

const extractTenoviDeviceDetails = (deviceData: Record<string, unknown>) => {
  const shippingStatusKey = 'status';
  const trackingCodeKey = 'trackingCode';
  const imeiKey = 'imei';
  const ownByClinicKey = 'ownByClinic';

  const tenoviDeviceDetails = {
    shippingStatus: deviceData[shippingStatusKey],
    trackingCode: deviceData[trackingCodeKey],
    imei: deviceData[imeiKey],
    ownByClinic: deviceData[ownByClinicKey],
  };

  return [tenoviDeviceDetails];
};
