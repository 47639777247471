<template>
  <Toaster position="bottom-left" :visibleToasts=5 richColors />
</template>

<script setup lang="ts">
import { defineProps, watch, ref, PropType } from 'vue';
import { Toaster, toast } from 'vue-sonner';
import ToastTemplate from './ToastTemplate.vue';
import { h } from 'vue';
import { IPatientReadingDetail } from '../interfaces/patientInsights.interface';
import { PatientVitalSeverity } from '../enums/patientInsights.enum';
import { useTheme } from 'vuetify'

const theme = useTheme()
const props = defineProps({
  patientDetail: {
    type: Object as PropType<IPatientReadingDetail>,
  },
});
const {warningToastMain ,toastInfoMain, errorToastMain , successToastMain , warningToastSecondary,toastInfoSecondary,errorToastSecondary,successToastSecondary}=theme.current.value.colors
const toastColor = ref({ mainColor: warningToastMain, secondaryColor: warningToastSecondary, bgColor: 'warning'});

const showReadingToast = (newValue: IPatientReadingDetail) => {
  const patientSeverity = newValue.patientSeverity;
  const isOutDated = newValue.isOutDated;
  toastColor.value = getToastColor(patientSeverity, isOutDated);

  toast(
    h(ToastTemplate, {
      patientDetail: newValue,
      toastColor: toastColor.value,
    }), {
    style: {
      backgroundColor: `rgba(var(--v-theme-${toastColor.value.bgColor}),0.25) !important`,
      padding: 0,
      width: '350px',
    },
    closeButton: true,
    class: 'vital-info-toast',
    duration: import.meta.env.VITE_TOAST_VISIBLE_DURATION || 60000,
  });
};

const getToastColor = (severity: PatientVitalSeverity, isOutDated: boolean) => {
  if (isOutDated) {
    return {
      mainColor:toastInfoMain,
      secondaryColor: toastInfoSecondary,
      bgColor: 'info'
    };
  }

  const colorMap: Record<PatientVitalSeverity, { mainColor: string; secondaryColor: string; bgColor: string }>= {
    [PatientVitalSeverity.HIGH]: {
      mainColor: errorToastMain,
      secondaryColor: errorToastSecondary,
      bgColor: 'error'
    },
    [PatientVitalSeverity.MODERATE]: {
      mainColor: warningToastMain,
      secondaryColor: warningToastSecondary,
      bgColor: 'warning'
    },
    [PatientVitalSeverity.NORMAL]: {
      mainColor: successToastMain ,
      secondaryColor: successToastSecondary,
      bgColor: 'success'
    },
  };
  return colorMap[severity] || {
      mainColor: warningToastMain,
      secondaryColor: warningToastSecondary,
      bgColor: 'warning'
    };
};


watch(() => props.patientDetail, (newValue) => {
  if (newValue) {
    showReadingToast(newValue);
  }
});
</script>

<style>
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

[data-sonner-toast] [data-content] {
  display: flex;
  flex-direction: column;
  gap: 2px;
  transform: translateZ(0);
  width: 100%;
}
</style>
