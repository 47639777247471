import { Gender, GenderLabel } from "@/enums/genderType";
import { SEARCH_PATIENTS } from "@/graphql/resources/PatientRepository";
import { IPatientInfo } from "@/interfaces/patient.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import router from "../router";
import { SearchResult } from "@/interfaces/app-entity-search";
import { encodePatientInfo } from "@/composables/patient/view-utility.composable";

const getPatients = async ({
  limit,
  offset,
  searchText,
}: {
  limit: number;
  offset: number;
  searchText: number;
}) => {
  const response = await apolloClient
    .query({
      query: SEARCH_PATIENTS,
      variables: {
        limit,
        offset,
        patientFilter: {
          textSearch: searchText,
        },
      },
      fetchPolicy: "network-only",
    })
    .catch((err) => {
      throw err;
    });

  const patients = response.data.searchPatients.results;
  const patientSearchResult = patients.map(
    (patient: IPatientInfo) =>
      ({
        title: `${patient.firstName} ${patient.lastName}`,
        value: patient.patientId,
        description: `DOB: ${patient.dob}, Gender: ${
          GenderLabel[patient.gender as Gender]
        }`,
        actions: {
          click: () => {
            router.push({
              name: "patientProfileWithInfo",
              params: {
                patientId: patient.patientId,
                patient: encodePatientInfo({
                  ...patient,
                  patientName: `${patient.firstName} ${patient.lastName}`,
                  providers: patient.providers,
                  gender: GenderLabel[patient.gender as Gender],
                }),
              },
            });
          },
        },
      }) as SearchResult,
  );

  return patientSearchResult;
};

export const searchEntity = {
  Patient: getPatients,
} as { [key: string]: Function };
