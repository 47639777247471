<template>
  <v-container fluid>
    <v-form @submit.prevent="saveThreshold">
    <v-row>
      <v-col cols="12">
        <v-card color="surfContainerHigh" rounded="false">
          <v-card-item class="bg-surface">
            <template v-slot:title>
              <div class="d-flex align-center">
                <div class="d-flex flex-wrap vt-widget-title-wrap-lg">
                  <span class="ml-2 text-primary">SPO2 Criteria Configuration</span>
                  <span class="ml-2 v-card-subtitle flex-1-1-100 text-primary">Configure SPO2 criteria for {{ patientId ? 'the patient'
                    : 'organization' }}</span>
                </div>
                <v-spacer />
              </div>
            </template>
          </v-card-item>
          <v-card-item>
            <v-container>
              <v-row no-gutters>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="pb-2">
                  <v-card class="mx-auto w-100 text-onSurfaceVar elevation-1 my-2 mb-4" color="surface">
                    <v-card-tile>
                          <div class="d-flex flex-wrap pa-2 bg-primary">
                            <span class=" text-subtitle-1 ">SPO2 Configuration</span>
                          </div>
                    </v-card-tile>
                    <v-card-text class="px-8">
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <div class="py-8">
                              <Slider v-model="spO2RangeThresholds" :step="1" :lazy="false" :classes="classes"
                                :min="SELECTION_RANGE_DEFAULT.min" :max="SELECTION_RANGE_DEFAULT.max"
                                :format="toolTipFormatFn" @update:model-value="emitUpdatedThresholds()" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col :cols="vitalCriteriaCompactView ? 12 : 6" class="d-flex align-center justify-center">
                  <SPO2RangeCriteriaVisualizer :spO2ValuesRange="spO2ValuesRange" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-item>
          <v-divider />
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions style="float: right;">
        <v-btn class="px-8 mt-2" variant="elevated" rounded="false" type="submit">Save
          Configuration</v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import Slider from "@vueform/slider";
import { ref, PropType, computed, defineEmits, watch } from 'vue';
import { IVitalConfigure, SPO2VitalThresholdCriteria, ISPO2Threshold } from "@/interfaces/IVitalConfigure";
import { Vitals } from '@/enums/patientInsights.enum';
import SPO2RangeCriteriaVisualizer from "./SPO2RangeCriteriaVisualizer.vue";

const props = defineProps({
  vitalSPO2ConfigurationData: {
    type: Object as PropType<IVitalConfigure>,
    required: true,
  },
  patientId: {
    type: String,
    required: false
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const spO2RangeThresholds = ref([0,0]);

const vitalConfig = ref<IVitalConfigure>();
const selectionRangeMin = Number(import.meta.env.VITE_SPO2_CONFIG_THRESHOLD_MIN || 0);
const selectionRangeMax = Number(import.meta.env.VITE_SPO2_CONFIG_THRESHOLD_MAX || 100);

const emits = defineEmits(["update:rangeSelection"]);

const classes = {
  target: "slider-target",
  ltr: "slider-ltr",
  rtl: "slider-rtl",
  horizontal: "slider-horizontal",
  vertical: "slider-vertical",
  textDirectionRtl: "slider-txt-dir-rtl",
  textDirectionLtr: "slider-txt-dir-ltr",
  base: "slider-base bg-critical vt-bp-config-slider",
  connects: "slider-connects",
  connect: "slider-connect",
  origin: "slider-origin",
  handle: "slider-handle",
  handleLower: "slider-handle-lower",
  handleUpper: "slider-handle-upper",
  touchArea: "slider-touch-area",
  tooltip: "slider-tooltip bg-surface",
  tooltipTop: "slider-tooltip-top",
  tooltipBottom: "slider-tooltip-bottom",
  tooltipLeft: "slider-tooltip-left",
  tooltipRight: "slider-tooltip-right",
  active: "slider-active",
  draggable: "slider-draggable",
  tap: "slider-state-tap",
  drag: "slider-state-drag",
};

const SELECTION_RANGE_DEFAULT = {
  min: selectionRangeMin,
  max: selectionRangeMax,
};

const createThresholdObject = (range: number[]) => ({
  critical: Number(SELECTION_RANGE_DEFAULT.min),
  warningMin: range[0],
  warningMax: range[1],
  normal: Number(SELECTION_RANGE_DEFAULT.max),
});

const bpReadingData = ref([
  createThresholdObject(spO2RangeThresholds.value),
]);

const vitalUnit = computed(() => {
  return "%";
});

const spO2Range = computed(() => {
  const config = vitalConfig.value?.config as SPO2VitalThresholdCriteria;
  const spO2Thresholds = config?.spO2?.thresholds as
    | ISPO2Threshold
    | undefined;
  return {
    min: spO2Thresholds?.critical ?? SELECTION_RANGE_DEFAULT.min,
    max: spO2Thresholds?.normal ?? SELECTION_RANGE_DEFAULT.max,
  };
});

const spO2ValuesRange = computed(() => {
  return {
    critical: `${spO2Range.value.min} - ${spO2RangeThresholds.value[0] - 1}`,
    warning: `${spO2RangeThresholds.value[0]} - ${spO2RangeThresholds.value[1] - 1 }`,
    normal: `${spO2RangeThresholds.value[1]} - ${spO2Range.value.max}`,
  };
});

const toolTipFormatFn = (val: number) => {
  return `${val} ${vitalUnit.value}`;
};

const emitUpdatedThresholds = () => {
  bpReadingData.value = [
    createThresholdObject(spO2RangeThresholds.value),
  ];
};

const saveThreshold = () => {
  emits("update:rangeSelection", bpReadingData.value, Vitals.BP);
}

watch(() => props.vitalSPO2ConfigurationData, (newValue) => {
  vitalConfig.value = newValue;

  const config = vitalConfig.value.config as SPO2VitalThresholdCriteria;
  if (config.spO2?.thresholds) {
    const {
      warningMin,
      warningMax,
    } = config.spO2!.thresholds;
    spO2RangeThresholds.value = [
      warningMin,
      warningMax,
    ];

    emitUpdatedThresholds();
  }
}, {deep: true, immediate: true});
</script>

<style src="@vueform/slider/themes/default.css">
.vt-bp-config-slider:first-child {
  background-color: yellow !important;
}
</style>
../thresholds/reportingFrequency/VitalReportingFrequencyConfig.vue
