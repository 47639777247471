import { IFormSelectItem } from "@/interfaces/input.interface";

export const TIME_ZONES: IFormSelectItem[] = [
  { title: "Hawaii-Aleutian Daylight Time	", value: "America/Adak" },
  { title: "Hawaii Standard Time", value: "Pacific/Honolulu" },
  { title: "Alaska Daylight Time", value: "America/Anchorage" },
  { title: "Pacific Daylight Time", value: "America/Los_Angeles" },
  { title: "Mountain Standard Time", value: "America/Phoenix" },
  { title: "Mountain Daylight Time", value: "America/Boise" },
  { title: "Central Daylight Time", value: "America/Chicago" },
  { title: "Eastern Daylight Time", value: "America/New_York" },
];
