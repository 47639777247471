import moment from "moment";
import { useTimeAgo } from "@vueuse/core";

export const daysFromNow = (date: string) => {
  return useTimeAgo(date).value.replace('"', "");
};

export const daysFromNowInt = (date: string) => {
  const currentDate = moment();
  const localTime = moment(date);
  return localTime.diff(currentDate, "days");
};

export const minutesFromNowInt = (date: string) => {
  const currentDate = moment();
  const localTime = moment(date);
  return localTime.diff(currentDate, "minutes")
}

export const age = (dob: string) => {
  const date = new Date(dob);
  const ageDiffMs = Date.now() - date.getTime();
  const ageDate = new Date(ageDiffMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatDateToHoursAndMinutes = (dateTime: string) => {
  const inputDate = moment(dateTime);
  const now = moment();
  const duration = moment.duration(now.diff(inputDate));
  const minutes = Math.floor(duration.asMinutes());
  const hours = Math.floor(duration.asHours());

  const formattedResult = !!hours
    ? `${hours} hour${hours !== 1 ? "s" : ""} ago`
    : `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;

  return formattedResult;
};

export const convertMilliSecondsToMinutes = (milliSeconds: number): number => {
  if (!milliSeconds) {
    return 0;
  }
  const minutes = moment.duration(milliSeconds).asMinutes();
  return Math.round(minutes);
};
