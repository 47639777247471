import { apolloClient } from "@/plugins/graphqlclient";
import { OrgCycleType } from "@/enums/cycle.enum";
import { GET_ORG_CYCLES } from "@/graphql/resources/OrgCycleRepository";

export const getOrgCycles = async (
  orgId: string,
  orgCycleType: OrgCycleType
) => {
  const orgCycles = await apolloClient.query({
    query: GET_ORG_CYCLES,
    variables: {
      orgId,
      orgCycleType,
    },
  });
  return orgCycles.data.orgCycles;
};
