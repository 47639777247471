import { defineStore } from "pinia";
import { Socket } from "socket.io-client";
import { useEventBus } from "@vueuse/core";
import { taskActionEventBusKey } from "@/events/bus-keys/task-events.bus-keys";
import { pushToServer } from "@/composables/events.composable";
import { AppActivityEventType } from '../../enums/app-activity-tracker.enum';
import { ITaskActionEvent } from '../../interfaces/task.interface';
import { patientTaskTimeTrackerBusKey } from "@/events/bus-keys/time-tracking-events.bus-keys";


const taskActivityEventBus = useEventBus(taskActionEventBusKey);
const patientTaskTrackerEventBus = useEventBus(patientTaskTimeTrackerBusKey);

export const useTaskActivityStore = defineStore("taskActivity", {
    state: () => ({
        
    }),
    actions: {
        listenForRemoteTaskActivity(socket: Socket) {            
            /* Listen for task activity from other instances of the app / via socket server */                        
   
            socket.on(AppActivityEventType.TASK_ACTIVITY, (payload: ITaskActionEvent) => {   
                taskActivityEventBus.emit(payload);
            });
        },
        listenForLocalTaskActivity() {
            /* Listen for task activity from local instance of the app via eventBus */
            patientTaskTrackerEventBus.on((event) => {              
                pushToServer({
                    eventType:AppActivityEventType.TASK_ACTIVITY,
                    payload: event,
                })
            });
        },
    },
});