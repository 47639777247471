import { useServerTimeSyncStore } from '../store/modules/server-time-sync.store';
<template>
  <v-container fluid class="clock-drift-msg-container" align-center>
    <v-row justify="center" class="ma-0">
      <v-col cols="12" class="flex align-center justify-center">
        <div>
          <BrandComp />
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <div class="title-container">
          <h6 class="title text-primary mb-10">Your system clock is out of sync</h6>
        </div>
        <div class="d-flex justify-center">
          <v-icon size="10rem" color="primary">mdi mdi-clock-alert-outline</v-icon>
        </div>
        <div class="subHeading mb-10 text-primary mt-4 text-center">
          Update your clock and reload the page
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import router from '@/router';
import { inRange } from 'lodash';
import { onMounted } from 'vue';
import BrandComp from "./BrandComp.vue";
import hasAccess, { viewDashboardRoles } from '@/composables/roleAccess';

onMounted(() => {
  const allowedDrift =
    import.meta.env.VITE_ALLOWED_CLOCK_DRIFT_PADDING || 60000;
  //@ts-ignore
  const clockDrift = GoTime.now() - new Date().getTime();
  if (inRange(clockDrift, -1 * allowedDrift, allowedDrift)) {
    router.push('/')
  }
});

</script>

<style scoped>
.clock-drift-msg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  text-align: center;
}

.title {
  font-size: 4rem;
  font-weight: 900;
}

.subHeading {
  font-size: 3rem;
  font-weight: 600;
}
</style>
