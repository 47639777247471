<template>
  <v-card flat color="surfContainer" class="w-100 h-100">
    <div class="d-flex flex-column">
      <v-tabs color="primary" class="bg-surface text-primary" density="compact">
        <v-tab :value="PATIENT_INTEGRATIONS.RPM">
          <span class="text-subtitle-2 font-weight-bold">
            {{ PATIENT_INTEGRATIONS.RPM }}
          </span>
        </v-tab>
      </v-tabs>
      <v-window v-model="dataTab">
        <v-window-item :value="PATIENT_INTEGRATIONS.RPM"></v-window-item>
        <PatientRPM :list-org-service-providers="getAllOrgServiceProviders"
          :list-source-definition-ids="listSourceDefinitions" :list-external-ids="getPatientSourceExternalIds"
          :patient-profile="patientProfile" :list-org-devices="getOrgDevicesFromQardio"
          :save-patient-source="savePatientSource" :list-patient-devices="listPatientSource"
          :user-handler="getLoggedInUserInfo" :patient-sources-loader="listPatientSources"
          :list-sources-input="listSourcesInput" :assign-inventory-item="assignInventoryItem"
          :inventory-item-input="inventoryItemInput" :ship-inventory-item="createShipInventoryItem"
          :patient-detail="getPatientByPatientId" :get-patient-program-consent-dependencies="getPatientProgramConsentDependencies"
          :get-details-dependencies="getDetailsDependencies"
          ></PatientRPM>
      </v-window>
    </div>
  </v-card>
</template>
<script setup lang="ts">
import { IIntegrationDependencies, IPatientDetailsDependencies, IPatientProgramConsentDependencies } from '@/interfaces/Service.interface'
import { pick } from 'lodash'
import { PropType, ref } from 'vue'
import { PATIENT_INTEGRATIONS } from '../../../../enums/patient.enum'
import PatientRPM from '../../../../components/views/patient/PatientRPM.vue'
import { IPatientProfileProps } from '@/interfaces/patient.interface'

const { getIntegrationDependencies, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },

  getPatientProgramConsentDependencies: {
    type: Object as PropType<IPatientProgramConsentDependencies>,
    required: true,
  },

  getIntegrationDependencies: {
    type: Object as PropType<IIntegrationDependencies>,
    required: true,
  },

  getDetailsDependencies: {
    type: Object as PropType<IPatientDetailsDependencies>,
    required: true,
  },
})

const {
  getOrgDevicesFromQardio,
  getPatientSourceExternalIds,
  listPatientSource,
  listSourceDefinitions,
  savePatientSource,
  getLoggedInUserInfo,
  listPatientSources,
  listSourcesInput,
  assignInventoryItem,
  inventoryItemInput,
  createShipInventoryItem,
  getAllOrgServiceProviders,
  getPatientByPatientId
} = pick(getIntegrationDependencies, [
  'getServiceProviders',
  'listSourceDefinitions',
  'getPatientSourceExternalIds',
  'getOrgDevicesFromQardio',
  'savePatientSource',
  'listPatientSource',
  'getLoggedInUserInfo',
  'listPatientSources',
  'listSourcesInput',
  'assignInventoryItem',
  'inventoryItemInput',
  'createShipInventoryItem',
  'getAllOrgServiceProviders',
  'getPatientByPatientId',
])

const dataTab = ref(PATIENT_INTEGRATIONS.RPM)
</script>
