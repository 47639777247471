<template>
  <v-card variant="text" class="ma-0  pb-0">
    <v-card-title class="vt-form-title">{{ !taskDetails ? "Add Task" : "Edit Task" }}</v-card-title>
    <v-card-item>
      <v-form @submit.prevent="submitActionHandler">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="taskTitle" required label="Title*" variant="outlined"
                hint="Provide a short summary of what needs to be done" :rules="[
      v => !!v || 'Title is required',
      v => (v && v.length <= 75) || 'Title must be less than or equal to 75 characters',
      v => (v && v.trim().length > 0) || 'Title cannot be only empty spaces'
    ]" />
            </v-col>
            <v-col cols="6" v-if="!taskDetails">
              <v-combobox v-model="selectedRequestor" required variant="outlined" dense
                :label="`${requestorInput.label}*`" :hint="requestorInput.description" :items="filteredRequestors"
                @update:search="triggerLoadFilteredRequestors" item-title="label" item-value="value"
                :rules="[isRequestorValid || 'Patient not found', ...requestorInput.validationRules]"
                :loading="requestorsLoading" />
            </v-col>

            <v-col :cols="!taskDetails! ? '6' : '12'">
              <v-select v-model="selectedTaskQueue" variant="outlined" dense :label="`${taskQueueInput.label}*`"
                :hint="taskQueueInput.description" :items="availableTaskQueues" :rules="taskQueueInput.validationRules"
                :loading="taskQueuesLoading" item-title="label" item-value="value" />
            </v-col>
            <v-col cols="4">
              <v-select v-model="selectedTaskPriority" required variant="outlined" dense label="Priority*"
                hint="Select priority" :items="taskPriorities" />
            </v-col>
            <v-col cols="8">
              <VueDatePicker class="vt-date-time-picker-btn" v-model="taskDueDate" teleport-center :is-24="false" utc
                time-picker-inline :min-date="getMinDate()">
                <template #trigger>
                  <v-text-field v-model="taskDueDateFormatted.date" :label="taskDueLabel()" :readonly="true"
                    variant="outlined" hint="When does this need to be done ?" dense clearable
                    :rules="[checkTaskDueValidation]" @click:clear="taskDueDate = ''" />
                </template>
              </VueDatePicker>
            </v-col>
            <v-col cols="12">
              <v-combobox v-model="selectedAssignees" multiple required variant="outlined" dense
                :label="`${assigneeInput.label}*`" :hint="assigneeInput.description" :items="filteredAssignees"
                @update:search="triggerLoadFilteredAssignees" item-title="label" item-value="value"
                :rules="[isAssigneesValid || 'One or more invalid assignee selected', ...assigneeInput.validationRules]"
                :loading="assigneesLoading" chips closable-chips clearable />
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="taskDescription" label="Description" variant="outlined"
                hint="Provide additional details to the assignee(s)" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn variant="tonal" rounded="false" elevation="3" class="mx-2" @click="handleCancel">
                cancel
              </v-btn>
              <v-btn v-if="!taskDetails" variant="elevated" rounded="false" class="mx-2"
                :disabled="!formInputValid || isSubmitting || !isAssigneesValid" type="submit" elevation="3">
                Create and Assign
              </v-btn>
              <v-btn v-else variant="elevated" class="mx-2" rounded="false" elevation="3"
                :disabled="!formInputValid || isSubmitting || !isAssigneesValid" @click="editTaskDetails">
                Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-item>
    <Loader :overlay="loading" />
  </v-card>
</template>

<script setup lang="ts">
import { ref, PropType, onMounted, computed } from 'vue';
import { ICreateTaskInput, ITask, IRequestorInput, IAssigneeInput, ITaskQueueInput, IRequestorItem, IAssigneeItem, ITaskQueueItem, ITaskActor, IRequestor, ITaskQueue } from '../../../interfaces/task.interface';
import { TaskPriority, AddTaskEvents } from '../../../enums/task.enum';
import { enumToArray } from "../../../helpers/utils.helpers";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
import { formatDate, getMinDate } from '../../../composables/FormUtility';
import { debounce, map } from 'lodash';
import Loader from '../../../components/common/Loader.vue';
import { useQueryClient } from '@tanstack/vue-query';

const { requestorInput, assigneeInput, taskQueueInput, submitActionDelegate, taskDetails, taskActors } = defineProps({
  submitActionDelegate: {
    type: Function as PropType<(createTaskInput: ICreateTaskInput) => Promise<ITask>>,
    required: false,
  },
  requestorInput: {
    type: Object as PropType<IRequestorInput>,
    required: true,
  },
  assigneeInput: {
    type: Object as PropType<IAssigneeInput>,
    required: true,
  },
  taskQueueInput: {
    type: Object as PropType<ITaskQueueInput>,
    required: true,
  },
  taskDetails: {
    type: Object as PropType<ITask>,
    required: false
  },
  taskActors: {
    type: Array as PropType<ITaskActor[]>,
    required: false,
  },
});

const emit = defineEmits([AddTaskEvents.CLOSED, AddTaskEvents.SUCCESS, 'editTask']);

const loading = ref(false);
const isSubmitting = ref(false);
const selectedRequestor = ref<IRequestorItem>();
const filteredRequestors = ref<IRequestorItem[]>([]);
const requestorsLoading = ref(false);

const selectedAssignees = ref<IAssigneeItem[]>([]);
const filteredAssignees = ref<IAssigneeItem[]>([]);
const assigneesLoading = ref(false);

const selectedTaskQueue = ref<ITaskQueueItem["value"]>();
const availableTaskQueues = ref<ITaskQueueItem[]>([]);
const taskQueuesLoading = ref(false);

const taskDueDate = ref<string>();
const taskPriorities = enumToArray(TaskPriority);
const selectedTaskPriority = ref<TaskPriority>(TaskPriority.MEDIUM);

const taskTitle = ref<string>();
const taskDescription = ref<string>();

const queryClient = useQueryClient();

const taskDueDateFormatted = computed(() => {
  if (!taskDueDate.value) {
    return { date: '', daysUntilDue: 0 };
  }
  const currentDate = moment();
  const localTime = moment(taskDueDate.value);
  const dueDate = localTime.format('MMM/DD/YYYY,  h:mm A');
  const daysUntilDue = localTime.diff(currentDate, 'days');
  return { date: `${dueDate}`, daysUntilDue };
});

const checkTaskDueValidation = () => {
  const isPastDate = moment(taskDueDate.value).isBefore(moment(), 'minute');
  if (isPastDate) {
    if (taskDetails?.dueDate === taskDueDate.value) {
      return true
    }
    return "Please select a future time"
  } else {
    return true;
  }
};

const isRequestorValid = computed(() => !!selectedRequestor.value?.value?.id);
const isAssigneesValid = computed(() => !!selectedAssignees.value.every(assignee => assignee?.value?.id));

const handleCancel = () => {
  emit(AddTaskEvents.CLOSED);
}

const loadFilteredRequestors = async (search: string) => {
  requestorsLoading.value = true;
  filteredRequestors.value = search
    ? await requestorInput.dataLoader(search)
    : [];
  requestorsLoading.value = false;
};

const triggerLoadFilteredRequestors = debounce(loadFilteredRequestors, 300)

const loadFilteredAssignees = async (search: string) => {
  assigneesLoading.value = true;
  const searchData = {
    orgUserName: search,
    isActive: true,
  };

  const selectedAssigneeIds = map(selectedAssignees.value, "value.id");
  filteredAssignees.value = search
    ? [
      ...(
        await assigneeInput.dataLoader(searchData)
      ).filter(
        (assignee) =>
          assignee.value && !selectedAssigneeIds.includes(assignee.value.id)
      ),
      ...selectedAssignees.value.filter(
        (assignee) =>
          assignee.value && selectedAssigneeIds.includes(assignee.value.id)
      ),
    ]
    : [];
  assigneesLoading.value = false;
};

const triggerLoadFilteredAssignees = debounce(loadFilteredAssignees, 300)

const taskDueLabel = () => {
  if (!taskDueDateFormatted.value.date) {
    return 'Due Date and Time';
  }
  return `Due in ${taskDueDateFormatted.value.daysUntilDue} days`;
}

const submitActionHandler = async () => {
  const title = taskTitle.value;
  const requestor = selectedRequestor.value;
  const assignees = selectedAssignees.value;
  const taskQueue = selectedTaskQueue.value;
  if (title && requestor && assignees) {
    if (taskDueDate.value && checkTaskDueValidation() !== true) {
      return;
    }
    loading.value = true;
    isSubmitting.value = true;
    await submitActionDelegate!({
      task: {
        assignees: map(assignees, (assignee) => assignee.value.id),
        requestorId: requestor.value.id,
        title,
        priority: selectedTaskPriority.value,
        description: taskDescription.value,
        ...(taskDueDate.value && { dueDate: formatDate(taskDueDate.value) }),
      },
      taskQueueId: taskQueue?.id || ''
    });
    queryClient.invalidateQueries({ queryKey: ['taskBoard'] })
    emit(AddTaskEvents.CLOSED);
    emit(AddTaskEvents.SUCCESS);
  }
}

const formInputValid = computed(() =>
  taskTitle.value &&
  taskTitle.value.length <= 75 &&
  taskTitle.value.trim().length > 0 &&
  (!taskDetails ? selectedRequestor.value : true) &&
  selectedAssignees.value.length &&
  selectedTaskQueue.value &&
  (!taskDetails ? isRequestorValid.value : true)
);

const editTaskDetails = async () => {
  const title = taskTitle.value;
  const requestor = selectedRequestor.value;
  const assignees = selectedAssignees.value;
  const taskQueue = selectedTaskQueue.value;
  if (title && assignees) {
    if (taskDueDate.value && checkTaskDueValidation() !== true) {
      return;
    }

    const editedTask = {
      taskId: taskDetails?.id,
      assignees: map(assignees, (assignee) => assignee.value.id),
      title,
      priority: selectedTaskPriority.value,
      description: taskDescription.value,
      ...(taskDueDate.value ? (taskDetails?.dueDate != taskDueDate.value ? { dueDate: formatDate(taskDueDate.value) } : ''): { dueDate: '' }),
      taskQueueId: taskQueue?.id || '',
    };
    emit('editTask', editedTask);
    emit(AddTaskEvents.CLOSED);
  }
}

onMounted(async () => {
  taskQueuesLoading.value = true;
  availableTaskQueues.value = await taskQueueInput.dataLoader();
  taskQueuesLoading.value = false;

  if (taskDetails) {
    taskTitle.value = taskDetails.title;
    taskDescription.value = taskDetails.description;
    taskDueDate.value = taskDetails.dueDate;
    const taskQueue = availableTaskQueues.value.find(task => task.value.id === taskDetails?.currentQueue?.taskQueueId);
    selectedTaskQueue.value = selectedTaskQueue.value = taskQueue?.value;
    selectedTaskPriority.value = taskDetails.priority;

    const assignees = taskActors?.filter(actor => taskDetails?.assignees.includes(actor.id));
    selectedAssignees.value = assignees?.map((assignee) => {
      return {
        label: assignee?.name,
        value: {
          id: assignee?.id as string,
          name: assignee?.name as string
        } as IRequestor
      };
    }) ?? [];
  }
});
</script>

<script lang="ts">
export default {
  name: "AddTaskForm",
};
</script>
