import {
  DOES_PATIENT_EMAIL_EXIST,
  GET_PATIENT,
  GET_PATIENT_PROVIDERS,
  UPDATE_PATIENT,
  GET_PATIENT_BY_ID,
  ADD_PATIENT_EVENT,
} from "@/graphql/resources/PatientRepository";
import { IPatientEvent, IPatientEventInput } from "@/interfaces/patient-event.interface";
import { IUpdatePatientInput } from "@/interfaces/patient.interface";
import { apolloClient } from "@/plugins/graphqlclient";
import { find, first } from "lodash";

export const getPatientByPatientId = async (patientIds: string[]) => {
  const { data } = await apolloClient.query({
    query: GET_PATIENT,
    variables: {
      patientIds: patientIds,
    },

    fetchPolicy: "network-only",
  });

  const { getPatientByIds } = data;
  const patientData = find(getPatientByIds, { patientId: first(patientIds) });

  return patientData;
};

export const getPatientById = async (patientId: string) => {
  const { data } = await apolloClient.query({
    query: GET_PATIENT_BY_ID,
    variables: {
      patientId: patientId,
    },

    fetchPolicy: "network-only",
  });

  const { getPatientById } = data;

  return getPatientById;
};

export const getPatientProviders = async (patientId: string) => {
  const { data } = await apolloClient.query({
    query: GET_PATIENT_PROVIDERS,
    variables: {
      patientId,
    },

    fetchPolicy: "network-only",
  });

  const { getPatientProviders } = data;

  return getPatientProviders;
};

export const updatePatient = async (patientInput: IUpdatePatientInput) => {
  const updatedPatient = await apolloClient.mutate({
    mutation: UPDATE_PATIENT,
    variables: {
      updatePatient: patientInput,
    },
  });
  return updatedPatient.data.updatePatient;
};

export const doesPatientEmailExist = async (
  email: string
): Promise<boolean> => {
  const { data } = await apolloClient.query({
    query: DOES_PATIENT_EMAIL_EXIST,
    variables: {
      email,
    },
    fetchPolicy: "network-only",
  });

  const { doesPatientEmailExist } = data;

  return doesPatientEmailExist;
};

export const savePatientEvent = async (
  input: IPatientEventInput
): Promise<IPatientEvent> => {
  const response = await apolloClient.mutate({
    mutation: ADD_PATIENT_EVENT,
    variables: {
      input: input
    }
  })

  return response.data;
}
