import { Ref, ref } from "vue";
import {
  getProgramEConsentDownloadUrl,
  resetPatientProgramConsent,
} from "@/services/patient-progam.service";
import { IEConsent } from "@/interfaces/econsent.interface";
//@ts-ignore
import html3pdf from "html3pdf";
import { v4 as uuidv4 } from "uuid";

export default function useConsentDownload(loadingRef: Ref<boolean>) {
  const errorMessage = ref("");
  const showEconsentOperationFailedErr = ref(false);

  const handleConsentDownload = async (eConsent: IEConsent) => {
    loadingRef.value = true;
    try {
      const downloadUrl = await getProgramEConsentDownloadUrl(eConsent.id);
      convertToPDF(downloadUrl, `${uuidv4()}.pdf`);
    } catch (err) {
      errorMessage.value =
        "Error in downloading EConsent. Please try again later or contact support";
      showEconsentOperationFailedErr.value = true;
    } finally {
      loadingRef.value = false;
    }
  };

  const handleResetConsent = async ({
    patientId,
    eConsentTemplateId,
  }: {
    patientId: string;
    eConsentTemplateId: string;
  }) => {
    loadingRef.value = true;
    try {
      await resetPatientProgramConsent(patientId, eConsentTemplateId);
    } catch (err) {
      errorMessage.value =
        "Error in resetting EConsent. Please try again later or contact support";
      showEconsentOperationFailedErr.value = true;
    } finally {
      loadingRef.value = false;
    }
  };

  return {
    handleConsentDownload,
    handleResetConsent,
    errorMessage,
    showEconsentOperationFailedErr,
  };
}

function convertToPDF(url: string, fileName: string) {
  const opt = {
    margin: 0.5,
    filename: fileName,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      scale: 1,
    },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  };
  fetch(url)
    .then((response) => response.text())
    .then((html) => {
      const doc = new DOMParser().parseFromString(html, "text/xml");
      const templateContainer = doc.getElementById("templateContainer");
      if (templateContainer) {
        /* Set default styles */
        templateContainer.style.margin = "10px";
        templateContainer.style.color = "#333";
      }
      html3pdf().set(opt).from(doc.getElementById("templateContainer")).save();
    })
    .catch((error) => {
      throw error;
    });
}
