<template>
  <v-card flat class="w-100">
    <v-card-item>
      <v-row class="d-flex  items-center pt-5 px-2">
        <v-col cols="4">
          <v-select variant="solo" color="primary" :items="serviceProviders" v-model="serviceProviderId"
            @update:model-value="getInventoryItem(serviceProviderId)" density="compact"
            label="Service Provider*" required />
        </v-col>
        <v-col cols="4">
          <v-text-field variant="solo" density="compact" v-model="inventoryItemFilter.deviceDetails" label="Search"
            @input="searchByDeviceInputs">
            <template v-slot:append-inner>
              <v-tooltip activator="parent" location="bottom">
                <span>Filter by Device Name, Gateway ID or Device Type</span>
              </v-tooltip>
              <v-icon icon="mdi-magnify" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field variant="solo" @input="searchByDeviceInputs" density="compact"
            v-model="inventoryItemFilter.patientName" label="Filter by Patient Name" :rules="[ validPatientName || 'Please enter valid patient name']" append-inner-icon="mdi-magnify" />
        </v-col>

      </v-row>
    </v-card-item>
  </v-card>
</template>
<script lang="ts" setup>
import { isValidName } from '../../composables/FormUtility';
import { watch, reactive, ref, computed } from 'vue';

const props = defineProps({
  serviceProviders: {
    type: Array,
    required: true
  },
});

const emits = defineEmits(['getInventoryItem', 'inventoryItems'])

const serviceProviderId = ref('');

const inventoryItemFilter = reactive({
  deviceDetails: "",
  patientName: "",
})

const validPatientName = computed(() => {
  return  isValidName(inventoryItemFilter.patientName);
})

const getInventoryItem = (serviceProviderId: string) => {
  emits('getInventoryItem', serviceProviderId);
}

const searchByDeviceInputs = () => {
  if (validPatientName.value) {
    emits('inventoryItems', inventoryItemFilter);
  }
}

watch(() => props.serviceProviders, (newValue) => {
  serviceProviderId.value = newValue[0] as string;
})

</script>
