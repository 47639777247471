import { apolloClient } from "@/plugins/graphqlclient";
import { useUserStore } from "../store/modules/User";
import { ref } from 'vue';
import { GET_USER_INFO } from "@/graphql/resources/UserRepository";
import { checkUserRoles } from "@/composables/SecurityService";
import { IUser } from "@/interfaces/user.interface";
import router from "@/router";
import hasAccess, { viewProgramInsightsRoles } from "../composables/roleAccess";

export const getLoggedInUserInfo =  () => {
  const store =  useUserStore();

  return {
    ...store.$state.user,
  };
};

export const patientCreated = ref('');
export const orgDetailsCreated = ref('');

export const getLoginDetails = async () => {
  const store = useUserStore();
  const response = await apolloClient.query({
    query: GET_USER_INFO,
    fetchPolicy: "network-only",
  });

  const { emailAddress, firstName, lastName, roles, userId, organizationId, organizationTimeZone } =
    response.data.getUserInfo;

  const user = {
    emailAddress,
    firstName,
    lastName,
    roles,
    userId,
    organizationId,
    isLogged: true,
    organizationTimeZone
  } as IUser;

  store.$state.user = user;
  checkUserRoles(roles);

  router.push({ name: getInitialRoute() });
  localStorage.setItem("loginUser", emailAddress);
  return user;
};

const getInitialRoute = () =>
  hasAccess.viewDashBoard(viewProgramInsightsRoles)
    ? "dashboard"
    : "OrganizationList";
