<template>
    <v-card flat class="pb-6 tenovi-form-style px-5">
        <v-container fluid>
            <v-card-text v-if="orgServiceProviderId" class="text-primary text-center pb-12 text-h5 font-weight-bold">
                Update Tenovi Configuration
            </v-card-text>
            <v-form ref="tenoviValidForm" @submit.prevent="emitConfigureServiceProvider">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field label="API Domain*" color="primary" v-model.trim="tenoviForm.apiDomain" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'API domain is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="API Key*" color="primary" v-model.trim="tenoviForm.apiKey" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'API Key is required']"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Client Name*" color="primary" v-model.trim="tenoviForm.clientName" variant="outlined"
                            density="compact" :rules="[(v) => !!v || 'Client name is required']"></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn color="primary" class="text-white px-12" variant="tonal" elevation="3" block
                            @click="emitClose" rounded="false">
                            Cancel
                        </v-btn>
                    </v-col>
                    <v-col sm="12" md="3" class="text-center">
                        <v-btn color="primary" class="text-white px-12" variant="elevated" elevation="3" type="submit" :disabled="!isFormDataValid"
                           rounded="false" block>
                            {{ orgServiceProviderId ? "Update" : "Configure" }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-card>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";

const tenoviValidForm = ref();
const orgServiceProviderId = ref("");

const isValidForm = async () => {
    const isFormValid: boolean = (await tenoviValidForm.value.validate()).valid;
    return isFormValid;
};

const emit = defineEmits([
    "show-configure-service-provider-form",
    "close",
    "show-configure-form",
]);
const props = defineProps(["editData", "organizationServiceProviderId"]);

const { apiDomain = "", apiKey = "", clientName = ""} = props.editData?.configuration || {};

const tenoviForm = reactive({
    apiDomain,
    apiKey,
    clientName,
});

const isFormDataValid = computed(() => {
  const formValues = Object.values(tenoviForm);
  return formValues.every(field => field.trim() !== '');
});

const emitConfigureServiceProvider = async () => {
    const isFormValid = await isValidForm();
    if (isFormValid) {
        emit("show-configure-service-provider-form", tenoviForm);
    }
};

const emitClose = () => {
    emit("close");
};

onMounted(() => {
    if (props.organizationServiceProviderId) {
        orgServiceProviderId.value = props.organizationServiceProviderId;
    }
});
</script>
<style scoped>
.tenovi-form-style{
    width: 650px;
}
</style>
