import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CookieStorage,
  CognitoUserSession,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { useAuthUserStore } from "../store/modules/AuthUser";
import { chain, forEach, includes, trim } from "lodash";
import { useUserStore } from "@/store/modules/User";
import { userSessionEventBusKey } from '@/events/bus-keys/user-events.bus-keys';
import { useEventBus } from "@vueuse/core";
import router from "@/router";

const userSessionEventBus = useEventBus(userSessionEventBusKey);

const poolData = {
  UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
  Storage: new CookieStorage({ domain: window.location.hostname }),
};
export const userPool = new CognitoUserPool(poolData);

const getCognitoUser = (userName: string): CognitoUser => {
  const userData = {
    Username: userName,
    Pool: userPool,
    Storage: new CookieStorage({ domain: window.location.hostname }),
  };

  return new CognitoUser(userData);
};

export const authenticateUser = async (username: string, password: string) => {
  const user = getCognitoUser(username);
  user.setAuthenticationFlowType("USER_PASSWORD_AUTH");

  return new Promise<void>((resolve, reject) => {
    user.authenticateUser(
      new AuthenticationDetails({ Username: username, Password: password }),
      {
        onSuccess: function () {
          resolve();
        },
        onFailure: function (err) {
          reject(err);
        },
        newPasswordRequired: function() {
          const error = new Error('Please reset your password before login');
          reject(error);
        }
      }
    );
  }).catch(error => {
    throw error;
  });
};

export const resetPassword = (
  oldPassword: string,
  newPassword: string
) => {
  return new Promise<void>((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      return reject(new Error("User not authenticated"));
    }

    authenticateUserSession(cognitoUser)
      .then(() => changeUserPassword(cognitoUser, oldPassword, newPassword))
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const authenticateUserSession = (cognitoUser: CognitoUser): Promise<void> => {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession(
      (err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }
    );
  });
};

export const changeUserPassword = (
  cognitoUser: CognitoUser,
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(oldPassword, newPassword, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const confirmUser = (userName: string, password: string) => {
  getCognitoUser(userName).confirmRegistration(
    password,
    true,
    function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));

        return;
      }
    }
  );
};

export const getAccessToken = async () => {
  return new Promise<string>((resolve, reject) => {
    userPool
      .getCurrentUser()
      ?.getSession(async (err: Error, session: CognitoUserSession | null) => {
        if (err) {
          logoutUser().then(() => {
            router.push({
              name: "login",
            });
          });
          reject(err);

          return;
        }

        if (session) {
          if (!session.isValid()) {
            try {
              const newAccessToken = await refreshAccessToken();
              resolve(newAccessToken);
            } catch (error) {
              reject(error);
            }
          } else {
            resolve(session.getAccessToken().getJwtToken());
          }
        }
        reject(new Error("No user session exists"));
      });
  });
};

export const logoutUser = () => {
  const currentUser = userPool.getCurrentUser();


  return new Promise((resolve, reject) => {
    try {
      const authStore = useAuthUserStore();
      const userStore = useUserStore();
      localStorage.clear();
      authStore.$reset();
      userStore.$reset();
      sessionStorage.clear();
      clearCookies();
      currentUser?.signOut();
      userSessionEventBus.emit({ event: "LOG_OUT" , user: userStore.user});
      resolve(
        "User has been successfully logged out and cookies have been cleared."
      );
    } catch (error) {
      reject("An error occurred during logout: " + error);
    }
  });
};

const clearCookies = () => {
  const cookies = document.cookie.split(';');
  forEach(cookies, (cookie) => {
    const [name] = cookie.split('=');
    document.cookie = `${name.trim()}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
};

let currentUsername: string;

export const submitForgotPasswordRequest = (username: string) => {
  currentUsername = username;

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise<void>((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function () {
        resolve();
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

export const completePasswordReset = (
  verificationCode: string,
  newPassword: string
) => {
  const username = currentUsername;

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise<void>((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: function () {
        resolve();
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

export const resetPasswordWithTemporaryPassword = async (
  username: string,
  temporaryPassword: string,
  newPassword: string
) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: temporaryPassword,
    });

    const cognitoUser = getCognitoUser(username);

    await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: resolve,
        onFailure: (error) => {
          reject(error);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          delete userAttributes.email_verified;
          handleNewPassword(cognitoUser, userAttributes, newPassword)
            .then(resolve)
            .catch(reject);
        },
      });
    });

};

const handleNewPassword = async (
  cognitoUser: CognitoUser,
  sessionUserAttributes: any,
  newPassword: string
) => {
    await new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {
          name: sessionUserAttributes.name,
          given_name: sessionUserAttributes.given_name,
          family_name: sessionUserAttributes.family_name,
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        }
      );
    });
};

export const refreshAccessToken = async () => {
  return new Promise<string>((resolve, reject) => {

    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      return reject(new Error("No user is currently logged in."));
    }

    const refreshToken = chain(document.cookie)
      .split(';')
      .find(cookie => trim(cookie).startsWith('CognitoIdentityServiceProvider') && includes(cookie, '.refreshToken'))
      .split('=')
      .last()
      .value();

    if (!refreshToken) {
      return reject(new Error("Refresh token not found in cookies."));
    }

    const refreshCognitoToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

    cognitoUser.refreshSession(refreshCognitoToken, (error, session) => {

      const accessToken = session.getAccessToken().getJwtToken();
      resolve(accessToken);
      reject(error);
    });
  });
};
